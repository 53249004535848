/* eslint-disable no-var */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { good178S_store } from '~/pages/good178_stock/_private/good178S_store';
/** Heikin_ashi */
export const stock_position_kbar = createIndicator({
    displayName: '價格趨勢',
    id: 'stockpositionkbar',
    filterOnTheme: 'dark',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const userPosition = good178S_store.usedStrategyTrend;
            const donchian = (e) => {
                var t = this._context.new_var(this.PineJS.Std.low(this._context)), i = this._context.new_var(this.PineJS.Std.high(this._context)), o = this.PineJS.Std.avg(this.PineJS.Std.lowest(t, e, this._context), this.PineJS.Std.highest(i, e, this._context));
                return o;
            };
            const f_1 = () => {
                const e = 7; //this._input(0)
                const t = 29; //this._input(1)
                const i = 47; //this._input(2)
                const o = 13; //this._input(3)
                const r = donchian(e);
                const s = donchian(t);
                const a = this.PineJS.Std.avg(r, s);
                const l = donchian(i);
                const c = this.PineJS.Std.close(this._context);
                const u = this.PineJS.Std.gt(a, l) ? 0 : 1;
                return [r, s, c, a, l, -o, o, o, u];
            };
            const len = 1;
            let pv0 = NaN;
            let pv1 = NaN;
            const pvflag = this._context.new_var();
            let pb1 = NaN;
            let pb2 = NaN;
            let pb3 = NaN;
            let pb4 = NaN;
            let pb5 = NaN;
            let ps1 = NaN;
            let ps2 = NaN;
            let ps3 = NaN;
            let ps4 = NaN;
            let ps5 = NaN;
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close = this.PineJS.Std.close(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const resolution = context.symbol.period;
            const r = f_1();
            const r0 = this._context.new_var(r[0]);
            const r1 = this._context.new_var(r[1]);
            const r2 = this._context.new_var(r[2]);
            const r3 = this._context.new_var(r[3]);
            const r4 = this._context.new_var(r[4]);
            const ichi_mm = r1.get(0);
            const ichi_ll = Math.min(r3.get(13), r4.get(13));
            const ichi_hh = Math.max(r3.get(13), r4.get(13));
            const entryPrice = this._context.new_var();
            //商品數值 開高低收Array
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const close_array = this._context.new_var(close);
            const itime_array = this._context.new_var(itime);
            var hh = this._context.new_var(this.PineJS.Std.highest(this._context.new_var(high), len, this._context));
            var ll = this._context.new_var(this.PineJS.Std.lowest(this._context.new_var(low), len, this._context));
            var bState = this._context.new_var();
            var bDt = this._context.new_var(); //紀錄部位成立的時間
            var pvDt = this._context.new_var(); //紀錄停利訊號成立的時間
            var holding = this._context.new_var();
            var localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const kbarLoadingTimeMs = 10000;
            const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            itime_array.get(1);
            low_array.get(1);
            high_array.get(1);
            bDt.get(0);
            bDt.get(1);
            pvDt.get(0);
            pvDt.get(1);
            bState.get(1);
            bState.get(2);
            pvflag.get(1);
            pvflag.get(2);
            //Entry High Entry Low-------------------------------------------------------------------
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            //部位進場-------------------------------------------------------------------
            if (close > ichi_mm && close > ichi_hh) {
                bState.set(1);
            }
            if (close < ichi_mm && close < ichi_ll) {
                bState.set(-1);
            }
            //部位進場與成立訊號-------------------------------------------------------------------
            if (userPosition !== 'short' && bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
                bDt.set(itime);
                entryPrice.set(close);
                pvflag.set(0);
                holding.set(1);
            }
            if (userPosition !== 'long' && bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
                bDt.set(itime);
                entryPrice.set(close);
                pvflag.set(0);
                holding.set(-1);
            }
            //單一方向策略部位歸零
            if (userPosition === 'long' && bState.get(1) === 1 && close < ichi_mm && close < ichi_ll) {
                bState.set(0);
                entryPrice.set(0);
                holding.set(0);
            }
            if (userPosition === 'short' && bState.get(1) === -1 && close > ichi_mm && close > ichi_hh) {
                bState.set(0);
                entryPrice.set(0);
                holding.set(0);
            }
            //根據股票面額返回相對應的停利點數
            const targetValue = (l1, l2, l3, l4, l5) => {
                if (close < 10)
                    return l1;
                if (close < 50)
                    return l2;
                if (close < 100)
                    return l3;
                if (close < 500)
                    return l4;
                if (close < 5000)
                    return l5;
            };
            if (resolution === 'D') {
                var p1 = targetValue(0.03, 0.03, 0.04, 0.06, 0.08) ?? 0.003;
                var p2 = targetValue(0.06, 0.06, 0.07, 0.09, 0.11) ?? 0.005;
                var p3 = targetValue(0.1, 0.1, 0.11, 0.14, 0.16) ?? 0.01;
                var p4 = targetValue(0.15, 0.15, 0.16, 0.19, 0.21) ?? 0.015;
                var p5 = targetValue(0.21, 0.21, 0.22, 0.26, 0.27) ?? 0.02;
            }
            else {
                p1 = 0.05;
                p2 = 0.1;
                p3 = 0.15;
                p4 = 0.25;
                p5 = 0.35;
            }
            //多單出場-------------------------------------------------------------------
            if (userPosition !== 'short' && bState.get(0) === 1) {
                if (pvflag.get(0) === 0 && high > entryPrice * (1 + p1)) {
                    pvflag.set(1);
                    pb1 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 1 && high > entryPrice * (1 + p2)) {
                    pvflag.set(2);
                    pb2 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 2 && high > entryPrice * (1 + p3)) {
                    pvflag.set(3);
                    pb3 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 3 && high > entryPrice * (1 + p4)) {
                    pvflag.set(4);
                    pb4 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 4 && high > entryPrice * (1 + p5)) {
                    pvflag.set(5);
                    pb5 = 1;
                    pvDt.set(itime);
                    //bState.set(0)
                    //holding.set(0)
                }
            }
            //空單出場-------------------------------------------------------------------
            if (userPosition !== 'long' && bState.get(0) === -1) {
                if (pvflag.get(0) === 0 && low < entryPrice * (1 - p1)) {
                    pvflag.set(1);
                    ps1 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 1 && low < entryPrice * (1 - p2)) {
                    pvflag.set(2);
                    ps2 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 2 && low < entryPrice * (1 - p3)) {
                    pvflag.set(3);
                    ps3 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 3 && low < entryPrice * (1 - p4)) {
                    pvflag.set(4);
                    ps4 = 1;
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 4 && low < entryPrice * (1 - p5)) {
                    pvflag.set(5);
                    ps5 = 1;
                    pvDt.set(itime);
                    //bState.set(0)
                    //holding.set(0)
                }
            }
            const display_color = () => {
                if (holding.get(0) === 1)
                    return 1;
                else if (holding.get(0) === -1)
                    return -1;
                else
                    return 0;
            };
            return [NaN, open, high, low, close, display_color()];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ffff',
                },
            },
            ohlcPlots: {
                plotcandle_0: {
                    borderColor: '#ffffff',
                    color: '#000000',
                    drawBorder: false,
                    drawWick: true,
                    plottype: 'ohlc_candles',
                    visible: true,
                    wickColor: '#737375',
                },
            },
            palettes: {
                palette_0: {
                    colors: [{ color: '#ff2200' }, { color: '#22ff22' }, { color: '#aaaaaa' }],
                },
            },
            inputs: { in_0: 60 },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'ohlc_open',
                target: 'plotcandle_0',
            },
            {
                id: 'plot_2',
                type: 'ohlc_high',
                target: 'plotcandle_0',
            },
            {
                id: 'plot_3',
                type: 'ohlc_low',
                target: 'plotcandle_0',
            },
            {
                id: 'plot_4',
                type: 'ohlc_close',
                target: 'plotcandle_0',
            },
            {
                id: 'plot_5',
                type: 'ohlc_colorer',
                palette: 'palette_0',
                target: 'plotcandle_0',
            },
        ],
        styles: {
            plot_0: {
                title: 'ma',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        palettes: {
            palette_0: {
                colors: [{ name: 'Up Color' }, { name: 'Down Color' }],
                valToIndex: {
                    '1': 0,
                    '-1': 1,
                    '0': 2,
                },
            },
        },
        ohlcPlots: {
            plotcandle_0: {
                title: 'Candles',
            },
        },
        is_price_study: !1,
        inputs: [
            {
                id: 'in_0',
                name: '均線length',
                defval: 60,
                type: 'integer',
                min: 1,
                max: 2e3,
            },
        ],
        scriptIdPart: '',
    },
});
export const stock_position_kbar_light = stock_position_kbar.duplicate({
    id: 'stockpositionkbar--light',
    filterOnTheme: 'light',
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#00ffff',
                },
            },
            ohlcPlots: {
                plotcandle_0: {
                    borderColor: '#ffffff',
                    color: '#000000',
                    wickColor: '#737375',
                },
            },
            palettes: {
                palette_0: {
                    colors: [{ color: '#ff2200' }, { color: '#22aa22' }, { color: '#888888' }],
                },
            },
        },
    },
});
