import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 成交量 */
export const wu5868_volume = createIndicator({
    displayName: '成交量',
    id: 'wu5868-volume',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const std = this._input(0);
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const volume = this.PineJS.Std.volume(this._context);
            const symbol = this.PineJS.Std.ticker(this._context);
            const resultVolume = symbol.includes('TSEA') ? volume / 10e7 : volume;
            const volume_array = this._context.new_var(resultVolume);
            const volma1 = this.PineJS.Std.sma(volume_array, 5, this._context);
            const volma2 = this.PineJS.Std.sma(volume_array, 10, this._context);
            const volma3 = this.PineJS.Std.sma(volume_array, 270, this._context);
            const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1;
            return [resultVolume, volma1, volma2, volma3, volma3 * std, colorIndex];
        },
    },
    metainfo: {
        precision: 0,
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '量',
                    },
                    1: {
                        name: '量',
                    },
                },
            },
        },
        defaults: {
            styles: {
                //--
                plot_0: {
                    transparency: 0,
                    visible: !0,
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: false,
                    color: '#ff55ff',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: false,
                    color: '#ffcc00',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: true,
                    color: '#ff8800',
                },
                plot_4: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: true,
                    color: '#ff2200',
                },
                //--
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 10,
                    visible: true,
                    color: '#02aa22',
                },
            },
            inputs: {
                std: 2,
            },
            //填充區域
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#cf304a',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#02aa22',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            vol: {
                title: '成交量',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '5均量',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '10均量',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: '270均量',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_4: {
                title: '1.5倍',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [
            {
                id: 'std',
                name: '成交量倍數',
                defval: 1,
                type: 'float',
                min: 0.1,
                max: 10,
                step: 0.1,
            },
        ],
        scriptIdPart: '',
    },
});
