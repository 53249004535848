import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const daddy960_revive_cci = createIndicator({
    id: 'daddy960-revive-cci',
    displayName: '超級CCI',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const length = 14; //this._input(0)
            const cci = context.new_var(this.cci(length));
            cci.get(100);
            const cross200 = cci.get(0) > 200 && cci.get(1) <= 200 ? 200 : NaN;
            const cross100 = cci.get(0) > 100 && cci.get(1) <= 100 ? 100 : NaN;
            const cross100_ = cci.get(0) > -100 && cci.get(1) <= -100 ? -100 : NaN;
            const cross200_ = cci.get(0) > -200 && cci.get(1) <= -200 ? -200 : NaN;
            return [cci.get(0), 200, 100, -100, -200, cross200, cross100, cross100_, cross200_];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'cci', type: 'line' },
            { id: 'threshold200', type: 'line' },
            { id: 'threshold100', type: 'line' },
            { id: 'threshold100_', type: 'line' },
            { id: 'threshold200_', type: 'line' },
            { id: 'cross200', type: 'line' },
            { id: 'cross100', type: 'line' },
            { id: 'cross100_', type: 'line' },
            { id: 'cross200_', type: 'line' },
        ],
        defaults: {
            styles: {
                cci: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#fdf188',
                },
                threshold200: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
                threshold100: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#00ff00',
                },
                threshold100_: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#13cbe7',
                },
                threshold200_: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ffff00',
                },
                cross200: {
                    linestyle: 0,
                    linewidth: 7,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
                cross100: {
                    linestyle: 0,
                    linewidth: 7,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
                cross100_: {
                    linestyle: 0,
                    linewidth: 7,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
                cross200_: {
                    linestyle: 0,
                    linewidth: 7,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
            },
            precision: 2,
        },
        styles: {
            cci: {
                title: 'CCI',
                histogramBase: 0,
                joinPoints: false,
            },
            threshold200: {
                title: '200',
                histogramBase: 0,
                joinPoints: false,
            },
            threshold100: {
                title: '100',
                histogramBase: 0,
                joinPoints: false,
            },
            threshold100_: {
                title: '-100',
                histogramBase: 0,
                joinPoints: false,
            },
            threshold200_: {
                title: '-100',
                histogramBase: 0,
                joinPoints: false,
            },
            cross200: {
                title: 'cross100',
                histogramBase: 0,
                joinPoints: false,
            },
            cross100: {
                title: 'cross100',
                histogramBase: 0,
                joinPoints: false,
            },
            cross100_: {
                title: 'cross100_',
                histogramBase: 0,
                joinPoints: false,
            },
            cross200_: {
                title: 'cross200_',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
