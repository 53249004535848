import { gorich55688_dailyHighLow5 } from '~/trades/indicators/gorich55688/gorich55688_dailyHighLow';
import { gorich55688_kd } from '~/trades/indicators/gorich55688/gorich55688_kd';
import { gorich55688_ma } from '~/trades/indicators/gorich55688/gorich55688_ma';
import { gorich55688_stockForeignDiff } from '~/trades/indicators/gorich55688/gorich55688_stockForeignDiff';
import { gorich55688_stockSignal } from '~/trades/indicators/gorich55688/gorich55688_stockSignal';
import { gorich55688_stockTrustDiff } from '~/trades/indicators/gorich55688/gorich55688_stockTrustDiff';
import { gorich55688_twseForeignDiff } from '~/trades/indicators/gorich55688/gorich55688_twseForeignDiff';
import { gorich55688_twsePatterns } from '~/trades/indicators/gorich55688/gorich55688_twsePatterns';
import { gorich55688_twseTimePrice } from '~/trades/indicators/gorich55688/gorich55688_twseTimePrice';
import { gorich55688_twseTrustDiff } from '~/trades/indicators/gorich55688/gorich55688_twseTrustDiff';
import { gorich55688_signal_ma } from '~/trades/indicators/gorich55688/gorich55688_signal_ma';
import { store } from '../heineken_template/_private/store';
import { gorich55688_volume } from '~/trades/indicators/gorich55688/gorich55688_volume';
import { gorich55688_stockSignal2 } from '~/trades/indicators/gorich55688/gorich55688_stockSignal2';
//加權XX16式
export const gorich55688_indiceIndicatorsMinute = [
    gorich55688_twseTimePrice,
    gorich55688_twsePatterns,
    gorich55688_volume,
];
//日K買訊
export const gorich55688_stockIndicatorsDaily = [
    gorich55688_stockSignal,
    gorich55688_signal_ma,
    gorich55688_kd,
    gorich55688_stockForeignDiff,
    gorich55688_stockTrustDiff,
    gorich55688_volume,
];
//日K買訊2
export const gorich55688_stockIndicatorsDaily2 = [
    gorich55688_stockSignal2,
    gorich55688_signal_ma,
    gorich55688_kd,
    gorich55688_stockForeignDiff,
    gorich55688_stockTrustDiff,
    gorich55688_volume,
];
//候補
export const gorich55688_stockIndicatorsMinute = [gorich55688_dailyHighLow5];
export const gorich55688_indiceIndicatorsDaily = [
    gorich55688_ma,
    gorich55688_kd,
    gorich55688_twseForeignDiff,
    gorich55688_twseTrustDiff,
];
export const gorich55688_strategyGroup = {
    main: [
        {
            displayName: '控盤十六式',
            indicators: gorich55688_indiceIndicatorsMinute,
            symbol: 'TSEA',
            interval: '5',
            panesRatio: 66,
        },
        {
            displayName: '日K買訊1',
            indicators: gorich55688_stockIndicatorsDaily,
            symbol: '2330',
            interval: 'D',
            panesRatio: 66,
        },
        {
            displayName: '日K買訊2',
            indicators: gorich55688_stockIndicatorsDaily2,
            symbol: '2330',
            interval: 'D',
            panesRatio: 66,
        },
        // {
        //   displayName: '選股區間',
        //   indicators: gorich55688_stockIndicatorsDaily,
        //   symbol: '2330',
        //   interval: 'D',
        //   panesRatio: 66,
        // },
    ],
};
export const gorich55688_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...gorich55688_indiceIndicatorsDaily,
        ...gorich55688_indiceIndicatorsMinute,
        ...gorich55688_stockIndicatorsDaily,
        ...gorich55688_stockIndicatorsMinute,
    ];
    store.charting.initStrategy({
        configs: [...gorich55688_strategyGroup.main],
    });
    store.charting.changeSymbol('TSEA');
};
