import { memo, useState } from 'react';
import { SymbolList } from './symbolList';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useInterval } from 'react-use';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
export const ConditionLong = memo(function ConditionLong() {
    const intraDay = useSnapshot(staticStore).tradedDate.intraday;
    const [flag, setFlag] = useState(0);
    const data = useDailyRankResource({
        date: intraDay.format('YYYY-MM-DD'),
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 5e8,
        amount_lte: 0,
        order_by: 'desc',
        sort_by: 'change_percent',
    }, flag);
    useInterval(() => {
        setFlag(flag + 1);
    }, 30000);
    const symbol = data.data
        ?.filter(datum => datum.change_percent > 0)
        .map(datum => datum.symbol)
        .slice(0, 5) ?? [];
    return (<SymbolList symbol={symbol} titleFillColor='#c85d4a'>
      最新飆股
    </SymbolList>);
});
