import { css } from '@emotion/react';
import { memo } from 'react';
import { ThemeToggle } from '~/components/theme/ThemeToggle';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { store } from '~/pages/heineken_template/_private/store';
import useMedia from '~/hooks/useMedia';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import styled from '@emotion/styled';
export const Good178_TopBar = memo(function Good178_TopBar() {
    const theme = useThemeStore(s => s.theme);
    const { isPc } = useMedia();
    const topbarColor = theme === 'dark' ? '#262a37' : '#eaeaea';
    return (<styleds.container fill={topbarColor}>
      {isPc && (<img src='good178/favicon.png' css={css `
            height: 48px;
          `}/>)}
      {isPc && (<styleds.desktopTop>
          <ThemeToggle onClick={event => {
                store.charting.setThemeMode(useThemeStore.getState().theme);
                store.charting.updateFromThemeMode();
                store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators);
            }}/>
          <UserAvatarAsDialogButton />
          <ChartingServerSwitch charting={store.charting}/>
        </styleds.desktopTop>)}

      {!isPc && (<Preset_Topbar showLeftBurger componentsInRight={<div css={fill_horizontal_cross_center}>
              <ThemeToggle onClick={event => {
                    store.charting.setThemeMode(useThemeStore.getState().theme);
                    store.charting.updateFromThemeMode();
                    store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators);
                }}/>
              <ChartingServerSwitch charting={store.charting}/>
              <UserAvatarAsDialogButton />
            </div>}></Preset_Topbar>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    background-color: ${props => props.fill};
    padding: 0 8px;
  `,
    desktopTop: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
  `,
};
