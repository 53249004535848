import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 多空分界 */
export const s178178_signal = createIndicator({
    displayName: '訊號',
    id: 's178178signal',
    enabledOn(symbol, data, channel) {
        return data?.type === channel.tw_futures || data?.type === channel.tw_stocks_50;
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol_mode = this._input(0);
            const type = symbol_mode === 1 ? '#bs_indicator_acc' : '#foreigner_twse';
            const symbol = ticker + type;
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const symbol_mode = this._input(0);
            //奇妙的多商品code------------
            const i = this._context.new_unlimited_var(this._context.symbol.time);
            this.PineJS.Std.period(this._context);
            const close = this.PineJS.Std.close(this._context); // <=這是商品本身
            const itime = this.PineJS.Std.time(this._context);
            this._context.select_sym(1);
            const s = this._context.new_unlimited_var(this._context.symbol.time);
            const a = symbol_mode === 1
                ? this.PineJS.Std.high(this._context)
                : this.PineJS.Std.open(this._context) / 1e7;
            const l = this._context.new_unlimited_var(a);
            this._context.select_sym(0);
            const value = l.adopt(s, i, 0); // <=這是籌碼
            const value_array = this._context.new_var(value);
            value_array.get(1000);
            //奇妙的多商品code------------
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, 20, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, 200, this._context);
            const sma1_array = this._context.new_var(sma1);
            const sma2_array = this._context.new_var(sma2);
            sma1_array.get(1);
            sma1_array.get(2);
            sma2_array.get(1);
            sma2_array.get(2);
            const long = close > sma1 && close > sma2 && sma1 > sma2 && sma1_array.get(1) > sma1_array.get(2);
            const short = close < sma1 && close < sma2 && sma1 < sma2; //&& sma1_array.get(1) < sma1_array.get(2)
            //---------------------------
            const isCheckDay = this.isSettlementDayDailyKbar();
            const valueAcc = this._context.new_var();
            valueAcc.get(1);
            if (isCheckDay) {
                valueAcc.set(value);
            }
            else {
                valueAcc.set(valueAcc.get(1) + value);
            }
            const line = this.PineJS.Std.sma(valueAcc, 2, this._context);
            const line_array = this._context.new_var(line);
            line_array.get(1);
            line_array.get(2);
            line_array.get(3);
            const condition_buy = line_array.get(1) < 0 &&
                line_array.get(0) > 300 &&
                line_array.get(2) < 0 &&
                line_array.get(3) < 0 &&
                long;
            const condition_short = line_array.get(1) > 0 && line_array.get(0) < -1000 && short;
            //---------------------------
            const marketposition = this._context.new_var();
            marketposition.get(1);
            marketposition.get(2);
            let buy_icon = NaN;
            let short_icon = NaN;
            if (condition_buy) {
                marketposition.set(1);
            }
            if (condition_short) {
                marketposition.set(-1);
            }
            if ((marketposition.get(0) === 1 && close < sma2) ||
                (line_array.get(1) < 0 && line_array.get(0) < 0)) {
                marketposition.set(0);
            }
            if ((marketposition.get(0) === -1 && close > sma2) ||
                (line_array.get(1) > 0 && line_array.get(0) > 0)) {
                marketposition.set(0);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
            }
            return [buy_icon, short_icon];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
            },
            //樣式調整
            inputs: {
                in_0: 0,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: '模式: 外資動向[0] / 大小單[1]',
                defval: 0,
                type: 'integer',
                min: 0,
                max: 1,
            },
        ],
        scriptIdPart: '',
    },
});
