import { css } from '@emotion/react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const goldbrainStock_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    ${FuiButton.classes.button.Root} {
      ${flex.h.allCenter};
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      font-size: 18px;
    }

    ${FuiButton.classes.button.default.Default} {
      background-color: rgb(238, 238, 238);
      border: 1px solid rgb(90, 118, 133);
      color: rgb(51, 51, 51);
    }

    ${FuiButton.classes.button.default.Active} {
      background-color: rgb(221, 221, 221);
      border: 1px solid rgb(90, 118, 133);
      color: rgb(51, 51, 51);
    }
  `;
    store.charting.lightOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#00aa00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#cc0000',
        'mainSeriesProperties.candleStyle.downColor': '#00aa00',
        'mainSeriesProperties.candleStyle.upColor': '#cc0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
        'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#444444',
        'paneProperties.horzGridProperties.color': '#444444',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#ffffff',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#333333',
        'scalesProperties.lineColor': '#333333',
    };
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#00aa00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#cc0000',
        'mainSeriesProperties.candleStyle.downColor': '#00aa00',
        'mainSeriesProperties.candleStyle.upColor': '#cc0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
        'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#444444',
        'paneProperties.horzGridProperties.color': '#444444',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#141721',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#eeeeee',
        'scalesProperties.lineColor': '#eeeeee',
    };
};
