import styled from '@emotion/styled';
import React, { memo, useEffect } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSnapshot } from 'valtio';
import { store } from '../heineken_template/_private/store';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { IndicatorsCard } from './component/IndicatorsCard';
import { PriceChangeCard } from './component/PriceChangeCard';
import { TrendCard } from './component/TrendCard';
import { PriceDiffCard } from './component/PriceDiffCard';
import { VolumeCard } from './component/VolumeCard';
import { OpenInterestCard } from './component/OpenInterestCard';
import { apirc } from '~/configs/apirc';
import dayAPI from '~/utils/dayAPI';
import { monthToFuturesContract } from './component/useSgxSymbolData';
import { sgxIndicatorsValueStorage } from './sgx_store';
import { Select } from '@mantine/core';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
const generateMonthArray = (currentMonth) => {
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1; // 處理1月的情况
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1; // 處理12月的情况
    const result = [
        { value: prevMonth.toString(), label: prevMonth + '月' },
        { value: currentMonth.toString(), label: currentMonth + '月' },
        { value: nextMonth.toString(), label: nextMonth + '月' },
    ];
    return result;
};
export const Sgx_DataCard = memo(function Sgx_DataCard() {
    const { isPc } = useMedia();
    const state = useSnapshot(sgxIndicatorsValueStorage);
    const charting = useSnapshot(store.charting);
    //----商品
    const symbol = charting.symbol.replace('-1', '');
    const date1 = dayAPI().set('month', state.month1 - 1); //sgxIndicatorsValueStorage 有+1
    const date2 = date1.add(1, 'month');
    //----年份
    const year1 = date1.format('YY');
    const year2 = date2.format('YY');
    //----近月月份
    const month1 = date1.format('MM');
    const month1Code = monthToFuturesContract(month1);
    //----遠月月份
    const month2 = date2.format('MM');
    const month2Code = monthToFuturesContract(month2);
    //----獲取API
    const sgxData = apirc.marketDataAPI.sgxSymbolData.useSWR({
        symbol: symbol,
        from: dayAPI().subtract(30, 'day').unix(),
        to: dayAPI().unix(),
    }).data;
    //先過濾非日盤時段
    const tradingSessionData = sgxData?.filter(s => s.trading_session === 0);
    //時間排序 最新在前面
    const sortDateData = tradingSessionData?.sort((a, b) => dayAPI(a.date).unix() - dayAPI(b.date).unix());
    const symbol1 = symbol + month1Code + year1;
    const symbol2 = symbol + month2Code + year2;
    useEffect(() => {
        sgxIndicatorsValueStorage.symbol1 = symbol1;
        sgxIndicatorsValueStorage.symbol2 = symbol2;
    }, [symbol1, symbol2]);
    //近月
    const data1 = sortDateData?.filter(s => s.symbol === symbol1);
    //遠月
    const data2 = sortDateData?.filter(s => s.symbol === symbol2);
    //
    const data3 = data1?.map(datum1 => {
        const matchingDatum2 = data2?.find(datum2 => datum2.date === datum1.date);
        const close1 = datum1.close ?? null;
        const close2 = matchingDatum2 ? matchingDatum2.close ?? null : null;
        const diff = close1 !== null && close2 !== null ? close1 - close2 : 0;
        const volume1 = datum1.volume ?? null;
        const volume2 = matchingDatum2 ? matchingDatum2.volume ?? null : null;
        const openInterest1 = datum1.open_interest ?? null;
        const openInterest2 = matchingDatum2 ? matchingDatum2.open_interest ?? null : null;
        return {
            date: datum1.date,
            symbol1: datum1.symbol,
            symbol2: data2?.map(s => s.symbol)[0] ?? '',
            close1: close1,
            close2: close2,
            diff: diff,
            volume1: volume1,
            volume2: volume2,
            openInterest1: openInterest1,
            openInterest2: openInterest2,
        };
    });
    return (<styleds.container>
      <styleds.title>
        <div css={fill_horizontal_cross_center}>
          <SymbolName symbol={charting.symbol}/> - 商品訊息
        </div>
        <styleds.contractAdjust>
          <styleds.contractContent>
            <span>主合約</span>
            <Select css={css `
                width: 64px;
                .mantine-1cn2mlo {
                  padding-right: 0;
                  padding-left: 16px;
                }
                .mantine-10iuxpt {
                  padding-right: 0;
                }
              `} placeholder='主合約月份' defaultValue={state.month1.toString()} data={generateMonthArray(dayAPI().month() + 1)} onChange={value => {
            sgxIndicatorsValueStorage.month1 = Number(value);
        }}/>
          </styleds.contractContent>
          <styleds.contractContent>
            <span>次合約</span>
            <Select css={css `
                width: 56px;
              `} readOnly placeholder='次合約月份' defaultValue={(state.month1 + 1).toString()} value={(state.month1 + 1).toString()} data={generateMonthArray(state.month1 + 1)}/>
          </styleds.contractContent>
        </styleds.contractAdjust>
      </styleds.title>
      <styleds.cardCintent isPc={isPc}>
        <IndicatorsCard />
        <TrendCard />
        <PriceChangeCard />
        <PriceDiffCard data={data3 ?? []}/>
        <VolumeCard data={data3 ?? []}/>
        <OpenInterestCard data={data3 ?? []}/>
      </styleds.cardCintent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    border-top: 1px solid #e0e4ea;
  `,
    cardCintent: styled.div `
    display: grid;
    width: 100%;
    grid-template-columns: ${props => props.isPc ? 'calc(50% - 4px) calc(50% - 4px)' : 'calc(100% - 4px)'};
    grid-gap: 4px;
  `,
    title: styled.div `
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    color: #112267;
  `,
    contractAdjust: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
  `,
    contractContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 40px;
    width: auto;
    gap: 4px;
    padding: 0 4px;
  `,
};
export const sgxClasses = {
    card: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: ${props => props.height}px;
    //background-color: #fafafa;
    border: 1px solid #acacac;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    gap: 4px;
  `,
    title: styled.div `
    ${fill_horizontal_cross_center};
    height: 30px;
    font-weight: 600;
    font-size: 16.8px;
    color: #112267;
  `,
    subTitle: styled.div `
    ${fill_horizontal_cross_center};
    height: 30px;
    font-weight: 600;
    color: #252525;
    font-size: 15px;
  `,
};
