/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { edwin_store } from '~/pages/edwin/edwin_store';
import dayAPI from '~/utils/dayAPI';
export const edwin_screener_mark = createIndicator({
    id: 'date-mark',
    displayName: '選股日',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const dateTime = this.PineJS.Std.time(this._context);
            const date = dayAPI(dateTime).format('YYYY-MM-DD');
            const screenreDate = edwin_store.screenerDate;
            const plot = date === screenreDate ? 10000 : NaN;
            return [plot];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [{ id: 'plot_0', type: 'histogram' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 1,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 40,
                    color: '#ffffff',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '日期',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
