import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
export const south_strategies = [
    {
        id: 'RT_south_01',
        description: '相對強弱',
        config: {
            displayName: 'SOUTH_1',
            query: SignalWallId.SOUTH_1,
            enabled: true,
        },
    },
    {
        id: 'RT_south_02',
        description: '量大量小',
        config: {
            displayName: 'SOUTH_2',
            query: SignalWallId.SOUTH_2,
            enabled: true,
        },
    },
    {
        id: 'RT_south_03',
        description: '反彈空',
        config: {
            displayName: 'SOUTH_3',
            query: SignalWallId.SOUTH_3,
            enabled: true,
        },
    },
    {
        id: 'RT_south_04',
        description: '出量弱勢',
        config: {
            displayName: 'SOUTH_4',
            query: SignalWallId.SOUTH_4,
            enabled: true,
        },
    },
    {
        id: 'RT_south_05',
        description: '反空A轉',
        config: {
            displayName: 'SOUTH_5',
            query: SignalWallId.SOUTH_5,
            enabled: true,
        },
    },
    {
        id: 'RT_south_06',
        description: '出量強勢攻擊',
        config: {
            displayName: 'SOUTH_6',
            query: SignalWallId.SOUTH_6,
            enabled: true,
        },
    },
];
