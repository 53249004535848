import styled from '@emotion/styled';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import KbarSymbolQuote from '../quote/KbarSymbolQuote';
import { edwinGroupName } from '../WatchListGroupName';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useMount } from 'react-use';
export const RankingContent = memo(function RankingContent(props) {
    const value = props.quoteValue;
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'amount';
    });
    return (<styleds.container>
        <styleds.header>
          <KbarSymbolQuote.header />
        </styleds.header>
        <styleds.body>
          {value?.map((quote, index) => {
            return (<KbarSymbolQuote.body groupName={edwinGroupName} key={index} quote={quote}/>);
        })}
        </styleds.body>
      </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    header: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 36px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
    heigth: calc(100% - 40px);
  `,
};
