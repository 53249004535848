import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import { memo } from 'react';
import { MdAdd } from 'react-icons/md';
import { VscError } from 'react-icons/vsc';
import { useSnapshot } from 'valtio';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { store } from '~/pages/heineken_template/_private/store';
export const WatchListAddButton = memo(function WatchListAddButton(props) {
    const { state: watchListState1, acts: watchListActs1 } = useSymbolWatchList(props.groupName);
    const charting = useSnapshot(store).charting;
    const hadSymbol = watchListState1.symbolListArray.includes(charting.symbol);
    const stockType = charting.symbol.match(/^\d{4,5}[A-Z]?$/);
    return (<Button css={css `
          width: 64px;
          height: 24px;
          padding-left: 0;
          padding-right: 0;
        `} disabled={!stockType} onClick={() => {
            !hadSymbol
                ? stockType && watchListActs1.addSymbol(charting.symbol, 30)
                : watchListActs1.removeSymbol(charting.symbol);
        }} radius='sm' color={hadSymbol ? 'red' : 'blue'}>
        {hadSymbol ? <VscError size={16}/> : <MdAdd size={16}/>} 自選
      </Button>);
});
