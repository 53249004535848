import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { AgentCarouselSubscriptionCheck } from '~/modules/auth/components/AgentCarouselSubscriptionCheck';
import { Gd1788_Sidebar } from '~/pages/gd1788/_private/gd1788_Sidebar';
import { gd1788_Strategies } from '~/pages/gd1788/_private/gd1788_Strategies';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import col_UserAvatarAndChartingServersSimple from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServersSimple';
export const gd1788_init = (templateProps) => {
    useThemeStore.setState({ theme: 'dark' });
    gd1788_Strategies();
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        overrides: {
            'mainSeriesProperties.candleStyle.upColor': '#ff2000',
            'mainSeriesProperties.candleStyle.downColor': '#00ff0b',
            'scalesProperties.fontSize': 14,
        },
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../gd1788/chartingTheme.css',
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;

    grid-template-areas:
      'Row1 Row1'
      'Drawer1 Chart';

    ${createIPadCss(css `
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = (<Preset_Topbar showLeftBurger componentsInRight={<col_UserAvatarAndChartingServersSimple.Display />}/>);
    templateProps.layout.Drawer1 = Gd1788_Sidebar;
    templateProps.layout.login = (<AgentCarouselSubscriptionCheck resources={[
            { image: [true, '/richadam/login1.png'] },
            { image: [true, '/win168/login1.png'] },
        ]}/>);
};
