import { store } from '~/pages/heineken_template/_private/store';
import { win9882_daytrade_signal_1m_default } from '~/trades/indicators/win988/win9882_daytrade_signal_1m_default';
import { win9882_daytrade_1m_power } from '~/trades/indicators/win988/win9882_daytrade_1m_power';
import { win9882_daytrade_signal_3m_default } from '~/trades/indicators/win988/win9882_daytrade_signal_3m_default';
import { win9882_daytrade_signal_3m_original } from '~/trades/indicators/win988/win9882_daytrade_signal_3m_original';
import { win9882_daytrade_signal_3m } from '~/trades/indicators/win988/win9882_daytrade_signal_3m';
import { win9882_daytrade_trend } from '~/trades/indicators/win988/win9882_daytrade_trend';
import { win988_daytradePower } from '~/trades/indicators/win988/win988_daytradePower';
import { win9882_main_trend } from '~/trades/indicators/win988/win9882_main_trend';
import { win9882_attack_trend } from '~/trades/indicators/win988/win9882_attack_trend';
import { win9882_volume } from '~/trades/indicators/win988/win9882_volume';
import { win9882_trendtrade_signal_15m } from '~/trades/indicators/win988/win9882_trendtrade_signal_15m';
import { win9882_trendtrade_signal_30m_rang } from '~/trades/indicators/win988/win9882_trendtrade_signal_30m_rang';
import { win9882_trendtrade_signal_30m_chips } from '~/trades/indicators/win988/win9882_trendtrade_signal_30m_chips';
import { win9882_trebdtrade_signal_60m } from '~/trades/indicators/win988/win9882_trebdtrade_signal_60m';
import { win9882_trend_power } from '~/trades/indicators/win988/win9882_trend_power';
export const win988_strategiesDayTradeGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '極短當沖',
            indicators: [win9882_daytrade_signal_1m_default, win9882_daytrade_1m_power, win9882_volume],
            symbol: 'TX-1',
            interval: '1',
            panesRatio: 66,
        },
        {
            displayName: '日內當沖',
            indicators: [win9882_daytrade_signal_3m_default, win9882_main_trend, win9882_volume],
            symbol: 'TX-1',
            interval: '3',
            panesRatio: 66,
        },
        {
            displayName: '日盤當沖',
            indicators: [
                win9882_daytrade_signal_3m_original,
                win9882_daytrade_trend,
                win988_daytradePower,
                win9882_volume,
            ],
            symbol: 'TXAM-1',
            interval: '3',
            panesRatio: 66,
        },
        {
            displayName: '逆勢當沖',
            indicators: [win9882_daytrade_signal_3m, win9882_attack_trend, win9882_volume],
            symbol: 'TX-1',
            interval: '3',
            panesRatio: 66,
        },
    ],
};
export const win988_strategiesBandGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '短線波段',
            indicators: [win9882_trendtrade_signal_15m, win9882_volume],
            symbol: 'TX-1',
            interval: '15',
            panesRatio: 66,
        },
        {
            displayName: '區間波段',
            indicators: [win9882_trendtrade_signal_30m_rang, win9882_volume],
            symbol: 'TX-1',
            interval: '30',
            panesRatio: 66,
        },
        {
            displayName: '籌碼波段',
            indicators: [win9882_trendtrade_signal_30m_chips, win9882_trend_power, win9882_volume],
            symbol: 'TX-1',
            interval: '30',
            panesRatio: 66,
        },
        {
            displayName: '長線波段',
            indicators: [win9882_trebdtrade_signal_60m, win9882_volume],
            symbol: 'TX-1',
            interval: '60',
            panesRatio: 66,
        },
    ],
};
export const win988_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded];
    store.charting.initStrategy({
        configs: [...win988_strategiesDayTradeGroup.main, ...win988_strategiesBandGroup.main],
    });
};
