import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { weng888Stock_initStrategies } from '~/pages/weng888_stock/_private/weng888Stock_initStrategies';
import { weng888Stock_initStyling } from '~/pages/weng888_stock/_private/weng888Stock_initStyling';
import { Weng888Stock_Sidebar1 } from '~/pages/weng888_stock/_private/weng888Stock_Sidebar1';
import { Weng888Stock_Sidebar2 } from '~/pages/weng888_stock/_private/weng888Stock_Sidebar2';
import { Weng888Stock_Topbar } from './weng888Stock_Topbar';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const weng888Stock_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    useThemeStore.setState({ theme: 'dark' });
    weng888Stock_initStyling(templateProps);
    weng888Stock_initStrategies();
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: '1D',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledHeaderSaveload: true,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Drawer1 = Weng888Stock_Sidebar1;
    templateProps.layout.Drawer2 = Weng888Stock_Sidebar2;
    templateProps.layout.Row1 = Weng888Stock_Topbar;
    templateProps.hooks.add(useSignalrStart2_0);
};
