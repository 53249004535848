import { store } from '~/pages/heineken_template/_private/store';
import { lung88988_dayTrade } from '~/trades/indicators/lung88988/lung88988_dayTrade';
import { lung88988_dayTradePower } from '~/trades/indicators/lung88988/lung88988_dayTradePower';
import { lung88988_tradeRecon } from '~/trades/indicators/lung88988/lung88988_tradeRecon';
import { lung88988_trendTrade } from '~/trades/indicators/lung88988/lung88988_trendTrade';
export const lung88988_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '順勢當沖',
            indicators: [lung88988_dayTrade, lung88988_dayTradePower, lung88988_tradeRecon],
            symbol: 'TX-1',
            interval: '3',
            panesRatio: [20, 10],
        },
        {
            displayName: '波段',
            indicators: [lung88988_trendTrade, lung88988_dayTradePower, lung88988_tradeRecon],
            symbol: 'TX-1',
            interval: '30',
            panesRatio: [20, 10],
        },
    ],
};
export const lung88988_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[lung88988_dayTrade, lung88988_dayTradePower, lung88988_trendTrade, lung88988_tradeRecon],
    ];
    store.charting.initStrategy({
        configs: [...lung88988_strategiesGroup.main],
    });
};
