import { css } from '@emotion/react';
import { DefaultSimpleQuoteBodyItemClasses } from '~/modules/symbolQuote/simple/SimpleQuoteBodyItem';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
import col_WatchSymbolToggleSingleButton from '../heineken_template/_col/col_WatchSymbolToggleSingleButton';
export const yaya168Stock_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      .${DefaultSimpleQuoteBodyItemClasses.LimitUpAnimation} {
        animation: twinkle 1s infinite alternate;
        @keyframes twinkle {
          0% {
            background-color: #880000ff;
          }
          100% {
            background-color: #88000000;
          }
        }
      }

      ${FuiButton.classes.button.Root} {
        border-radius: 5px;
        height: 32px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #1e1e1e;
        border: 1px solid #555555;
        &:hover {
          background-color: #2f3136;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #545454;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #868686;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
      ${col_WatchSymbolToggleSingleButton.classes.Root} {
        background-color: #165d7d;
      }
    }
  `;
    store.charting.darkOverrides = {
        ...store.charting.darkOverrides,
        'mainSeriesProperties.candleStyle.borderDownColor': '#eeeeee',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff2e26',
        'mainSeriesProperties.candleStyle.downColor': '#eeeeee',
        'mainSeriesProperties.candleStyle.upColor': '#ff2e26',
        'mainSeriesProperties.candleStyle.wickDownColor': '#cccccc',
        'mainSeriesProperties.candleStyle.wickUpColor': '#cccccc',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'timeScale.rightOffset': 5,
        'paneProperties.background': '#111111',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.vertGridProperties.color': '#111111',
        'paneProperties.horzGridProperties.color': '#111111',
        'mainSeriesProperties.showPriceLine': false,
        'paneProperties.legendProperties.showStudyValues': false,
    };
};
