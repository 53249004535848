import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Pionex_Footer } from './pionex_Footer';
import { pionex_initStrategies } from './pionex_initStrategies';
import { pionex_initStyling } from './pionex_initStyling';
import { Pionex_LoginView } from './pionex_LoginView';
import { Pionex_QuoteInfo } from './pionex_QuoteInfo';
import { Pionex_SidePane1 } from './pionex_SidePane1';
import { Pionex_SidePane2 } from './pionex_SidePane2';
import { Pionex_Topbar } from './pionex_Topbar';
export const pionex_init = (templateProps) => {
    pionex_initStyling(templateProps);
    pionex_initStrategies();
    useThemeStore.setState({ theme: 'light' });
    store.charting.setThemeMode(useThemeStore.getState().theme);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'BTCUSDT',
        interval: '5',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 64px calc(100vh - 112px) 256px;
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;
    font-family: HarmonyOS Sans, PingFang TC, Microsoft YaHei, sans-serif;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Row3 Drawer2'
      'Drawer1 Chart Drawer2'
      'Row2 Row2 Row2';

    ${createIPadCss(css `
      grid-template-rows: 48px 64px calc(100vh - 112px) 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 64px calc(100vh - 112px) 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `;
    templateProps.layout.Row1 = Pionex_Topbar;
    templateProps.layout.Drawer1 = Pionex_SidePane1;
    templateProps.layout.Drawer2 = Pionex_SidePane2;
    templateProps.layout.Row2 = Pionex_Footer;
    templateProps.layout.Row3 = Pionex_QuoteInfo;
    templateProps.layout.login = Pionex_LoginView;
};
