import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import QuickFuturesOrderBox from '~/modules/investment-consultant/orderBox/QuickFuturesOrderBox';
import { SymbolListContent } from '~/pages/weng888/weng888_SidePane';
export const Good178_SideBar = memo(function Good178_SideBar() {
    const { isPc } = useMedia();
    const theme = useThemeStore(state => state.theme);
    return (<styleds.container className={theme}>
      <styleds.header className={theme}>商品觀察清單</styleds.header>
      <div css={css `
          width: 100%;
          height: calc(100% - ${isPc ? 232 : 80}px);
        `}>
        <SymbolListContent groupName='good178_group_1'/>
      </div>
      {isPc && <styleds.header className={theme}>國內期貨網頁下單</styleds.header>}
      {isPc && <QuickFuturesOrderBox.Display />}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;

    gap: 8px;
    &.dark {
      background-color: #141721;
      border-right: 2px solid #2b2e38;
    }
    &.light {
      background-color: #fafafa;
      border-right: 2px solid #cacaca;
    }
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    border-radius: 4px;
    &.dark {
      background-color: #1d1f28;
    }
    &.light {
      background-color: #eaeaea;
    }
  `,
};
