import { css } from '@emotion/react';
import { SymbolQuoteInfoClasses } from '~/modules/symbolQuote/information-form';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const imoney8888_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 5px;
        height: 32px;
      }

      .${SymbolQuoteInfoClasses.button} {
        background-color: #41424d;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #404249;
        border: 1px solid #555555;
        &:hover {
          background-color: #51535a;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #6c757d;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #787c7d;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
      ${FuiButton.classes.button.long.Default} {
        height: 32px;
        color: #eeeeee;
        background: linear-gradient(72deg, #aa0000, #cc2292);
        transition: 0.5s;
        &:hover {
          border: 1px solid #ffffff;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
      ${FuiButton.classes.button.short.Default} {
        height: 32px;
        color: #eeeeee;
        background: linear-gradient(72deg, #00aa00, #22cc92);
        transition: 0.5s;
        &:hover {
          border: 1px solid #ffffff;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
      ${FuiButton.classes.button.long.Active} {
        height: 32px;
        color: #eeeeee;
        background: linear-gradient(72deg, #aa0000, #cc2292);
        transition: 0.5s;
        border: 1px solid #ffffff;
      }
      ${FuiButton.classes.button.short.Active} {
        height: 32px;
        color: #eeeeee;
        background: linear-gradient(72deg, #00aa00, #22cc92);
        transition: 0.5s;
        border: 1px solid #ffffff;
      }
    }
  `;
    store.charting.darkOverrides = {
        ...store.charting.darkOverrides,
        'mainSeriesProperties.candleStyle.borderDownColor': '#eeeeee',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff2e26',
        'mainSeriesProperties.candleStyle.downColor': '#eeeeee',
        'mainSeriesProperties.candleStyle.upColor': '#ff2e26',
        'mainSeriesProperties.candleStyle.wickDownColor': '#cccccc',
        'mainSeriesProperties.candleStyle.wickUpColor': '#cccccc',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'timeScale.rightOffset': 5,
        'paneProperties.background': '#15171b',
        'paneProperties.vertGridProperties.color': '#111111',
        'paneProperties.horzGridProperties.color': '#111111',
        'mainSeriesProperties.showPriceLine': false,
    };
};
