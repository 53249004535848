import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { Discord_svg, Facebook_svg, Line_svg, Telegram_svg, Tiwtter_svg } from './component/icon';
export const Pionex_Footer = memo(function Pionex_Footer() {
    return (<styleds.container>
      <styleds.title>派網交易機器人</styleds.title>
      <styleds.subTitle>派網社區</styleds.subTitle>
      <styleds.svgGroupContent>
        <a href='https://t.me/Pionex_TW' target={'_blank'} rel='noreferrer'>
          <Telegram_svg />
        </a>
        <a href='https://discord.gg/8BDsEDAtDZ' target={'_blank'} rel='noreferrer'>
          <Discord_svg />
        </a>
        <a href='https://twitter.com/pionex_com' target={'_blank'} rel='noreferrer'>
          <Tiwtter_svg />
        </a>
        <a href='https://www.facebook.com/pionexglobal' target={'_blank'} rel='noreferrer'>
          <Facebook_svg />
        </a>
        <a href='https://line.me/ti/g2/EBiX81biPklUBWtMi4XF8iuMX6kzo_mEuTFXiQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default' target={'_blank'} rel='noreferrer'>
          <Line_svg />
        </a>
      </styleds.svgGroupContent>
      <styleds.copyright>All rights reserved © 2023 Pionex</styleds.copyright>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    display-template-rows: 40% 20% 20% 20%;
    width: 100%;
    height: 100%;
    background-color: #222632;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    color: #fafafa;
    font-size: 40px;
    font-weight: bold;
  `,
    subTitle: styled.div `
    ${fill_horizontal_all_center};
    color: #fafafa;
    font-size: 20px;
    font-weight: bold;
  `,
    svgGroupContent: styled.div `
    ${fill_horizontal_all_center};
    gap: 8px;
  `,
    copyright: styled.div `
    ${fill_horizontal_all_center};
    color: #ffffff4c;
    font-size: 12px;
  `,
    icon: styled.img `
    width: 24px;
    height: 24px;
  `,
};
