import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 60/20日移動平均線 */
export const gorich55688_ma = createIndicator({
    displayName: 'gorich55688_ma',
    id: ' gorich55688-ma',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = this._input(0);
            const in2 = this._input(1);
            const in3 = this._input(2);
            const in4 = this._input(3);
            const in5 = this._input(4);
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const sma3 = this.PineJS.Std.sma(close_array, in3, this._context);
            const sma4 = this.PineJS.Std.sma(close_array, in4, this._context);
            const sma5 = this.PineJS.Std.sma(close_array, in5, this._context);
            return [sma1, sma2, sma3, sma4, sma5];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#aaaaaa',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#00ffff',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#cccc00',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#cc0000',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#0000aa',
                },
            },
            inputs: {
                in_0: 5,
                in_1: 10,
                in_2: 60,
                in_3: 120,
                in_4: 240,
            },
        },
        styles: {
            plot_0: {
                title: '5',
                histogramBase: 0,
            },
            plot_1: {
                title: '10',
                histogramBase: 0,
            },
            plot_2: {
                title: '60',
                histogramBase: 0,
            },
            plot_3: {
                title: '120',
                histogramBase: 0,
            },
            plot_4: {
                title: '240',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: '長度',
                defval: 5,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_1',
                name: '長度',
                defval: 10,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_2',
                name: '長度',
                defval: 60,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_3',
                name: '長度',
                defval: 120,
                type: 'integer',
                min: 1,
                max: 360,
            },
            {
                id: 'in_4',
                name: '長度',
                defval: 240,
                type: 'integer',
                min: 1,
                max: 360,
            },
        ],
        scriptIdPart: '',
    },
});
