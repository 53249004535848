import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_vertical_all_center, fill_vertical_cross_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
const LinearGauges = memo(function LinearGauges(props) {
    const theme = useThemeStore(s => s.theme);
    const needleFill = theme === 'dark' ? classesFill.needleDark : classesFill.needleLight;
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme}>
        <classes.mark>
          <classes.itemTop width={100} margin={0}>
            <div>中</div>
          </classes.itemTop>
          <classes.item width={88} margin={16}>
            <div>小</div>
            <div>大</div>
          </classes.item>
        </classes.mark>
        <div css={css `
            ${flex.h.allCenter};
            position: absolute;
            margin-top: 22px;
            width: 100%;
          `}>
          <classes.icon className={theme}/>
          <GaugeChart id={'chart'} percent={props.currentValue || 0} formatTextValue={() => ''} nrOfLevels={999} textColor='transparent' arcPadding={0.0} cornerRadius={0} arcWidth={0.1} needleBaseColor={needleFill} needleColor={needleFill} colors={['#db494f', '#4051ce']}/>
        </div>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${fill_vertical_all_center}
  `,
    container: styled.div `
    ${fill_vertical_all_center};
    width: 232px;
    height: 124px;
    position: relative;
    font-size: 14px;
  `,
    mark: styled.div `
    ${fill_vertical_cross_center};
    position: absolute;
    padding: 4px;
  `,
    itemTop: styled.div `
    ${fill_vertical_all_center};
    width: ${props => props.width}%;
    height: 28px;
    margin-top: ${props => props.margin};
  `,
    item: styled.div `
    ${fill_vertical_all_center};
    ${flex.h.allCenter};
    ${jc.spaceBetween};
    width: ${props => props.width}%;
    height: 28px;
    margin-top: ${props => props.margin}px;
  `,
    icon: styled.div `
    position: absolute;
    width: 140px;
    height: 70px;
    border-radius: 70px 70px 0 0;
    margin-top: -6px;
    border-width: 2px 2px 0 2px;
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
  }
  ${classes.mark} {
  }
  ${classes.item} {
  }
  ${classes.itemTop} {
  }
  ${classes.icon} {
    &.dark {
      background: #aaaaaa11;
      border: 1px solid #888888;
      border-width: 2px 2px 0 2px;
    }
    &.light {
      background: #11111111;
      border: 1px solid #cacaca;
      border-width: 2px 2px 0 2px;
    }
  }
`;
const classesFill = {
    needleDark: '#ffffff',
    needleLight: '#252525',
};
export default {
    Display: LinearGauges,
    classes,
    classesFill,
};
