import { css } from '@emotion/react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import Notification from '~/modules/notification/Notification';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler';
import ModuleWrapper from '~/modules/virtual-exchange-center/components/Wrapper';
import { ModuleSelect } from '~/modules/virtual-exchange-center/components/ModuleSelect';
import OrderForm from '~/modules/virtual-exchange-center/components/OrderForm';
import StrategyCenterProvider from '~/modules/virtual-exchange-center/containers/SCRootProvider';
import RecordTable from '~/modules/virtual-exchange-center/components/RecordTable';
import ChartDialogBtns from '~/modules/virtual-exchange-center/components/ChartDialogBtns';
import StrategyCenterLogin from '~/pages/strategy_center/_private/Login';
import StrategyModuleRelated from '~/pages/strategy_center/_private/StrategyModuleRelated';
export const strategyCenter_init = (templateProps) => {
    templateProps.permissions.pageview = meCreateCheckHandler(() => true);
    useThemeStore.setState({ theme: 'light' });
    templateProps.layout.cssset = css `
    grid-template-rows: 80px auto 1fr;
    padding: 16px;
    justify-content: stretch;
    align-items: stretch;
    user-select: none;
    place-content: inherit;
    grid-template-areas:
      'Row1 Row1'
      'Row2 Row2'
      'Row3 Row3';
    & .Row4,
    & .Row5,
    & .Row6,
    & .Chart {
      position: fixed;
    }
  `;
    templateProps.layout.Row1 = function UpperRow() {
        return (<div css={css `
          ${flex.h.crossCenter};
          height: 100%;
        `}>
        <div css={css `
            position: fixed;
            top: 16px;
            right: 16px;
          `}>
          <StrategyCenterLogin />
        </div>
        <StrategyModuleRelated />
        <ModuleSelect />
        <div css={css `
            width: 16px;
          `}/>
        <ChartDialogBtns />
      </div>);
    };
    templateProps.layout.Providers = StrategyCenterProvider;
    templateProps.layout.Row2 = ModuleWrapper;
    templateProps.layout.Row3 = RecordTable;
    templateProps.layout.Row4 = OrderForm;
    templateProps.layout.Row5 = Notification;
    templateProps.layout.Charting = undefined;
};
