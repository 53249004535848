import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SgxSymbolQuote } from './component/SymbolQuote';
export const Sgx_SidePane1 = memo(function Sgx_SidePane1() {
    return (<styleds.container>
      <styleds.header>
        <styleds.title>商品報價</styleds.title>
        <styleds.grayDivider />
      </styleds.header>
      <SgxSymbolQuote fullName='SGX FTSE China A50 Index Futures' symbol='CN-1' digits={2}/>
      <SgxSymbolQuote fullName='SGX FTSE Taiwan Index Futures' symbol='TWN-1' digits={2}/>
      <SgxSymbolQuote fullName='SGX Nikkei 225 Index Futures' symbol='NK-1' digits={2}/>
      <SgxSymbolQuote fullName='FTSE China H50 Index Futures' symbol='FCH-1' digits={2}/>
    </styleds.container>);
});
export const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    border-right: 1px solid #e0e4ea;
    padding: 4px;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    width: 100%;
    height: 36px;
  `,
    title: styled.div `
    ${fill_horizontal_cross_center};
    height: 32px;
    width: auto;
    color: #112267;
    font-size: 16px;
    font-weight: 600;
  `,
    grayDivider: styled.div `
    flex: 1 1 auto;
    display: block;
    height: 6px;
    background: #e7e9f0;
    margin-left: 20px;
  `,
};
