import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { sgxClasses } from '../sgx_DataCard';
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource';
import { store } from '~/pages/heineken_template/_private/store';
import { proxy, useSnapshot } from 'valtio';
import dayAPI from '~/utils/dayAPI';
const sgx_store = proxy({
    maxChange: NaN,
});
export const PriceChangeCard = memo(function PriceChangeCard() {
    const charting = useSnapshot(store).charting;
    //先拿30天的'近月商品'資料
    //最新的為close[0]
    //data[0].close / data.close[19]
    /* 拿商品資料 */
    const { data: rawData } = useSymbolHistoryResource({
        symbol: charting.symbol,
        fromTo: [dayAPI().subtract(360, 'day').unix(), dayAPI().unix()],
        resolution: '1D',
        refreshInterval: 600000,
    });
    const sortDateData = rawData?.sort((a, b) => b.time - a.time);
    const newData = sortDateData?.map(s => s.close) ?? [];
    const data240days = newData[240 - 1];
    const data120days = newData[120 - 1];
    const data60days = newData[60 - 1];
    const data20days = newData[20 - 1];
    const data10days = newData[10 - 1];
    const data5days = newData[5 - 1];
    const lastdays = newData[0];
    const priceChange = (close1, close2) => {
        return ((close1 - close2) / close2) * 100;
    };
    const change5day = priceChange(lastdays, data5days);
    const change10day = priceChange(lastdays, data10days);
    const change20day = priceChange(lastdays, data20days);
    const change60day = priceChange(lastdays, data60days);
    const change120day = priceChange(lastdays, data120days);
    const change240day = priceChange(lastdays, data240days);
    const maxChange = Math.max(Math.abs(change5day), Math.abs(change10day), Math.abs(change20day), Math.abs(change60day), Math.abs(change120day), Math.abs(change240day));
    useEffect(() => {
        sgx_store.maxChange = maxChange;
    }, [maxChange]);
    return (<sgxClasses.card height={280}>
      <sgxClasses.title>近期價格波動</sgxClasses.title>
      <sgxClasses.subTitle>波動表現</sgxClasses.subTitle>
      <ChangeBar barWidth={change5day}>5日</ChangeBar>
      <ChangeBar barWidth={change10day}>10日</ChangeBar>
      <ChangeBar barWidth={change20day}>20日</ChangeBar>
      <ChangeBar barWidth={change60day}>60日</ChangeBar>
      <ChangeBar barWidth={change120day}>120日</ChangeBar>
      <ChangeBar barWidth={change240day}>240日</ChangeBar>
    </sgxClasses.card>);
});
const ChangeBar = memo(function ChangeBar(props) {
    const maxChange = useSnapshot(sgx_store).maxChange;
    const width = props.barWidth;
    const barFill = width > 0 ? '#cc3344' : '#33aa44';
    const margincss = width > 0
        ? css `
          margin-left: 50%;
        `
        : css `
          margin-right: 50%;
        `;
    const direction = width > 0
        ? css `
          flex-direction: row;
        `
        : css `
          flex-direction: row-reverse;
        `;
    return (<div css={css `
        ${fill_horizontal_cross_center};
        height: 30px;
      `}>
      <div css={css `
          margin-left: 16px;
          width: 20%;
        `}>
        {props.children}
      </div>
      <div css={css `
          display: flex;
          ${direction};
          align-items: center;
          width: 80%;
          height: 24px;
          background-color: #f5f5f5;
          border-radius: 2px;
        `}>
        <div css={css `
            width: ${(Math.abs(width) / maxChange) * 30}%;
            ${margincss};
            height: 16px;
            background-color: ${barFill};
          `}></div>
        <div css={css `
            padding: 0 8px;
            font-size: 14px;
          `}>
          ({width.toFixed(2)}%)
        </div>
      </div>
    </div>);
});
const styleds = {};
