import { css } from '@emotion/react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme';
export const lung88988_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__next {
      ${TemplateTheme.classes.Dark} {
      }

      ${FuiButton.classes.button.Root} {
        display: flex;
        border-radius: 3px;
        height: 26px;
        font-size: 16px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #aaaaaa;
        background-color: #162844aa;
        border: 1px solid #4b85d5aa;
        &:hover {
          background-color: #163955aa;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #4893bd;
        border: 1px solid #ffffff;
        box-shadow: 0px 0px 16px 1px #4893bdaa;
        transition: 0.5s;
        &:hover {
          background-color: #48a3cd;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.long.Default} {
        color: #eeeeee;
        background-color: #550000;
        &:hover {
          color: #eeeeee;
          background-color: #770000;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.short.Default} {
        color: #eeeeee;
        background-color: #005500;
        &:hover {
          color: #eeeeee;
          background-color: #007700;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.long.Active} {
        color: #eeeeee;
        background-color: #aa0000;
        box-shadow: 0px 0px 16px 1px #aa0000aa;
        transition: 0.5s;
      }

      ${FuiButton.classes.button.short.Active} {
        color: #eeeeee;
        background-color: #00aa00;
        box-shadow: 0px 0px 16px 1px #00aa00aa;
        transition: 0.5s;
      }
    }
  `;
};
