import { proxy } from 'valtio';
import dayAPI from '~/utils/dayAPI';
import { FuiUserAvatar } from '../heineken_template/components/FuiUserAvatar';
/** 指標傳參數 */
export const sgxIndicatorsValueStorage = proxy({
    userAvatar: new FuiUserAvatar(),
    //圖表當期商品代號
    chartSymbol: 'CN',
    //預設當月合約 返回0-11 (+1人類可讀)
    month1: (dayAPI().month() + 1),
    //主月合約商品代號
    symbol1: '',
    //次月合約商品代號
    symbol2: '',
    // Mov avg
    ma5: NaN,
    ma10: NaN,
    ma20: NaN,
    ma60: NaN,
    ma120: NaN,
    // Mov avg slope
    slope5: NaN,
    slope10: NaN,
    slope20: NaN,
    slope60: NaN,
    slope120: NaN,
    //Rsv
    rsv5: NaN,
    rsv20: NaN,
    rsv60: NaN,
    rsv120: NaN,
    //Bias
    bias5: NaN,
    bias20: NaN,
    bias60: NaN,
    bias120: NaN,
    //Dmi
    dmi5: NaN,
    dmi20: NaN,
    dmi60: NaN,
    dmi120: NaN,
});
