import { proxy } from 'valtio';
import { FuiTabs2 } from '../heineken_template/components/FuiTabs2';
export const yaya168Stock_store = proxy({
    tabsOfMain: new FuiTabs2(['墊高', '精選潛力股', '點火']),
    trendLeader: '盤整',
    trendState: '盤整',
    bisa: NaN,
    volume: NaN,
    bsValue: NaN,
    bsMaValue: NaN,
});
