import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { SessionType } from '~/modules/SDK/Chart2/SessionType';
export const gorich55688_dailyHighLow5 = createIndicator({
    id: 'gorich55688-dailyHighLow5',
    displayName: '5日高低',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const symbol = this._context.symbol.ticker;
            const session = symbol.includes('-') ? SessionType.TAIFEX : SessionType.TWSE;
            if (session === SessionType.TAIFEX)
                return;
            const length = 6;
            const dailyHigh = this.dailyHigh(length, session);
            const dailyLow = this.dailyLow(length, session);
            dailyHigh.shift();
            dailyLow.shift();
            return [this.maxList(dailyHigh), this.minList(dailyLow)];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'high', type: 'line' },
            { id: 'low', type: 'line' },
        ],
        defaults: {
            styles: {
                high: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: '#d75442',
                },
                low: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: '#54d742',
                },
            },
            precision: 2,
            inputs: { length: 5 },
        },
        styles: {
            high: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            low: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
            {
                id: 'length',
                name: '期間',
                defval: 5,
                type: 'integer',
                min: 1,
                max: 5,
                step: 1,
            },
        ],
    },
});
