import { css } from '@emotion/react';
import { store } from '~/pages/heineken_template/_private/store';
import ChartingStrategy from '~/modules/SDK/chart4/components/ChartingStrategy';
import winnerA_AttackLineToggle from '~/pages/winnerA/_private/winnerA_AttackLineToggle';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight400 } from '~/css/font';
export const winnerA_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.candleStyle.downColor': '#00ff00',
        'mainSeriesProperties.candleStyle.upColor': '#ff0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'timeScale.rightOffset': 5,
        'scalesProperties.fontSize': 16,
        'scalesProperties.textColor': '#ffffcc',
        'paneProperties.vertGridProperties.color': '#000110',
        'paneProperties.horzGridProperties.color': '#333333',
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#000110',
        'mainSeriesProperties.showPriceLine': false,
        'paneProperties.legendProperties.showSeriesOHLC': true,
        'scalesProperties.showSeriesLastValue': true,
        'scalesProperties.showStudyLastValue': false,
    };
    templateProps.globalCssset = css `
    #__html {
      ${ChartingStrategy.classes.Button} {
        ${defaultCss}
      }
      ${ChartingStrategy.classes.ButtonOfActive} {
        background-color: #484e5d;
        border: 1px solid #bbbbbb;
        color: #eeeeee;
      }

      ${winnerA_AttackLineToggle.classes.Button} {
        ${defaultCss}
      }
      ${winnerA_AttackLineToggle.classes.ButtonOfActive} {
        background-color: #484e5d;
        color: #eeeeee;
        border: 1px solid #bbbbbb;
      }
    }
  `;
};
const defaultCss = css `
  ${fill_horizontal_all_center};
  height: 32px;
  background-color: #394051;
  border: 1px solid #555555;
  color: #aaaaaa;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  ${fontWeight400}
  &:hover {
    background-color: #373b4c;
  }
`;
