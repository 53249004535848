import { css } from '@emotion/react';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { store } from '~/pages/heineken_template/_private/store';
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar';
export const Wu5868_Topbar = memo(function Wu5868_Topbar(props) {
    const { isPc } = useMedia();
    useThemeStore.setState({ theme: 'light' });
    return (<div css={css `
          ${fill_horizontal_cross_center};
          justify-content: end;
          background: linear-gradient(359deg, #ffffff, #ffffff);
          border-bottom: 1px solid #aaaaaa;
        `}>
        {isPc && (<div css={css `
              ${fill_horizontal_all_center};
              justify-content: end;
              padding: 0px 16px;
            `}>
            <ChartingServerSwitch charting={store.charting}/>
            <UserAvatarAsDialogButton />
            {/* <avatarStore.userAvatar.AvatarModal
              cssset={css`
                width: 280px;
                height: 360px;
                transition: 0.3s;
              `}
            >
              <OpkevinLogOutView />
            </avatarStore.userAvatar.AvatarModal> */}
          </div>)}

        {!isPc && (<Preset_Topbar showRightBurger={props.rightBerger ?? true} showLeftBurger={props.leftBurger ?? true} componentsInRight={<div css={css `
                  ${fill_horizontal_all_center};
                  justify-content: end;
                `}>
                <ChartingServerSwitch charting={store.charting}/>
                <UserAvatarAsDialogButton />
                {/* <avatarStore.userAvatar.AvatarModal
                  cssset={css`
                    width: 280px;
                    height: 360px;
                  `}
                >
                  <OpkevinLogOutView />
                </avatarStore.userAvatar.AvatarModal> */}
              </div>}></Preset_Topbar>)}
      </div>);
});
