export const futuresContractToNumber = (contract) => {
    if (contract === 'F') {
        return 1;
    }
    else if (contract === 'G') {
        return 2;
    }
    else if (contract === 'H') {
        return 3;
    }
    else if (contract === 'J') {
        return 4;
    }
    else if (contract === 'K') {
        return 5;
    }
    else if (contract === 'M') {
        return 6;
    }
    else if (contract === 'N') {
        return 7;
    }
    else if (contract === 'Q') {
        return 8;
    }
    else if (contract === 'U') {
        return 9;
    }
    else if (contract === 'V') {
        return 10;
    }
    else if (contract === 'X') {
        return 11;
    }
    else if (contract === 'Z') {
        return 12;
    }
    else
        return NaN;
};
export const monthToFuturesContract = (contract) => {
    if (contract === '01') {
        return 'F';
    }
    if (contract === '02') {
        return 'G';
    }
    if (contract === '03') {
        return 'H';
    }
    if (contract === '04') {
        return 'J';
    }
    if (contract === '05') {
        return 'K';
    }
    if (contract === '06') {
        return 'M';
    }
    if (contract === '07') {
        return 'N';
    }
    if (contract === '08') {
        return 'Q';
    }
    if (contract === '09') {
        return 'U';
    }
    if (contract === '10') {
        return 'V';
    }
    if (contract === '11') {
        return 'X';
    }
    if (contract === '12') {
        return 'Z';
    }
};
// const fetcher = async (url: string) => {
//   const res = await fetch(url)
//   if (res.status >= 400) throw new Error()
//   return res.json()
// }
// /** 星交所商品API */
// export const useSgxSymbolData = (symbol: string, from: number, to: number) => {
//   const url = urlcat(apirc.unclassified.sgxSymbolData.toString(), { symbol, from, to })
//   const res = useSWR<SgxSymbolData[]>(url, fetcher, {
//     revalidateOnFocus: false,
//     refreshInterval: 15000,
//   })
//   return {
//     data: res.data ? res.data : [],
//     isLoading: !res.data && !res.error,
//   }
// }
