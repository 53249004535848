import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const winner_os_chips = createIndicator({
    displayName: '贏家籌碼動向',
    id: 'winneroschips',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //----------------------------------------------
            //RSV
            const input0 = 53;
            //K
            const input1 = 12;
            //D
            const input2 = 9;
            //MA1
            const input3 = 45;
            //MA2
            const input4 = 2;
            //----------------------------------------------
            const time = this.PineJS.Std.time(this._context);
            const time_hours = new Date(time).getHours();
            const time_minutes = new Date(time).getMinutes();
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const ticks = this.PineJS.Std.volume(this._context);
            const close_array = this._context.new_var(close);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const total_closeticks = this._context.new_var();
            const total_ticks = this._context.new_var();
            const avg_price = total_closeticks / total_ticks;
            if (time_hours === 0 && time_minutes === 0) {
                total_closeticks.set(close * ticks);
                total_ticks.set(ticks);
            }
            else {
                total_closeticks.set(total_closeticks + close * ticks);
                total_ticks.set(total_ticks + ticks);
            }
            const ma1 = this.PineJS.Std.sma(close_array, input3, this._context);
            const ma2 = this.PineJS.Std.sma(close_array, input4, this._context);
            //----------------------------------------------
            const in0 = input0;
            const in1 = input1;
            const in2 = input2;
            const s = this.PineJS.Std.close(this._context);
            const a = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const c = this._context.new_var(s);
            const u = this._context.new_var(a);
            const h = this._context.new_var(l);
            const d = this.PineJS.Std.stoch(c, u, h, in0, this._context);
            const p = this._context.new_var(d);
            const g = this.PineJS.Std.sma(p, in1, this._context);
            const f = this._context.new_var(g);
            const j = this.PineJS.Std.sma(f, in2, this._context);
            //----------------------------------------------
            const isCL = this.PineJS.Std.ticker(this._context);
            const value1 = (close - avg_price) * (isCL.includes('CL') ? 100 : 1);
            const value2 = (f.get(0) - 50) * 2;
            const line1_color = close < avg_price ? 0 : 1;
            const line2_color = f <= j ? 0 : 1;
            //----------------------------------------------
            return [value1, value2, line1_color, line2_color];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'colorer',
                target: 'plot_0',
                palette: 'palette_0',
            },
            {
                id: 'plot_3',
                type: 'colorer',
                target: 'plot_1',
                palette: 'palette_1',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
            palette_1: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 1,
                    transparency: 20,
                    visible: !0,
                    color: '#0099ff',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    transparency: 0,
                    visible: !0,
                    color: '#0099ff',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#0080ff',
                            width: 5,
                            style: 0,
                        },
                        1: {
                            color: '#ff0080',
                            width: 5,
                            style: 0,
                        },
                    },
                },
                palette_1: {
                    colors: {
                        0: {
                            color: '#00ff00',
                            width: 2,
                            style: 0,
                        },
                        1: {
                            color: '#FF0000',
                            width: 2,
                            style: 0,
                        },
                    },
                },
            },
            // inputs: { in_0: 53, in_1: 12, in_2: 9, in_3: 45, in_4: 2 },
        },
        styles: {
            plot_0: {
                title: '趨勢',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [
        // { id: 'in_0', name: 'KD-RSV', defval: 53, type: 'integer', min: 1, max: 120 },
        // { id: 'in_1', name: 'KD-K', defval: 12, type: 'integer', min: 1, max: 120 },
        // { id: 'in_2', name: 'KD-D', defval: 9, type: 'integer', min: 1, max: 120 },
        // { id: 'in_3', name: 'ma1', defval: 45, type: 'integer', min: 1, max: 240 },
        // { id: 'in_4', name: 'ma2', defval: 2, type: 'integer', min: 1, max: 240 },
        ],
        scriptIdPart: '',
    },
});
