import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { edwinGroupName } from '../WatchListGroupName';
import KbarWatchListQuote from '../quote/KbarWatchListQuote';
import { proxy, useSnapshot } from 'valtio';
import { sortBy } from 'lodash';
export const useBig3StockDataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
export const WatchListContent = memo(function WatchListContent(props) {
    const state = useSnapshot(useBig3StockDataStore);
    const value = props.quoteValue;
    const data = props.data;
    const newData = value.map(s => {
        const matchingData = data.find(item => item.symbol === s.symbol);
        if (matchingData) {
            return {
                signalr: s,
                big3: matchingData,
            };
        }
        else {
            return {
                signalr: s,
                big3: {
                    date: '',
                    symbol: '',
                    foreigner_diff_volume: 0,
                    trust_diff_volume: 0,
                    dealer_diff_volume: 0,
                    dealer_hedge_diff_volume: 0,
                    big3_diff_volume: 0,
                    foreigner_diff_value: 0,
                    trust_diff_value: 0,
                    dealer_diff_value: 0,
                    dealer_hedge_diff_value: 0,
                    big3_diff_value: 0,
                },
            };
        }
    });
    //這邊是做另一種資料排序
    //外資與投信買賣超排序
    const sortData = () => {
        if (state.sortKey === 'foreigner' && state.orderKey === 'asc') {
            return sortBy(newData, datum => datum.big3.foreigner_diff_value);
        }
        if (state.sortKey === 'foreigner' && state.orderKey === 'desc') {
            return sortBy(newData, datum => datum.big3.foreigner_diff_value).reverse();
        }
        if (state.sortKey === 'trust' && state.orderKey === 'asc') {
            return sortBy(newData, datum => datum.big3.trust_diff_value);
        }
        if (state.sortKey === 'trust' && state.orderKey === 'desc') {
            return sortBy(newData, datum => datum.big3.trust_diff_value).reverse();
        }
        return newData;
    };
    return (<styleds.container>
      <styleds.header>
        <KbarWatchListQuote.header />
      </styleds.header>
      <styleds.body>
        {sortData()?.map((quote, index) => {
            return (<KbarWatchListQuote.body groupName={edwinGroupName} data={quote.big3} key={index} quote={quote.signalr}/>);
        })}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    header: styled.div `
    width: 100%;
    height: 36px;
    flex-shrink: 0;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 40px);
    gap: 2px;
  `,
};
