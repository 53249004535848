import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
//簡易波動率 逆勢摸頭、抄底
export const pionex_strategy03 = createIndicator({
    id: 'pionex-strategy03',
    displayName: 'pionex_strategy03',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.ohlc.close;
            const open = this.ohlc.open;
            const high = this.ohlc.high;
            const highArray = this.ohlc.highArray;
            const low = this.ohlc.low;
            const lowArray = this.ohlc.lowArray;
            const f_0 = function (e, t, i, n, r) {
                return (e * t * (i - n)) / r;
            };
            const highest = this.PineJS.Std.highest(highArray, 20, this._context);
            const lowest = this.PineJS.Std.lowest(lowArray, 20, this._context);
            const highestStop = this.PineJS.Std.highest(highArray, 5, this._context);
            const lowestStop = this.PineJS.Std.lowest(lowArray, 5, this._context);
            const base = 10000;
            const length = 16;
            const hl2 = this.PineJS.Std.hl2(this._context);
            const hl2_array = this._context.new_var(hl2);
            const a = this.PineJS.Std.change(hl2_array);
            const f0 = f_0(base, a, this.PineJS.Std.high(this._context), this.PineJS.Std.low(this._context), this.PineJS.Std.volume(this._context));
            const f0_array = this._context.new_var(f0);
            const eom = this.PineJS.Std.sma(f0_array, length, this._context);
            const position = context.new_var();
            const stoplossPoint = context.new_var();
            const setStartPoint = this._context.new_var();
            const setEndPoint = this._context.new_var();
            position.get(1);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            let buy_exit_icon = NaN;
            let short_exit_icon = NaN;
            // ---------------------------------------- Condition ----------------------------------------
            const longEntry = eom < -40000 && close > open;
            const shortEntry = eom > 40000 && close < open;
            const exitLongLine = position.get(0) === 1 ? (setStartPoint.get(0) + setEndPoint.get(0)) / 2 : NaN;
            const exitShortLine = position.get(0) === -1 ? (setStartPoint.get(0) + setEndPoint.get(0)) / 2 : NaN;
            // ---------------------------------------- Entry ----------------------------------------
            if (longEntry)
                position.set(1);
            if (shortEntry)
                position.set(-1);
            if (position.get(0) === 1 && position.get(1) !== 1) {
                setStartPoint.set(open);
                setEndPoint.set(highest);
                stoplossPoint.set(lowestStop);
                buy_icon = 1;
            }
            if (position.get(0) === -1 && position.get(1) !== -1) {
                setStartPoint.set(open);
                setEndPoint.set(lowest);
                stoplossPoint.set(highestStop);
                short_icon = 1;
            }
            // ---------------------------------------- Exit ----------------------------------------
            if (position.get(0) === 1 && position.get(1) === 1) {
                if (high > exitLongLine) {
                    buy_target_icon = 1;
                    position.set(0);
                }
                if (low < stoplossPoint.get(0)) {
                    buy_exit_icon = 1;
                    position.set(0);
                }
            }
            if (position.get(0) === -1 && position.get(1) === -1) {
                if (low < exitShortLine) {
                    short_target_icon = 1;
                    position.set(0);
                }
                if (high > stoplossPoint.get(0)) {
                    short_exit_icon = 1;
                    position.set(0);
                }
            }
            const colorer = () => {
                return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2;
            };
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                buy_exit_icon,
                short_exit_icon,
                exitLongLine,
                exitShortLine,
                colorer(),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag',
                type: 'shapes',
            },
            {
                id: 'entryShortFlag',
                type: 'shapes',
            },
            {
                id: 'targetLongFlag',
                type: 'shapes',
            },
            {
                id: 'targetShortFlag',
                type: 'shapes',
            },
            {
                id: 'exitLongFlag',
                type: 'shapes',
            },
            {
                id: 'exitShortFlag',
                type: 'shapes',
            },
            {
                id: 'exitLongLine',
                type: 'line',
            },
            {
                id: 'exitShortLine',
                type: 'line',
            },
            {
                id: '部位',
                type: 'bar_colorer',
                palette: 'barPalette',
            },
        ],
        defaults: {
            styles: {
                entryLongFlag: { color: '#f04848', textColor: '#f04848', transparency: 10, visible: true },
                entryShortFlag: { color: '#00b070', textColor: '#00b070', transparency: 10, visible: true },
                targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
                targetShortFlag: {
                    color: '#00b070',
                    textColor: '#fafafa',
                    transparency: 20,
                    visible: true,
                },
                exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
                exitShortFlag: { color: '#00b070', textColor: '#fafafa', transparency: 20, visible: true },
                exitLongLine: { color: '#f04848', transparency: 20, visible: true, linestyle: 1 },
                exitShortLine: { color: '#00b070', transparency: 20, visible: true, linestyle: 1 },
            },
        },
        styles: {
            entryLongFlag: {
                title: '買進',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_triangle_up',
                size: 'small',
                text: 'Buy',
            },
            entryShortFlag: {
                title: '賣出',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_triangle_down',
                size: 'small',
                text: 'Short',
            },
            targetLongFlag: {
                title: '買進停利',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_label_down',
                size: 'tiny',
                text: 'Target',
            },
            targetShortFlag: {
                title: '賣出停利',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_label_up',
                size: 'tiny',
                text: 'Target',
            },
            exitLongFlag: {
                title: '買進出場',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_label_up',
                size: 'tiny',
                text: 'Exit',
            },
            exitShortFlag: {
                title: '賣出出場',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_label_down',
                size: 'tiny',
                text: 'Exit',
            },
        },
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#b2b5be',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
