import { intersection, isString, sortBy, filter } from 'lodash';
export const sortSymbolByValue = (
// value: Partial<{
//   [symbolString: string]: Signalr.ValueOfOHLC
// }>,
value, symbolNumber, sortType) => {
    const data = intersection(sortBy(value, datum => datum?.[sortType])
        .reverse()
        .map(datum => datum?.symbol)
        .filter(isString), symbolNumber);
    return data;
};
export const filterSymbolByValue = (value, symbolNumber, sortType, filterValue) => {
    const data = intersection(filter(value, datum => (Number(datum?.[sortType]) ?? 0) > filterValue)
        .map(datum => datum?.symbol)
        .filter(isString), symbolNumber);
    return data;
};
export const sortAndFilterSymbolByValue = (value, symbolNumber, type, filterValue) => {
    const dataFilter = intersection(filter(value, datum => (Number(datum?.[type]) ?? 0) > filterValue));
    const data = intersection(sortBy(dataFilter, datum => datum?.[type])
        .reverse()
        .map(datum => datum?.symbol)
        .filter(isString), symbolNumber);
    return data;
};
