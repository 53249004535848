import { memo } from 'react';
import { SymbolList } from './symbolList';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useChangePercent } from '~/modules/screener/containers/useStockScreenerResource';
export const ConditionChange = memo(function ConditionChange() {
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const data = useChangePercent({
        date: day0.format('YYYY-MM-DD'),
        days: 20,
        percent_gte: 30,
    });
    const symbol = data.data
        ?.filter(datum => datum.change_percent > 30)
        .map(datum => datum.symbol)
        .slice(0, 6) ?? [];
    return (<SymbolList symbol={symbol} titleFillColor='#c85d4a'>
      最新飆股
    </SymbolList>);
});
