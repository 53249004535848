import { south_store } from './south_store';
/**
 * Returns signals by symbol.
 *
 * @param symbol
 * @param originalSignals
 * @returns
 */
export const getSignalsBySymbol = (symbol, originalSignals) => {
    return Array.from(new Set(originalSignals
        .filter(signal => signal.symbol == symbol)
        .map(signal => JSON.stringify(signal))))
        .map(stringSignal => JSON.parse(stringSignal))
        .sort((a, b) => {
        const [aTime, bTime] = [new Date(a.datetime), new Date(b.datetime)];
        if (aTime < bTime) {
            return -1;
        }
        if (aTime > bTime) {
            return 1;
        }
        return 0;
    });
};
/**
 * Set position info into south_agentStore
 *
 * @param symbol
 * @param signals
 */
export const setPositionInfo = (signal) => {
    console.log('Set position info for:', signal.symbol);
    console.log(signal);
    if (!signal) {
        console.log(`No longer signal}`);
        return;
    }
    const entryData = signal.signal_data;
    south_store.symbol = signal.symbol;
    south_store.side =
        Number(entryData.current_position) === 1
            ? 'Long'
            : Number(entryData.current_position) === -1
                ? 'Short'
                : 'None';
    south_store.currentPosition = entryData.current_position;
    south_store.entryTime = signal.datetime;
    south_store.entryPrice = entryData.future_price;
    south_store.stoplossPrice = entryData.future_stoploss;
};
export const getEntrySignals = (signals, signalId) => signals
    .filter(signal => {
    const data = signal.signal_data;
    return Number(data?.current_position) !== 0 && signal.signal_id === signalId;
})
    .map(signal => signal);
