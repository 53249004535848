import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const pionex_stoch = createIndicator({
    displayName: 'KD',
    id: 'pionex-stoch',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const i = 63;
            const o = 14;
            const r = 7;
            const itime = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const stoch = this.PineJS.Std.stoch(close_array, high_array, low_array, i, this._context);
            const stoch_array = this._context.new_var(stoch);
            //高
            const vHn = this.PineJS.Std.highest(high_array, i, this._context);
            //低
            const vLn = this.PineJS.Std.lowest(low_array, i, this._context);
            //RSV
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            const vrsv_array = this._context.new_var(vrsv);
            //K%
            const vk = this.PineJS.Std.ema(vrsv_array, o, this._context);
            const vk_array = this._context.new_var(vk);
            //D%
            const vd = this.PineJS.Std.ema(vk_array, r, this._context);
            return [vk, vd];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#0099ee',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff4433',
                },
            },
            precision: 4,
            inputs: {
            // in_0: 9,
            // in_1: 3,
            // in_2: 3,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: '%K',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '%D',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'length',
        //   defval: 14,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e4,
        // },
        // {
        //   id: 'in_1',
        //   name: 'smoothK',
        //   defval: 1,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e4,
        // },
        // {
        //   id: 'in_2',
        //   name: 'smoothD',
        //   defval: 3,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e4,
        // },
        ],
        precision: 2,
        scriptIdPart: '',
    },
});
