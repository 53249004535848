import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const weng888_dayPower = createIndicator({
    displayName: '波段趨勢',
    id: 'weng888-dayPower',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const hlc3 = this.PineJS.Std.hlc3(this._context);
            const hlc3_array = this._context.new_var(hlc3);
            const ma20 = this.PineJS.Std.ema(hlc3_array, 20, this._context);
            const ma60 = this.PineJS.Std.sma(hlc3_array, 60, this._context);
            const diff = ma20 - ma60;
            const colorIndex = diff >= 0 ? 0 : 1;
            return [diff, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '市場多方能量',
                    },
                    1: {
                        name: '市場空方能量',
                    },
                },
            },
        },
        defaults: {
            styles: {
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#f44336',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#00aa33',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            vol: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
