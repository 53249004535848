import React, { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import SymbolRankingHeader from '~/modules/investment-consultant/symbolList/header/SymbolRankingHeader';
import SymbolRankingContent from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody';
import { SymbolRankingSpectrum } from '~/modules/screener/components/invesement-consultant/SymbolChangeRanking/SymbolRankingSpectrum';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { sortSymbolByValue } from '~/pages/lung88988_stock/modules/sortSymbolByValue';
import { generateScrollbar } from '~/css/scrollbarCss';
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators';
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
const contianer = css `
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  ${generateScrollbar('#454545', '#bbbbbb')}
`;
export const SymbolChangeRanking = memo(function SymbolChangeRanking(props) {
    const currentSymbol = useSnapshot(store.charting).symbol;
    const changeIndicators = useChartingChangeIndicatorsFn(props.useChart);
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.useChart);
    const value = useSignalrStoreValueOHLC(state => state.value);
    const priceValue = 'volume';
    const items = sortSymbolByValue(value, props.symbolNumber, priceValue);
    useEffect(() => {
        useSignalrStore.getState().subscribeAdd([...(props.symbolNumber || [])], 'ohlc');
        return () => {
            useSignalrStore.getState().subscribeRemove([...(props.symbolNumber || [])], 'ohlc');
        };
    }, [props.symbolNumber]);
    return (<div css={css `
        width: 100%;
        height: 100%;
      `}>
      <SymbolRankingSpectrum />
      <SymbolRankingHeader.Standard countArray={props.countArray}/>
      <div css={contianer}>
        {items.map(datum => {
            const symbol = datum;
            return (<div key={datum} onClick={event => {
                    props.handleClick?.(event);
                    if (currentSymbol !== symbol) {
                        changeSymbolInterval({ symbol: symbol });
                    }
                    if (props.indicators) {
                        changeIndicators(props.indicators);
                    }
                }}>
              <SymbolRankingContent.standard.Display currentSymbol={currentSymbol} symbol={datum} checkTrendMode={props.checkTrendMode} countArray={props.countArray}/>
            </div>);
        })}
      </div>
    </div>);
});
