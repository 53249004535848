import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
function sumN(arr, len) {
    let s = 0;
    for (let i = 0; i < len; i++) {
        s += arr.get(i);
    }
    return s;
}
export const daddy960_revive_holdingLine = createIndicator({
    id: 'daddy960-revive-holdingLine',
    displayName: '持股線',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //#region Init
            const volume = this.PineJS.Std.volume(context);
            const open = this.ohlc.openArray;
            const close = this.ohlc.closeArray;
            const priceSeries = context.new_var();
            const volumeSeries = context.new_var();
            const movAvg = context.new_var();
            open.get(100);
            close.get(100);
            priceSeries.get(100);
            volumeSeries.get(100);
            movAvg.get(100);
            //#endregion
            //#region indicator
            const price = volume * (open.get(0) + close.get(0)) * 0.5;
            priceSeries.set(price);
            volumeSeries.set(volume);
            const lifeLine = sumN(priceSeries, 88) / sumN(volumeSeries, 88);
            const maValue = this.average(close, 17);
            movAvg.set(maValue);
            const movAvgHigh = this.highest(movAvg, 2);
            const holdingLine = this.highest(movAvg, 2);
            const holdingLineYellow = maValue - (movAvgHigh - maValue);
            //#endregion
            return [lifeLine, maValue, holdingLine, holdingLineYellow];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'lifeLine', type: 'line' },
            { id: 'movAvg', type: 'line' },
            { id: 'holdingLine', type: 'line' },
            { id: 'holdingLineYellow', type: 'line' },
        ],
        defaults: {
            styles: {
                lifeLine: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#b22d5b',
                },
                movAvg: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ffcc66',
                },
                holdingLine: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#00ff00',
                },
                holdingLineYellow: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ffff00',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            lifeLine: {
                title: '我的生命線',
                histogramBase: 0,
                joinPoints: false,
            },
            movAvg: {
                title: '均線',
                histogramBase: 0,
                joinPoints: false,
            },
            holdingLine: {
                title: '持股線',
                histogramBase: 0,
                joinPoints: false,
            },
            holdingLineYellow: {
                title: '黃色持股線',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
