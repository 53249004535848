import styled from '@emotion/styled';
import { memo } from 'react';
import { fontWeight600 } from '~/css/font';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const AsiajyeLogoCard = memo(function LogoCard() {
    return (<styleds.container>
      <styleds.logo src='asiajye_stock/line.png'></styleds.logo>
      <styleds.disContent>大探極專業團隊</styleds.disContent>
      <styleds.disContent>LINE ID : @a957</styleds.disContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    background-color: #f2f2f2;
    border-radius: 6px;
    padding: 4px;
  `,
    logo: styled.img `
    width: 50%;
  `,
    disContent: styled.div `
    font-size: 22px;
    ${fontWeight600};
  `,
};
