import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import SymbolOverviewChart from '~/modules/investment-consultant/symbolList/overView/SymbolOverviewChart';
import { WatchListWithChart } from '~/modules/symbolQuote/simple';
import { WatchSymbolToggleSingleButton } from '~/pages/heineken_template/_col/col_WatchSymbolToggleSingleButton';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { styleds } from './styleds';
export const m168168tock_agentStore = proxy({
    /** 持有股票標的方向 */
    holdingPositionType: 'long',
});
export const M168168Stock_SidePane2 = memo(function M168168Stock_SidePane2() {
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(m168168tock_agentStore);
    const watchListGroupString = state.holdingPositionType === 'long' ? 'm168168_stock_group_1' : 'm168168_stock_group_2';
    return (<styleds.SideBar>
      <TitleSymbolQuote.Default symbol={charting.symbol}/>
      <styleds.TrendChartContent>
        <SymbolOverviewChart.Display symbol={[charting.symbol, 'TSE13', 'TSE17']}/>
      </styleds.TrendChartContent>
      <styleds.WatchToggleButton>
        <WatchSymbolToggleSingleButton groupName='m168168_stock_group_1' type='long'/>
        <WatchSymbolToggleSingleButton groupName='m168168_stock_group_2' type='short'/>
      </styleds.WatchToggleButton>
      <styleds.WatchListTitle>
        <styleds.ScreenerDateString>自選股</styleds.ScreenerDateString>
        <styleds.WatchListTypeBtton variant='long' active={state.holdingPositionType === 'long'} onClick={() => (m168168tock_agentStore.holdingPositionType = 'long')}>
          多方群組
        </styleds.WatchListTypeBtton>
        <styleds.WatchListTypeBtton variant='short' active={state.holdingPositionType === 'short'} onClick={() => (m168168tock_agentStore.holdingPositionType = 'short')}>
          空方群組
        </styleds.WatchListTypeBtton>
      </styleds.WatchListTitle>
      <styleds.WatchListContent variant={state.holdingPositionType}>
        <WatchListWithChart groupName={watchListGroupString} useChart={store.charting}/>
      </styleds.WatchListContent>
    </styleds.SideBar>);
});
