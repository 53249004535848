import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const South_Footer = memo(function Kabujuku_Footer(props) {
    return (<div css={css `
        ${fill_horizontal_all_center}
        width: 100%;
        height: 100%;
        background-color: #191342;
        color: #cccccc;
        font-size: 14px;
      `}>
      元富期貨股份有限公司111年金管期總字第003號
    </div>);
});
