import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { M168168Stock_SidePane1 } from './m168168Stock_SidePane1';
import { M168168Stock_SidePane2 } from './m168168Stock_SidePane2';
import { m168168Stock_initStrategies } from './m168168Stock_initStrategies';
import { M168168Stock_Topbar } from './m168168Stock_Topbar';
import { styleds } from './styleds';
import { m168168_initStyling } from './m168168Stock_initStyling';
import { AgentCarouselSubscriptionCheck } from '~/modules/auth/components/AgentCarouselSubscriptionCheck';
export const m168168Stock_init = (templateProps) => {
    m168168Stock_initStrategies();
    m168168_initStyling(templateProps);
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<AgentCarouselSubscriptionCheck resources={[{ image: [true, '/m168168/loginView.jpg'] }]}/>);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../m168168/chartingTheme.css',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: '1D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.Charting = styleds.Charting;
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = M168168Stock_Topbar;
    templateProps.layout.Drawer1 = M168168Stock_SidePane1;
    templateProps.layout.Drawer2 = M168168Stock_SidePane2;
};
