import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const m168168_foreignerRatio = createIndicator({
    id: 'm168168-foreignerRatio',
    displayName: '比重',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#big3_net_stock';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(0);
            const time0 = context.new_var(this._context.symbol.time);
            this._context.select_sym(1);
            const time1 = this._context.new_var(this._context.symbol.time);
            const o = this.PineJS.Std.open(this._context);
            const unlimited1 = this._context.new_unlimited_var(o);
            const chip = unlimited1.adopt(time1, time0, 0); //商品最終數值
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const ratio = chip / volume;
            const o_pos = chip > 0 ? ratio : NaN;
            const o_neg = chip < 0 ? ratio : NaN;
            return [o_pos, o_neg, 0.3, -0.3, 0.7, -0.7];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
            { id: 'plot_3', type: 'line' },
            { id: 'plot_4', type: 'line' },
            { id: 'plot_5', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    color: '#00ff00',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '買超',
                histogramBase: 0,
            },
            plot_1: {
                title: '賣超',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
