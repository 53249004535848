import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, flex } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
export const Imoney8888_TopBar = memo(function Imoney8888_TopBar() {
    const { isPc } = useMedia();
    return (<div>
      {' '}
      {isPc && (<div css={css `
            ${flex.h.allCenter}
            justify-content: end;
            background-color: #455164;
            width: 100%;
            height: 100%;
          `}>
          <ChartingServerSwitch charting={store.charting} css={css ``}/>
          <UserAvatarAsDialogButton />
        </div>)}{' '}
      {!isPc && (<Preset_Topbar css={css `
            background-color: #455164;
          `} showLeftBurger 
        //showRightBurger
        componentsInRight={<div css={fill_horizontal_all_center}>
              <ChartingServerSwitch charting={store.charting} css={css ``}/>
              <UserAvatarAsDialogButton />
            </div>}></Preset_Topbar>)}
    </div>);
});
