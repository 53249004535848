/* eslint-disable no-var */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { good178S_store } from '~/pages/good178_stock/_private/good178S_store';
export const stock_sg3 = createIndicator({
    id: 'stocksg3',
    displayName: '出手(短)',
    filterOnTheme: 'dark',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const userPosition = good178S_store.usedStrategyTrend;
            const donchian = (e) => {
                var t = this._context.new_var(this.PineJS.Std.low(this._context)), i = this._context.new_var(this.PineJS.Std.high(this._context)), o = this.PineJS.Std.avg(this.PineJS.Std.lowest(t, e, this._context), this.PineJS.Std.highest(i, e, this._context));
                return o;
            };
            const f_1 = () => {
                const e = 3; //this._input(0)
                const t = 14; //this._input(1)
                const i = 17; //this._input(2)
                const o = 4; //this._input(3)
                const r = donchian(e);
                const s = donchian(t);
                const a = this.PineJS.Std.avg(r, s);
                const l = donchian(i);
                const c = this.PineJS.Std.close(this._context);
                const u = this.PineJS.Std.gt(a, l) ? 0 : 1;
                return [r, s, c, a, l, -o, o, o, u];
            };
            const len = 1;
            let pv0 = NaN;
            let pv1 = NaN;
            const pvflag = this._context.new_var();
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close = this.PineJS.Std.close(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const resolution = context.symbol.period;
            const r = f_1();
            const r0 = this._context.new_var(r[0]);
            const r1 = this._context.new_var(r[1]);
            const r2 = this._context.new_var(r[2]);
            const r3 = this._context.new_var(r[3]);
            const r4 = this._context.new_var(r[4]);
            const ichi_mm = r1.get(1);
            const ichi_ll = Math.min(r3.get(8), r4.get(8));
            const ichi_hh = Math.max(r3.get(8), r4.get(89));
            const entryPrice = this._context.new_var();
            //商品數值 開高低收Array
            const high_array = this._context.new_var(high);
            const open_array = this._context.new_var(open);
            const low_array = this._context.new_var(low);
            const close_array = this._context.new_var(close);
            const itime_array = this._context.new_var(itime);
            const interval = context.symbol.period;
            var bState = this._context.new_var();
            var bDt = this._context.new_var(); //紀錄部位成立的時間
            var pvDt = this._context.new_var(); //紀錄停利訊號成立的時間
            itime_array.get(1);
            low_array.get(1);
            high_array.get(1);
            close_array.get(1);
            bDt.get(0);
            bDt.get(1);
            pvDt.get(0);
            pvDt.get(1);
            bState.get(1);
            bState.get(2);
            pvflag.get(1);
            pvflag.get(2);
            //Entry High Entry Low-------------------------------------------------------------------
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            if (bState.get(0) === 0) {
                entryHigh.set(0);
                entryLow.set(0);
            }
            if (bState.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (bState.get(0) === -1 && low < entryLow) {
                entryLow.set(low);
            }
            //部位進場-------------------------------------------------------------------
            if (interval !== '1D' && close_array.get(1) > ichi_mm && close_array.get(1) > ichi_hh) {
                bState.set(1);
            }
            if (interval !== '1D' && close_array.get(1) < ichi_mm && close_array.get(1) < ichi_ll) {
                bState.set(-1);
            }
            //部位進場與成立訊號-------------------------------------------------------------------
            if (userPosition !== 'short' && bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
                bDt.set(itime);
                entryPrice.set(open);
                pvflag.set(0);
            }
            if (userPosition !== 'long' && bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
                bDt.set(itime);
                entryPrice.set(open);
                pvflag.set(0);
            }
            //單一方向策略部位歸零
            if (userPosition === 'long' &&
                bState.get(1) === 1 &&
                close_array.get(1) < ichi_mm &&
                close_array.get(1) < ichi_ll) {
                bState.set(0);
                entryPrice.set(0);
            }
            if (userPosition === 'short' &&
                bState.get(1) === -1 &&
                close_array.get(1) > ichi_mm &&
                close_array.get(1) > ichi_hh) {
                bState.set(0);
                entryPrice.set(0);
            }
            //根據股票面額返回相對應的停利點數
            const targetValue = (l1, l2, l3, l4, l5) => {
                if (close < 10)
                    return l1;
                if (close < 50)
                    return l2;
                if (close < 100)
                    return l3;
                if (close < 500)
                    return l4;
                if (close < 5000)
                    return l5;
            };
            if (resolution === 'D') {
                var p1 = targetValue(0.03, 0.03, 0.04, 0.06, 0.08) ?? 0.003;
                var p2 = targetValue(0.06, 0.06, 0.07, 0.09, 0.11) ?? 0.005;
                var p3 = targetValue(0.1, 0.1, 0.11, 0.14, 0.16) ?? 0.01;
                var p4 = targetValue(0.15, 0.15, 0.16, 0.19, 0.21) ?? 0.015;
                var p5 = targetValue(0.21, 0.21, 0.22, 0.26, 0.27) ?? 0.02;
            }
            else {
                p1 = 0.05;
                p2 = 0.1;
                p3 = 0.15;
                p4 = 0.25;
                p5 = 0.35;
            }
            //多單出場-------------------------------------------------------------------
            if (userPosition !== 'short' && bState.get(0) === 1) {
                if (pvflag.get(0) === 0 && high > entryPrice * (1 + p1)) {
                    pvflag.set(1);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 1 && high > entryPrice * (1 + p2)) {
                    pvflag.set(2);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 2 && high > entryPrice * (1 + p3)) {
                    pvflag.set(3);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 3 && high > entryPrice * (1 + p4)) {
                    pvflag.set(4);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 4 && high > entryPrice * (1 + p5)) {
                    pvflag.set(5);
                    pvDt.set(itime);
                }
            }
            //空單出場-------------------------------------------------------------------
            if (userPosition !== 'long' && bState.get(0) === -1) {
                if (pvflag.get(0) === 0 && low < entryPrice * (1 - p1)) {
                    pvflag.set(1);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 1 && low < entryPrice * (1 - p2)) {
                    pvflag.set(2);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 2 && low < entryPrice * (1 - p3)) {
                    pvflag.set(3);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 3 && low < entryPrice * (1 - p4)) {
                    pvflag.set(4);
                    pvDt.set(itime);
                }
                else if (pvflag.get(0) === 4 && low < entryPrice * (1 - p5)) {
                    pvflag.set(5);
                    pvDt.set(itime);
                }
            }
            return [pv0, pv1];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: true,
        isCustomIndicator: true,
        visible: false,
        defaults: {
            styles: {
                plot_0: {
                    color: '#ff5554',
                    textColor: '#ff5554',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#2f7afd',
                    textColor: '#2f7afd',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {
            // in_0: 7,
            // in_1: 29,
            // in_2: 47,
            // in_3: 13,
            // in_4: 0.003,
            // in_5: 0.005,
            // in_6: 0.01,
            // in_7: 0.015,
            // in_8: 0.02,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'shapes',
            },
            {
                id: 'plot_1',
                type: 'shapes',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_triangle_up',
                size: 'small',
                text: '',
                title: 'Shapes',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_triangle_down',
                size: 'small',
                text: '',
                title: 'Shapes',
            },
        },
        is_price_study: true,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'Conversion Line Periods',
        //   defval: 7,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_1',
        //   name: 'Base Line Periods',
        //   defval: 29,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_2',
        //   name: 'Lagging Span 2 Periods',
        //   defval: 47,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_3',
        //   name: 'Displacement',
        //   defval: 13,
        //   type: 'integer',
        //   min: 1,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_4',
        //   name: '停利1',
        //   defval: 0.003,
        //   type: 'float',
        //   min: 0,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_5',
        //   name: '停利2',
        //   defval: 0.005,
        //   type: 'float',
        //   min: 0,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_6',
        //   name: '停利3',
        //   defval: 0.01,
        //   type: 'float',
        //   min: 0,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_7',
        //   name: '停利4',
        //   defval: 0.015,
        //   type: 'float',
        //   min: 0,
        //   max: 1e12,
        // },
        // {
        //   id: 'in_8',
        //   name: '停利5',
        //   defval: 0.02,
        //   type: 'float',
        //   min: 0,
        //   max: 1e12,
        // },
        ],
        scriptIdPart: '',
    },
});
export const stock_sg3_light = stock_sg3.duplicate({
    id: 'stocksg3--light',
    filterOnTheme: 'light',
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#FF0000',
                    textColor: '#FF0000',
                },
                plot_1: {
                    color: '#2196F3',
                    textColor: '#2196F3',
                },
            },
        },
    },
});
