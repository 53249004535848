/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 外資上市買賣超 */
export const gorich55688_twseForeignDiff = createIndicator({
    id: 'gorich55688-twseForeignDiff',
    displayName: '外資買賣超',
    enabledOn(symbol, data, channel) {
        return data?.type === channel.tw_futures;
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#foreigner_twse';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(1);
            const length = 60;
            const diffValue = this.PineJS.Std.open(this._context);
            const positiveValue = diffValue > 0 ? diffValue : NaN;
            const negativeValue = diffValue < 0 ? diffValue : NaN;
            const diff = this._context.new_var(diffValue);
            return [positiveValue, negativeValue, this.summation(diff, length)];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'positiveDiff', type: 'line' },
            { id: 'negativeDiff', type: 'line' },
            { id: 'accValue', type: 'line' },
        ],
        defaults: {
            styles: {
                positiveDiff: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#ff0000',
                },
                negativeDiff: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#007f00',
                },
                accValue: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 40,
                    color: '#ffff00',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            positiveDiff: {
                title: '外資上市買賣超',
                histogramBase: 0,
            },
            negativeDiff: {
                title: '外資上市買賣超',
                histogramBase: 0,
            },
            accValue: {
                title: 'Acc Value',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
