import { css } from '@mui/material';
import { memo } from 'react';
import { useMount } from 'react-use';
import { useSnapshot } from 'valtio';
import { useDatePick } from '~/modules/screener/useDatePick';
import { WatchListWithChart } from '~/modules/symbolQuote/simple';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import fr_SimpleRow from '~/pages/heineken_template/_fr/fr_SimpleRow';
import Col_WatchSymbolToggleButton from '~/pages/heineken_template/_col/col_WatchSymbolToggleButton';
import Col_ZScoreSymbols from '~/pages/heineken_template/_col/col_ZScoreSymbols';
import { store } from '~/pages/heineken_template/_private/store';
import { hyt888Stock_store } from '~/pages/hyt888_stock/_private/hyt888Stock_store';
import styleds from '~/pages/hyt888_stock/_private/styleds';
import dayAPI from '~/utils/dayAPI';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
export const Hyt888Stock_Sidebar1 = memo(function Sidebar1(props) {
    const agentState = useSnapshot(hyt888Stock_store);
    const pickDate = useDatePick();
    useMount(() => {
        hyt888Stock_store.stockDate.setDate(dayAPI(pickDate).subtract(0, 'day'));
    });
    return (<styleds.Sidebar>
      <styleds.ButtonGroupContent>
        <hyt888Stock_store.tabsColumns.WithButton to='選股'>
          <FuiButton.Display>選股</FuiButton.Display>
        </hyt888Stock_store.tabsColumns.WithButton>

        <hyt888Stock_store.tabsColumns.WithButton to='自選股'>
          <FuiButton.Display>自選股</FuiButton.Display>
        </hyt888Stock_store.tabsColumns.WithButton>
      </styleds.ButtonGroupContent>

      <styleds.ModePageContent>
        <hyt888Stock_store.tabsColumns.WithContent for='選股'>
          <fr_SimpleRow.Display title={'選股日期'}>
            <hyt888Stock_store.stockDate.DatePickerOfMui />
          </fr_SimpleRow.Display>

          <styleds.ButtonGroupContent>
            <hyt888Stock_store.tabsZScore.WithButton to='短線操作'>
              <FuiButton.Display>短線操作</FuiButton.Display>
            </hyt888Stock_store.tabsZScore.WithButton>

            <hyt888Stock_store.tabsZScore.WithButton to='波段操作'>
              <FuiButton.Display>波段操作</FuiButton.Display>
            </hyt888Stock_store.tabsZScore.WithButton>
          </styleds.ButtonGroupContent>

          <hyt888Stock_store.tabsZScore.WithContent for='短線操作'>
            <styleds.Title variant='long'>短線強勢股</styleds.Title>
            <styleds.SymbolList>
              <Col_ZScoreSymbols.Display max={8} useZScorePickProps={{
            date: agentState.stockDate.formatBy('-'),
            days: 3,
            type: 'strong',
            volume: 1e9,
            itemLimit: 2000,
        }}/>
            </styleds.SymbolList>
            <styleds.Title variant='short'>短線弱勢股</styleds.Title>
            <styleds.SymbolList>
              <Col_ZScoreSymbols.Display max={8} useZScorePickProps={{
            date: agentState.stockDate.formatBy('-'),
            days: 3,
            type: 'weak',
            volume: 1e9,
            itemLimit: 2000,
        }}/>
            </styleds.SymbolList>
          </hyt888Stock_store.tabsZScore.WithContent>

          <hyt888Stock_store.tabsZScore.WithContent for='波段操作'>
            <styleds.Title variant='long'>波段走多股</styleds.Title>
            <styleds.SymbolList>
              <Col_ZScoreSymbols.Display max={8} useZScorePickProps={{
            date: agentState.stockDate.formatBy('-'),
            days: 10,
            type: 'strong',
            volume: 1e9,
            itemLimit: 2000,
        }}/>
            </styleds.SymbolList>
            <styleds.Title variant='short'>波段走空股</styleds.Title>
            <styleds.SymbolList>
              <Col_ZScoreSymbols.Display max={8} useZScorePickProps={{
            date: agentState.stockDate.formatBy('-'),
            days: 10,
            type: 'weak',
            volume: 1e9,
            itemLimit: 2000,
        }}/>
            </styleds.SymbolList>
          </hyt888Stock_store.tabsZScore.WithContent>
        </hyt888Stock_store.tabsColumns.WithContent>

        <hyt888Stock_store.tabsColumns.WithContent for='自選股'>
          <styleds.ButtonGroupContent>
            <hyt888Stock_store.tabsUserWatch.WithButton to='多方自選股'>
              <FuiButton.Display variant='long'>多方自選股</FuiButton.Display>
            </hyt888Stock_store.tabsUserWatch.WithButton>

            <hyt888Stock_store.tabsUserWatch.WithButton to='空方自選股'>
              <FuiButton.Display variant='short'>空方自選股</FuiButton.Display>
            </hyt888Stock_store.tabsUserWatch.WithButton>
          </styleds.ButtonGroupContent>

          <styleds.ButtonGroupContent>
            <hyt888Stock_store.tabsUserWatch.WithContent for='多方自選股'>
              <Col_WatchSymbolToggleButton.Display groupName='hyt888_stock_group_1'/>
            </hyt888Stock_store.tabsUserWatch.WithContent>

            <hyt888Stock_store.tabsUserWatch.WithContent for='空方自選股'>
              <Col_WatchSymbolToggleButton.Display groupName='hyt888_stock_group_2'/>
            </hyt888Stock_store.tabsUserWatch.WithContent>
          </styleds.ButtonGroupContent>

          <div css={css `
              ${fill_vertical_cross_center};
              height: calc(100% - 80px);
            `}>
            <hyt888Stock_store.tabsUserWatch.WithContent for='多方自選股'>
              <WatchListWithChart groupName={'hyt888_stock_group_1'} useChart={store.charting} indicators={store.charting.indicators}/>
            </hyt888Stock_store.tabsUserWatch.WithContent>
            <hyt888Stock_store.tabsUserWatch.WithContent for='空方自選股'>
              <WatchListWithChart groupName={'hyt888_stock_group_2'} useChart={store.charting} indicators={store.charting.indicators}/>
            </hyt888Stock_store.tabsUserWatch.WithContent>
          </div>
        </hyt888Stock_store.tabsColumns.WithContent>
      </styleds.ModePageContent>
    </styleds.Sidebar>);
});
