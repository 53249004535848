import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore';
export const gd1788_daytrade2 = createIndicator({
    displayName: '逆勢當沖',
    id: 'gd1788daytrade2',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const length = this._input(0);
            const up_precentage = this._input(1);
            const dn_precentage = this._input(2);
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close = this.ohlc.close;
            const close_array = this.ohlc.closeArray;
            const sma = this.PineJS.Std.sma(close_array, length, this._context);
            const up = sma * (1 + up_precentage);
            const dn = sma * (1 - dn_precentage);
            //--
            const itime = this.PineJS.Std.time(this._context);
            const hours = new Date(itime).getHours();
            const minutes = new Date(itime).getMinutes();
            const openTime = hours === 8 && minutes === 45;
            const closeTime = hours === 15 && minutes === 0;
            const entriesToday = this._context.new_var();
            const entriesLimit = this._context.new_var();
            const marketposition = this._context.new_var();
            const entryPrice = this._context.new_var();
            const targetLong = this._input(5);
            const targetShort = this._input(6);
            const stopLossLong = this._input(7);
            const stopLossShort = this._input(8);
            marketposition.get(1);
            marketposition.get(2);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_exit_icon = NaN;
            let short_exit_icon = NaN;
            //08:45 & 15:00 部位清除 & 交易次數歸零
            if (openTime || closeTime) {
                entriesToday.set(0);
                marketposition.set(0);
                //指定T盤的交易次數
                if (openTime) {
                    entriesLimit.set(this._input(3));
                }
                //指定T+1盤的交易次數
                if (closeTime) {
                    entriesLimit.set(this._input(4));
                }
            }
            const condition_buy = close_array.get(1) < dn &&
                close_array.get(0) > dn &&
                entriesToday.get(0) < entriesLimit.get(0);
            const condition_short = close_array.get(1) > up &&
                close_array.get(0) < up &&
                entriesToday.get(0) < entriesLimit.get(0);
            if (condition_buy) {
                marketposition.set(1);
            }
            if (condition_short) {
                marketposition.set(-1);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                entryPrice.set(close);
                entriesToday.set(entriesToday + 1);
                indicatorsValueStore.entryPrice = close;
                indicatorsValueStore.marketPosition = 1;
                indicatorsValueStore.entryDate = itime;
                indicatorsValueStore.multipleTargetPrice = [
                    entryPrice + targetLong,
                    entryPrice + targetLong * 2,
                ];
                indicatorsValueStore.multipleStopLossPrice = [
                    entryPrice + stopLossLong - 5,
                    entryPrice + stopLossLong + 5,
                ];
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
                entryPrice.set(close);
                entriesToday.set(entriesToday + 1);
                indicatorsValueStore.entryPrice = close;
                indicatorsValueStore.marketPosition = -1;
                indicatorsValueStore.entryDate = itime;
                indicatorsValueStore.multipleTargetPrice = [
                    entryPrice - targetShort,
                    entryPrice - targetShort * 2,
                ];
                indicatorsValueStore.multipleStopLossPrice = [
                    entryPrice + stopLossShort - 5,
                    entryPrice + stopLossShort + 5,
                ];
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                indicatorsValueStore.openPositionProfit = close - entryPrice.get(0);
                if (high > entryPrice.get(0) + targetLong) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    indicatorsValueStore.marketPosition = 0;
                    indicatorsValueStore.entryDate = NaN;
                    indicatorsValueStore.entryPrice = NaN;
                }
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close;
                if (low < entryPrice.get(0) - targetShort) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                    indicatorsValueStore.marketPosition = 0;
                    indicatorsValueStore.entryDate = NaN;
                    indicatorsValueStore.entryPrice = NaN;
                }
            }
            indicatorsValueStore.close = close;
            return [buy_icon, short_icon, buy_exit_icon, short_exit_icon];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
            },
            //樣式調整
            inputs: {
                in_0: 120,
                in_1: 0.003,
                in_2: 0.003,
                in_3: 2,
                in_4: 3,
                in_5: 30,
                in_6: 30,
                in_7: 30,
                in_8: 30,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: 'exit',
                title: '多單參考停利訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                text: 'exit',
                title: '空單參考停利訊號',
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: 'length',
                defval: 120,
                type: 'integer',
                min: 1,
                max: 240,
            },
            {
                id: 'in_1',
                name: 'up-value',
                defval: 0.003,
                type: 'float',
                min: 0.001,
                max: 1,
                step: 0.001,
            },
            {
                id: 'in_2',
                name: 'dn-value',
                defval: 0.003,
                type: 'float',
                min: 0.001,
                max: 1,
                step: 0.001,
            },
            {
                id: 'in_3',
                name: 'T盤交易次數',
                defval: 3,
                type: 'integer',
                min: 0,
                max: 10,
            },
            {
                id: 'in_4',
                name: 'T+1盤交易次數',
                defval: 2,
                type: 'integer',
                min: 0,
                max: 10,
            },
            {
                id: 'in_5',
                name: '多單參考停利點數',
                defval: 30,
                type: 'integer',
                min: 10,
                max: 150,
            },
            {
                id: 'in_6',
                name: '空單參考停利點數',
                defval: 30,
                type: 'integer',
                min: 10,
                max: 150,
            },
            {
                id: 'in_7',
                name: '多單參考停損點數',
                defval: 30,
                type: 'integer',
                min: 10,
                max: 150,
            },
            {
                id: 'in_8',
                name: '空單參考停損點數',
                defval: 30,
                type: 'integer',
                min: 10,
                max: 150,
            },
        ],
        scriptIdPart: '',
    },
});
