import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const winner98_trend_bar = createIndicator({
    displayName: '黑白',
    id: 'winner98trendbar',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const f_0 = (e, t) => {
                return e * t;
            };
            const f_1 = (e, t) => {
                return e + t;
            };
            const f_2 = (e, t) => {
                return e - t;
            };
            const f_3 = (e, t, i) => {
                return (e - t) / (i - t);
            };
            const i = this.PineJS.Std.close(this._context);
            const r = this._input(0);
            const o = this._input(1);
            const s = this._context.new_var(i);
            const a = this.PineJS.Std.sma(s, r, this._context);
            const l = this._context.new_var(i);
            const c = this.PineJS.Std.stdev(l, r, this._context);
            const u = f_0(o, c);
            const h = f_1(a, u);
            const d = f_2(a, u);
            const display_color = f_3(i, d, h) >= 1 || f_3(i, d, h) < 0 ? 0 : 1;
            return [1, -1, display_color, display_color, 3, -3];
        },
    },
    metainfo: {
        is_price_study: !1,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            {
                id: 'powerPalette',
                type: 'colorer',
                target: 'plot_0',
                palette: 'powerPalette',
            },
            {
                id: 'powerPalette',
                type: 'colorer',
                target: 'plot_1',
                palette: 'powerPalette',
            },
            { id: 'plot_2', type: 'line' },
            { id: 'plot_3', type: 'line' },
        ],
        palettes: {
            powerPalette: {
                colors: {
                    0: {
                        name: '-',
                    },
                    1: {
                        name: '-',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 6,
                    plottype: 4,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#d75442',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 6,
                    plottype: 4,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#009712',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    visible: !0,
                    color: '#ffffff',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            palettes: {
                powerPalette: {
                    colors: {
                        0: {
                            color: '#ffffff',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#0c0c0c',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
            precision: 2,
            inputs: { in_0: 20, in_1: 2 },
        },
        styles: {
            plot_0: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
            {
                id: 'in_0',
                name: '長度',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 240,
            },
            {
                id: 'in_1',
                name: '多元',
                defval: 2,
                type: 'float',
                min: 1,
                max: 5,
            },
        ],
    },
});
