import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontWeight600 } from '~/css/font';
import { fill_vertical_cross_center, flex } from '~/modules/AppLayout/FlexGridCss';
import { Charting } from '../heineken_template/_private/Charting';
export const Styleds = {
    Sidebar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: calc(100% - 0px);
    border-radius: 5px;
    border: 1px solid #333333;
    background-image: linear-gradient(to right, #181818, #161820);
  `,
    SidebarTitle: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 32px;
    color: #eeeeee;
    font-size: 20px;
    ${fontWeight600};
    background: linear-gradient(45deg, #515156, #4b525b);
  `,
    TrendChartContainer: styled.div `
    height: 174px;
    width: 100%;
  `,
    StrategyButtonGroup: styled.div `
    ${flex.h.allCenter}
    width:100%;
    height: 35px;
    gap: 4px;
    padding: 4px;
  `,
    SymbolList: styled.div `
    ${flex.v.allCenter};
    height: calc(100% - 424px);
    width: 100%;
  `,
    PostitionInfoContainer: styled.div `
    width: 100%;
    height: 250px;
  `,
    NotifyContainer: styled.div `
    width: 100%;
    height: 168px;
    border-bottom: 1px solid #666666;
  `,
    LogoContainer: styled.div `
    ${fill_vertical_cross_center}
    justify-content: end;
    width: 100%;
    height: calc(100% - 400px);
    padding: 4px;
  `,
    Dashboard: styled.div `
    ${flex.h.allCenter}
    width:100%;
    height: 50px;
    background-color: #333333;
    color: #ffffff;
  `,
    Charting: function () {
        return (<div css={css `
          height: calc(100% - 0px);
          padding: 2px;
          border-radius: 2px;
        `}>
        <Charting />
      </div>);
    },
    Button: styled.div `
    ${flex.h.allCenter};
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    font-size: 18px;

    ${options => {
        const variantDefault = css `
        background-color: #343434;
        border: 1px solid #444444;
        color: #aaaaaa;
      `;
        const variantDefaultActive = options.active &&
            css `
          color: #eeeeee;
          border: solid 1.5px transparent;
          border-radius: 5px;
          background-image: linear-gradient(to right, #4a4454, #444a64),
            linear-gradient(to right, #8f41e9, #578aef);
          background-origin: border-box;
          background-clip: content-box, border-box;
        `;
        return css([variantDefault, variantDefaultActive]);
    }}
  `,
};
