import { css } from '@emotion/react';
import percentDiffChart from '~/modules/SDK/percentDiffChart';
export const ok1788Stock_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      ${(percentDiffChart.classesFill.diffBarFill = '#5069ad55')}
      ${(percentDiffChart.classesFill.compareSymbolLine = '#146eb4')}
      ${(percentDiffChart.classesFill.mainSymbolLine = '#ff9900')}
    }
  `;
};
