import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { edwinGroupName } from '../WatchListGroupName';
import KbarTrustQuote from '../quote/KbarTrustQuote';
import { sortBy } from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useMount } from 'react-use';
import { edwin_store } from '../../edwin_store';
import { css } from '@emotion/react';
import { Button } from '@mantine/core';
export const useTrustDataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
export const TrustContent = memo(function TrustContent(props) {
    const state = useSnapshot(useTrustDataStore);
    const edwinState = useSnapshot(edwin_store);
    const value = props.quoteValue;
    const data = props.data;
    const upDate = edwinState.trustDate.format('YYYY-MM-DD');
    const newData = value.map(s => {
        const matchingData = data.find(item => item.symbol === s.symbol);
        if (matchingData) {
            return {
                signalr: s,
                trust: matchingData,
            };
        }
        else {
            return {
                signalr: s,
                trust: {
                    symbol: '',
                    open: 0,
                    high: 0,
                    low: 0,
                    close: 0,
                    volume: 0,
                    amount: 0,
                    total_volume: 0,
                    diff_volume: 0,
                    diff_volume_percentage: 0,
                },
            };
        }
    });
    //先過濾投信買超很少的
    const filterValueData = newData.filter(val => Math.abs(val.trust.diff_volume) * val.trust.close * 1000 > 1e7);
    //這邊是做另一種資料排序
    const sortData = () => {
        if (state.sortKey === 'diff' && state.orderKey === 'asc') {
            return sortBy(filterValueData, datum => datum.trust.diff_volume * datum.trust.close);
        }
        if (state.sortKey === 'diff' && state.orderKey === 'desc') {
            return sortBy(filterValueData, datum => datum.trust.diff_volume * datum.trust.close).reverse();
        }
        if (state.sortKey === 'diffPercent' && state.orderKey === 'asc') {
            return sortBy(filterValueData, datum => datum.trust.diff_volume_percentage);
        }
        if (state.sortKey === 'diffPercent' && state.orderKey === 'desc') {
            return sortBy(filterValueData, datum => datum.trust.diff_volume_percentage).reverse();
        }
        return filterValueData;
    };
    //第一次預設買賣超金額排序
    useMount(() => {
        useTrustDataStore.sortKey = 'diff';
        useSortSignalrDataStore.sortKey = 'none';
    });
    useEffect(() => {
        edwinState.trustType === 'buy'
            ? (useTrustDataStore.orderKey = 'desc')
            : (useTrustDataStore.orderKey = 'asc');
    }, [edwinState.trustType]);
    return (<styleds.container>
      <styleds.selecter>
        <Button css={css `
            width: 72px;
            height: 32px;
          `} variant='filled' color={edwinState.trustType === 'buy' ? 'red' : 'gray'} radius='md' onClick={() => {
            edwin_store.trustType = 'buy';
        }}>
          買超
        </Button>
        <Button css={css `
            width: 72px;
            height: 32px;
          `} variant='filled' color={edwinState.trustType === 'sell' ? 'green' : 'gray'} radius='md' onClick={() => {
            edwin_store.trustType = 'sell';
        }}>
          賣超
        </Button>
        <styleds.updateContent>資料日期: {upDate}</styleds.updateContent>
      </styleds.selecter>
      <styleds.header>
        <KbarTrustQuote.header />
      </styleds.header>
      <styleds.body>
        {sortData()?.map((quote, index) => {
            return (<KbarTrustQuote.body groupName={edwinGroupName} key={index} data={quote.trust} quote={quote.signalr}/>);
        })}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    selecter: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 36px;
  `,
    updateContent: styled.div `
    ${fill_horizontal_cross_center};
    width: 160px;
    height: 18px;
    line-height: 18px;
    font-size: 13px;
  `,
    header: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 36px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
    height: calc(100% - 80px);
  `,
};
