import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontWeight400 } from '~/css/font';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
const Styleds = {
    /** Sidebar 標題 */
    Title: styled.div `
    ${flex.h.crossCenter};
    width: 100%;
    height: 30px;
    background-color: #0184d622;
    border: 1.5px solid #3387db;
    border-radius: 2px;
    //border-image: linear-gradient(45deg #750cf2, #eeeeee) 10;
    font-size: 16px;
    position: relative;
  `,
    /** Siderbar副標題 */
    SubTitle: styled.div `
    ${flex.h.allCenter};
    ${jc.spaceBetween};
    width: 100%;
    height: 24px;
    font-size: 14px;
    gap: 8px;
    padding: 2px;
    background-color: #192844;
    border: 1.5px solid #3387db;
  `,
    SubText: styled.div `
    ${flex.h.allCenter};
    font-size: 12px;
  `,
    SymbolInfoTitle: styled.div `
    hight: 20px;
    width: 100%;
    font-size: 16px;
    background-color: #0d4c7c44;
    //border: 1.5px solid #3387db;
  `,
    /** Sidebar 文字 */
    Text: styled.div `
    ${flex.h.allCenter};
    font-size: 16px;
  `,
    TrendChartContainer: styled.div `
    width: 95%;
    height: 104px;
    border-radius: 5px;
  `,
    /** 默認按鈕 */
    Button: styled.div `
    ${flex.h.allCenter};
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    font-size: 18px;

    ${options => {
        const variantDefault = css `
        background-color: rgb(179, 181, 189);
        border: 1px solid rgb(153, 153, 153);
        color: rgb(119, 119, 119);
      `;
        const variantDefaultActive = options.active &&
            css `
          color: rgb(34, 34, 34);
          background-color: rgb(224, 227, 235);
          border: 1px solid rgb(92, 110, 126);
        `;
        const variantBuy = options.variant === 'long' &&
            css `
          color: rgb(119, 119, 119);
          background-color: rgb(85, 0, 0);

          &:hover {
            background-color: rgb(187, 0, 0);
          }
        `;
        const variantBuyActive = options.variant === 'long' &&
            options.active &&
            css `
          color: white;
          background-color: rgb(187, 0, 0);
          border: 1px solid rgb(255, 187, 187);
          &:hover {
            background-color: rgb(187, 0, 0);
          }
        `;
        const variantSell = options.variant === 'short' &&
            css `
          color: rgb(119, 119, 119);
          background-color: rgb(0, 85, 0);

          &:hover {
            background-color: rgb(0, 187, 0);
          }
        `;
        const variantSellActive = options.variant === 'short' &&
            options.active &&
            css `
          color: white;
          background-color: rgb(0, 187, 0);
          border: 1px solid rgb(187, 255, 187);

          &:hover {
            background-color: rgb(0, 187, 0);
          }
        `;
        return css([
            variantDefault,
            variantDefaultActive,
            variantBuy,
            variantBuyActive,
            variantSell,
            variantSellActive,
        ]);
    }}
  `,
    Sidebar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: calc(100% - 2px);
    border-radius: 5px;
    font-size: 22px;
    background-size: 100% 40%;
    background: linear-gradient(125deg, #0e1222, #0b203a);
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
    padding: 2px;
    row-gap: 4px;
  `,
    SidebarSection: styled.div `
    ${flex.h.allCenter};
    width: 100%;
  `,
    SidebarButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    gap: 2.5px;
  `,
    SidebarPositionForm: styled.div `
    width: 100%;
    height: 300px;
  `,
    SidebarControlBorad: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 50px;
    gap: 5px;
  `,
    SidebarTrendChart: styled.div `
    width: 100%;
    height: 175px;
  `,
    SidebarSymbolGroup: styled.div `
    width: 100%;
    height: calc(100% - 310px);
  `,
    SidebarSubItemGroup: styled.div `
    ${flex.h.allCenter};
    height: 95%;
    gap: 4px;
  `,
    SidebarSubItemValue: styled.div `
    ${flex.h.allCenter}
    width: 72px;
    height: 100%;
    background-color: #556699;
    border-radius: 5px;
    &.TSE13 {
      background-color: #aa9f54;
    }
    &.TSE17 {
      background-color: #802ba4;
    }
  `,
    SidebarSymbolText: styled.div `
    ${flex.h.allCenter};
    ${fontWeight400};
    &.light {
      color: #333333;
    }
    &.dark {
      color: #dddddd;
    }
  `,
    SidebarPositionButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 26px;
    gap: 5px;
  `,
    SidebarAvtarContainer: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 40px;
  `,
};
export default Styleds;
