import { MantineProvider } from '@mantine/core';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { useSnapshot } from 'valtio';
import { store } from '../heineken_template/_private/store';
import IntradayTrendChart from '~/modules/trendChart';
import styleds from './styleds';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import { jerry597_stockIndicators } from './jerry597_initStrategies';
import { GiRadarSweep } from 'react-icons/gi';
import { MdAddchart } from 'react-icons/md';
import { useBig3ConsecutiveResource } from '~/modules/screener/containers/useStockScreenerResource';
import { staticStore } from '../heineken_template/_private/staticStore';
import { jerry597_store } from './jerry597_store';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import { SymbolModal } from './component/SymbolModal';
import useMedia from '~/hooks/useMedia';
export const Jerry597_SidePane1 = memo(function Jerry597_SidePane1() {
    const theme = useThemeStore(state => state.theme);
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(jerry597_store);
    const { isPc } = useMedia();
    /** 自選股 */
    const watchListGroupString = 'jerry597_stock_group_1';
    /** Firebase老師自行輸入精選股 */
    const long = useFirebaseValue('jerry597_signal', 'long');
    const lastTradeDate = useSnapshot(staticStore).tradedDate.day0;
    const longSymbolData = useBig3ConsecutiveResource({
        date: lastTradeDate.format('YYYY-MM-DD'),
        days: 3,
        action: 'buy',
        institution: 'foreigner',
        amount_gte: 100000000,
        volume_gte: 5000,
    });
    const shortSymbolData = useBig3ConsecutiveResource({
        date: lastTradeDate.format('YYYY-MM-DD'),
        days: 5,
        action: 'sell',
        institution: 'foreigner',
        amount_gte: 100000000,
        volume_gte: 5000,
    });
    const longSymbol = longSymbolData.data?.map(s => s.symbol)?.slice(0, 10) ?? [];
    const shortSymbol = shortSymbolData.data?.map(s => s.symbol)?.slice(0, 10) ?? [];
    // console.log('Strategy DisplayName =>', store.charting.strategySelected.displayName)
    return (<MantineProvider theme={{ colorScheme: theme }}>
      <styleds.SideBar1 className={theme}>
        <styleds.IntradayTrendChart className={theme}>
          <styleds.AreaTitle className={theme}>
            <TitleSymbolQuote.Default symbol={charting.symbol}/>
          </styleds.AreaTitle>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={14} ticksHeight={16} transparency={0.4} priceTicksSize={14} priceTicksMargin={-15}/>
        </styleds.IntradayTrendChart>
        <styleds.WatchlistButton>
          <div css={fill_vertical_all_center}>自選股狀態</div>
          <AddSymbolButtonSmall groupName={watchListGroupString} symbolNumber={charting.symbol} listSize={50}/>
        </styleds.WatchlistButton>

        <styleds.StrategyArea className={theme}>
          <styleds.AreaTitle className={theme}>
            <MdAddchart size={'30px'} fill={'#ff5500'}/>
            功能選擇
          </styleds.AreaTitle>
          <styleds.StrategyGroup>
            <styleds.SymbolTypeButton className={theme} selected={state.pageMode === 'screener'} onClick={() => (jerry597_store.pageMode = 'screener')}>
              選股
            </styleds.SymbolTypeButton>
            <styleds.SymbolTypeButton className={theme} selected={state.pageMode === 'pro'} onClick={() => (jerry597_store.pageMode = 'pro')}>
              精選
            </styleds.SymbolTypeButton>
            <styleds.SymbolTypeButton className={theme} selected={state.pageMode === 'watchlist'} onClick={() => (jerry597_store.pageMode = 'watchlist')}>
              自選
            </styleds.SymbolTypeButton>
          </styleds.StrategyGroup>
        </styleds.StrategyArea>

        {state.pageMode === 'screener' && (<styleds.SymbolLsitArea className={theme}>
            <styleds.AreaTitle className={theme}>
              <GiRadarSweep size={'30px'} fill={'#ff5500'}/>
              金旺選股
              {isPc && (<SymbolModal symbolLong={longSymbol} symbolShort={shortSymbol} symbolFeatured={long?.symbols ?? []}/>)}
            </styleds.AreaTitle>
            <styleds.ScreenerTitle variant='long'>金旺多方選股</styleds.ScreenerTitle>
            <styleds.SymbolList>
              <SimpleQuoteListInteractWithChart data={longSymbol} chart={store.charting} indicators={jerry597_stockIndicators}/>
            </styleds.SymbolList>
            <styleds.ScreenerTitle variant='short'>金旺空方選股</styleds.ScreenerTitle>
            <styleds.SymbolList>
              <SimpleQuoteListInteractWithChart data={shortSymbol} chart={store.charting} indicators={jerry597_stockIndicators}/>
            </styleds.SymbolList>
          </styleds.SymbolLsitArea>)}
        {state.pageMode === 'pro' && (<styleds.SymbolLsitArea>
            <SimpleQuoteListInteractWithChart data={long?.symbols ?? []} chart={store.charting}/>
          </styleds.SymbolLsitArea>)}
        {state.pageMode === 'watchlist' && (<styleds.SymbolLsitArea>
            <WatchListWithChart groupName={watchListGroupString} useChart={store.charting}/>
          </styleds.SymbolLsitArea>)}
      </styleds.SideBar1>
    </MantineProvider>);
});
