import { bandtrade_enduring } from '~/trades/indicators/win168/bandtrade_enduring';
import { bandtrade_shorter } from '~/trades/indicators/win168/bandtrade_shorter';
import { daytrade_default } from '~/trades/indicators/win168/daytrade_default';
import { store } from '~/pages/heineken_template/_private/store';
import dayAPI from '~/utils/dayAPI';
export const win168_initStrategies = () => {
    store.charting.initStrategy({
        configs: [
            {
                displayName: '豐收1號(順勢當沖)',
                indicators: [daytrade_default],
                symbol: 'TXAM-1',
                interval: '3',
                calcFrom: dayAPI().subtract(30, 'day'),
            },
            // {
            //   displayName: '豐收2號(逆勢)',
            //   indicators: [daytrade_countertrend],
            //   symbol: 'TXAM-1',
            //   interval: '3',
            // },
            {
                displayName: '短波聚財(短波段)',
                indicators: [bandtrade_shorter],
                symbol: 'TX-1',
                interval: '60',
            },
            {
                displayName: '長波聚寶(長波段)',
                indicators: [bandtrade_enduring],
                symbol: 'TX-1',
                interval: '60',
            },
            // {
            //   displayName: '日日春',
            //   indicators: [brandtrade_spring, morerich_kd],
            //   symbol: 'TX-1',
            //   interval: '60',
            // },
        ],
    });
};
