import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { AgentCarouselSubscriptionCheck } from '~/modules/auth/components/AgentCarouselSubscriptionCheck';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { imoney8888_initStrategies } from './imoney8888_initStrategies';
import { imoney8888_initStyling } from './imoney8888_initStyling';
import { Imoney8888_SidePane1 } from './imoney8888_SidePane1';
import { Imoney8888_TopBar } from './imoney8888_Topbar';
export const imoney8888_init = (templateProps) => {
    imoney8888_initStyling(templateProps);
    imoney8888_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<AgentCarouselSubscriptionCheck resources={[{ image: [true, '/imoney8888/loginView.png'] }]}/>);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../imoney8888/chartingTheme.css',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: '2330',
        interval: 'D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        overrides: store.charting.darkOverrides,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Chart'
      'Drawer1 Chart Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Imoney8888_TopBar;
    templateProps.layout.Drawer1 = Imoney8888_SidePane1;
};
