import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
//均線排列
export const pionex_strategy02 = createIndicator({
    id: 'pionex-strategy02',
    displayName: 'pionex_strategy02',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const datetime = dayAPI(this.PineJS.Std.time(context));
            const close = this.ohlc.close;
            const open = this.ohlc.open;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close_array = this.ohlc.closeArray;
            const ma1 = this.PineJS.Std.sma(close_array, 7, this._context);
            const ma2 = this.PineJS.Std.sma(close_array, 14, this._context);
            const ma3 = this.PineJS.Std.sma(close_array, 21, this._context);
            const ma1_array = this._context.new_var(ma1);
            const ma2_array = this._context.new_var(ma2);
            const ma3_array = this._context.new_var(ma3);
            const atr = this.PineJS.Std.atr(24, this._context);
            const atr_array = this._context.new_var(atr);
            const atr_ma = this.PineJS.Std.sma(atr_array, 240, this._context);
            const atr_up = atr_ma * 1.1;
            const atr_dn = atr_ma * 0.9;
            const sma = this.PineJS.Std.ema(close_array, 44, this._context);
            const up = sma * (1 + 0.007);
            const dn = sma * (1 - 0.007);
            ma1_array.get(2);
            ma2_array.get(2);
            ma3_array.get(2);
            const position = context.new_var();
            const entryPrice = context.new_var();
            const lastPositionType = context.new_var();
            position.get(1);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            let buy_exit_icon = NaN;
            let short_exit_icon = NaN;
            // ---------------------------------------- Condition ----------------------------------------
            const longEntry = ma1_array.get(0) > ma1_array.get(1) &&
                ma2_array.get(0) > ma2_array.get(1) &&
                ma3_array.get(0) > ma3_array.get(1) &&
                atr > atr_up &&
                ma1 > ma2 &&
                ma2 > ma3 &&
                open > ma1 &&
                lastPositionType.get(0) !== 1;
            const shortEntry = ma1_array.get(0) < ma1_array.get(1) &&
                ma2_array.get(0) < ma2_array.get(1) &&
                ma3_array.get(0) < ma3_array.get(1) &&
                atr > atr_up &&
                ma1 < ma2 &&
                ma2 < ma3 &&
                open < ma1 &&
                lastPositionType.get(0) !== -1;
            // ---------------------------------------- Entry ----------------------------------------
            if (longEntry)
                position.set(1);
            if (shortEntry)
                position.set(-1);
            if (position.get(0) === 1 && position.get(1) !== 1) {
                entryPrice.set(close);
                lastPositionType.set(1);
                buy_icon = 1;
            }
            if (position.get(0) === -1 && position.get(1) !== -1) {
                entryPrice.set(close);
                lastPositionType.set(-1);
                short_icon = 1;
            }
            // ---------------------------------------- Exit ----------------------------------------
            if (position.get(0) === 1 && position.get(1) === 1) {
                if (high > entryPrice.get(1) * 1.05) {
                    buy_target_icon = 1;
                    position.set(0);
                }
                if (close < dn) {
                    buy_exit_icon = 1;
                    position.set(0);
                }
            }
            if (position.get(0) === -1 && position.get(1) === -1) {
                if (low < entryPrice.get(1) * 0.95) {
                    short_target_icon = 1;
                    position.set(0);
                }
                if (close > up) {
                    short_exit_icon = 1;
                    position.set(0);
                }
            }
            const colorer = () => {
                return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2;
            };
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                buy_exit_icon,
                short_exit_icon,
                colorer(),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag',
                type: 'shapes',
            },
            {
                id: 'entryShortFlag',
                type: 'shapes',
            },
            {
                id: 'targetLongFlag',
                type: 'shapes',
            },
            {
                id: 'targetShortFlag',
                type: 'shapes',
            },
            {
                id: 'exitLongFlag',
                type: 'shapes',
            },
            {
                id: 'exitShortFlag',
                type: 'shapes',
            },
            {
                id: '部位',
                type: 'bar_colorer',
                palette: 'barPalette',
            },
        ],
        defaults: {
            styles: {
                entryLongFlag: { color: '#f04848', textColor: '#f04848', transparency: 10, visible: true },
                entryShortFlag: { color: '#00b070', textColor: '#00b070', transparency: 10, visible: true },
                targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
                targetShortFlag: {
                    color: '#00b070',
                    textColor: '#fafafa',
                    transparency: 20,
                    visible: true,
                },
                exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
                exitShortFlag: { color: '#00b070', textColor: '#fafafa', transparency: 20, visible: true },
            },
        },
        styles: {
            entryLongFlag: {
                title: '買進',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_triangle_up',
                size: 'small',
                text: 'Buy',
            },
            entryShortFlag: {
                title: '賣出',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_triangle_down',
                size: 'small',
                text: 'Short',
            },
            targetLongFlag: {
                title: '買進停利',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_label_down',
                size: 'tiny',
                text: 'Target',
            },
            targetShortFlag: {
                title: '賣出停利',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_label_up',
                size: 'tiny',
                text: 'Target',
            },
            exitLongFlag: {
                title: '買進出場',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_label_up',
                size: 'tiny',
                text: 'Exit',
            },
            exitShortFlag: {
                title: '賣出出場',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_label_down',
                size: 'tiny',
                text: 'Exit',
            },
        },
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#b2b5be',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
