import { css } from '@emotion/react';
import { store } from '~/pages/heineken_template/_private/store';
import TemplateTheme from '../heineken_template/_private/TemplateTheme';
export const win988_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      ${TemplateTheme.classes.Dark} {
      }
    }
  `;
};
store.charting.darkOverrides = {
    'paneProperties.legendProperties.showLegend': false,
    'paneProperties.topMargin': 5,
    'paneProperties.bottomMargin': 5,
    'scalesProperties.fontSize': 14,
    'paneProperties.backgroundType': 'gradient',
    'paneProperties.backgroundGradientStartColor': '#141517',
    'paneProperties.backgroundGradientEndColor': '#171718',
    'paneProperties.vertGridProperties.color': '#333333',
    'paneProperties.horzGridProperties.color': '#333333',
    'mainSeriesProperties.candleStyle.downColor': '#00ee00',
    'mainSeriesProperties.candleStyle.upColor': '#ee0000',
    'scalesProperties.textColor': '#cccccc',
    'scalesProperties.lineColor': '#cccccc',
};
