import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { wu5868_store } from '../wu5868_SidePane2';
import dayAPI from '~/utils/dayAPI';
import { apirc } from '~/configs/apirc';
import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { Select } from '@mantine/core';
import { FcCalendar } from 'react-icons/fc';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
import { wu5868_strategiesDayTradeGroup } from '../wu5868_initStrategies';
export const StockPick = memo(function StockPick() {
    //盤中: 09:00 ~ 15:00 => lastTradeDate
    //收盤後: 15:00 ~ 05:00 => beforeLastTradeDate
    //test --
    const intraday = useSnapshot(staticStore).tradedDate.intraday;
    const currentDate = dayAPI();
    const tradeDateArray = useSnapshot(staticStore)
        .tradedDate.days.concat(intraday)
        .sort((a, b) => b.unix() - a.unix());
    const result = Array.from(new Set(tradeDateArray.map(s => s.format('YYYY-MM-DD'))));
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const tradeDate = useSnapshot(wu5868_store).tradeDate;
    const fromDate = dayAPI(tradeDate);
    const endDate = dayAPI(fromDate).set('hour', 23).set('minute', 59);
    console.log({
        fromDate: intraday.format('YYYY-MM-DD HH:mm'),
        currentDate: currentDate.format('YYYY-MM-DD HH:mm'),
        new_fromDate: fromDate.format('YYYY-MM-DD HH:mm'),
        new_currentDate: endDate.format('YYYY-MM-DD HH:mm'),
    });
    useEffect(() => {
        wu5868_store.tradeDate = intraday.format('YYYY-MM-DD');
    }, [intraday]);
    const newHighSymbol = apirc.signalWall.data
        .useAsync({
        signal_id: SignalWallId.展哥突破5日高,
        from: fromDate,
        to: endDate,
    })
        .data?.map(f => f.symbol);
    const lowBuySymbol = apirc.signalWall.data
        .useAsync({
        signal_id: SignalWallId.展哥拉回買進,
        from: fromDate,
        to: endDate,
    })
        .data?.map(f => f.symbol);
    const newHighSymbol_ = newHighSymbol?.filter((symbol, index) => newHighSymbol.indexOf(symbol) === index);
    const lowBuySymbol_ = lowBuySymbol?.filter((symbol, index) => lowBuySymbol.indexOf(symbol) === index);
    return (<div css={fill_vertical_cross_center}>
      <styleds.container>
        <div css={fill_horizontal_all_center}>選股日期</div>
        <Select icon={<FcCalendar size='24px'/>} defaultValue={result[0]} data={result} onChange={(date) => {
            wu5868_store.tradeDate = date;
        }}/>
      </styleds.container>
      <styleds.area>
        <styleds.title>創五日新高</styleds.title>
        <styleds.list>
          <SimpleQuoteListInteractWithChart data={newHighSymbol_ ?? []} chart={store.charting} handleClick={() => {
            store.charting.setStrategy(wu5868_strategiesDayTradeGroup.main[0]);
            //store.charting.changeInterval('5')
            //store.charting.updateFromStrategyState()
        }}/>
        </styleds.list>
      </styleds.area>
      <styleds.area>
        <styleds.title>拉回買進</styleds.title>
        <styleds.list>
          <SimpleQuoteListInteractWithChart data={lowBuySymbol_ ?? []} chart={store.charting}/>
        </styleds.list>
      </styleds.area>
    </div>);
});
const styleds = {
    container: styled.div `
    display: grid;
    grid-template-columns: 36% 64%;
    height: 40px;
  `,
    area: styled.div `
    ${fill_vertical_all_center};
    height: calc(50% - 24px);
  `,
    title: styled.div `
    ${fill_vertical_all_center};
    height: 32px;
    background-color: #cecece;
    border-radius: 4px;
  `,
    list: styled.div `
    ${fill_vertical_all_center};
    height: calc(100% - 32px);
  `,
};
