import { css } from '@emotion/react';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { fontWeight600 } from '~/css/font';
import { fill_vertical_main_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import dayAPI from '~/utils/dayAPI';
import { optionAnalyzeStore } from './optionAnalyze/optionAnalyzeStore';
import { getWeeklyMxfContract } from './optionAnalyze/useGetOptionContract';
export const TimeCard = memo(function TimeCard() {
    const dayTime = new Date();
    const [time, setTime] = useState(dayTime);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const result = dayAPI(time).format('HH:mm:ss');
    return (<div css={css `
        ${cardBaseStyled};
        background: linear-gradient(90deg, #d4d145, #efbe44);
        color: #222222;
        ${fontWeight600};
        font-size: 28px;
      `}>
      {result}
    </div>);
});
export const DefaultCard = memo(function DefaultTime(props) {
    const value = isNaN(props.value) ? '-' : props.value;
    return (<div css={css `
        ${cardBaseStyled};
        background: linear-gradient(90deg, #2b458f, #4372d1);
      `}>
      <div css={css `
          font-size: 14px;
        `}>
        {props.children}
      </div>
      <div css={css `
          ${fontWeight600}
        `}>
        {value}
      </div>
    </div>);
});
export const CheapCard = memo(function CheapCard(props) {
    const value = isNaN(props.value) ? '-' : props.value;
    const fill1 = props.value > 50 ? '#FF0000' : '#5b984e';
    const fill2 = props.value > 50 ? '#fe3535' : '#7eb462';
    return (<div css={css `
        ${cardBaseStyled};
        background: linear-gradient(90deg, ${fill1}, ${fill2});
      `}>
      <div css={css `
          font-size: 16px;
        `}>
        {props.children}
      </div>
      <div css={css `
          ${fontWeight600}
        `}>
        {value}
      </div>
    </div>);
});
/** 台指期 */
export const TXFQuoteCard = memo(function TXFQuoteCard() {
    useEffect(() => {
        signalrStore2.addQuote(['TX-1']);
        return () => {
            signalrStore2.removeQuote(['TX-1']);
        };
    }, [JSON.stringify(['TX-1'])]);
    const value = signalrHooks2.useQuotes(['TX-1']);
    return <DefaultCard value={value.map(s => s.close)[0]}>台指期</DefaultCard>;
});
/** 週小台 */
export const MTXQuoteCard = memo(function MTXQuoteCard() {
    const state = useSnapshot(optionAnalyzeStore);
    const mtx = getWeeklyMxfContract(state.currentContract);
    useEffect(() => {
        signalrStore2.addQuote([mtx]);
        return () => {
            signalrStore2.removeQuote([mtx]);
        };
    }, [JSON.stringify([mtx])]);
    const value = useSnapshot(signalrStore2.values.quote)[mtx];
    const bid = value?.bid ?? NaN;
    const ask = value?.ask ?? NaN;
    const price = Math.round((bid + ask) / 2);
    return (<DefaultCard value={price}>週小台{getWeeklyMxfContract(state.currentContract)}</DefaultCard>);
});
/** 加權指 */
export const TSEAQuoteCard = memo(function TSEAQuoteCard() {
    useEffect(() => {
        signalrStore2.addQuote(['TSEA']);
        return () => {
            signalrStore2.removeQuote(['TSEA']);
        };
    }, [JSON.stringify(['TSEA'])]);
    const value = signalrHooks2.useQuotes(['TSEA']);
    return <DefaultCard value={value.map(s => s.close)[0]}>加權指數</DefaultCard>;
});
const cardBaseStyled = css `
  ${fill_vertical_main_center};
  height: 64px;
  width: 100%;
  border-radius: 5px;
  font-size: 24px;
  padding: 0 8px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    .margin-left: 4px;
    width: 102%;
    transition: 0.4s;
  }
`;
