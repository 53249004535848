import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { PositionInfomation } from '~/modules/investment-consultant/positionValueForm/PositonInfotion';
import { store } from '~/pages/heineken_template/_private/store';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { s178178_strategiesGroup } from './s178178_initStrategies';
import { Styleds } from './styleds';
import { useGetIndicatorsValue } from './useIndicatorsValue';
import useMedia from '~/hooks/useMedia';
export const S178178_SidePane2 = memo(function S178178_SidePane2() {
    const charting = useSnapshot(store.charting);
    const { isPc } = useMedia();
    return (<Styleds.Sidebar>
      <Styleds.Dashboard>
        <ChartingServerSwitch charting={store.charting}/>
        <UserAvatarAsDialogButton />
      </Styleds.Dashboard>
      <Styleds.SidebarTitle>策略選擇</Styleds.SidebarTitle>
      <Styleds.StrategyButtonGroup>
        {s178178_strategiesGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<Styleds.Button key={config.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </Styleds.Button>);
        })}
      </Styleds.StrategyButtonGroup>
      <Styleds.SidebarTitle>商品部位資訊</Styleds.SidebarTitle>
      <Styleds.PostitionInfoContainer>
        <PositionInfomation useChart={store.charting} PositionOptions={useGetIndicatorsValue()}/>
      </Styleds.PostitionInfoContainer>

      {isPc && (<Styleds.LogoContainer>
          <img src='/s178178/logo.png' css={css `
              max-width: 100%;
              max-height: 100%;
              border-radius: 3px;
            `}/>
        </Styleds.LogoContainer>)}
    </Styleds.Sidebar>);
});
