import { css } from '@emotion/react';
import { memo, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
import { lung88988Stock_agentStore } from './lung88988Stock_agentStore';
import { dayTradeIndicators } from './lung88988Stock_initStrategies';
import { sortSymbolByValue } from '../modules/sortSymbolByValue';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
//持倉中
export const RealtimeSymbolPage = memo(function RealtimeSymbolPage() {
    const realtimeLongHolding_ = useVirtualAccountHolding('lung88988_long');
    const realtimeShortHolding_ = useVirtualAccountHolding('lung88988_short');
    const realtimeLongHolding = useMemo(() => {
        return realtimeLongHolding_?.map(s => s.symbol);
    }, [realtimeLongHolding_]);
    const realtimeShortHolding = useMemo(() => {
        return realtimeShortHolding_?.map(s => s.symbol);
    }, [realtimeShortHolding_]);
    const allSymbol = realtimeLongHolding?.concat(realtimeShortHolding ?? []);
    useEffect(() => {
        //signalrStore2.addQuote(allSymbol)
        useSignalrStore.getState().subscribeAdd([...(allSymbol || [])], 'ohlc');
        return () => {
            //signalrStore2.removeQuote(allSymbol)
            useSignalrStore.getState().subscribeRemove([...(allSymbol || [])], 'ohlc');
        };
    }, [JSON.stringify(allSymbol), JSON.stringify(realtimeLongHolding), JSON.stringify(realtimeShortHolding)]);
    const value = useSignalrStoreValueOHLC(state => state.useGroupOHLC(allSymbol ?? []));
    /** 經過成交量盤排序的多方商品 */
    const resultLong = useMemo(() => {
        return sortSymbolByValue(value, realtimeLongHolding ?? [], 'volume').slice(0, 10);
    }, [realtimeLongHolding, value]);
    /** 經過成交量盤排序的空方商品 */
    const resultShort = useMemo(() => {
        return sortSymbolByValue(value, realtimeShortHolding ?? [], 'volume').slice(0, 10);
    }, [realtimeShortHolding, value]);
    const type = useSnapshot(lung88988Stock_agentStore);
    const resultSymbol = () => {
        if (type.realTimeType === 'long')
            return resultLong;
        else if (type.realTimeType === 'short')
            return resultShort;
    };
    return (<div css={css `
        width: 100%;
        height: 100%;
      `}>
      <div css={css `
          ${flex.h.allCenter};
          height: 30px;
          gap: 4px;
        `}>
        <FuiButton.Display variant='long' active={type.realTimeType === 'long'} onClick={event => {
            lung88988Stock_agentStore.realTimeType = 'long';
        }}>
          多方標的
        </FuiButton.Display>
        <FuiButton.Display variant='short' active={type.realTimeType === 'short'} onClick={event => {
            lung88988Stock_agentStore.realTimeType = 'short';
        }}>
          空方標的
        </FuiButton.Display>
      </div>

      <div css={css `
          ${flex.h.allCenter};
          height: calc(100% - 32px);
        `}>
        <SimpleQuoteListInteractWithChart data={resultSymbol() ?? []} indicators={dayTradeIndicators} chart={store.charting} noAutoSubscribe={true} handleClick={() => store.charting.changeInterval('5')}/>
      </div>
    </div>);
});
