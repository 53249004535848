import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { sgxIndicatorsValueStorage } from '~/pages/sgx/sgx_store';
export const sgx_valueProvider = createIndicator({
    displayName: '-',
    id: 'sgx-valueProvider',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const length1 = 5;
            const length2 = 10;
            const length3 = 20;
            const length4 = 60;
            const length5 = 120;
            const close = this.ohlc.closeArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const volumeValue = this.PineJS.Std.volume(this._context);
            const volume = this._context.new_var(volumeValue);
            close.get(200);
            high.get(200);
            low.get(200);
            volume.get(200);
            const ma5Value = this.average(close, length1);
            const ma10Value = this.average(close, length2);
            const ma20Value = this.average(close, length3);
            const ma60Value = this.average(close, length4);
            const ma120Value = this.average(close, length5);
            const ma5 = this._context.new_var(ma5Value);
            const ma10 = this._context.new_var(ma10Value);
            const ma20 = this._context.new_var(ma20Value);
            const ma60 = this._context.new_var(ma60Value);
            const ma120 = this._context.new_var(ma120Value);
            ma5.get(200);
            ma10.get(200);
            ma20.get(200);
            ma60.get(200);
            ma120.get(200);
            // Mov. Avg.
            sgxIndicatorsValueStorage.ma5 = ma5Value;
            sgxIndicatorsValueStorage.ma10 = ma10Value;
            sgxIndicatorsValueStorage.ma20 = ma20Value;
            sgxIndicatorsValueStorage.ma60 = ma60Value;
            sgxIndicatorsValueStorage.ma120 = ma120Value;
            sgxIndicatorsValueStorage.slope5 = ma5.get(0) - ma5.get(1);
            sgxIndicatorsValueStorage.slope10 = ma10.get(0) - ma10.get(1);
            sgxIndicatorsValueStorage.slope20 = ma20.get(0) - ma20.get(1);
            sgxIndicatorsValueStorage.slope60 = ma60.get(0) - ma60.get(1);
            sgxIndicatorsValueStorage.slope120 = ma120.get(0) - ma120.get(1);
            // Bias
            sgxIndicatorsValueStorage.bias5 = this.bias(length1);
            sgxIndicatorsValueStorage.bias20 = this.bias(length3);
            sgxIndicatorsValueStorage.bias60 = this.bias(length4);
            sgxIndicatorsValueStorage.bias120 = this.bias(length5);
            // Rsv
            sgxIndicatorsValueStorage.rsv5 =
                ((close.get(0) - this.lowest(low, length1)) /
                    (this.highest(high, length1) - this.lowest(low, length1))) *
                    100;
            sgxIndicatorsValueStorage.rsv20 =
                ((close.get(0) - this.lowest(low, length3)) /
                    (this.highest(high, length3) - this.lowest(low, length3))) *
                    100;
            sgxIndicatorsValueStorage.rsv60 =
                ((close.get(0) - this.lowest(low, length4)) /
                    (this.highest(high, length4) - this.lowest(low, length4))) *
                    100;
            sgxIndicatorsValueStorage.rsv120 =
                ((close.get(0) - this.lowest(low, length5)) /
                    (this.highest(high, length5) - this.lowest(low, length5))) *
                    100;
            // Dmi
            const dmi5 = this.dmi(length1, 14);
            const dmi20 = this.dmi(length3, 14);
            const dmi60 = this.dmi(length4, 14);
            const dmi120 = this.dmi(length5, 14);
            sgxIndicatorsValueStorage.dmi5 = dmi5[0] > dmi5[1] ? 1 : -1;
            sgxIndicatorsValueStorage.dmi20 = dmi20[0] > dmi20[1] ? 1 : -1;
            sgxIndicatorsValueStorage.dmi60 = dmi60[0] > dmi60[1] ? 1 : -1;
            sgxIndicatorsValueStorage.dmi120 = dmi120[0] > dmi120[1] ? 1 : -1;
            return [NaN];
        },
    },
    metainfo: {
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 100,
                    visible: !1,
                    color: '#fe80c0',
                },
            },
        },
        is_price_study: !0,
        scriptIdPart: '',
        inputs: [],
    },
});
