import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { memo } from 'react';
import { ResponsiveContainer, YAxis, XAxis, Bar, Tooltip, CartesianGrid, ComposedChart, Line, Label, } from 'recharts-new';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
const DayPriceDiffChart = memo(function SimpleTrendChart(props) {
    /** 後端最新交易資料 國內的交易日期 */
    const data = props.data;
    if (!data || (data && data.length === 0))
        return (<div css={css `
          ${fill_horizontal_all_center}
        `}>
        ...尚未載入
      </div>);
    //價差diff
    const maxDiff = Math.max(...(data?.map(datum => datum?.diff ?? 0) || []));
    const minDiff = Math.min(...(data?.map(datum => datum?.diff ?? 0) || []));
    const absMaxDiff = Math.max(Math.abs(maxDiff), Math.abs(minDiff));
    const yRangeDiff = [-absMaxDiff, absMaxDiff];
    const yTickDiff = [
        -absMaxDiff,
        -absMaxDiff / 2,
        0,
        absMaxDiff / 2,
        absMaxDiff,
    ];
    //價格
    const maxClose1 = Math.max(...(data?.map(datum => (datum.close1 !== null ? datum.close1 : 0)) || []));
    const minClose1 = Math.min(...(data?.map(datum => (datum.close1 !== null ? datum.close1 : 0)) || []));
    const maxClose2 = Math.max(...(data?.map(datum => (datum.close2 !== null ? datum.close2 : 0)) || []));
    const minClose2 = Math.min(...(data?.map(datum => (datum.close2 !== null ? datum.close2 : 0)) || []));
    const priceMax = Math.max(maxClose1, maxClose2);
    const priceMin = Math.max(minClose1, minClose2);
    const yRangePrice = [
        priceMax * 1.01,
        (priceMax + priceMin) / 2,
        priceMin * 0.99,
    ];
    const yTickPrice = [priceMax * 1.01, priceMin * 0.99];
    return (<ResponsiveContainer width='100%' height='100%'>
      <ComposedChart css={css `
          cursor: pointer !important;
        `} data={data} margin={{
            top: 16,
            right: -40,
            left: -32,
            bottom: 1,
        }}>
        <CartesianGrid strokeDasharray='0 0' vertical={false}/>
        <XAxis dataKey='date' tickFormatter={str => str.slice(-5)}/>
        <YAxis width={86} yAxisId={1} fontSize={12} domain={yRangeDiff} ticks={yTickDiff}>
          <Label value={'價差'} angle={-90} position='outside' fill='#666666' fontSize={14}/>
        </YAxis>
        <YAxis width={104} yAxisId={2} orientation='right' fontSize={12} domain={yTickPrice} ticks={yRangePrice} tickFormatter={str => str.toFixed(0)}>
          <Label value={'指數'} angle={-90} position='outside' fill='#666666' fontSize={14}/>
        </YAxis>

        <Tooltip content={<CustomizedTooltip />}/>
        <Bar yAxisId={1} barSize={12} dataKey='diff' fill='#b6d13e' fillOpacity={0.9} isAnimationActive={false}/>
        <Line yAxisId={2} type='linear' dot={false} dataKey='close1' stroke='#0f8baa'/>
        <Line yAxisId={2} type='linear' dot={false} dataKey='close2' stroke='#F7552F'/>
      </ComposedChart>
    </ResponsiveContainer>);
});
const CustomizedTooltip = ({ payload }) => {
    if (!payload || (payload && payload.length < 1))
        return null;
    const datum = payload[0].payload;
    const date = datum.date;
    const diff = datum.diff;
    const close1 = datum.close1;
    const close2 = datum.close2;
    const symbol1 = datum.symbol1;
    const symbol2 = datum.symbol2;
    return (<styleds.container>
      <div css={css `
          color: #222222;
        `}>
        日期: {date}
      </div>
      <div css={css `
          color: #222222;
        `}>
        價差: {diff}
      </div>
      <div css={css `
          color: #0f8baa;
        `}>
        {symbol1}: {close1}
      </div>
      <div css={css `
          color: #f7552f;
        `}>
        {symbol2}: {close2}
      </div>
    </styleds.container>);
};
const styleds = {
    container: styled.div `
    width: 148px;
    padding: 4px;
    background-color: #fafafacc;
    opacity: 1;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
  `,
};
export default DayPriceDiffChart;
