import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
export const SymbolList = memo(function SymbolList(props) {
    return (<styleds.container>
        <styleds.header fill={props.titleFillColor}>{props.children}</styleds.header>
        <styleds.body fill={props.titleFillColor + '33'}>
          <SimpleQuoteListInteractWithChart data={props.symbol} chart={store.charting} noAutoSubscribe={false}/>
        </styleds.body>
      </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 30px calc(100% - 32px);
    gap: 2px;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    border-radius: 4px;
    color: #ffffff;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    background-color: ${props => props.fill};
    border-radius: 4px;
  `,
};
