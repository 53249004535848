import styled from '@emotion/styled';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import SymbolHoldingProfitList from '~/modules/investment-consultant/symbolList/list/SymbolHoldingProfitList';
import { useIntradayPick } from '~/modules/screener/useDatePick';
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { store } from '~/pages/heineken_template/_private/store';
import dayAPI from '~/utils/dayAPI';
export const StockHolding = memo(function StockHolding() {
    const intraDay = useIntradayPick();
    const userStartDate = dayAPI(intraDay).subtract(80, 'days').format('YYYY-MM-DD');
    const holding = useVirtualAccountHolding('wu5868_stock_long')?.filter(s => s.lastModifiedDatetime > userStartDate);
    const holdingLong = holding
        ?.filter(s => s.qty > 0 && s.marketPrice / s.averagePrice > 0.99)
        .sort((a, b) => b.marketPrice / b.averagePrice - a.marketPrice / a.averagePrice) ?? [];
    const longLength = holdingLong.length;
    return (<styleds.container>
      <styleds.header>持有商品</styleds.header>
      <styleds.body>
        <SymbolHoldingProfitList.DisplayStyled2 data={holdingLong ?? []} chart={store.charting}/>
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 32px;
    background-color: #ac2222;
    border-radius: 4px;
    color: #fafafa;
  `,
    body: styled.div `
    ${scrollbar2Css};
    height: calc(100% - 36px);
  `,
};
