import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const pionex_ladder = createIndicator({
    displayName: '趨勢階梯',
    id: 'pionex-ladder',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const length = 14;
            const close = this.ohlc.close;
            const close_array = this.ohlc.closeArray;
            const ma = this.PineJS.Std.sma(close_array, 60, this._context);
            const dc = this.ladderChannl(length);
            const mid = dc[4];
            const colorIndex = dc[4] >= dc[5] ? 0 : 1;
            return [mid, colorIndex];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'colorer',
                target: 'plot_0',
                palette: 'palette_0',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 3,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 50,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#e15241',
                            width: 3,
                            style: 0,
                        },
                        1: {
                            color: '#42a5f5',
                            width: 3,
                            style: 0,
                        },
                    },
                },
            },
            inputs: {
            // in_0: 14,
            },
        },
        styles: {
            plot_0: {
                title: '攻擊線顏色',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: '長度',
        //   defval: 14,
        //   type: 'integer',
        //   min: 1,
        //   max: 100,
        // },
        ],
        scriptIdPart: '',
    },
});
