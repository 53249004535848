import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const edwin_macd = createIndicator({
    displayName: 'MACD',
    id: 'edwin-macd',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //macd
            const macd1 = 12;
            const macd2 = 26;
            const macd3 = 9;
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            //macd
            const fast = this.PineJS.Std.ema(close_array, macd1, this._context);
            const slow = this.PineJS.Std.ema(close_array, macd2, this._context);
            const dif = fast - slow;
            const dif_array = this._context.new_var(dif);
            const dem = this.PineJS.Std.ema(dif_array, macd3, this._context);
            const osc = dif - dem;
            let red = NaN;
            let green = NaN;
            if (osc >= 0) {
                red = osc;
            }
            else {
                green = osc;
            }
            return [red, green, dif, dem];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 15,
                    visible: !0,
                    color: '#eb4849',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 15,
                    visible: !0,
                    color: '#118b7d',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 15,
                    visible: !0,
                    color: '#00e5ff',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 15,
                    visible: !0,
                    color: '#ffff00',
                },
            },
            precision: 4,
            inputs: {
                in_0: 200,
                in_1: 201,
                in_3: 'close',
                in_2: 202,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'osc',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'osc',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: 'dif',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: 'dem',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [],
        is_price_study: !1,
        scriptIdPart: '',
    },
});
