import styled from '@emotion/styled';
import { memo, useState } from 'react';
import { fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { MarketView } from './component/MarketView';
import { css } from '@emotion/react';
import { Chip } from '@mantine/core';
import { horizontalScrollbarCss } from '~/css/scrollbarCss';
export const Edwin_Col1 = memo(function Edwin_Col1() {
    //const charting = useSnapshot(store).charting
    const [type, setType] = useState('overView');
    return (<styleds.container>
      <styleds.switch.container>
        <styleds.switch.title>圖卡選項</styleds.switch.title>
        <Chip checked={type === 'overView'} onClick={() => setType('overView')} size={'xs'} radius='md' color='yellow' css={chipsCss}>
          漲跌家
        </Chip>
        <Chip checked={type === 'chart'} onClick={() => setType('chart')} size={'xs'} radius='md' color='yellow' css={chipsCss}>
          走勢圖
        </Chip>
      </styleds.switch.container>
      {type === 'chart' && (<styleds.content.container>
          <styleds.content.item>
            <styleds.content.trendChart>
              <TitleSymbolQuote.Default symbol={'TSEA'}/>
              <IntradayTrendChart symbol={'TSEA'} ticksSize={10} ticksHeight={14} priceTicksMargin={-12} priceTicksSize={12}/>
            </styleds.content.trendChart>
          </styleds.content.item>
          <styleds.content.item>
            <styleds.content.trendChart>
              <TitleSymbolQuote.Default symbol={'OTCA'}/>
              <IntradayTrendChart symbol={'OTCA'} ticksSize={10} ticksHeight={14} priceTicksMargin={-20} priceTicksSize={12}/>
            </styleds.content.trendChart>
          </styleds.content.item>
        </styleds.content.container>)}

      {type === 'overView' && (<styleds.content.container>
          <styleds.content.item>
            <useStockPriceChangeDistributionState.Provider>
              <MarketView market='TSEA'/>
            </useStockPriceChangeDistributionState.Provider>
          </styleds.content.item>
          <styleds.content.item>
            <useStockPriceChangeDistributionState.Provider>
              <MarketView market='OTCA'/>
            </useStockPriceChangeDistributionState.Provider>
          </styleds.content.item>
        </styleds.content.container>)}
    </styleds.container>);
});
const chipsCss = css `
  .mantine-hwb60 {
    width: 72px;
    height: 32px;
    font-size: 12px;
    background-color: #25262e;
  }
`;
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    background-color: #1d2630;
    border-radius: 6px;
    padding: 2px;
    gap: 8px;
  `,
    switch: {
        container: styled.div `
      ${fill_vertical_all_center};
      width: 80px;
      height: 100%;
      gap: 8px;
      background-color: #2a2f3b;
      border-radius: 4px;
      padding: 4px;
    `,
        title: styled.div `
      font-size: 14px;
    `,
    },
    content: {
        container: styled.div `
      ${horizontalScrollbarCss};
      width: 100%;
      gap: 4px;
    `,
        trendChart: styled.div `
      display: grid;
      ${fill_vertical_all_center};
      background-color: #2e323b;
      border-radius: 4px;
      grid-template-rows: 30px 1fr;
      width: 300px;
      padding: 2px 2px;
    `,
        item: styled.div `
      //${fill_vertical_all_center};
      width: 300px;
      height: 136px;
      flex-shrink: 0;
    `,
    },
};
