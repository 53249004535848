import { css } from '@emotion/react';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useCommonSymbolListStore, } from '~/modules/SDK/Symbol/useCommonSymbolListStore';
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators';
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn';
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol';
import { useStockToFutureMapper } from '~/modules/SDK/Symbol/useCommonSymbolList';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import styled from '@emotion/styled';
import { fontWeight500, fontWeight600 } from '~/css/font';
const SymbolPositionQuoteBodyClasses = {
    container: `InformationForm-container`,
    symbolTypeButton: `InformationForm-symbolTypeButton`,
};
export const SignalQuoteBrick = memo(function SignalQuoteBrick(props) {
    const currentSymbol = useChartingSymbol(props.chart)();
    const stockFutureSymbols = useCommonSymbolListStore.getState().stockFuturesMapper;
    const changeIndicators = useChartingChangeIndicatorsFn(props.chart);
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.chart);
    return (<div css={css `
        ${fill_vertical_cross_center};
        width: 100%;
        height: 100%;
        gap: 4px;
        ${scrollbar2Css};
      `}>
      {props.signals.length === 0 && (<div css={fill_vertical_all_center}>目前沒有資料或選擇其他日期</div>)}
      {props.signals.map((signal, index) => {
            const active = signal.symbol === currentSymbol;
            return (<div css={css `
              width: 100%;
              height: auto;
            `} key={index} onClick={event => {
                    props.handleClick?.(signal);
                    if (currentSymbol !== signal.symbol) {
                        changeSymbolInterval({ symbol: signal.symbol === 'FITX*1' ? 'TX-1' : stockFutureSymbols[signal.symbol] + '-1' });
                    }
                    if (props.indicators) {
                        changeIndicators(props.indicators);
                    }
                }}>
            <SignalQuoteBrickContent signal={signal} active={active}/>
          </div>);
        })}
    </div>);
});
export const SignalQuoteBrickContent = memo(function SignalQuoteBrickContent(props) {
    const signalID = props.signal.signal_id;
    const signalType = '量化當沖選股期'; //data.signal_data_type
    const symbol = props.signal.symbol;
    const datetime = props.signal.datetime;
    const message = props.signal.message;
    //DatumOfSouth給一下type 原本的type error
    const signalData = props.signal.signal_data;
    const strategyName = signalData?.strategyName ?? '';
    const position = signalData?.current_position ?? 0;
    const entryPrice = signalData?.future_price ?? 0;
    const stoplossPrice = signalData?.future_stoploss ?? 0;
    const stockPrice = signalData?.stock_price ?? 0;
    //----元件內容----
    const futuresDisctionary = useStockToFutureMapper();
    const positionLong = position === '1';
    const positionShort = position === '-1';
    const positionString = () => {
        if (positionLong) {
            return '多';
        }
        else if (positionShort) {
            return '空';
        }
        else {
            return '無';
        }
    };
    return (<bodyStyleds.Container active={props.active} className={`${SymbolPositionQuoteBodyClasses.container}`}>
      {/* <bodyStyleds.TradeButton>下單</bodyStyleds.TradeButton> */}

      <bodyStyleds.TitleContent>
        <bodyStyleds.PositionTip position={position}>{positionString()}</bodyStyleds.PositionTip>
        {signalType} {strategyName}
      </bodyStyleds.TitleContent>

      <bodyStyleds.NameContent>
        <SymbolName symbol={symbol}/>期 {symbol} {futuresDisctionary?.[symbol]}
      </bodyStyleds.NameContent>

      <bodyStyleds.QuoteContent>
        <bodyStyleds.QuoteItem>
          <bodyStyleds.SymbolTypeTip color='#6a8eae' className={`${SymbolPositionQuoteBodyClasses.symbolTypeButton}`}>
            期
          </bodyStyleds.SymbolTypeTip>
          {entryPrice}
        </bodyStyleds.QuoteItem>
        <bodyStyleds.QuoteItem>
          <bodyStyleds.SymbolTypeTip color='#777777' className={`${SymbolPositionQuoteBodyClasses.symbolTypeButton}`}>
            現
          </bodyStyleds.SymbolTypeTip>
          {stockPrice}
        </bodyStyleds.QuoteItem>
      </bodyStyleds.QuoteContent>

      <bodyStyleds.DefendPriceContent>停損參考: {stoplossPrice}</bodyStyleds.DefendPriceContent>

      <bodyStyleds.DateTimeContetn>
        {new Date(new Date(datetime).getTime() + 8 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 16)
            .replace('T', ' ')}
      </bodyStyleds.DateTimeContetn>
    </bodyStyleds.Container>);
});
const baseCss = css `
  ${fill_horizontal_cross_center};
  width: 100%;
`;
export const bodyStyleds = {
    Container: styled.div `
    ${fill_vertical_all_center};
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(20%, 5);
    row-gap: 4px;
    width: 100%;
    height: auto;
    background-color: ${options => (options.active ? '#4a4a4a' : '#353535')};
    border: 1px solid ${options => (options.active ? '#cccccc' : '#777777')};
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    &:hover {
      background-color: #3f3f3f;
      border: 1px solid #aaaaaa;
      transition: 0.3s;
    }
  `,
    TradeButton: styled.div `
    ${fill_vertical_all_center};
    ${fontWeight500};
    grid-column: 2;
    grid-row: 1 / 3;
    background-color: #777777;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #888888;
      transition: 0.2s;
    }
    &:active {
      background-color: #999999;
    }
  `,
    TitleContent: styled.div `
    ${baseCss};
    ${fontWeight500};
    gap: 8px;
    grid-column: 1 / 3;
    grid-row: 1;
  `,
    NameContent: styled.div `
    ${baseCss};
    ${fontWeight500};
    grid-column: 1;
    grid-row: 2;
  `,
    PositionTip: styled.div `
    ${fill_vertical_all_center};
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${options => options.position === '1' ? '#ff0000' : options.position === '-1' ? '#00aa00' : '#777777'};
  `,
    SymbolTypeTip: styled.div `
    ${fill_vertical_all_center};
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background-color: ${options => options.color};
    font-size: 12px;
    color: #ffffff;
  `,
    QuoteContent: styled.div `
    ${fill_horizontal_cross_center};
    grid-column: 1 / 3;
    grid-row: 3;
  `,
    QuoteItem: styled.div `
    ${baseCss};
    color: #d5b86f;
    ${fontWeight600};
  `,
    DefendPriceContent: styled.div `
    ${baseCss};
    grid-column: 1;
    grid-row: 4;
  `,
    DateTimeContetn: styled.div `
    ${baseCss};
    grid-column: 2;
    grid-row: 2;
  `,
};
