import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const big3_bandDc = createIndicator({
    displayName: '趨勢雙線',
    id: 'big3-bandDc',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const length = this._input(0);
            const r = this.PineJS.Std.low(this._context);
            const o = this._context.new_var(r);
            const dn = this.PineJS.Std.lowest(o, length, this._context);
            const a = this.PineJS.Std.high(this._context);
            const l = this._context.new_var(a);
            const up = this.PineJS.Std.highest(l, length, this._context);
            const up_line = this._context.new_var();
            const dn_line = this._context.new_var();
            dn_line.get(1);
            up_line.get(1);
            high_array.get(1);
            low_array.get(1);
            up_line.set(up);
            dn_line.set(dn);
            return [dn_line.get(0), up_line.get(0)];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
        ],
        filledAreas: [
            {
                id: 'fill_0',
                objAId: 'plot_0',
                objBId: 'plot_1',
                type: 'plot_plot',
                title: 'Plots Background',
            },
        ],
        styles: {
            plot_0: {
                title: 'Lower',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Upper',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
            },
            filledAreasStyle: {
                fill_0: { color: '#8996ff', transparency: 92, visible: !0 },
            },
            inputs: {
                in_0: 60,
            },
            precision: 4,
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: '週期',
                defval: 60,
                type: 'integer',
                min: 1,
                max: 2e3,
            },
        ],
        scriptIdPart: '',
    },
});
