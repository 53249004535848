import { Button, css, Dialog, TextField } from '@mui/material';
import React, { memo, useState } from 'react';
import { useText } from '~/hooks/useText';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { AuthTradeAPI } from '~/modules/SDK/Trade/AuthTradeAPI';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
const StrategyCenterLogin = memo(function StrategyCenterLogin() {
    const [open, setOpen] = useState(false);
    const strategyAuth = useTradeStore(s => s.strategyAuth);
    const account = useText();
    const password = useText();
    return (<>
      {strategyAuth ? (<Button color='error' variant='contained' onClick={() => {
                useTradeStore.setState({
                    strategyAuth: undefined,
                });
                localStorage.removeItem('futuresai.futuresai.strategy-center-auth');
            }}>
          登出
        </Button>) : (<Button variant='contained' onClick={() => {
                setOpen(true);
            }}>
          登入
        </Button>)}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div css={css `
            ${flex.v.default};
            gap: 8px;
            padding: 16px;
          `}>
          <TextField size='small' variant='outlined' label='帳號' value={account.value} onChange={account.acts.onChange}/>

          <TextField size='small' variant='outlined' type='password' label='密碼' value={password.value} onChange={password.acts.onChange} onKeyDown={e => {
            if (e.key === 'Enter') {
                // noop
            }
        }}/>

          <Button size='large' variant='contained' color='primary' onClick={() => {
            console.log(account.value, password.value);
            if (account.value && password.value)
                AuthTradeAPI.signin({ account: account.value, password: password.value });
            setOpen(false);
        }}>
            Go~
          </Button>
        </div>
      </Dialog>
    </>);
});
export default StrategyCenterLogin;
