import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { Charting } from '~/pages/heineken_template/_private/Charting';
export const styleds = {
    SideBar: styled.div `
    ${fill_vertical_cross_center}
    background-color: #16161e;
    gap: 4px;
    padding: 4px;
    padding: 4px;
  `,
    ScreenerContent: styled.div `
    ${fill_vertical_cross_center}
    width: 100%;
    height: calc(100% - 48px);
    gap: 4px;
  `,
    TitleString: styled.div `
    ${fill_vertical_all_center}
    width: 100%;
    height: 32px;
    background-color: #333333;
    border-radius: 5px;
    font-size: 16px;
  `,
    IntradayTrendChart: styled.div `
    width: 100%;
    height: 180px;
  `,
    SimpleQuoteListTXF: styled.div `
    width: 100%;
    height: 132px;
  `,
    SimpleQuoteListInteract: styled.div `
    width: 100%;
    height: calc(100% - 424px);
  `,
    Charting: function () {
        return (<div css={css `
          height: 100%;
          padding: 4px;
          border-radius: 5px;
        `}>
        <Charting />
      </div>);
    },
};
