import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { WatchListWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
const groupName = 'asiajye_stock_group_1';
export const ConditionWatchlist = memo(function ConditionWatchlist() {
    const intraDay = useSnapshot(staticStore).tradedDate.day0;
    return (<WatchListWithChart groupName={groupName} useChart={store.charting}/>);
});
