import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountOrderPre, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { wu5868_strategiesDayTradeGroup } from '../wu5868_initStrategies';
import { store } from '~/pages/heineken_template/_private/store';
import dayAPI from '~/utils/dayAPI';
export const StockSignal = memo(function StocStockSignalkPopular() {
    const lastTradeDate = useSnapshot(staticStore).tradedDate.day0;
    const intraDay = useSnapshot(staticStore).tradedDate.intraday;
    const data = useVirtualAccountOrderPre('wu5868_stock_long', 'B');
    const data2 = useVirtualAccountOrderPre('wu5868_stock_long', 'S');
    const entrySymbol = data?.map(s => s.symbol);
    const exitSymbol = data2?.map(s => s.symbol);
    const exitDate = data2?.map(s => s.createdDatetime)[0];
    return (<styleds.container>
      <styleds.title backgroundFill='#ac2222'>
        <span>進場</span>
        <styleds.updateContent>
          {dayAPI(exitDate).add(8, 'hour').format('MM/DD HH:mm')}更新
        </styleds.updateContent>
      </styleds.title>
      <styleds.lsitContent>
        <SimpleQuoteListInteractWithChart data={entrySymbol ?? []} chart={store.charting} handleClick={() => {
            store.charting.setStrategy(wu5868_strategiesDayTradeGroup.main[1]);
            store.charting.changeInterval('D');
            store.charting.updateFromState();
        }}/>
      </styleds.lsitContent>
      <styleds.title backgroundFill='#a3a3a3'>
        <span>出場</span>
        <styleds.updateContent>
          {dayAPI(exitDate).add(8, 'hour').format('MM/DD HH:mm')}更新
        </styleds.updateContent>
      </styleds.title>
      <styleds.lsitContent>
        <SimpleQuoteListInteractWithChart data={exitSymbol ?? []} chart={store.charting} handleClick={() => {
            store.charting.setStrategy(wu5868_strategiesDayTradeGroup.main[1]);
            store.charting.changeInterval('D');
            store.charting.updateFromState();
        }}/>
      </styleds.lsitContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    headerContent: styled.div `
    width: 100%;
    height: 36px;
  `,
    lsitContent: styled.div `
    ${scrollbar2Css};
    height: calc(50% - 32px);
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    position: relative;
    height: 32px;
    border-radius: 4px;
    color: #fafafa;
    background-color: ${props => props.backgroundFill};
  `,
    updateContent: styled.span `
    position: absolute;
    ${fill_horizontal_cross_center};
    justify-content: end;
    align-items: end;
    font-size: 12px;
    padding: 0 4px;
    margin-bottom: 4px;
  `,
};
