import React, { memo } from 'react';
import { useRouter } from 'next/router';
import { IconButton } from '@mui/material';
import html2canvas from 'html2canvas';
import { HiPhotograph, HiPrinter } from 'react-icons/hi';
import { selectors } from '~/modules/tradeSheet/tradingSummary/selectors';
export const PageToSnapShot = memo(function PageToSnapShot(props) {
    const router = useRouter();
    return (<div>
      <IconButton className={selectors.PRINT_HIDE} onClick={c => {
            const target = document.querySelector(props.selectorId);
            if (target) {
                // 禁止scroll
                document.body.style.overflowY = 'hidden';
                // 複製要列印的node，並且設置print顯示內容
                const copiedTarget = target.cloneNode(true);
                hideDom(copiedTarget, `.${selectors.PRINT_HIDE}`);
                showDivider(copiedTarget, `.${selectors.PRINT_SHOW}`);
                // 讓下面的 html2canvas 方便找
                document.body.appendChild(copiedTarget);
                html2canvas(copiedTarget)
                    .then(canvas => {
                    // 下載
                    const a = document.createElement('a');
                    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
                    a.download = 'image.jpg';
                    a.click();
                })
                    .then(() => {
                    router.reload();
                });
            }
        }}>
        <HiPhotograph />
      </IconButton>
    </div>);
});
export const PageToDirectPrint = memo(function PageToDirectPrint(props) {
    const router = useRouter();
    return (<div>
      <IconButton className={selectors.PRINT_HIDE} onClick={c => {
            const target = document.querySelector(props.selectorId);
            const originalContents = document.body.innerHTML;
            const bodyOverflowY = document.body.style.overflowY;
            if (target) {
                // 禁止scroll
                document.body.style.overflowY = 'hidden';
                if (props.isChrome)
                    hardCodeChromePrint();
                // 複製要列印的node，並且設置print顯示內容
                const copiedTarget = target.cloneNode(true);
                copiedTarget.style.margin = '0 0 0 -16px';
                hideDom(copiedTarget, `.${selectors.PRINT_HIDE}`);
                showDivider(copiedTarget, `.${selectors.PRINT_SHOW}`);
                if (props.isChrome)
                    heightBeShorter(copiedTarget, `.${selectors.PRINT_BE_SHORTER}`);
                // 讓下面的 html2canvas 方便找
                document.body.appendChild(copiedTarget);
                html2canvas(copiedTarget)
                    .then(canvas => {
                    // document.body.appendChild(canvas)
                    document.body.innerHTML = '';
                    document.body.appendChild(canvas);
                    window.print();
                    document.body.innerHTML = originalContents;
                    document.body.style.overflowY = bodyOverflowY;
                })
                    .then(() => {
                    router.reload();
                });
            }
        }}>
        <HiPrinter />
      </IconButton>
    </div>);
});
const hideDom = (parent, selector) => {
    const elements = parent.querySelectorAll(selector);
    elements.forEach(element => element.remove());
};
const showDivider = (parent, selector) => {
    const elements = parent.querySelectorAll(selector);
    elements.forEach(element => {
        const dom = element;
        dom.style.opacity = '1';
        dom.style.margin = '-8px 0 8px 0';
    });
};
const heightBeShorter = (parent, selector) => {
    const elements = parent.querySelectorAll(selector);
    elements.forEach(element => {
        const dom = element;
        dom.style.height = '80px';
    });
};
const hardCodeChromePrint = () => {
    document.body.style.width = '1100px';
    //document.body.style.padding = '0 32px'
    document.body.style.background = '#fafafa';
};
