import { god_movAvg } from '~/trades/indicators/god/god_movAvg';
import { god_volume } from '~/trades/indicators/god/god_volume';
import { store } from '../heineken_template/_private/store';
import { god_positionLong } from '~/trades/indicators/god/god_positionLong';
import { god_positionShort } from '~/trades/indicators/god/god_positionShort';
import { god_intradayLong } from '~/trades/indicators/god/god_intradayLong';
import { god_intradayShort } from '~/trades/indicators/god/god_intradayShort';
import { god_intraday } from '~/trades/indicators/god/god_intraday';
const DEFAULT_SYMBOL = 'TX-1';
export const god_baseGroup = [god_movAvg, god_volume];
export const god_intradayGroup = [god_intraday, ...god_baseGroup];
export const god_intradayLongGroup = [god_intradayLong, ...god_baseGroup];
export const god_intradayShortGroup = [god_intradayShort, ...god_baseGroup];
export const god_positionLongGroup = [god_positionLong, ...god_baseGroup];
export const god_positionShortGroup = [god_positionShort, ...god_baseGroup];
export const god_strategyGroup = {
    intraday: [
        {
            displayName: '當沖策略',
            indicators: [...god_intradayGroup],
            symbol: DEFAULT_SYMBOL,
            interval: '1',
            panesRatio: 75,
        },
    ],
    position: [
        {
            displayName: '波段策略(多)',
            indicators: [...god_positionLongGroup],
            symbol: DEFAULT_SYMBOL,
            interval: '1',
            panesRatio: 75,
        },
        {
            displayName: '波段策略(空)',
            indicators: [...god_positionShortGroup],
            symbol: DEFAULT_SYMBOL,
            interval: '1',
            panesRatio: 75,
        },
    ],
};
export const god_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        // ...god_intradayLongGroup,
        // ...god_intradayShortGroup,
        ...god_intradayGroup,
        ...god_positionLongGroup,
        ...god_positionShortGroup,
    ];
    store.charting.initStrategy({
        configs: [...god_strategyGroup.intraday, ...god_strategyGroup.position],
    });
    store.charting.changeSymbol(DEFAULT_SYMBOL);
};
