import React, { memo } from 'react';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { useMount, useUnmount } from 'react-use';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import styled from '@emotion/styled';
import { fill, fill_vertical_all_center, flex } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
const SymbolCardSimpleBody = memo(function SymbolCardSimpleBody(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    useMount(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    });
    useUnmount(() => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    const limitUp = symbolInfo?.close === symbolInfo?.limitUpPrice;
    const limitDn = symbolInfo?.close === symbolInfo?.limitDownPrice;
    //顏色邏輯判斷
    /** 收盤報價色 */
    const qouteFill = quoteChanges.closeChange >= 0 ? classesFill.upFill : classesFill.dnFill;
    /** 漲停背景色 */
    const limitBackground = limitUp ? classesFill.limitUpFill : limitDn ? classesFill.limitDnFill : '';
    const theme = useThemeStore(s => s.theme);
    const isAvtive = props.symbol === props.currentSymbol ? '-active' : '';
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme + isAvtive}>
        <classes.name className={theme} css={limitBackground}>
          <div>
            <SymbolName symbol={props.symbol}/>
          </div>
        </classes.name>
        <classes.qouteClose css={qouteFill} className={theme}>
          {symbolInfo?.close ?? '-'}
        </classes.qouteClose>
        <classes.qouteChange css={qouteFill} className={theme}>
          <div>
            {symbolInfo ? (quoteChanges.closeChange >= 0 ? '+' : '') : ''}
            {symbolInfo ? quoteChanges.closeChange : '-'}
          </div>
          &nbsp;&nbsp;
          <div>{symbolInfo ? quoteChanges.closeChangePercentString : '-'}</div>
        </classes.qouteChange>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${fill};
  `,
    container: styled.div `
    ${fill_vertical_all_center};
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  `,
    name: styled.div `
    ${flex.h.allCenter};
    font-size: 14px;
    ${fontWeight600}
    padding: 0px 5px;
  `,
    qouteClose: styled.div `
    ${flex.h.allCenter};
    ${fontWeight600};
    font-size: 14px;
  `,
    qouteChange: styled.div `
    ${flex.h.allCenter};
    font-size: 14px;
  `,
};
/** 預設的css樣式 */
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    //未選中的狀態樣式
    &.dark {
      background-color: #343434;
      border: 1px solid #343434;
      color: #ffffff;
      &:hover {
        background-color: #444444;
      }
    }
    &.light {
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
      color: #252525;
      &:hover {
        background-color: #eeeeee;
      }
    }

    //被選中的狀態樣式
    &.dark-active {
      background-color: #3c3c3c;
      color: #ffffff;
      border: 1px solid #aaaaaa;
    }
    &.light-active {
      background-color: #f1f1f1;
      color: #252525;
      border: 1px solid #aaaaaa;
    }
  }
  ${classes.name} {
    border-radius: 3px;
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #545454;
    }
  }
`;
//關於報價顏色
//考慮到 dark/light 與 up/down的顏色需要客製化
//因此將顏色css部份抽出來
const classesFill = {
    upFill: css `
    &.dark {
      color: #fb0000;
    }
    &.light {
      color: #aa0000;
    }
  `,
    dnFill: css `
    &.dark {
      color: #00ff00;
    }
    &.light {
      color: #009900;
    }
  `,
    //-----------------
    //商品名稱漲停/跌停時的樣式
    limitUpFill: css `
    &.dark {
      background-color: #fb0000;
    }
    &.light {
      background-color: #aa0000;
    }
    > div {
      color: #ffffff;
    }
  `,
    limitDnFill: css `
    &.dark {
      background-color: #00ff00;
    }
    &.light {
      background-color: #009900;
    }
    > div {
      color: #ffffff;
    }
  `,
    //-----------------
};
export default {
    Display: SymbolCardSimpleBody,
    classes,
    classesFill,
};
