import { memo } from 'react';
import { sgxClasses } from '../sgx_DataCard';
import VolumeChart from './VolumeChart';
export const VolumeCard = memo(function VolumeCard(props) {
    const data = props.data;
    const symbol1 = data.map(s => s.symbol1)[0];
    const symbol2 = data.map(s => s.symbol2)[0];
    return (<sgxClasses.card height={280}>
      <sgxClasses.title>合約成交量比較</sgxClasses.title>
      <sgxClasses.subTitle>
        {symbol1} {symbol2} 成交量
      </sgxClasses.subTitle>
      <VolumeChart data={data ?? []} symbol1={symbol1} symbol2={symbol2}/>
    </sgxClasses.card>);
});
