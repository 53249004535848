/* eslint-disable react/display-name */
import { css } from '@emotion/react';
import { Fragment, memo } from 'react';
import { proxy, ref, useSnapshot } from 'valtio';
export class FuiCollapse {
    store;
    constructor(initialState) {
        this.store = proxy({
            open: true,
            ...initialState,
        });
        this.WithContent.displayName = `${FuiCollapse.name}.WithContent`;
        this.RenderProps.displayName = `${FuiCollapse.name}.RenderProps`;
    }
    toggle() {
        this.store.open = !this.store.open;
    }
    RenderProps = ref(memo(props => {
        const state = useSnapshot(this.store);
        return <Fragment>{props.children(state)}</Fragment>;
    }));
    WithContent = ref(memo(props => {
        const state = useSnapshot(this.store);
        if (!state.open) {
            return null;
        }
        return (<div css={css `
            display: contents;
          `}>
          {props.children}
        </div>);
    }));
}
