import { css } from '@emotion/react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const hyt888Stock_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__next {
      ${FuiButton.classes.button.Root} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
      }

      ${FuiButton.classes.button.default.Default} {
        background-color: rgb(50, 50, 50);
        border: 1px solid rgb(79, 82, 92);
        color: white;
      }

      ${FuiButton.classes.button.default.Active} {
        background-color: rgb(79, 82, 92);
        border: 1px solid rgb(170, 170, 170);
      }

      ${FuiButton.classes.button.long.Default} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid rgb(79, 82, 92);
        background-color: rgb(85, 0, 0);
      }

      ${FuiButton.classes.button.long.Active} {
        border: 1px solid rgb(170, 170, 170);
        background-color: rgb(204, 0, 0);
      }

      ${FuiButton.classes.button.short.Default} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid rgb(79, 82, 92);
        background-color: rgb(0, 85, 0);
      }

      ${FuiButton.classes.button.short.Active} {
        border: 1px solid rgb(170, 170, 170);
        background-color: rgb(0, 187, 0);
      }
    }
  `;
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#02ff22',
        'mainSeriesProperties.candleStyle.borderUpColor': '#f0304a',
        'mainSeriesProperties.candleStyle.downColor': '#02ff22',
        'mainSeriesProperties.candleStyle.upColor': '#f0304a',
        'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 5,
        'paneProperties.bottomMargin': 5,
        'paneProperties.vertGridProperties.color': '#10131c',
        'paneProperties.horzGridProperties.color': '#10131c',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#11131b',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 12,
        'scalesProperties.textColor': '#aaaaaa',
        'scalesProperties.lineColor': '#aaaaaa',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        overrides: store.charting.darkOverrides,
    };
};
