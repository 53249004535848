import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import styled from '@emotion/styled';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const strategyPerformanceStyleds = {
    title: styled.div `
    ${fill_horizontal_all_center};
    font-weight: 600;
    height: 32px;
    background-color: ${props => props.backgroundFill};
    border-radius: 2px;
  `,
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 2px;
  `,
    closedTradeTableContent: styled.div `
    ${scrollbar2Css};
    height: calc(100% - 492px);
    gap: 4px;
    font-size: 14px;
  `,
    intradayContent: styled.div `
    ${scrollbar2Css};
    gap: 4px;
    height: 104px;
    font-size: 14px;
  `,
    monthInfoContent: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: 104px;
    font-size: 14px;
  `,
    emptyContent: styled.div `
    ${fill_vertical_all_center}
  `,
    openPositionTableHeader: styled.div `
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    height: 30px;
    width: 100%;
    background-color: #252525;
    font-size: 14px;
  `,
    closedTradeTableHeader: styled.div `
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    height: 30px;
    width: 100%;
    background-color: #252525;
    font-size: 14px;
  `,
    positionBody: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    height: 30px;
    width: 100%;
    background-color: #151515;
    font-size: 14px;
    &:hover {
      cursor: pointer;
      background-color: #313131;
    }
  `,
    value: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
  `,
    position: styled.div `
    ${fill_horizontal_all_center};
    height: 26px;
    width: 36px;
    color: ${props => (props.value > 0 ? '#ee2222' : '#00cc00')};
    border-radius: 4px;
  `,
    profit: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    color: ${props => (props.value > 0 ? '#ee2222' : '#00cc00')};
  `,
    monthProfitHeader: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 30% 40%;
    height: 30px;
    background-color: #252525;
    font-size: 14px;
  `,
    monthProfitBody: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 30% 40%;
    height: 30px;
    & > * {
      ${fill_horizontal_all_center};
    }
    &:hover {
      cursor: pointer;
      background-color: #313131;
    }
  `,
};
