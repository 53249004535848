import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { PageToSnapShot } from '~/modules/tradeSheet/tradingSummary/SaveMe';
import { selectors } from '~/modules/tradeSheet/tradingSummary/selectors';
export const sgx_TopInfo = memo(function sgx_TopInfo() {
    return (<styleds.container>
      <styleds.icon></styleds.icon>
      <styleds.title>產品觀測報告</styleds.title>
      <styleds.snapshot>
        <PageToSnapShot selectorId={`#${selectors.PRINT_ROOT}`} css={css `
            & > * {
              padding: 0px !important;
            }
          `}/>
        <span>截圖保存</span>
      </styleds.snapshot>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    border: 1px solid #e0e3ea;
    background-image: url('sgx/derivatives.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 16px;
  `,
    grayDivider: styled.div `
    flex: 1 1 auto;
    display: block;
    height: 6px;
    background: #e7e9f0;
    margin-left: 20px;
  `,
    title: styled.div `
    color: #0b236b;
    font-size: 32px;
    font-weight: 600;
  `,
    icon: styled.div `
    background: #a4dd36;
    margin-top: 5px;
    width: 18px;
    height: 18px;
    -webkit-box-shadow: 7px 5px 0 #091f60;
    box-shadow: 7px 5px 0 #091f60;
    margin-right: 16px;
    margin-bottom: 8px;
  `,
    snapshot: styled.div `
    ${fill_vertical_all_center};
    width: 64px;
    font-size: 12px;
  `,
};
