import { store } from '~/pages/heineken_template/_private/store';
import { day_vix1 } from '~/trades/indicators/dadday960/opkevin/day_vix1';
import { day_vix2 } from '~/trades/indicators/dadday960/opkevin/day_vix2';
import { minutes_vix } from '~/trades/indicators/dadday960/opkevin/minutes_vix';
import { healthy } from '../../trades/indicators/dadday960/opkevin/healthy';
export const opkevin_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '日線',
            indicators: [day_vix1, day_vix2],
            symbol: 'TX-1',
            interval: 'D',
            panesRatio: 66,
        },
        {
            displayName: '5分',
            indicators: [minutes_vix, healthy],
            symbol: 'TX-1',
            interval: '5',
            panesRatio: 66,
        },
    ],
};
export const opkevin_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[day_vix1, day_vix2, minutes_vix, healthy],
    ];
    store.charting.initStrategy({
        configs: [...opkevin_strategiesGroup.main],
    });
};
