import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Winner98_Topbar } from '~/pages/winner98/_private/winner98_Topbar';
import { Winner98_fullVersion_sidebarPane1 } from './winner98_fullVersion_SidebarPane';
import { winner98_initStyling } from '~/pages/winner98/_private/winner98_initStyling';
import { Winner98_Charting } from '~/pages/winner98/_private/component/winner98_Charting';
import { winner98_fullVersion_initStrategies } from './winner98_fullVersion_initstrategies';
export const winner98_fullVersion_init = (templateProps) => {
    winner98_initStyling(templateProps);
    winner98_fullVersion_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('backtest');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: '1D',
        custom_css_url: '../../winner/winner98/chartingTheme.css',
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.Charting = Winner98_Charting;
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 1fr 336px;

    grid-template-areas:
      'Row1 Row1'
      'Chart Drawer1'
      'Chart Drawer1';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 336px;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 336px;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Winner98_Topbar;
    templateProps.layout.Drawer1 = Winner98_fullVersion_sidebarPane1;
};
