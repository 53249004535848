import { proxy } from 'valtio';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
import dayAPI from '~/utils/dayAPI';
export const mvp888Stock_agentStore = proxy({
    /** # 選股日期 */
    stockPickedDate: dayAPI(),
    days: 20,
    strategyType: 'bandtrade',
    watchListStrategyType: 'bandtrade',
    tabs: new FuiTabs2(['交易類別', '自選股', '訊號']),
});
