import React, { memo } from 'react';
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
const titleCss = css `
  ${flex.h.allCenter}
  ${pureGrid};
  width: 100%;
  grid-template-columns: 10% 80% 10%;
  height: 30px;
  font-size: 16px;
  padding: 2px;
  background-color: #333333;
`;
const chartCss = css `
  ${flex.h.allCenter}
  width: 100%;
  height: 12.5px;
  grid-template-columns: 9.09%;
`;
const value = css `
  ${flex.h.allCenter};
  height: 15px;
  font-size: 8px;
  background-color: #555555;
  color: #ffffff;
  border-radius: 5px;
`;
const color = [
    '#C62828',
    '#D32F2F',
    '#F44336',
    '#EF5350',
    '#E57373',
    '#BDBDBD',
    '#81C784',
    '#66BB6A',
    '#4CAF50',
    '#43A047',
    '#2E7D32',
];
export const SymbolRankingSpectrum = memo(function SymbolRankingSpectrum() {
    return (<div css={titleCss}>
      <div css={value}>+10%</div>
      <div css={chartCss}>
        {color.map(datum => (<span key={datum} css={css `
              background-color: ${datum};
              width: 100%;
              height: 12.5px;
            `}/>))}
      </div>
      <div css={value}>-10%</div>
    </div>);
});
