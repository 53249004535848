import { store } from '~/pages/heineken_template/_private/store';
import { stock_dpo1, stock_dpo1_light } from '~/trades/indicators/good178/stock_dpo1';
import { stock_ichi1 } from '~/trades/indicators/good178/stock_ichi1';
import { stock_ichi2 } from '~/trades/indicators/good178/stock_ichi2';
import { stock_position_kbar, stock_position_kbar_light, } from '~/trades/indicators/good178/stock_position_kbar';
import { stock_sg2, stock_sg2_light } from '~/trades/indicators/good178/stock_sg2';
import { stock_sg3, stock_sg3_light } from '~/trades/indicators/good178/stock_sg3';
export const good178S_initCharting = () => {
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        overrides: store.charting.darkOverrides,
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledTimeframesToolbar: true,
        symbol: 'TSEA',
        interval: 'D',
    };
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        stock_sg3,
        stock_sg3_light,
        stock_sg2,
        stock_sg2_light,
        stock_ichi2,
        stock_ichi1,
        stock_position_kbar,
        stock_position_kbar_light,
        stock_dpo1,
        stock_dpo1_light,
    ];
    store.charting.initStrategy({
        configs: [
            {
                displayName: '主要',
                indicators: [
                    stock_sg2,
                    stock_sg2_light,
                    stock_ichi2,
                    stock_ichi1,
                    stock_position_kbar,
                    stock_position_kbar_light,
                    stock_dpo1,
                    stock_dpo1_light,
                    stock_sg3,
                    stock_sg3_light,
                ],
                symbol: 'TSEA',
                interval: 'D',
            },
        ],
    });
};
