import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const healthy = createIndicator({
    id: 'healthy',
    displayName: 'healthy',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma100 = this.PineJS.Std.sma(close_array, 100, this._context);
            const c100 = this.PineJS.Std.stdev(close_array, 100, this._context);
            const sma200 = this.PineJS.Std.sma(close_array, 200, this._context);
            const c200 = this.PineJS.Std.stdev(close_array, 200, this._context);
            const sma300 = this.PineJS.Std.sma(close_array, 300, this._context);
            const c300 = this.PineJS.Std.stdev(close_array, 300, this._context);
            const u1001 = 1 * c100;
            const u1002 = 2 * c100;
            const band100_up1 = sma100 + u1001;
            const band100_dn1 = sma100 - u1001;
            const band100_up2 = sma100 + u1002;
            const band100_dn2 = sma100 - u1002;
            const u2001 = 1 * c200;
            const u2002 = 2 * c200;
            const band200_up1 = sma200 + u2001;
            const band200_dn1 = sma200 - u2001;
            const band200_up2 = sma200 + u2002;
            const band200_dn2 = sma200 - u2002;
            const u3001 = 1 * c300;
            const u3002 = 2 * c300;
            const band300_up1 = sma300 + u3001;
            const band300_dn1 = sma300 - u3001;
            const band300_up2 = sma300 + u3002;
            const band300_dn2 = sma300 - u3002;
            const score100 = () => {
                if (close > band100_up1 && close > band100_up2) {
                    return 2;
                }
                else if (close > band100_up1 && close < band100_up2) {
                    return 1;
                }
                else if (close < band100_dn1 && close < band100_dn2) {
                    return -2;
                }
                else if (close < band100_dn1 && close > band100_dn2) {
                    return -1;
                }
                else
                    return 0;
            };
            const score200 = () => {
                if (close > band200_up1 && close > band200_up2) {
                    return 2;
                }
                else if (close > band200_up1 && close < band200_up2) {
                    return 1;
                }
                else if (close < band200_dn1 && close < band200_dn2) {
                    return -2;
                }
                else if (close < band200_dn1 && close > band200_dn2) {
                    return -1;
                }
                else
                    return 0;
            };
            const score300 = () => {
                if (close > band300_up1 && close > band300_up2) {
                    return 2;
                }
                else if (close > band300_up1 && close < band300_up2) {
                    return 1;
                }
                else if (close < band300_dn1 && close < band300_dn2) {
                    return -2;
                }
                else if (close < band300_dn1 && close > band300_dn2) {
                    return -1;
                }
                else
                    return 0;
            };
            const scroe = score100() + score200() + score300();
            const colorIndex = scroe > 0 ? 0 : 1;
            return [scroe, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '健康',
                    },
                    1: {
                        name: '健康',
                    },
                },
            },
        },
        defaults: {
            styles: {
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#c18346',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#974cc1',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            vol: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
