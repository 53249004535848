import { intersection } from 'lodash';
import { useDatePick } from '~/modules/screener/useDatePick';
import { usePercentRangeResource, useTurnoverRateResource, } from '~/modules/screener/containers/useStockScreenerResource';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
/** 疊加股票篩選機制: 趨勢轉折 + 周轉率 */
export const useTurnoverAndZScoreScreener = () => {
    const date = useDatePick();
    const popularData = usePopularPick({ date: '', sort: 'volume', limit: 100 });
    const turnoverRateData = useTurnoverRateResource({
        date: date,
        days: 1,
        threshold: 0.1,
        volume_gte: 0,
        volume_lte: 10000000,
        amount_gte: 1000000,
        amount_lte: 100000000000,
    });
    const stockPriceData = usePercentRangeResource({
        date: date,
        days: 60,
        status: 'new_high',
    });
    const popularSymbol = popularData.resultSymbol?.filter(item => item.length < 5);
    const turnoverRateSymbolNumber = turnoverRateData?.data
        ?.filter(datum => datum.volume > 25000)
        ?.map(datum => datum.symbol);
    /** 預防主邏輯沒有商品 備用的商品 */
    const stockPriceDataNumber = stockPriceData?.data
        ?.filter(datum => datum.volume > 25000)
        .map(datum => datum.symbol);
    /** 主邏輯選股 */
    const result = intersection(popularSymbol, turnoverRateSymbolNumber);
    /** 如果主邏輯選出來沒有股票 則用備用邏輯 */
    const data = result.length === 0 ? stockPriceDataNumber : result;
    return data;
};
