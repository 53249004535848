import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol';
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn';
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useSnapshot } from 'valtio';
import SymbolTransactionBody from '~/modules/investment-consultant/symbolList/body/SymbolCardTransactionBody';
const HeaderItem = memo(function HeaderItem(props) {
    return (<div css={css `
        ${flex.h.allCenter}
        width: 100%;
      `} onClick={event => props.handleClick?.(event)}>
      {props.children}
    </div>);
});
export const TransactionSymbolQuoteHeader = memo(function TransactionSymbolQuoteHeader() {
    const sortSnap = useSnapshot(useSortSignalrDataStore);
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const toggleSortKey = sortSnap.sortKey;
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '⬆' : '⬇') : undefined;
    }, [sortSnap.sortKey, toggleOrderKey]);
    const handleColumnSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<div css={css `
        ${flex.h.allCenter}
        ${pureGrid};
        grid-template-columns: 20% 10% 19% 16% 16% 19%;
        width: 100%;
        height: 40px;
        font-size: 14px;
      `}>
      <HeaderItem>部位</HeaderItem>
      <HeaderItem arrow={getArrow('symbol')} handleClick={() => handleColumnSelected('symbol')}>
        商品
      </HeaderItem>
      <HeaderItem arrow={getArrow('close')} handleClick={() => handleColumnSelected('close')}>
        收盤
      </HeaderItem>
      <HeaderItem arrow={getArrow('change')} handleClick={() => handleColumnSelected('change')}>
        漲跌
      </HeaderItem>
      <HeaderItem arrow={getArrow('changePrecent')} handleClick={() => handleColumnSelected('changePrecent')}>
        漲跌幅
      </HeaderItem>
      <HeaderItem arrow={getArrow('volume')} handleClick={() => handleColumnSelected('volume')}>
        成交量
      </HeaderItem>
    </div>);
});
//資料進來是 VitualExchangeTransaction 裡面沒有volume
//餵給TransactionSymbolQuote不是symbolNumber 沒辦法用Signalr訂閱再排序
export const TransactionSymbolList = memo(function TransactionSymbolList(props_) {
    const currentSymbol = useChartingSymbol(props_.chart)();
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props_.chart);
    const changeIndicators = useChartingChangeIndicatorsFn(props_.chart);
    return (<div css={css `
        ${flex.v.default};
        width: 100%;
        height: 100%;
      `}>
      <TransactionSymbolQuoteHeader />
      <div css={css `
          ${scrollbar2Css};
          gap: 2px;
          height: calc(100% - 40px);
        `}>
        {props_.data.map(symbol => {
            return (<div key={symbol} onClick={event => {
                    props_.handleClick?.(event);
                    if (currentSymbol !== symbol) {
                        changeSymbolInterval({ symbol: symbol });
                    }
                    if (props_.indicators) {
                        changeIndicators(props_.indicators);
                    }
                }}>
              <SymbolTransactionBody.Display currentSymbol={currentSymbol} symbol={symbol} bs={props_.bs}/>
            </div>);
        })}
      </div>
    </div>);
});
