import { lung_dc } from '~/trades/indicators/lung88988/lung_dc';
import { signal } from '~/trades/indicators/lung88988/signal';
import { stock_dealer } from '~/trades/indicators/futuresai/stock_chips/stock_dealer';
import { stock_foreigner } from '~/trades/indicators/futuresai/stock_chips/stock_foreigner';
import { stock_trust } from '~/trades/indicators/futuresai/stock_chips/stock_trust';
import { lung_power } from '~/trades/indicators/lung88988/lung_power';
import { volume } from '~/trades/indicators/lung88988/volume';
import { lung88988_strategy } from '~/trades/indicators/lung88988/lung_strategy';
import { stockDayTradeAvgPrice } from '~/trades/indicators/defaults/stockDayTradeAvgPrice';
import { store } from '~/pages/heineken_template/_private/store';
export const bandTradeIndicators = [
    lung_dc,
    signal,
    stockDayTradeAvgPrice,
    volume,
    lung_power,
    stock_foreigner,
    stock_trust,
    stock_dealer,
];
export const dayTradeIndicators = [lung88988_strategy, volume];
export const lung88988Stock_strategiesGroup = {
    main: [
        {
            displayName: '波段策略',
            indicators: bandTradeIndicators,
            symbol: 'TSEA',
            interval: '1D',
            panesRatio: 50,
        },
        {
            displayName: '當沖策略',
            indicators: dayTradeIndicators,
            symbol: 'TSEA',
            interval: '5',
        },
    ],
};
export const lung88988Stock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...bandTradeIndicators,
        ...dayTradeIndicators,
    ];
    store.charting.initStrategy({
        configs: [...lung88988Stock_strategiesGroup.main],
    });
};
