import { store } from '~/pages/heineken_template/_private/store';
import { rich888_chips2 } from '~/trades/indicators/rich888/rich888_chips2';
import { rich888_chips } from '~/trades/indicators/rich888/rich888_chips';
import { rich888_ma } from '~/trades/indicators/rich888/rich888_ma';
import { rich888_volume } from '~/trades/indicators/rich888/rich888_volume';
import { rich888_chips3 } from '~/trades/indicators/rich888/rich888_chips3';
export const datatree_strategiesGroup = {
    main: [
        {
            displayName: '策略',
            indicators: [rich888_ma, rich888_chips, rich888_chips2, rich888_chips3, rich888_volume],
            symbol: 'TX-1',
            interval: '5',
            panesRatio: 70,
        },
    ],
};
export const rich888_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[rich888_ma, rich888_chips],
    ];
    store.charting.initStrategy({
        configs: [...datatree_strategiesGroup.main],
    });
};
