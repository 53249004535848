import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const yaya168_signal = createIndicator({
    displayName: '四部曲《墊高》',
    id: 'yaya168-signal',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            this.f_0 = function (e) {
                return this.PineJS.Std.max(e, 0);
            };
            this.f_1 = function (e) {
                return -this.PineJS.Std.min(e, 0);
            };
            this.f_2 = function (e, t) {
                return this.PineJS.Std.eq(e, 0)
                    ? 100
                    : this.PineJS.Std.eq(t, 0)
                        ? 0
                        : 100 - 100 / (1 + t / e);
            };
            const over = 25;
            const smaLength = 15;
            const rsiLength = 5;
            const wamLength = 10;
            const profitPrecent = 2;
            const holdingDays = 15;
            // in_0: 25,
            // in_1: 15,
            // in_2: 5,
            // in_3: 10,
            // in_4: 2,
            // in_5: 15,
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const low = this.PineJS.Std.low(this._context);
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            //rsi
            const change = this.PineJS.Std.change(close_array);
            const up = this.f_0(change);
            const up_array = this._context.new_var(up);
            const up_ram = this.PineJS.Std.rma(up_array, rsiLength, this._context);
            const dn = this.f_1(change);
            const dn_array = this._context.new_var(dn);
            const dn_rma = this.PineJS.Std.rma(dn_array, rsiLength, this._context);
            const rsi = this.f_2(dn_rma, up_ram);
            const rsi_array = this._context.new_var(rsi);
            const rsi_ma = this.PineJS.Std.sma(rsi_array, 3, this._context);
            //策略
            const ma20 = this.PineJS.Std.sma(close_array, smaLength, this._context);
            const wma20 = this.PineJS.Std.wma(close_array, wamLength, this._context);
            const ma20_array = this._context.new_var(ma20);
            const marketposition = this._context.new_var();
            const pvflag = this._context.new_var();
            const entryPrice = this._context.new_var();
            const stopLossPrice = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryTime = this._context.new_var();
            const buyFlag = this._context.new_var();
            marketposition.get(2);
            entryPrice.get(5);
            itime_array.get(60);
            ma20_array.get(5);
            let buy_icon = NaN;
            let buy_exit_icon = NaN;
            const test = rsi_ma < over;
            if (test) {
                buyFlag.set(1);
            }
            const testLong = buyFlag.get(0) === 1 && close > ma20 && close > open;
            if (testLong) {
                marketposition.set(1);
                //new---
                buy_icon = 1;
                buyFlag.set(0);
            }
            if (marketposition.get(0) === 1) {
                entryHigh.set(0);
            }
            if (marketposition.get(0) === 1 && high > entryHigh) {
                entryHigh.set(high);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                entryPrice.set(close);
                stopLossPrice.set(low * 0.9);
                entryHigh.set(high);
                entryTime.set(itime);
                pvflag.set(0);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                //停損
                if (close < stopLossPrice.get(0)) {
                    marketposition.set(0);
                }
                //停利
                if (entryHigh.get(0) > entryPrice.get(0) * (1 + profitPrecent / 100) &&
                    //進場後第N根k棒才可以停利出場
                    itime_array.get(holdingDays) > entryTime.get(0) &&
                    close < wma20) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                }
            }
            const colorIndex = () => {
                if (rsi_ma < over) {
                    return 0;
                }
                else
                    return NaN;
            };
            return [buy_icon, buy_exit_icon, wma20, colorIndex()];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: '鎖單',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#ffe649',
                    textColor: '#ffe649',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#ffe649',
                    textColor: '#ffe649',
                    transparency: 0,
                    visible: false,
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 50,
                    visible: false,
                    color: '#ff4949',
                },
            },
            inputs: {},
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '',
                title: '參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'tiny',
                text: 'Target',
                title: '參考停利訊號',
            },
            plot_2: {
                title: '-',
                histogramBase: 0,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#f19d38',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
