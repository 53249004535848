import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fill, fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const styleds = {
    SideBar: styled.div `
    ${fill_vertical_cross_center}
    width: 100%;
    height: 100%;
    background-color: #e2effb;
    padding: 4px;
    gap: 4px;
  `,
    ComponeyLogo: styled.img `
    width: 100%;
    height: 64px;
    background-color: #d0dce6;
    border-radius: 4px;
  `,
    FaviconLogo: styled.img `
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
  `,
    QuoteTitleContent: styled.div `
    ${fill_horizontal_all_center}
    width: 100%;
    height: 28px;
    border: 4px;
    color: #ffffff;
    background-color: #5568a8;
    border-radius: 5px;
  `,
    ButtonContent: styled.div `
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    font-size: 14px;
    gap: 4px;
  `,
    ModeContent: styled.div `
    ${fill_vertical_cross_center}
    width: 100%;
    height: calc(100% - 104px);
    gap: 4px;
  `,
    TopDownGroup: styled.div `
    ${fill}
  `,
    TopDownTitle: styled.div `
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    color: #ffffff;
    ${options => {
        const buyCssset = options.variant === 'buy' &&
            css `
          background-color: #c62a31;
        `;
        const shortCssset = options.variant === 'short' &&
            css `
          background-color: #33a29a;
        `;
        return css([buyCssset, shortCssset]);
    }}
  `,
    TopDownSymbolList: styled.div `
    width: 100%;
    height: calc(100% - 32px);
  `,
};
