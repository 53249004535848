import { store } from '~/pages/heineken_template/_private/store';
import { winner98_trend_line } from '~/trades/indicators/winner98/winner98_trend_line';
import { winner98_signal } from '~/trades/indicators/winner98/winner98_signal';
import { winner98_trend_bar } from '~/trades/indicators/winner98/winner98_trend_bar';
import { winner98_elevator } from '~/trades/indicators/winner98/winner98_elevator';
export const winner98_fullVersion_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '帝王決策指標',
            indicators: [winner98_signal, winner98_trend_line /*winner98_trend_bar, winner98_elevator*/],
            symbol: 'TSEA',
            interval: '1D',
            panesRatio: [30],
        },
    ],
};
export const winner98_fullVersion_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[winner98_signal, winner98_trend_line, winner98_trend_bar, winner98_elevator],
    ];
    store.charting.initStrategy({
        configs: [...winner98_fullVersion_strategiesGroup.main],
    });
};
