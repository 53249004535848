import { store } from '~/pages/heineken_template/_private/store';
import { imoney8888_diffPower } from '~/trades/indicators/imoney8888/imoney8888_diffPower';
import { imoney8888_signal } from '~/trades/indicators/imoney8888/imoney8888_signal';
import { imoney8888_volume } from '~/trades/indicators/imoney8888/imoney8888_volume';
export const imoney8888_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '趨勢波段',
            indicators: [imoney8888_signal, imoney8888_diffPower, imoney8888_volume],
            symbol: '2330',
            interval: 'D',
            panesRatio: 66,
        },
    ],
};
export const imoney8888_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[imoney8888_signal, imoney8888_diffPower, imoney8888_volume],
    ];
    store.charting.initStrategy({
        configs: [...imoney8888_strategiesGroup.main],
    });
};
