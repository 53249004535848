import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const lung_dc = createIndicator({
    displayName: '主力動能階梯',
    id: 'lung88988dc',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const resolution = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            const dc_ = this.ladderChannl(resolution === '1D' ? 60 : 10);
            //0是下緣
            //1是上緣
            return [dc_[0], dc_[1], NaN];
        },
    },
    metainfo: {
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00aa00',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#aa0000',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ffff',
                },
            },
            precision: 4,
            inputs: {
            // in_0: 60,
            // in_1: 60,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Lower',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Upper',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: 'Basis',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !0,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: '階梯length',
        //   defval: 60,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_1',
        //   name: '均線length',
        //   defval: 60,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        ],
        scriptIdPart: '',
    },
});
