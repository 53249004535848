import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import TrendTable from '../eurex/_private/component/TrendTable';
import { store } from '../heineken_template/_private/store';
import RadialSeparators from './component/RadialSeparators';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { daddy960_tc1688_strategyGroup } from './daddy960_tc1688_initStrategies';
import { eurexIndicatorsValueStorage } from '~/trades/indicators/example/eurexIndicatorsValueStorage';
export const Tc1688_SidePane2 = memo(function Tc1688_SidePane2() {
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(eurexIndicatorsValueStorage);
    return (<styleds.container>
      <styleds.gaugeHeader>指標組合</styleds.gaugeHeader>
      <styleds.indicatorContent>
        {daddy960_tc1688_strategyGroup.main.map(config => {
            const isActive = config.displayName === charting.strategySelected.displayName;
            return (<div css={css `
                ${fill_horizontal_all_center};
                background-color: ${!isActive ? '#353535' : '#186ec8'};
                color: ${!isActive ? '#777777' : '#eeeeee'};
                border: 1px solid #777777;
                border-radius: 4px;
                cursor: pointer;
              `} key={config.displayName} onClick={() => {
                    store.charting.setStrategy(config);
                    store.charting.changeInterval(60);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </div>);
        })}
      </styleds.indicatorContent>
      <styleds.gaugeGroup>
        <div css={fill_vertical_cross_center}>
          <styleds.gaugeHeader>趨勢表</styleds.gaugeHeader>
          <styleds.gaugeContent>
            <DefaultGauge value={(state.rsv60 - 50) * 2.4}/>
          </styleds.gaugeContent>
        </div>
        <div css={fill_vertical_cross_center}>
          <styleds.gaugeHeader>動能表</styleds.gaugeHeader>
          <styleds.gaugeContent>
            <DefaultGauge value={(state.rsv5 - 50) * 2.4}/>
          </styleds.gaugeContent>
        </div>
      </styleds.gaugeGroup>
      <styleds.trendChartGroup>
        <styleds.gaugeHeader>走勢圖</styleds.gaugeHeader>
        <styleds.quoteTitleContent>
          <TitleSymbolQuote.Default symbol={charting.symbol}/>
        </styleds.quoteTitleContent>
        <styleds.trendChartContent>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={14} ticksHeight={20} priceTicksMargin={2.5} priceTicksSize={14}/>
        </styleds.trendChartContent>
      </styleds.trendChartGroup>
      <styleds.trendTableContent>
        <TrendTable.Dispaly intervalText='均'/>
      </styleds.trendTableContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
  `,
    indicatorContent: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 36px;
    gap: 4px;
    padding: 4px;
  `,
    gaugeGroup: styled.div `
    ${fill_horizontal_all_center};
    height: 190px;
    gap: 4px;
  `,
    gaugeHeader: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 30px;
    font-size: 14px;
    background-color: #202026;
    border-radius: 8px 8px 0 0;
  `,
    gaugeContent: styled.div `
    width: 100%;
    height: 160px;
    background-color: #18191f;
    border-radius: 0 0 8px 8px;
  `,
    trendTableContent: styled.div `
    width: 100%;
    background-color: #18191f;
    border-radius: 8px;
    padding: 4px;
  `,
    trendChartGroup: styled.div `
    ${fill_vertical_cross_center};
    height: 194px;
  `,
    quoteTitleContent: styled.div `
    width: 100%;
    height: 30px;
    background-color: #18191f;
  `,
    trendChartContent: styled.div `
    width: 100%;
    height: 164px;
    padding: 4px;
    background-color: #18191f;
    border-radius: 0 0 8px 8px;
  `,
};
//gauge 之後再抽出去
const bgcolor = '#33363d';
const borderWidth = 12;
export const DefaultGauge = memo(function DefaultGauge(props) {
    return (<classes.container>
      <classes.outside>
        <classes.outsideBottom />
        <classes.scaleBorder />
        <classes.needle value={props.value}/>
        <classes.needlePoint />
        <RadialSeparators count={30} style={{
            background: '#aaaaaa',
            width: '1px',
            height: `${6}%`,
        }}/>
      </classes.outside>
    </classes.container>);
});
const classes = {
    container: styled.div `
    width: 100%;
    height: 100%;
    padding: 8px;
  `,
    outside: styled.div `
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: double ${borderWidth}px transparent;
    background-image: linear-gradient(${bgcolor}, ${bgcolor}),
      conic-gradient(from -180deg at 50% 30%, #47ff00, #cecece, #fc002a);
    border-bottom: ${borderWidth}px solid #18191f;
    background-origin: border-box;
    background-clip: content-box, border-box;
  `,
    outsideBottom: styled.div `
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid #00000000;
    border-bottom: 8px solid ${bgcolor};
    z-index: 400;
  `,
    scaleBorder: styled.div `
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #aaaaaa;
    border-bottom: 1px solid #252525;
    box-shadow: 0 0 6px 4px #252525;
    z-index: 500;
  `,
    needle: styled.div `
    position: absolute;
    width: 100%;
    height: 40%;
    margin-top: 10%;
    background-color: #ffffff !important;
    clip-path: polygon(50% 0, 50% 0, 52% 100%, 48% 100%);
    transform-origin: bottom center;
    animation: rotate 2s ease-in-out;
    z-index: 300;
    transform: rotate(${props => props.value}deg);
    box-shadow: 0 0 4px 2px #232323;
    transition: 0.4s;
  `,
    needlePoint: styled.div `
    position: absolute;
    top: 46%;
    right: 46%;
    width: 8%;
    height: 8%;
    border-radius: 50%;
    transform-origin: bottom center;
    box-shadow: 0 0 4px 2px #232323;
    background-color: #cfcfcf !important;
    z-index: 300;
  `,
};
