import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { ThemeToggle } from '~/components/theme/ThemeToggle';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { HoldingCostPrice } from '~/modules/investment-consultant/positionValueFormOld/HoldingCostPrice';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import Styleds from '~/pages/goldbrain_stock/_private/styleds';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import Fr_StrategyButton from '~/pages/heineken_template/_fr/fr_StrategyButton';
import { store } from '~/pages/heineken_template/_private/store';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
export const GoldbrainStock_SidePane1 = memo(function SidePane1(props) {
    const charting = useSnapshot(store.charting);
    const chartPosition = useSnapshot(useChartPositionStore);
    const theme = useThemeStore(s => s.theme);
    return (<Styleds.Sidebar className={theme}>
      <Styleds.SidebarControlBorad>
        <ChartingServerSwitch charting={store.charting}/>
        <UserAvatarAsDialogButton />
        <ThemeToggle onClick={event => {
            store.charting.setThemeMode(useThemeStore.getState().theme);
            store.charting.updateFromThemeMode();
        }}/>
      </Styleds.SidebarControlBorad>

      <img src='/goldbrain/favicon.png' css={css `
          width: 87.5%;
          height: 175px;
        `}/>

      <Styleds.Title>策略選擇</Styleds.Title>
      <Styleds.SidebarButtonGroup>
        <Fr_StrategyButton.Display strategy={store.charting.strategyConfigs[0]}/>
        <Fr_StrategyButton.Display strategy={store.charting.strategyConfigs[1]}/>
      </Styleds.SidebarButtonGroup>

      <Styleds.Title>操作方向</Styleds.Title>
      <Styleds.SidebarButtonGroup>
        <FuiButton.Display variant='long' active={chartPosition.positionType === 'long'} onClick={event => {
            useChartPositionStore.positionType = 'long';
            store.charting.updateFromStrategyState();
        }}>
          多方操作
        </FuiButton.Display>

        <FuiButton.Display active={chartPosition.positionType === 'default'} onClick={event => {
            useChartPositionStore.positionType = 'default';
            store.charting.updateFromStrategyState();
        }}>
          多空操作
        </FuiButton.Display>

        <FuiButton.Display variant='short' active={chartPosition.positionType === 'short'} onClick={event => {
            useChartPositionStore.positionType = 'short';
            store.charting.updateFromStrategyState();
        }}>
          空方操作
        </FuiButton.Display>
      </Styleds.SidebarButtonGroup>

      <Styleds.Title>部位資訊</Styleds.Title>
      <Styleds.SidebarPositionForm>
        <Styleds.SidebarSymbolText className={theme}>
          <span>{charting.symbol}</span>
          <SymbolName symbol={charting.symbol}/>
        </Styleds.SidebarSymbolText>

        <HoldingCostPrice tragetCount={2} floatingPoint={0}/>
      </Styleds.SidebarPositionForm>
    </Styleds.Sidebar>);
});
