import { css } from '@emotion/react';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill } from '~/modules/AppLayout/FlexGridCss';
import { SymbolSimpleTrendChartCard } from '../eurex/_private/eurex_SidePaneMenu';
const symbols = ['2303', 'YM-1', '2303', '2014', '6116'];
export const Investment_SidePaneMenu = memo(function Investment_SidePaneMenu() {
    const theme = useThemeStore(t => t.theme);
    return (<div css={css `
        ${fill};
        background-color: ${theme === 'dark' ? '#252525' : '#efefef'};
        padding: 2px;
        gap: 4px;
        border-right: 1px solid #777777;
      `}>
      <span>商品報價列</span>
      {symbols.map(s => {
            return (<SymbolSimpleTrendChartCard symbol={s} key={s}/>);
        })}
    </div>);
});
