import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore';
import { trafficLightStore } from '~/pages/s178178/useTrafficLightStore';
/** 多空分界 */
export const s178178_kbar = createIndicator({
    displayName: '長線參考',
    id: 's178178kbar',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const interval = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            const display = interval === '1D' || interval === '1W' || interval === '1M';
            if (display) {
                const in1 = this._input(0);
                const in2 = this._input(1);
                const close = this.PineJS.Std.close(this._context);
                const close_array = this._context.new_var(close);
                const itime = this.PineJS.Std.time(this._context);
                const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
                const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
                const sma1_array = this._context.new_var(sma1);
                const sma2_array = this._context.new_var(sma2);
                const entryPrice = this._context.new_var();
                const state = this._context.new_var();
                sma1_array.get(1);
                sma1_array.get(2);
                sma2_array.get(1);
                sma2_array.get(2);
                state.get(5);
                const long = close > sma1 &&
                    close > sma2 &&
                    sma1_array.get(1) > sma1_array.get(2) &&
                    sma2_array.get(1) > sma2_array.get(2);
                const short = close < sma1 &&
                    close < sma2 &&
                    sma1_array.get(1) < sma1_array.get(2) &&
                    sma2_array.get(1) < sma2_array.get(2);
                if (long) {
                    state.set(1);
                    indicatorsValueStore.openPositionProfit = close - entryPrice.get(0);
                }
                if (short) {
                    state.set(-1);
                    indicatorsValueStore.openPositionProfit = entryPrice.get(0) - close;
                }
                if (state.get(0) === 1 && state.get(1) !== 1) {
                    entryPrice.set(close);
                    indicatorsValueStore.entryPrice = close;
                    indicatorsValueStore.marketPosition = 1;
                    indicatorsValueStore.entryDate = itime;
                    indicatorsValueStore.multipleTargetPrice = [entryPrice + 205, entryPrice + 201 * 2];
                    indicatorsValueStore.stopLossPrice = entryPrice - 100;
                    trafficLightStore.state = 'red';
                }
                if (state.get(0) === -1 && state.get(1) !== -1) {
                    entryPrice.set(close);
                    indicatorsValueStore.entryPrice = close;
                    indicatorsValueStore.marketPosition = -1;
                    indicatorsValueStore.entryDate = itime;
                    indicatorsValueStore.multipleTargetPrice = [entryPrice - 205, entryPrice - 201 * 2];
                    indicatorsValueStore.stopLossPrice = entryPrice + 100;
                    trafficLightStore.state = 'green';
                }
                if (state.get(0) === 1 && !long) {
                    state.set(0);
                    indicatorsValueStore.marketPosition = 0;
                    indicatorsValueStore.entryDate = NaN;
                    indicatorsValueStore.entryPrice = NaN;
                    trafficLightStore.state = 'yellow';
                }
                if (state.get(0) === -1 && !short) {
                    state.set(0);
                    indicatorsValueStore.marketPosition = 0;
                    indicatorsValueStore.entryDate = NaN;
                    indicatorsValueStore.entryPrice = NaN;
                    trafficLightStore.state = 'yellow';
                }
                const colorIndex = state.get(0) === 1 ? 0 : state.get(0) === -1 ? 2 : 1;
                return [sma1, sma2, colorIndex];
            }
            else
                return [NaN, NaN, NaN];
        },
    },
    metainfo: {
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#ffff00',
                    transparency: 0,
                    linewidth: 2,
                    plottype: 0,
                    visible: false,
                },
                plot_1: {
                    color: '#00ffff',
                    transparency: 0,
                    linewidth: 2,
                    plottype: 0,
                    visible: false,
                },
            },
            //樣式調整
            inputs: {
                in_0: 20,
                in_1: 200,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#ff3f5f',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#efff10',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#00ff2c',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: 'ma1_length',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 1e4,
            },
            {
                id: 'in_1',
                name: 'ma2_length',
                defval: 200,
                type: 'integer',
                min: 1,
                max: 1e4,
            },
        ],
        scriptIdPart: '',
    },
});
