import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import Col_UserAvatarAndChartingServersSimple from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServersSimple';
import { store } from '~/pages/heineken_template/_private/store';
import { Asiajye_intl_SidePane } from './asiajye_intl_SidePane';
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar';
export const asiajye_intl_init = (templateProps) => {
    useThemeStore.setState({ theme: 'light' });
    store.charting.setThemeMode('light');
    templateProps.permissions.pageview =
        meCheckHandlerCreateByProductNameWithExpiredAt('web_os_futures');
    store.charting.lightOverrides = {
        ...store.charting.lightOverrides,
        'mainSeriesProperties.candleStyle.borderDownColor': '#4caf50',
        'mainSeriesProperties.candleStyle.borderUpColor': '#d32f30',
        'mainSeriesProperties.candleStyle.downColor': '#4caf50',
        'mainSeriesProperties.candleStyle.upColor': '#d32f30',
        'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
        'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'timeScale.rightOffset': 5,
        'paneProperties.background': '#ffffff',
        'paneProperties.vertGridProperties.color': '#cccccc',
        'paneProperties.horzGridProperties.color': '#cccccc',
        'scalesProperties.textColor': '#555555',
        'scalesProperties.lineColor': '#cccccc',
        'scalesProperties.fontSize': 13,
        'mainSeriesProperties.showPriceLine': false,
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'YM-1',
        interval: '5',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        overrides: { ...store.charting.lightOverrides },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 232px 1fr 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Chart';
    }

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 200px 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Chart Chart;

    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = templateProps.layout.Row1 = (<Preset_Topbar showLeftBurger componentsInRight={<Col_UserAvatarAndChartingServersSimple.Display />} css={css `
        background-color: #dedede;
        width: 100%;
        height: 48px;
      `}/>);
    templateProps.layout.Drawer1 = Asiajye_intl_SidePane;
};
