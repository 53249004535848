import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const win9882_trendtrade_signal_30m_chips = createIndicator({
    id: 'win9882-trendtrade-signal-30m-chips',
    displayName: '籌碼波段',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator_acc';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
            const ticker2 = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol2 = ticker2 + '#opof';
            this._context.new_sym(symbol2, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const interval = context.symbol.period; // 1, 3, 5, 15, 30, 60, 1D, 1W
            //奇妙的多商品code------------------------------------------------
            const i = this._context.new_unlimited_var(this._context.symbol.time);
            //#bs_indicator_acc------------------------------------------------
            this._context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            const quote1 = this.PineJS.Std.high(this._context);
            const unlimited1 = this._context.new_unlimited_var(quote1);
            const value1 = unlimited1.adopt(time1, i, 0); // <=這是籌碼
            const value1_array = this._context.new_var(value1);
            value1_array.get(1000);
            //#opof------------------------------------------------
            this._context.select_sym(2);
            const time2 = this._context.new_unlimited_var(this._context.symbol.time);
            const qote2 = this.PineJS.Std.close(this._context);
            const unlimited2 = this._context.new_unlimited_var(qote2);
            const value2 = unlimited2.adopt(time2, i, 0); // <=這是籌碼
            const value2_array2 = this._context.new_var(value2);
            value2_array2.get(1000);
            //TX-1商品------------------------------------------------
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const close = this.PineJS.Std.close(this._context); // <=這是商品本身
            const open = this.PineJS.Std.open(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const low_array = this._context.new_var(low);
            const high_array = this._context.new_var(high);
            const close_array = this._context.new_var(close);
            const open_array = this._context.new_var(open);
            //參數宣告------------------------------------------------
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const entryPrice = this._context.new_var();
            const entryTime = this._context.new_var();
            const exitPrice = this._context.new_var();
            const pvflag = this._context.new_var();
            const position = this._context.new_var();
            const bigSma = this.PineJS.Std.sma(close_array, 360, this._context);
            const smallSma = this.PineJS.Std.sma(close_array, 60, this._context);
            const bigSma_array = this._context.new_var(bigSma);
            const smallSma_array = this._context.new_var(smallSma);
            close_array.get(10);
            open_array.get(10);
            bigSma_array.get(10);
            smallSma_array.get(10);
            position.get(10);
            itime_array.get(30);
            exitPrice.get(5);
            entryPrice.get(5);
            if (isNaN(entryTime.get(0))) {
                entryTime.set(0);
            }
            if (isNaN(entryPrice.get(0))) {
                entryPrice.set(0);
            }
            const rangeHigh = this.PineJS.Std.highest(high_array, 5, this._context);
            const rangeLow = this.PineJS.Std.lowest(low_array, 5, this._context);
            //條件------------------------------------------------
            const openD = this.dailyOpen(1, 2);
            const state = this._context.new_var();
            //部位確立------------------------------------------------
            if (start_h === 12) {
                if (value1 > 100 && close > openD[0]) {
                    state.set(1);
                }
                if (value1 < 100 && close < openD[0] && low > rangeLow) {
                    state.set(-1);
                }
                if (value1 > 1000 && close > openD[0]) {
                    position.set(1);
                }
                if (value1 < 1000 && close < openD[0] && low > rangeLow) {
                    position.set(-1);
                }
            }
            if (start_h > 12 && state.get(0) === 1 && value1 > 100) {
                position.set(1);
            }
            if (start_h > 12 && state.get(0) === -1 && value1 < 100) {
                position.set(-1);
            }
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            let buy_exit_icon = NaN;
            let short_exit_icon = NaN;
            //訊號顯示------------------------------------------------
            if (position.get(0) === 1 && position.get(1) !== 1) {
                buy_icon = 1;
                short_icon = NaN;
                entryPrice.set(close);
                entryTime.set(itime);
                pvflag.set(0);
            }
            if (position.get(0) === -1 && position.get(1) !== -1) {
                short_icon = 1;
                buy_icon = NaN;
                entryPrice.set(close);
                entryTime.set(itime);
                pvflag.set(0);
            }
            const sar_long = this.sarLongStopLoss('linear', 7, position, 20, 1.4, 0.1, 2);
            const sar_short = this.sarShortStopLoss('linear', 8, position, 20, 1.4, 0.3, 2);
            const longEnd = position.get(1) === 1 && position.get(0) === 1 && close < sar_long.get(0);
            const shortEnd = position.get(1) === -1 && position.get(0) === -1 && close > sar_short.get(0);
            //多方出場------------------------------------------------
            if (position.get(0) === 1 && position.get(1) === 1) {
                if (low < entryPrice - 100) {
                    position.set(0);
                    entryTime.set(itime);
                }
                if (pvflag.get(0) === 0 && high > entryPrice + 150) {
                    buy_target_icon = 1;
                    pvflag.set(1);
                }
                if (longEnd) {
                    position.set(0);
                    entryTime.set(itime);
                    buy_exit_icon = 1;
                }
                if (pvflag.get(0) === 1 && high > entryPrice + 300) {
                    position.set(0);
                    entryTime.set(itime);
                    buy_exit_icon = 1;
                    pvflag.set(2);
                }
            }
            //空方出場------------------------------------------------
            if (position.get(0) === -1 && position.get(1) === -1) {
                if (high > entryPrice + 100) {
                    position.set(0);
                    entryTime.set(itime);
                }
                if (pvflag.get(0) === 0 && low < entryPrice - 150) {
                    short_target_icon = 1;
                    pvflag.set(1);
                }
                if (shortEnd) {
                    position.set(0);
                    entryTime.set(itime);
                    short_exit_icon = 1;
                }
                if (pvflag.get(0) === 1 && low < entryPrice - 300) {
                    position.set(0);
                    entryTime.set(itime);
                    short_exit_icon = 1;
                    pvflag.set(1);
                }
            }
            const colorer = () => {
                return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 3 : 4;
            };
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                buy_exit_icon,
                short_exit_icon,
                colorer(),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag',
                type: 'chars',
            },
            {
                id: 'entryShortFlag',
                type: 'chars',
            },
            {
                id: 'targetLongFlag',
                type: 'chars',
            },
            {
                id: 'targetShortFlag',
                type: 'chars',
            },
            {
                id: 'exitLongFlag',
                type: 'chars',
            },
            {
                id: 'exitShortFlag',
                type: 'chars',
            },
            {
                id: '多空輔助',
                type: 'bar_colorer',
                palette: 'barPalette',
            },
        ],
        defaults: {
            styles: {
                entryLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                entryShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
                targetLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                targetShortFlag: {
                    color: '#5cb642',
                    textColor: '#5cb642',
                    transparency: 20,
                    visible: true,
                },
                exitLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                exitShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
            },
        },
        styles: {
            entryLongFlag: {
                title: '多單進場',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'Buy',
            },
            entryShortFlag: {
                title: '空單進場',
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'Short',
            },
            targetLongFlag: {
                title: '多單停利',
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'tiny',
                text: 'target',
            },
            targetShortFlag: {
                title: '空單停利',
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'tiny',
                text: 'target',
            },
            exitLongFlag: {
                title: '多單出場',
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'tiny',
                text: 'Exit',
            },
            exitShortFlag: {
                title: '空單出場',
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'tiny',
                text: 'Exit',
            },
        },
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#e39e9c',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#91b48a',
                        width: 1,
                        style: 0,
                    },
                    3: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    4: {
                        color: '#aaaaaa',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
