import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SegmentedControl } from '@mantine/core';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '../heineken_template/_private/store';
import TradeInfoCard from './component/TradeInfoCard';
import { win988_strategiesBandGroup, win988_strategiesDayTradeGroup } from './win988_initStrategies';
export const win988_store = proxy({
    tradeCardState: 'priceDiff',
});
export const Win988_SidePane2 = memo(function Win988_SidePane2() {
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(win988_store).tradeCardState;
    return (<styleds.container>
      <div css={css `
          width: 100%;
          gap: 2px;
        `}>
        <styleds.strategyGroupHeader>當沖策略</styleds.strategyGroupHeader>
        <styleds.strategyGroupCard>
          {win988_strategiesDayTradeGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<styleds.strategyButtton isActive={isActive} key={config.displayName} onClick={() => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
                {config.displayName}
              </styleds.strategyButtton>);
        })}
        </styleds.strategyGroupCard>
      </div>
      <div css={css `
          width: 100%;
          gap: 2px;
        `}>
        <styleds.strategyGroupHeader>波段策略</styleds.strategyGroupHeader>
        <styleds.strategyGroupCard>
          {win988_strategiesBandGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<styleds.strategyButtton isActive={isActive} key={config.displayName} onClick={() => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
                {config.displayName}
              </styleds.strategyButtton>);
        })}
        </styleds.strategyGroupCard>
      </div>
      <styleds.strategyGroupHeader css={css `
          background-image: linear-gradient(177deg, #29394f, #2e303c);
        `}>
        盤中資訊
      </styleds.strategyGroupHeader>
      <SegmentedControl fullWidth={true} css={css `
          width: 100%;
        `} data={[
            { label: '期現價差', value: 'priceDiff' },
            { label: '電金走勢', value: 'electricGoldPriceDiff' },
            { label: '漲跌貢獻', value: 'contribtuion' },
        ]} onChange={val => {
            win988_store.tradeCardState = val;
        }}/>
      <div css={css `
          width: 100%;
          height: calc(100% - 376px);
        `}>
        {state === 'priceDiff' && <TradeInfoCard.PriceDiff />}
        {state === 'electricGoldPriceDiff' && <TradeInfoCard.ElectricGoldPriceDiff />}
        {state === 'contribtuion' && <TradeInfoCard.StockContribtuion />}
      </div>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-image: linear-gradient(to bottom, #26272d, #111317);
    padding: 4px;
    gap: 8px;
  `,
    strategyGroupHeader: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 28px;
    background-image: linear-gradient(177deg, #52545f, #3b3c44);
    border-radius: 4px;
  `,
    strategyGroupCard: styled.div `
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: calc(50% - 2px) calc(50% - 2px);
    grid-gap: 4px;
    background-image: linear-gradient(to bottom, #333333, #323338);
    border-radius: 8px;
    padding: 4px;
  `,
    strategyButtton: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    width: 100%;
    border-radius: 4px;
    background-image: ${props => props.isActive === false
        ? 'linear-gradient(172deg, #17181e, #1f2025)'
        : 'linear-gradient(172deg,  #28282d, #2b2d35)'};
    border: 1px solid ${props => (props.isActive === false ? '#333333' : '#666666')};
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      background-image: linear-gradient(172deg, #27282e, #1f2025);
    }
  `,
};
