import { css } from '@emotion/react';
import { memo, useState } from 'react';
import { useSnapshot } from 'valtio';
import { eurexIndicatorsValueStorage } from '~/trades/indicators/example/eurexIndicatorsValueStorage';
import { styleds } from './styleds';
import { VolumeTable } from './component/VolumeTable';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar';
const eurexSymbol = [
    'FDX-1',
    'FDXM-1',
    'FGBS-1',
    'FGBM-1',
    'FGBL-1',
    'FGBX-1',
    'FOAT-1',
    'STXE-1',
    'FSXE-1',
    'FMCH-1',
    'FSTB-1',
    'FVS-1',
    'FXXP-1',
];
export const Eurex_Sidepane2 = memo(function Eurex_Sidepane2() {
    const [rankType, setRankType] = useState('volume');
    const state = useSnapshot(eurexIndicatorsValueStorage);
    const volume = state.averageVolume5;
    const volume5 = state.averageVolume5;
    const volume20 = state.averageVolume20;
    const volume60 = state.averageVolume60;
    const amp = state.currentAmp;
    const amp5 = state.averageAmp05;
    const amp20 = state.averageAmp20;
    const amp60 = state.averageAmp60;
    const allData = [
        { name: '當值', value: amp, value1: volume },
        { name: '5平均', value: amp5, value1: volume5 },
        { name: '20平均', value: amp20, value1: volume20 },
        { name: '60平均', value: amp60, value1: volume60 },
    ];
    return (<styleds.Sidebar>
      <styleds.SidebarTitle>振幅與成交量</styleds.SidebarTitle>
      <styleds.ContentCard>
        <VolumeTable data={allData} barFill1={'#ff9800'} barFill2={'#26c6da'}/>
      </styleds.ContentCard>
      <styleds.SidebarTitle>商品排行</styleds.SidebarTitle>
      <styleds.ContentCard css={contentCardexpansionCss}>
        <styleds.RankTypeButton onClick={() => setRankType(rankType === 'changePercent' ? 'volume' : 'changePercent')}>
          排序方式: {rankType === 'changePercent' ? '漲跌幅' : '成交量'}
        </styleds.RankTypeButton>
        <SymbolRankingHorizontalBar.Display type={rankType} symbols={eurexSymbol} nameLabelWidth={96}/>
      </styleds.ContentCard>
    </styleds.Sidebar>);
});
const contentCardexpansionCss = css `
  ${fill_vertical_all_center};
  height: 592px;
  gap: 0px;
  padding: 4px;
`;
