import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Group, HoverCard } from '@mantine/core';
import { memo } from 'react';
import { AiOutlineWechat } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
const Link = memo(function Link(props) {
    return <a href={props.href}>{props.children}</a>;
});
export const SocialLinkGroup = memo(function SocialLinkGroup() {
    return (<styleds.socialIconGroup>
      <Link href='https://www.facebook.com/SGX.SingaporeExchange/'>
        <FaFacebookF fill='#112267' size={24}/>
      </Link>
      <Link href='https://twitter.com/SGXGroup'>
        <FaTwitter fill='#112267' size={24}/>
      </Link>
      <Link href='https://www.linkedin.com/company/sgxgroup'>
        <FaLinkedinIn fill='#112267' size={24}/>
      </Link>
      <Link href='https://www.youtube.com/user/sgxchannel'>
        <FaYoutube fill='#112267' size={24}/>
      </Link>
      <Group position='center'>
        <HoverCard>
          <HoverCard.Target>
            <div>
              <AiOutlineWechat fill='#112267' size={24}/>
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <div css={css `
                ${fill_vertical_all_center}
              `}>
              <span>請掃此二維碼或搜索SGX進行關注</span>
              <img src='https://www.sgx.com/images/4e08ed8b34236addb71c0147c3d7c9dd.png' css={css `
                  width: 264px;
                `}/>
            </div>
          </HoverCard.Dropdown>
        </HoverCard>
      </Group>
    </styleds.socialIconGroup>);
});
export const Sgx_Footer = memo(function Sgx_Footer() {
    return (<styleds.container>
      <styleds.socialContent>
        <styleds.socialTitle>關注我們</styleds.socialTitle>
        <SocialLinkGroup />
      </styleds.socialContent>
      <styleds.allRights>© Singapore Exchange Limited. All Rights Reserved.</styleds.allRights>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    border-top: 2px solid #e7e9ef;
    gap: 16px;
  `,
    allRights: styled.p `
    font-size: 14px;
    color: #5f6062;
  `,
    socialContent: styled.div `
    ${fill_vertical_cross_center};
    width: 240px;
    height: 80px;
    gap: 8px;
  `,
    socialTitle: styled.div `
    ${fill_horizontal_cross_center};
    height: 32px;
    font-size: 20px;
    color: #112267;
  `,
    socialIconGroup: styled.div `
    ${fill_horizontal_cross_center};
    width: 240px;
    height: 48px;
    gap: 24px;
    & > * {
      cursor: pointer;
    }
  `,
};
