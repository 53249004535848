import React, { memo } from 'react';
import { css } from '@emotion/react';
import { useMount, useUnmount } from 'react-use';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_vertical_all_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { useThemeStore } from '~/components/theme/useThemeStore';
const SymbolCardBrickBody = memo(function SymbolCardBrickBody(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const changeArrow = quoteChanges.closeChange >= 0 ? '▲' : '▼';
    const limitUp = symbolInfo?.close === symbolInfo?.limitUpPrice;
    const limitDn = symbolInfo?.close === symbolInfo?.limitDownPrice;
    useMount(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    });
    useUnmount(() => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    //顏色邏輯判斷
    /** 收盤報價色 */
    const qouteFill = quoteChanges.closeChange >= 0 ? classesFill.upFill : classesFill.dnFill;
    /** 漲停背景色 */
    const limitBackground = limitUp ? classesFill.limitUpFill : limitDn ? classesFill.limitDnFill : '';
    const theme = useThemeStore(s => s.theme);
    const isAvtive = props.symbol === props.currentSymbol ? '-active' : '';
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme + isAvtive}>
        <classes.name className={theme} css={limitBackground}>
          <SymbolName symbol={props.symbol}/>
          &nbsp;
          {props.symbol}
        </classes.name>

        <classes.quoteClose css={qouteFill} className={theme}>
          {changeArrow}
          &nbsp;
          {symbolInfo?.close ?? '-'}
        </classes.quoteClose>

        <classes.quoteChange css={qouteFill} className={theme}>
          <p>
            {symbolInfo ? (quoteChanges.closeChange >= 0 ? '+' : '') : ''}
            {symbolInfo ? quoteChanges.closeChange : '-'}
          </p>
          <p>{symbolInfo ? quoteChanges.closeChangePercentString : '-'}</p>
        </classes.quoteChange>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div ``,
    container: styled.div `
    ${flex.v.allCenter};
    width: 132px;
    height: 72px;
    font-size: 14px;
    ${fontWeight600};
    border-radius: 5px;
    transition: 0.1s;
    cursor: pointer;
    &:hover {
    }
  `,
    name: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 35%;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
  `,
    quoteClose: styled.div `
    ${fill_vertical_all_center};
    height: 40%;
  `,
    quoteChange: styled.div `
    ${fill_horizontal_all_center};
    height: 25%;
    ${jc.spaceAround};
    line-height: 14px;
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    &.dark {
      background-color: #343434;
      border: 1px solid #343434;
      &:hover {
        background-color: #3c3c3c;
      }
    }
    &.light {
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &.dark-active {
      background-color: #343434;
      border: 1px solid #eeeeee;
      &:hover {
        background-color: #3c3c3c;
      }
    }
    &.light-active {
      background-color: #f1f1f1;
      border: 1px solid #888888;
      &:hover {
        background-color: #e5e5e5;
      }
    }
  }
  ,
  ${classes.name} {
    &.dark {
      background-color: #454545;
    }
    &.light {
      background-color: #dddddd;
    }
  }
`;
const classesFill = {
    upFill: css `
    &.dark {
      color: #fb0000;
    }
    &.light {
      color: #aa0000;
    }
  `,
    dnFill: css `
    &.dark {
      color: #00ff00;
    }
    &.light {
      color: #009900;
    }
  `,
    //-----------------
    //商品名稱漲停/跌停時的樣式
    limitUpFill: css `
    &.dark {
      background-color: #fb0000 !important;
    }
    &.light {
      background-color: #aa0000 !important;
    }
    color: #ffffff !important;
  `,
    limitDnFill: css `
    &.dark {
      background-color: #00ff00 !important;
    }
    &.light {
      background-color: #009900 !important;
    }
    color: #ffffff !important;
  `,
};
export default {
    Display: SymbolCardBrickBody,
    classes,
    classesFill,
};
