import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { datatree_store } from '~/pages/datatree/datatree_store';
export const datatree_ti = createIndicator({
    id: 'datatree-ti',
    displayName: '掛單能量',
    enabledOn(symbol, data, channel) {
        return ((data?.type === channel.tw_futures && symbol.includes('-')) ||
            (symbol.includes('TX') && symbol.length == 10));
    },
    constructorScope: {
        init(context, inputcallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#trade_info';
            context.new_sym(symbol, this.PineJS.Std.period(context));
        },
        main(context, inputcallback) {
            context.select_sym(1);
            const o = this.PineJS.Std.open(context);
            const h = this.PineJS.Std.high(context);
            const l = this.PineJS.Std.low(context);
            datatree_store.valuePower = o;
            datatree_store.valueSmallPower = l;
            return [o, l, h];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 4,
                    trackPrice: false,
                    transparency: 60,
                    color: '#0000ff',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 4,
                    trackPrice: false,
                    transparency: 60,
                    color: '#007f00',
                },
                plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 30,
                    color: '#ff0000',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '大戶掛單',
                histogramBase: 0,
            },
            plot_1: {
                title: '散戶成交',
                histogramBase: 0,
            },
            plot_2: {
                title: '市場掛單',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
