import { store } from '~/pages/heineken_template/_private/store';
import { yaya168_fire } from '~/trades/indicators/yaya168/yaya168_fire';
import { yaya168_main_power } from '~/trades/indicators/yaya168/yaya168_main_power';
import { yaya168_signal } from '~/trades/indicators/yaya168/yaya168_signal';
import { yaya168_volume } from '~/trades/indicators/yaya168/yaya168_volume';
export const yaya168Stock_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '順勢波段',
            indicators: [yaya168_signal, yaya168_fire, yaya168_main_power, yaya168_volume,],
            symbol: '2330',
            interval: '3',
            panesRatio: 70,
        },
    ],
};
export const yaya168Stock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[yaya168_signal, yaya168_fire, yaya168_main_power, yaya168_volume],
    ];
    store.charting.initStrategy({
        configs: [...yaya168Stock_strategiesGroup.main],
    });
};
