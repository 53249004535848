import camelcaseKeys from 'camelcase-keys';
import useSWR from 'swr-0-5-6';
import { apirc } from '~/configs/apirc';
export const useOptionsGreeksResource = (params) => {
    const restfulApiURL = apirc.options.optionsgreeksUrlGet({
        contract_month: params?.contract,
    });
    const swr = useSWR(params?.contract ? restfulApiURL : null, {
        revalidateOnFocus: false,
        refreshInterval: 30000,
    });
    return {
        res: {
            ...swr,
            data: swr.data ? camelcaseKeys(swr.data) : null,
        },
    };
};
