import { proxy } from 'valtio';
export const big3_store = proxy({
    timeRange: 'all',
    retailMarketValue: NaN,
    mainMarketValue: NaN,
    crucialPrice: [NaN, NaN, NaN, NaN],
    symbolClose: NaN,
    dcDailyTrend: 0,
    powerTrend: 0,
    priceLineTrend: 0,
    analyzerTrend: 0,
    chip: 0,
    opof: 0,
});
