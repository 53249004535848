import { jerry597_futureMainChart, jerry597_futureMainChart_light, } from '~/trades/indicators/jerry597/jerry597_futureMainChart';
import { jerry597_futureSubChart, jerry597_futureSubChart_light, } from '~/trades/indicators/jerry597/jerry597_futureSubChart';
import { jerry597_stockMainChart, jerry597_stockMainChart_light, } from '~/trades/indicators/jerry597/jerry597_stockMainChart';
import { jerry597_stockSubChart, jerry597_stockSubChart_light, } from '~/trades/indicators/jerry597/jerry597_stockSubChart';
import { store } from '../heineken_template/_private/store';
export const jerry597_stockIndicators = [
    jerry597_stockMainChart,
    jerry597_stockMainChart_light,
    jerry597_stockSubChart,
    jerry597_stockSubChart_light,
];
export const jerry597_futureIndicatorsLongTern = [
    jerry597_futureMainChart,
    jerry597_futureMainChart_light,
    jerry597_futureSubChart,
    jerry597_futureSubChart_light,
];
export const jerry597_futureIndicatorsShortTern = [
    jerry597_futureMainChart,
    jerry597_futureMainChart_light,
    jerry597_futureSubChart,
    jerry597_futureSubChart_light,
];
export const jerry597_strategyGroup = {
    // stock: [
    //   {
    //     displayName: '金旺股票',
    //     indicators: jerry597_stockIndicators,
    //     symbol: 'TSEA',
    //     interval: '1D',
    //   },
    // ] as ChartTypes.StrategyConfig[],
    future: [
        {
            displayName: '期貨波段',
            indicators: jerry597_futureIndicatorsLongTern,
            symbol: 'TX-1',
            interval: 15,
        },
        {
            displayName: '期貨短線',
            indicators: jerry597_futureIndicatorsShortTern,
            symbol: 'TX-1',
            interval: 3,
        },
        {
            displayName: '金旺股票',
            indicators: jerry597_stockIndicators,
            symbol: '2330',
            interval: '1D',
        },
    ],
};
export const jerry597_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...jerry597_stockIndicators,
        ...jerry597_futureIndicatorsLongTern,
        ...jerry597_futureIndicatorsShortTern,
    ];
    store.charting.initStrategy({
        configs: [...jerry597_strategyGroup.future],
    });
    store.charting.changeSymbol('TX-1');
};
