import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const weng888_bandTrade = createIndicator({
    displayName: '部位訊號',
    id: 'weng888-bandTrade',
    constructorScope: {
        init() {
            return;
        },
        main() {
            const marketposition = this._context.new_var();
            const entryPrice = this._context.new_var();
            const targetFlag = this._context.new_var();
            const in0 = 9;
            const in1 = 3;
            const in2 = 3;
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const vHn = this.PineJS.Std.highest(high_array, in0, this._context);
            const vLn = this.PineJS.Std.lowest(low_array, in0, this._context);
            const vrsv = ((close - vLn) / (vHn - vLn)) * 100;
            marketposition.get(2);
            entryPrice.get(2);
            targetFlag.get(2);
            //K%
            const vk_array = this._context.new_var();
            const s = (1.0 / in1) * vrsv + (1 - 1.0 / in1) * vk_array.get(1);
            if (isNaN(s)) {
                vk_array.set(0);
            }
            else {
                vk_array.set(s);
            }
            //D%
            const vd_array = this._context.new_var();
            const q = (1.0 / in2) * vk_array + (1 - 1.0 / in2) * vd_array.get(1);
            if (isNaN(q)) {
                vd_array.set(0);
            }
            else {
                vd_array.set(q);
            }
            const hlc3 = this.PineJS.Std.hlc3(this._context);
            const hlc3_array = this._context.new_var(hlc3);
            const ma20 = this.PineJS.Std.ema(hlc3_array, 20, this._context);
            const ma60 = this.PineJS.Std.sma(hlc3_array, 60, this._context);
            const diff = ma20 - ma60;
            const colorIndex = () => {
                if (marketposition.get(0) === 1) {
                    return 0;
                }
                else if (marketposition.get(0) === -1) {
                    return 2;
                }
                else
                    return 1;
            };
            //KD 介於 60 ~ 80、收紅、diff呈現正值 買進
            const long = close > open && diff > 0 && vk_array.get(0) >= 60 && vk_array.get(0) <= 75;
            //KD 介於 20 ~ 40、收黑、diff呈現負值
            const short = close < open && diff < 0 && vk_array.get(0) >= 25 && vk_array.get(0) <= 40;
            if (long) {
                marketposition.set(1);
            }
            if (short) {
                marketposition.set(-1);
            }
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target0 = NaN;
            let short_target0 = NaN;
            let buy_target1 = NaN;
            let short_target1 = NaN;
            //
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                entryPrice.set(close);
                targetFlag.set(0);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
                entryPrice.set(close);
                targetFlag.set(0);
            }
            //
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                if (vk_array.get(0) <= 20) {
                    marketposition.set(0);
                }
                if (targetFlag.get(0) === 0 && high > entryPrice.get(0) * 1.1) {
                    buy_target0 = 1;
                    targetFlag.set(1);
                }
                if (targetFlag.get(0) === 1 && high > entryPrice.get(0) * 1.225) {
                    buy_target0 = 1;
                    targetFlag.set(2);
                }
                if (targetFlag.get(0) === 2 && high > entryPrice.get(0) * 1.35) {
                    buy_target1 = 1;
                    targetFlag.set(3);
                    marketposition.set(0);
                }
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                if (vk_array.get(0) >= 80) {
                    marketposition.set(0);
                }
                if (targetFlag.get(0) === 0 && low < entryPrice.get(0) * 0.9) {
                    short_target0 = 1;
                    targetFlag.set(1);
                }
                if (targetFlag.get(0) === 1 && low < entryPrice.get(0) * 0.775) {
                    short_target0 = 1;
                    targetFlag.set(2);
                }
                if (targetFlag.get(0) === 2 && low < entryPrice.get(0) * 0.65) {
                    short_target1 = 1;
                    targetFlag.set(3);
                    marketposition.set(0);
                }
            }
            return [
                buy_icon,
                short_icon,
                buy_target0,
                short_target0,
                buy_target1,
                short_target1,
                colorIndex(),
            ];
        },
    },
    metainfo: {
        is_price_study: !0,
        scriptIdPart: '',
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#ff2e26',
                    textColor: '#ff2e26',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#22f42a',
                    textColor: '#22f42a',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#8d8e2d',
                    textColor: '#8d8e2d',
                    transparency: 10,
                    visible: true,
                },
                plot_3: {
                    color: '#3e8b8e',
                    textColor: '#3e8b8e',
                    transparency: 10,
                    visible: true,
                },
                plot_4: {
                    color: '#ccaa00',
                    textColor: '#ccaa00',
                    transparency: 10,
                    visible: true,
                },
                plot_5: {
                    color: '#00ccaa',
                    textColor: '#00ccaa',
                    transparency: 10,
                    visible: true,
                },
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多單',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '空單',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: '多單參考停利',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: '空單參考停利',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: '多單參考出場',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: '空單參考出場',
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#D32F2F',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#BDBDBD',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#43A047',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
// '#C62828',
// '#D32F2F',
// '#F44336',
// '#EF5350',
// '#E57373',
// '#BDBDBD',
// '#81C784',
// '#66BB6A',
// '#4CAF50',
// '#43A047',
// '#2E7D32',
