import { store } from '~/pages/heineken_template/_private/store';
import { volume } from '~/trades/indicators/lung88988/volume';
import { mvp888_bandTrade } from '~/trades/indicators/mvp888/mvp888_bandTrade';
import { mvp888_chip } from '~/trades/indicators/mvp888/mvp888_chip';
import { mvp888_dayTrade } from '~/trades/indicators/mvp888/mvp888_dayTrade';
import { mvp888_power } from '~/trades/indicators/mvp888/mvp888_power';
import { mvp888_tx_daytrade } from '~/trades/indicators/mvp888/mvp888_tx1_daytrade';
export const mvp888Stock_strategiesGroup = {
    main: [
        {
            displayName: '波段',
            indicators: [mvp888_bandTrade, mvp888_chip, volume],
            interval: '1D',
        },
        {
            displayName: '當沖',
            indicators: [mvp888_dayTrade, mvp888_power, volume],
            interval: '5',
        },
    ],
};
export const mvp888Stock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[volume, mvp888_power, mvp888_chip, mvp888_bandTrade, mvp888_dayTrade, mvp888_tx_daytrade],
    ];
    store.charting.initStrategy({
        configs: [...mvp888Stock_strategiesGroup.main],
    });
    store.charting.changeSymbol('TSEA');
};
export const returnIndicators = (state) => {
    if (state === 'bandtrade') {
        return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[0];
    }
    else if (state === 'daytrade') {
        return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[1];
    }
};
export const returnInterval = (state) => {
    if (state === 'bandtrade') {
        return '1D';
    }
    else
        return '5';
};
export const returnWatchIndicators = (state) => {
    if (state === 'bandtrade') {
        return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[0];
    }
    else if (state === 'daytrade') {
        return mvp888Stock_strategiesGroup.main.map(s => s.indicators)[1];
    }
};
