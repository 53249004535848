import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
import { mvp888Stock_strategiesGroup, returnIndicators, returnInterval, returnWatchIndicators, } from './mvp888Stock_initStrategies';
import Fr_SimpleTopDown from '~/pages/heineken_template/_fr/fr_SimpleTopDown';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import { useZScorePick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { mvp888Stock_agentStore } from './mvp888Stock_store';
import { styleds } from './styleds';
import dayAPI from '~/utils/dayAPI';
import { useLatestTradeDateSize, } from '~/modules/screener/containers/useStockScreenerResource';
import { mvp888_tx_daytrade } from '~/trades/indicators/mvp888/mvp888_tx1_daytrade';
import { mvp888_power } from '~/trades/indicators/mvp888/mvp888_power';
import { volume } from '~/trades/indicators/lung88988/volume';
import { useVirtualAccountOrderPre } from '~/modules/virtualExchange/useVirtualExchangeResource';
export const Mvp888Stock_SidePane1 = memo(function Mvp888Stock_SidePane1() {
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(mvp888Stock_agentStore);
    const days = state.days;
    const notTXSymbol = !charting.symbol.includes('-');
    const bandTradeLongSymbol = useZScorePick({
        date: state.stockPickedDate.format('YYYY-MM-DD'),
        days: days,
        type: 'strong',
        volume: 1e9,
        amountGte: 1000000000,
        itemLimit: 10,
    }).resultSymbol ?? [''];
    return (<styleds.SideBar>
      <styleds.ComponeyLogo src='mvp888/sidebar-logo.png'/>

      <styleds.ButtonContent>
        {mvp888Stock_strategiesGroup.main.map(config => {
            const isActive = notTXSymbol &&
                charting.strategySelected.displayName === config.displayName &&
                state.tabs.store.active === '交易類別';
            const setStrategyType = () => {
                if (config.displayName === '當沖')
                    return 'daytrade';
                else
                    return 'bandtrade';
            };
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    /** 依照選擇波段/當沖去設定選股天數 */
                    mvp888Stock_agentStore.days = config.displayName === '當沖' ? 5 : 20;
                    /** 暫存現在選取的策略名稱 */
                    mvp888Stock_agentStore.strategyType = setStrategyType();
                    /** 避免點擊策略按鈕時畫面卡在自選股回不去選股商品列 */
                    mvp888Stock_agentStore.tabs.showTab('交易類別');
                    /** 如果圖表中現在是期貨商品 點選策略按鈕時會切換成股票商品(避免股票商品套用到台指的策略) */
                    !notTXSymbol && store.charting.changeSymbol(bandTradeLongSymbol[0]);
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </FuiButton.Display>);
        })}
        <FuiButton.Display active={charting.symbol === 'TX-1'} onClick={() => {
            store.charting.changeSymbol('TX-1');
            store.charting.changeInterval('5');
            store.charting.replaceIndicators([mvp888_tx_daytrade, mvp888_power, volume]);
        }}>
          台指當沖
        </FuiButton.Display>

        <mvp888Stock_agentStore.tabs.WithButton to='自選股'>
          <FuiButton.Display onClick={event => {
            /** 剛剛是波段/當沖 自選股策略就是那個 */
            mvp888Stock_agentStore.watchListStrategyType = state.strategyType;
        }}>
            自選股
          </FuiButton.Display>
        </mvp888Stock_agentStore.tabs.WithButton>

        <mvp888Stock_agentStore.tabs.WithButton to='訊號'>
          <FuiButton.Display onClick={event => {
            mvp888Stock_agentStore.tabs.showTab('訊號');
            mvp888Stock_agentStore.tabs.store.active = '訊號';
        }}>
            訊號
          </FuiButton.Display>
        </mvp888Stock_agentStore.tabs.WithButton>
      </styleds.ButtonContent>
      <styleds.ModeContent>
        <mvp888Stock_agentStore.tabs.WithContent for='交易類別'>
          <DateSelectorGroup />
          <DefaultSymbol />
        </mvp888Stock_agentStore.tabs.WithContent>

        <mvp888Stock_agentStore.tabs.WithContent for='訊號'>
          <RealTimeSymbol />
        </mvp888Stock_agentStore.tabs.WithContent>

        <mvp888Stock_agentStore.tabs.WithContent for='自選股'>
          <styleds.ButtonContent>
            <FuiButton.Display active={notTXSymbol && state.watchListStrategyType === 'bandtrade'} onClick={() => {
            mvp888Stock_agentStore.watchListStrategyType = 'bandtrade';
            notTXSymbol && store.charting.changeInterval('D');
            notTXSymbol &&
                store.charting.replaceIndicators(mvp888Stock_strategiesGroup.main.map(a => a.indicators)[0]);
        }}>
              套用波段策略
            </FuiButton.Display>
            <FuiButton.Display active={notTXSymbol && state.watchListStrategyType === 'daytrade'} onClick={() => {
            mvp888Stock_agentStore.watchListStrategyType = 'daytrade';
            notTXSymbol && store.charting.changeInterval('5');
            notTXSymbol &&
                store.charting.replaceIndicators(mvp888Stock_strategiesGroup.main.map(a => a.indicators)[1]);
        }}>
              套用當沖策略
            </FuiButton.Display>
          </styleds.ButtonContent>
          <div css={css `
              width: 100%;

              height: calc(100% - 40px);
            `}>
            <WatchListWithChart groupName={'mvp888_stock_group_1'} useChart={store.charting} indicators={returnWatchIndicators(state.watchListStrategyType)} handleClick={() => store.charting.changeInterval(returnInterval(state.strategyType))}/>
          </div>
        </mvp888Stock_agentStore.tabs.WithContent>
      </styleds.ModeContent>
    </styleds.SideBar>);
});
const DefaultSymbol = memo(function DefaultSymbol() {
    const state = useSnapshot(mvp888Stock_agentStore);
    const days = state.days;
    const topDownDisplayType = days === 5 ? '近期短線' : '近期波段';
    const bandTradeLongSymbol = useZScorePick({
        date: state.stockPickedDate.format('YYYY-MM-DD'),
        days: days,
        type: 'strong',
        volume: 1e9,
        amountGte: 1000000000,
        itemLimit: 10,
    }).resultSymbol ?? [''];
    const bandTradeShortSymbol = useZScorePick({
        date: state.stockPickedDate.format('YYYY-MM-DD'),
        days: days,
        type: 'weak',
        volume: 1e9,
        amountGte: 1000000000,
        itemLimit: 10,
    }).resultSymbol ?? [''];
    return (<div css={css `
        ${fill_vertical_cross_center}
        width: 100%;
        height: calc(100% - 40px);
      `}>
      <Fr_SimpleTopDown.Display top={<styleds.TopDownGroup>
            <styleds.TopDownTitle variant='buy'>{topDownDisplayType}多頭強勢</styleds.TopDownTitle>
            <styleds.TopDownSymbolList>
              <SimpleQuoteListInteractWithChart data={bandTradeLongSymbol ?? []} chart={store.charting} indicators={returnIndicators(state.strategyType)} handleClick={() => store.charting.changeInterval(returnInterval(state.strategyType))}/>
            </styleds.TopDownSymbolList>
          </styleds.TopDownGroup>} down={<styleds.TopDownGroup>
            <styleds.TopDownTitle variant='short'>
              {topDownDisplayType}空頭強勢
            </styleds.TopDownTitle>
            <styleds.TopDownSymbolList>
              <SimpleQuoteListInteractWithChart data={bandTradeShortSymbol ?? []} chart={store.charting} indicators={returnIndicators(state.strategyType)} handleClick={() => store.charting.changeInterval(returnInterval(state.strategyType))}/>
            </styleds.TopDownSymbolList>
          </styleds.TopDownGroup>}/>
    </div>);
});
const RealTimeSymbol = memo(function Asssss() {
    const state = useSnapshot(mvp888Stock_agentStore);
    const orderLongEntry = useVirtualAccountOrderPre('mvp888_stock_long', 'B');
    const orderShortEntry = useVirtualAccountOrderPre('mvp888_stock_short', 'S');
    const longSymbol_ = orderLongEntry?.map(s => s.symbol);
    const shortSymbol_ = orderShortEntry?.map(s => s.symbol);
    return (<div css={css `
        ${fill_vertical_cross_center}
        width: 100%;
        height: calc(100% - 10px);
      `}>
      <Fr_SimpleTopDown.Display top={<styleds.TopDownGroup>
            <styleds.TopDownTitle variant='buy'>近期多方訊號</styleds.TopDownTitle>
            <styleds.TopDownSymbolList>
              <SimpleQuoteListInteractWithChart data={longSymbol_ ?? []} chart={store.charting} indicators={mvp888Stock_strategiesGroup.main.map(s => s.indicators)[0]} handleClick={() => {
                store.charting.changeInterval('D');
            }}/>
            </styleds.TopDownSymbolList>
          </styleds.TopDownGroup>} down={<styleds.TopDownGroup>
            <styleds.TopDownTitle variant='short'>近期空方訊號</styleds.TopDownTitle>
            <styleds.TopDownSymbolList>
              <SimpleQuoteListInteractWithChart data={shortSymbol_ ?? []} chart={store.charting} indicators={mvp888Stock_strategiesGroup.main.map(s => s.indicators)[0]} handleClick={() => {
                store.charting.changeInterval('D');
            }}/>
            </styleds.TopDownSymbolList>
          </styleds.TopDownGroup>}/>
    </div>);
});
const DateSelectorGroup = memo(function DateSelectorGroup() {
    const state = useSnapshot(mvp888Stock_agentStore);
    const lastTradeDatePick = useLatestTradeDateSize({ size: 10 });
    const lastTradeDate = lastTradeDatePick?.[0];
    const beforeLastTradeDate = lastTradeDatePick?.[1];
    return (<styleds.ButtonContent>
      <FuiButton.Display active={state.stockPickedDate.format('MM/DD') === dayAPI(lastTradeDate).format('MM/DD')} onClick={() => (mvp888Stock_agentStore.stockPickedDate = dayAPI(lastTradeDate))}>
        最新標的
      </FuiButton.Display>
      <FuiButton.Display active={state.stockPickedDate.format('MM/DD') === dayAPI(beforeLastTradeDate).format('MM/DD')} onClick={() => (mvp888Stock_agentStore.stockPickedDate = dayAPI(beforeLastTradeDate))}>
        前日標的
      </FuiButton.Display>
    </styleds.ButtonContent>);
});
