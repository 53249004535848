import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { Charting } from '~/pages/heineken_template/_private/Charting';
const Styled = {
    Title: styled.div `
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    color: rgb(238, 238, 238);
    font-size: 20px;
    font-weight: 600;
    background: linear-gradient(45deg, rgb(230, 31, 25), rgb(154, 28, 33));
  `,
    Sidebar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: calc(100% - 5px);
    border-radius: 5px;
    border: 1px solid #666666;
    background-color: #201d1d;
  `,
    SidebarSection: styled.div(() => css `
      ${flex.h.allCenter};
      width: 100%;
    `),
    SidebarSectionGrayBg: function (props) {
        return (<Styled.SidebarSection className={props.className} css={css `
          background-color: #333333;
        `}>
        {props.children}
      </Styled.SidebarSection>);
    },
    Charting: function () {
        return (<div css={css `
          border: 1px solid #666666;
          height: calc(100% - 6px);
          margin: 2px;
          border-radius: 5px;
        `}>
        <Charting />
      </div>);
    },
};
export default Styled;
