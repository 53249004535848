import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { big3_store } from '~/pages/big3/_private/big3_store';
import dayAPI from '~/utils/dayAPI';
export const big3_stockPower = createIndicator({
    displayName: '主力動向',
    id: 'big3-stockPower',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator_acc';
            context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const i = context.new_unlimited_var(this._context.symbol.time);
            context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            const high1 = this.PineJS.Std.high(this._context);
            const low1 = this.PineJS.Std.low(this._context);
            const highUnlimited = this._context.new_unlimited_var(high1);
            const lowUnlimited = this._context.new_unlimited_var(low1);
            const main = highUnlimited.adopt(time1, i, 0); // <=這是籌碼
            const retail = lowUnlimited.adopt(time1, i, 0); // <=這是籌碼
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const state = big3_store.timeRange;
            const itime = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const hours = dayAPI(itime).hour();
            const minute = dayAPI(itime).minute();
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            //context.select_sym(1)
            const main_array = this._context.new_var(main);
            const retail_array = this._context.new_var(retail);
            //紀錄支撐壓力價的陣列
            const crucialPrice = this._context.new_var();
            main_array.get(2);
            retail_array.get(2);
            high_array.get(2);
            low_array.get(2);
            crucialPrice.get(5);
            //力道轉換的時候設置支撐壓力價
            const setLongStartPrice = main_array.get(0) > 1 && main_array.get(1) < 0;
            const setShortStartPrice = main_array.get(0) < -1 && main_array.get(1) > 0;
            //力道轉換時設置近期2根K的高低點
            const highest = this.PineJS.Std.highest(high_array, 2, this._context);
            const lowest = this.PineJS.Std.lowest(low_array, 2, this._context);
            //根據用戶所選的不同時段紀錄關鍵價
            if ((setLongStartPrice && hours >= 8 && hours < 15) ||
                (state === 'new' && setLongStartPrice && hours >= 20 && hours <= 22) ||
                (state === 'all' && setShortStartPrice)) {
                crucialPrice.set(lowest);
                big3_store.crucialPrice = [
                    crucialPrice.get(0),
                    crucialPrice.get(1),
                    crucialPrice.get(2),
                    crucialPrice.get(3),
                ];
                //console.log(state, lowest, dayAPI(itime).format())
            }
            if ((setShortStartPrice && hours >= 8 && hours < 15) ||
                (state === 'new' && setShortStartPrice && hours >= 20 && hours <= 22) ||
                (state === 'all' && setShortStartPrice)) {
                crucialPrice.set(highest);
                big3_store.crucialPrice = [
                    crucialPrice.get(0),
                    crucialPrice.get(1),
                    crucialPrice.get(2),
                    crucialPrice.get(3),
                ];
                //console.log(state, highest, dayAPI(itime).format())
            }
            big3_store.mainMarketValue = main;
            big3_store.retailMarketValue = retail;
            big3_store.symbolClose = close;
            const colorIndex = main >= 0 ? 0 : 1;
            if (main > 100) {
                big3_store.powerTrend = 1;
            }
            if (main < -100) {
                big3_store.powerTrend = -1;
            }
            if (main < 100 && main > -100) {
                big3_store.powerTrend = 0;
            }
            return [main, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '多方主力',
                    },
                    1: {
                        name: '空方主力',
                    },
                },
            },
        },
        defaults: {
            styles: {
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#dd5441',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#00aa0a',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            vol: {
                title: '能量',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
