import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const rich888_chips2 = createIndicator({
    id: 'rich888-chips2',
    displayName: '籌碼指標2',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const type = ticker?.includes('-') ? '#foreigner_txf' : '#big3_net_stock';
            const symbol = ticker + type;
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const i = this._context.new_unlimited_var(this._context.symbol.time);
            //#big3_net_stock------------------------------------------------
            this._context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            //外資
            const quote1 = this.PineJS.Std.open(this._context);
            const unlimited1 = this._context.new_unlimited_var(quote1);
            const value1 = unlimited1.adopt(time1, i, 0); // <=這是籌碼
            //自營
            const quote2 = this.PineJS.Std.low(this._context);
            const unlimited2 = this._context.new_unlimited_var(quote2);
            const value2 = unlimited2.adopt(time1, i, 0); // <=這是籌碼
            //#TX-1商品------------------------------------------------
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const close = this.ohlc.closeArray;
            const low = this.ohlc.low;
            const open = this.ohlc.open;
            const volume = this.PineJS.Std.volume(this._context);
            const volume_array = this._context.new_var(volume);
            const volume_ma = this.PineJS.Std.sma(volume_array, this._input(1), this._context);
            close.get(10);
            //要顯示的籌碼
            const value = this._input(2) === 1 ? value1 : this._input(0) === 2 ? value2 : value1 + value2;
            //累加
            const acc = this._context.new_var();
            acc.get(100);
            //flag
            const flag = this._context.new_var();
            flag.get(1);
            //defend
            const defend = this._context.new_var();
            //爆量
            const explosion = this._context.new_var();
            if (volume > volume_ma * 1.5 && close > open) {
                flag.set(1);
                defend.set(low);
                explosion.set(acc.get(0) + value);
            }
            else
                explosion.set(0);
            if (flag.get(0) === 1) {
                acc.set(acc.get(0) + value / 3);
            }
            else
                acc.set(value);
            if (close < defend.get(0)) {
                flag.set(0);
            }
            const colorIndex = acc.get(0) >= 0 ? 0 : 1;
            const avgChip_array = this._context.new_var(Math.abs(value));
            const avgChip = this.PineJS.Std.sma(avgChip_array, 20, this._context);
            //顯示個別籌碼, 變色, 累加(條件), 上通道, 下通道, 假的每日籌碼
            return [acc.get(0), colorIndex, explosion.get(0), avgChip, -avgChip];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '多市場籌碼',
                    },
                    1: {
                        name: '空市場籌碼',
                    },
                },
            },
        },
        defaults: {
            styles: {
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#d63864',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 30,
                    visible: !0,
                    color: '#e39e9c',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 30,
                    visible: !0,
                    color: '#7bb972',
                },
            },
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#e39e9c',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#7bb972',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
            precision: 0,
            inputs: { in_0: 1, in_1: 20 },
        },
        styles: {
            vol: {
                title: '籌碼',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_0: {
                title: '籌碼單',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '買方均值',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '賣方均值',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
            {
                id: 'in_0',
                name: '類別',
                defval: 1,
                type: 'integer',
                min: 1,
                max: 3,
            },
            {
                id: 'in_1',
                name: 'vol',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 200,
            },
        ],
    },
});
