import { proxy } from 'valtio';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
/** # 投顧層 */
export const goldbrainStock_agentStore = proxy({
    tabs1: new FuiTabs2(['智能飆股', '金頭腦', '即時選股', '自選股']),
    // /**
    //  * # 頁籤分類設定列舉
    //  *
    //  * ## 唯讀
    //  */
    // tabs1: {
    //   default: 'tabs1.智能飆股',
    //   fireBaseStock: 'tabs1.金頭腦',
    //   goldbrain: 'tabs1.即時選股',
    //   userStock: 'tabs1.自選股',
    // } as const,
});
