import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const yaya168_fire = createIndicator({
    displayName: '四部曲《點火-新》',
    id: 'yaya168-fire',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            this.f_0 = function (e) {
                return this.PineJS.Std.max(e, 0);
            };
            this.f_1 = function (e) {
                return -this.PineJS.Std.min(e, 0);
            };
            this.f_2 = function (e, t) {
                return this.PineJS.Std.eq(e, 0)
                    ? 100
                    : this.PineJS.Std.eq(t, 0)
                        ? 0
                        : 100 - 100 / (1 + t / e);
            };
            const over = 25;
            const smaLength = 15;
            const rsiLength = 5;
            const wamLength = 10;
            const profitPrecent = 2;
            const holdingDays = 15;
            // in_0: 25,
            // in_1: 15,
            // in_2: 5,
            // in_3: 10,
            // in_4: 2,
            // in_5: 15,
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const low = this.PineJS.Std.low(this._context);
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            //rsi
            const change = this.PineJS.Std.change(close_array);
            const up = this.f_0(change);
            const up_array = this._context.new_var(up);
            const up_ram = this.PineJS.Std.rma(up_array, rsiLength, this._context);
            const dn = this.f_1(change);
            const dn_array = this._context.new_var(dn);
            const dn_rma = this.PineJS.Std.rma(dn_array, rsiLength, this._context);
            const rsi = this.f_2(dn_rma, up_ram);
            const rsi_array = this._context.new_var(rsi);
            const rsi_ma = this.PineJS.Std.sma(rsi_array, 3, this._context);
            //策略
            const wma20 = this.PineJS.Std.wma(close_array, wamLength, this._context);
            const ma20 = this.PineJS.Std.sma(close_array, smaLength, this._context);
            const ma20_array = this._context.new_var(ma20);
            const positionFlag = this._context.new_var();
            const markPrice = this._context.new_var();
            const markStopPrice = this._context.new_var();
            const markHigh = this._context.new_var();
            const markTime = this._context.new_var();
            const buyFlag = this._context.new_var();
            const arrowFlag = this._context.new_var();
            positionFlag.get(2);
            markPrice.get(5);
            itime_array.get(60);
            ma20_array.get(5);
            buyFlag.get(2);
            const conditionResetBuy = rsi_ma < over;
            const conditionPrepare = buyFlag.get(0) === 1 && close > ma20 && close > open;
            if (conditionResetBuy) {
                buyFlag.set(1);
            }
            if (conditionPrepare) {
                positionFlag.set(1);
                buyFlag.set(0);
                arrowFlag.set(0);
                //console.log('conditionPrepare', dayAPI(itime).format('YYYY/MM/DD'))
            }
            if (positionFlag.get(0) === 1 && buyFlag.get(0) === 0 && buyFlag.get(1) === 1) {
                markPrice.set(close);
                markStopPrice.set(low * 0.9);
                markHigh.set(high);
                markTime.set(itime);
            }
            //這個有問題 先以上面的為主
            // if (positionFlag.get(0) === 1 && positionFlag.get(1) !== 1) {
            //   markPrice.set(close)
            //   markStopPrice.set(low * 0.9)
            //   markHigh.set(high)
            //   markTime.set(itime)
            //   //console.log('XpositionFlag=1', dayAPI(itime).format('YYYY/MM/DD'), markStopPrice.get(0))
            // }
            if (positionFlag.get(0) === 1 && high > markHigh) {
                markHigh.set(high);
            }
            if (positionFlag.get(0) === 1 && positionFlag.get(1) === 1) {
                if (close < markStopPrice.get(0)) {
                    positionFlag.set(0);
                }
                if (markHigh.get(0) > markPrice.get(0) * (1 + profitPrecent / 100) &&
                    itime_array.get(holdingDays) > markTime.get(0) &&
                    close < wma20) {
                    positionFlag.set(0);
                }
            }
            //箭頭
            let up_arrow = NaN;
            const in1 = 15;
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma1_array = this._context.new_var(sma1);
            const arrowUp = close_array.get(1) < sma1_array.get(1) && close > sma1;
            if (arrowUp &&
                !conditionPrepare &&
                positionFlag.get(0) === 1 &&
                positionFlag.get(1) === 1 &&
                arrowFlag.get(0) === 0) {
                up_arrow = 1;
                arrowFlag.set(1);
            }
            if (arrowUp && !conditionPrepare) {
                up_arrow = 1;
            }
            return [sma1, up_arrow];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 9,
                    trackPrice: !1,
                    transparency: 50,
                    visible: true,
                    color: '#ffff00',
                },
                plot_1: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '點火',
                histogramBase: 0,
            },
            plot_1: {
                isHidden: false,
                location: 'BelowBar',
                char: '✯',
                size: 'small',
                text: '',
                title: '✯',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
