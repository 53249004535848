import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Win988LoginView } from './component/LoginView';
import { Win988_Topbar } from './component/win988_Topbar';
import { win988_initStrategies } from './win988_initStrategies';
import { win988_initStyling } from './win988_initStyling';
import { Win988_SidePane1 } from './win988_SidePane1';
import { Win988_SidePane2 } from './win988_SidePane2';
export const win988_init = (templateProps) => {
    win988_initStyling(templateProps);
    win988_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../win988/chartingTheme.css',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TX-1',
        interval: '1',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 336px 1fr;

    background-image: linear-gradient(to bottom, #16171c, #303237);

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Drawer2 Chart'
      'Drawer1 Drawer2 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Win988_Topbar;
    templateProps.layout.Drawer1 = Win988_SidePane1;
    templateProps.layout.Drawer2 = Win988_SidePane2;
    templateProps.layout.login = Win988LoginView;
};
