import { proxy } from 'valtio';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
import dayAPI from '~/utils/dayAPI';
export const m168168_agentStore = proxy({
    /** # 選股日期 */
    stockPickedDate: dayAPI(),
    /** 持有股票標的方向 */
    holdingPositionType: 'long',
    tabsOfMain: new FuiTabs2(['強勢選股', '撿便宜', '短線軋空']),
});
