import styled from '@emotion/styled';
import { memo } from 'react';
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent';
const classes = {
    Root: styled.div `
    width: 100%;
    height: 100%;
    display: grid;
    gap: 4px;
    place-content: flex-start;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      'fr_SimpleTopDown_Top fr_SimpleTopDown_Top'
      'fr_SimpleTopDown_Down fr_SimpleTopDown_Down';
  `,
    Top: styled.div `
    grid-area: fr_SimpleTopDown_Top;
  `,
    Down: styled.div `
    grid-area: fr_SimpleTopDown_Down;
  `,
};
export const Display = memo(function Display(props) {
    const Top = useElementAsComponent(props.top);
    const Down = useElementAsComponent(props.down);
    return (<classes.Root className={props.className}>
      <classes.Top>
        <Top />
      </classes.Top>
      <classes.Down>
        <Down />
      </classes.Down>
    </classes.Root>);
});
export default { Display, classes };
