import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextInput } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { CryptoQuote, CryptoQuoteHeader } from './component/CryptoQuote';
const symbols = [
    'BTCUSDT',
    'ETHUSDT',
    'BNBUSDT',
    'XRPUSDT',
    'SOLUSDT',
    'ADAUSDT',
    'DOGUSDT',
    'AVAXUSDT',
    'DOTUSDT',
    'DOGEUSDT',
];
const watchListGroup = 'pionex_group_1';
export const cryptoPlatfromStore = proxy({
    symbolListState: 'default',
});
export const Pionex_SidePane2 = memo(function Pionex_SidePane2() {
    const state = useSnapshot(cryptoPlatfromStore);
    //自選股
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList(watchListGroup);
    const watchlistSymbol = watchListState.symbolListArray;
    //要顯示在清單上的商品群組
    const displaySymbol = state.symbolListState === 'default' ? symbols : watchlistSymbol;
    //signalr報價
    useEffect(() => {
        signalrStore2.addQuote(displaySymbol);
        return () => {
            signalrStore2.removeQuote(displaySymbol);
        };
    }, [JSON.stringify(displaySymbol)]);
    //報價排序
    const sortSignalr = useSignalr2QuoteSort(displaySymbol) ?? [];
    //filter symbols
    const [filterSymbol, setFilterSymbol] = useState('');
    //signalr
    const value = signalrHooks2.useQuotes(sortSignalr).filter(s => s.symbol?.includes(filterSymbol));
    return (<styleds.container>
      <styleds.symbolSearchContent>
        <TextInput placeholder='搜尋幣種名稱' value={filterSymbol} icon={<AiOutlineSearch />} onChange={event => setFilterSymbol(event.currentTarget.value.toLocaleUpperCase())} css={css `
            width: 100%;
            .mantine-rnw0l:focus-within {
              border-color: #ff7028;
            }
          `}/>
      </styleds.symbolSearchContent>

      <styleds.selectContent>
        <styleds.selectButton active={state.symbolListState === 'default'} onClick={() => (cryptoPlatfromStore.symbolListState = 'default')}>
          USDT
        </styleds.selectButton>
        <styleds.selectButton active={state.symbolListState === 'watchList'} onClick={() => (cryptoPlatfromStore.symbolListState = 'watchList')}>
          自選
        </styleds.selectButton>
      </styleds.selectContent>
      <CryptoQuoteHeader />
      <styleds.symbolListContent>
        {value.map((quote, index) => {
            return (<CryptoQuote watchListSymbol={watchlistSymbol} watchlistActs={watchListActs} key={index} quote={quote}/>);
        })}
        {value.length === 0 && (<styleds.emptyListContent>
            <img css={css `
                width: 64px;
                height: 64px;
              `} src='pionex/folder_icon.png'/>
            <p>沒有資料</p>
          </styleds.emptyListContent>)}
      </styleds.symbolListContent>
      <div></div>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center}
    border-left: 1px solid #e0e4ea;
    //background-color: #f0f1f3;
  `,
    symbolSearchContent: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    padding: 4px;
  `,
    selectContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 36px;
    padding: 0px 12px;
    gap: 16px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px solid #e0e4ea;
  `,
    symbolListContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 112px);
  `,
    emptyListContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 30px);
    padding: 32px;
  `,
    selectButton: styled.div `
    padding: 0px 8px;
    border-radius: 4px;
    background-color: ${props => (props.active === true ? '#f2f2f4' : 'transparent')};
    &:hover {
      background-color: #f0f1f3;
    }
  `,
};
