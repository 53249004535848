import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const south_op = createIndicator({
    displayName: 'OP666',
    id: 'OP666',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker.includes('AM') ? 'TXAM-1#opmf_acc' : 'TX-1#opmf_acc';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            const call = this.PineJS.Std.open(context);
            const put = this.PineJS.Std.high(context);
            const net = call - put;
            if (call === put) {
                return [call, put, NaN, NaN];
            }
            return [call, put, net > 0 ? net : NaN, net < 0 ? net : NaN];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'call', type: 'line' },
            { id: 'put', type: 'line' },
            { id: 'positive', type: 'line' },
            { id: 'negative', type: 'line' },
        ],
        defaults: {
            styles: {
                call: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 4,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 60,
                    color: '#FF2E2E',
                },
                put: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 6,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 60,
                    color: '#5FFA6D',
                },
                positive: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#FF2E2E',
                },
                negative: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#5FFA6D',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            call: {
                title: 'Call',
                histogramBase: 0,
            },
            put: {
                title: 'Put',
                histogramBase: 0,
            },
            positive: {
                title: '差額(正)',
                histogramBase: 0,
            },
            negative: {
                title: '差額(負)',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
