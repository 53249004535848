import dayAPI from '~/utils/dayAPI';
export class GodPerformanceManager {
    executions;
    openPositions = [];
    closedTrades = [];
    constructor(executions) {
        this.executions = executions;
        executions.forEach(e => this._onOrderExecuted(e));
    }
    get monthlyStatistics() {
        const monthlyStatistics = {};
        this.closedTrades.forEach(trade => {
            const closeTime = dayAPI(trade.closeTime);
            const month = `${closeTime.year()}/${(closeTime.month() + 1).toLocaleString()}`;
            if (!monthlyStatistics[month]) {
                monthlyStatistics[month] = {
                    month: month,
                    totalNumberOfTrades: 0,
                    pnl: 0,
                };
            }
            monthlyStatistics[month].totalNumberOfTrades++;
            monthlyStatistics[month].pnl += trade.pnl;
        });
        return Object.values(monthlyStatistics);
    }
    getClosedTradesByDate(date) {
        return this.closedTrades.filter(trade => dayAPI(trade.closeTime).format('MM-DD') === dayAPI(date).format('MM-DD'));
    }
    _onOrderExecuted(execution) {
        if (execution.positionType === 'O') {
            this.openPositions.push({
                side: execution.bs === 'B' ? 'long' : 'short',
                quantity: execution.qty,
                averagePrice: execution.price,
                unrealizedPnL: 0,
            });
        }
        else if (execution.positionType === 'C') {
            let quantityToClose = execution.qty;
            const BIG_POINT_VALUE = 200;
            while (quantityToClose > 0) {
                const openPosition = this.openPositions[0];
                openPosition.quantity--;
                this.closedTrades.push({
                    closeTime: execution.datetime,
                    side: openPosition.side,
                    openPrice: openPosition.averagePrice,
                    closePrice: execution.price,
                    pnl: openPosition.side === 'long'
                        ? (execution.price - openPosition.averagePrice) * BIG_POINT_VALUE
                        : (openPosition.averagePrice - execution.price) * BIG_POINT_VALUE,
                });
                quantityToClose--;
                if (openPosition.quantity === 0)
                    this.openPositions.shift();
            }
        }
    }
}
