import React, { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import IntradayTrendChart from '~/modules/trendChart';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import styled from '@emotion/styled';
import { fill, fill_horizontal_all_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSnapshot } from 'valtio';
import QuoteValueBody from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { quoteBasicStyled } from '~/modules/investment-consultant/symbolList/css/quoteBasicStyled';
import { useThemeStore } from '~/components/theme/useThemeStore';
const SymbolCardTrendChart = memo(function SymbolCardTrendChart(props) {
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    useEffect(() => {
        signalrStore2.addQuote(props.symbol);
        return () => {
            signalrStore2.removeQuote(props.symbol);
        };
    }, [props.symbol]);
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const isAvtive = props.symbol === props.currentSymbol ? '-active' : '';
    const theme = useThemeStore(s => s.theme);
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme + isAvtive}>
        <classes.arrow>
          <QuoteValueBody.arrow.Display quote={quote} changeType='closeChange'/>
        </classes.arrow>
        <classes.name>
          <div>{dictionary[props.symbol]}</div>
          <div>{props.symbol}</div>
        </classes.name>

        <classes.qoute>
          <QuoteValueBody.ohlc.Display quote={quote} ohlcType='close'/>
        </classes.qoute>
        <classes.change>
          <QuoteValueBody.change.Display quote={quote} changeType='closeChange'/>
          <QuoteValueBody.change.Display quote={quote} changeType='closeChangePercent' unit='%'/>
        </classes.change>

        <classes.chart>
          <IntradayTrendChart symbol={props.symbol} ticksSize={8} ticksHeight={12} transparency={0.6} hidePriceLabel={true} hideTooltip={true}/>
        </classes.chart>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${fill}
  `,
    container: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 5% 25% 20% 50%;
    grid-template-rows: 50% 50%;
    width: 100%;
    height: 56px;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px;
    cursor: pointer;
    transition: 0.3s;
  `,
    arrow: styled.div `
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  `,
    name: styled.div `
    ${fill_vertical_all_center};
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  `,
    qoute: styled.div `
    ${fill_vertical_all_center};
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  `,
    change: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceAround};
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  `,
    chart: styled.div `
    ${fill_horizontal_all_center};
    grid-column: 4 / 5;
    grid-row: 1 / 3;
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    ${quoteBasicStyled.containerWithBorder}
  }
`;
export default {
    Display: SymbolCardTrendChart,
    classes,
};
