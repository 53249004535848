import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton';
import { BsChevronRight } from 'react-icons/bs';
import { God_Topbar } from './god_TopBar';
import { God_SidePane1 } from './god_SidePane1';
import { god_initStyling } from './god_initStyling';
import { god_initStrategies } from './god_initStrategies';
import dayAPI from '~/utils/dayAPI';
import { God_SidePane2 } from './god_SidePane2';
export const god_init = (templateProps) => {
    god_initStyling(templateProps);
    god_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    store.charting.calcFrom = dayAPI().startOf('month').subtract(1, 'month');
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TX-1',
        interval: '1',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledPaneMenu: true,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderSaveload: true,
        disabledHeaderCompare: true,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px calc(100% - 48px);
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = God_Topbar;
    templateProps.layout.Drawer2 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronRight css={css `
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #56659d99;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}/>}>
      <God_SidePane2 />
    </Fr_WithDrawerCloseButton.Display>);
    templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronRight css={css `
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #56659d99;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}/>}>
      <God_SidePane1 />
    </Fr_WithDrawerCloseButton.Display>);
};
