import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { Jerry597_Topbar } from './jerry597_TopBar';
import { Jerry597_SidePane1 } from './jerry597_SidePane1';
import { Jerry597_SidePane2 } from './jerry597_SidePane2';
import { jerry597_initStyling } from './jerry597_initStyling';
import { jerry597_initStrategies } from './jerry597_initStrategies';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { Jerry597_Row } from './jerry597_Row';
import { Jerry597_Footer } from './jerry597_Footer';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const jerry597_init = (templateProps) => {
    jerry597_initStyling(templateProps);
    jerry597_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TX-1',
        interval: '15',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledPaneMenu: true,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderSaveload: true,
        disabledHeaderCompare: true,
        overrides: store.charting.darkOverrides,
        custom_css_url: '../../jerry597/chartingTheme.css',
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 40px calc(100vh - 98px) 1fr;
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;

    .Chart {
      height: calc(100vh - 98px);
    }

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Row2 Drawer2'
      'Drawer1 Chart Drawer2'
      'Row3 Row3 Row3';

    ${createIPadCss(css `
      grid-template-rows: 48px 40px calc(100vh - 98px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Chart Chart Chart'
        'Row3 Row3 Row3';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 40px calc(100vh - 98px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Chart Chart Chart'
        'Row3 Row3 Row3';
    `)}
  `;
    // Init layout components
    templateProps.layout.Row1 = Jerry597_Topbar;
    templateProps.layout.Row2 = Jerry597_Row;
    templateProps.layout.Row3 = Jerry597_Footer;
    templateProps.layout.Drawer1 = Jerry597_SidePane1;
    templateProps.layout.Drawer2 = Jerry597_SidePane2;
    templateProps.hooks.add(useSignalrStart2_0);
};
