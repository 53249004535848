import React, { memo, useEffect } from 'react';
import { useInterval } from 'react-use';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import VirtualExchangeOpbsProvider from '~/modules/virtual-exchange-center/containers/VirtualExchangeOpbsProvider';
const VirtualExchangeProvider = memo(function VirtualExchangeProvider(props) {
    useSignalrBuild({ reconnectCheckInterval: 3000 });
    useSignalrStoreValueOHLC.getState().useValueUpdateInterval(5000);
    const privateAccounts = useTradeStore(state => state.accounts);
    const currentSelectAccountId = useTradeStore(state => state.currentSelectAccountId);
    const token = useMeStore(s => s.meFirebaseState?.jwt);
    const isCurrentAccountIdPrivate = !!privateAccounts.find(a => a.id === currentSelectAccountId);
    useInterval(() => {
        if (currentSelectAccountId) {
            tradeAPI.getTransactions(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getOrders(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getPendingOrders(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getPositions(isCurrentAccountIdPrivate ? false : true);
        }
    }, 10000);
    useInterval(() => {
        if (currentSelectAccountId) {
            tradeAPI.getUser();
            tradeAPI.getAccountById(isCurrentAccountIdPrivate ? false : true);
        }
    }, 10000);
    useEffect(() => {
        if (token) {
            tradeAPI.getUser();
            tradeAPI.getCurrentUserAccounts();
        }
        tradeAPI.getAllPublicAccounts();
    }, [token]);
    useEffect(() => {
        if (currentSelectAccountId) {
            tradeAPI.getTransactions(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getOrders(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getPendingOrders(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getPositions(isCurrentAccountIdPrivate ? false : true);
            tradeAPI.getAccountById(isCurrentAccountIdPrivate ? false : true);
        }
    }, [currentSelectAccountId, isCurrentAccountIdPrivate]);
    return (<VirtualExchangeOpbsProvider>
      <useVirtualExchangeCenterParametersState.Provider>
        <useOpbsStrategyLogicState.Provider>{props.children}</useOpbsStrategyLogicState.Provider>
      </useVirtualExchangeCenterParametersState.Provider>
    </VirtualExchangeOpbsProvider>);
});
export default VirtualExchangeProvider;
