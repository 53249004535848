import { css } from '@emotion/react';
import React, { memo } from 'react';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { IndicatorsStore } from '~/modules/cons-misc/mode-buttons/IndicatorsButton';
import { QtyStateStore } from '~/modules/cons-misc/mode-buttons/QtyButton';
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore';
import QuerySettings from '~/modules/screener/components/invesement-consultant/QuerySettings';
import ScreenerSimpleTable from '~/modules/screener/components/invesement-consultant/ScreenerTable';
import { useConditionState } from '~/modules/screener/containers/useConditionState';
import { useDatePick } from '~/modules/screener/useDatePick';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import IntradayTrendChart from '~/modules/trendChart';
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { QuerySettingsCss, ScreenerTableCss } from '~/pages/goldbrain_stock/modules/css';
import { goldbrainStock_agentStore } from '~/pages/goldbrain_stock/_private/goldbrainStock_agentStore';
import Styleds from '~/pages/goldbrain_stock/_private/styleds';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import Col_WatchSymbolToggleButton from '~/pages/heineken_template/_col/col_WatchSymbolToggleButton';
import { store } from '~/pages/heineken_template/_private/store';
import { goldbrain_bandtrade } from '~/trades/indicators/goldbrain/goldbrain_bandtrade';
import { goldbrain_cci } from '~/trades/indicators/goldbrain/goldbrain_cci';
import { goldbrain_shorttrade } from '~/trades/indicators/goldbrain/goldbrain_shorttrade';
import dayAPI from '~/utils/dayAPI';
symbolGroupStore.volumeLimit = 0;
const strategyType = (state) => {
    if (state === 0)
        return [goldbrain_shorttrade, goldbrain_cci];
    if (state === 1)
        return [goldbrain_bandtrade, goldbrain_cci];
};
QtyStateStore.state = 'firebaseLong';
const FirebaseSymbolGroup = memo(function FirebaseSymbol_() {
    const indicatorsState = useSnapshot(IndicatorsStore);
    const qtyState = useSnapshot(QtyStateStore);
    const qtyMode = useSnapshot(QtyStateStore);
    const long = useFirebaseValue('goldbrain_signal', 'long');
    const short = useFirebaseValue('goldbrain_signal', 'short');
    const [fbLong, fbShort] = [long?.symbols, short?.symbols];
    const firebaseSymbolResult = () => (qtyState.state === 'firebaseLong' ? fbLong : fbShort);
    const strategy = strategyType(indicatorsState.indicatorsMode) ?? [];
    return (<div>
      <Styleds.SidebarButtonGroup>
        <FuiButton.Display variant='long' active={qtyMode.state === 'firebaseLong'} onClick={event => {
            QtyStateStore.state = 'firebaseLong';
        }}>
          多方標的
        </FuiButton.Display>

        <FuiButton.Display variant='short' active={qtyMode.state === 'firebaseShort'} onClick={event => {
            QtyStateStore.state = 'firebaseShort';
        }}>
          空方標的
        </FuiButton.Display>
      </Styleds.SidebarButtonGroup>
      <div>
        <SimpleQuoteListInteractWithChart data={firebaseSymbolResult() ?? []} indicators={strategy} chart={store.charting}/>
      </div>
    </div>);
});
export const GoldbrainStock_SidePane2 = memo(function SidePane2(props) {
    const charting = useSnapshot(store.charting);
    const qtyMode = useSnapshot(QtyStateStore);
    const theme = useThemeStore(s => s.theme);
    /** 即時訊號 */
    const lastTradeString = useDatePick();
    const lastTradeDay = dayAPI(lastTradeString).add(-1, 'day').format('YYYY-MM-DD');
    const realtimeLongSymbol = useVirtualTransaction('goldbrain_long', {
        beginDatetime: lastTradeDay,
    })
        ?.filter(datum => datum.bs === 'B')
        .map(datum => datum.symbol);
    const realtimeShortSymbol = useVirtualTransaction('goldbrain_short', {
        beginDatetime: lastTradeDay,
    })
        ?.filter(datum => datum.bs === 'S')
        .map(datum => datum.symbol);
    const realtimeSymbol = QtyStateStore.state === 'firebaseLong' ? realtimeLongSymbol : realtimeShortSymbol;
    return (<Styleds.Sidebar className={theme}>
      <Styleds.Title>
        <SymbolName symbol={charting.symbol}/>
        <span>走勢圖</span>
      </Styleds.Title>
      <Styleds.SidebarTrendChart>
        <IntradayTrendChart symbol={charting.symbol} ticksSize={14} ticksHeight={20} priceTicksMargin={2.5} priceTicksSize={14}/>
      </Styleds.SidebarTrendChart>
      <Styleds.SidebarSection>
        <Col_WatchSymbolToggleButton.Display groupName='goldbrain_stock_group_1' maxSize={50}/>
      </Styleds.SidebarSection>
      <Styleds.Title>智能選飆股</Styleds.Title>
      <Styleds.SidebarButtonGroup>
        <goldbrainStock_agentStore.tabs1.WithButton to='智能飆股'>
          <FuiButton.Display>智能飆股</FuiButton.Display>
        </goldbrainStock_agentStore.tabs1.WithButton>

        <goldbrainStock_agentStore.tabs1.WithButton to='金頭腦'>
          <FuiButton.Display>金頭腦</FuiButton.Display>
        </goldbrainStock_agentStore.tabs1.WithButton>

        <goldbrainStock_agentStore.tabs1.WithButton to='即時選股'>
          <FuiButton.Display>即時選股</FuiButton.Display>
        </goldbrainStock_agentStore.tabs1.WithButton>

        <goldbrainStock_agentStore.tabs1.WithButton to='自選股'>
          <FuiButton.Display>自選股</FuiButton.Display>
        </goldbrainStock_agentStore.tabs1.WithButton>
      </Styleds.SidebarButtonGroup>

      <Styleds.SidebarSymbolGroup>
        <goldbrainStock_agentStore.tabs1.WithContent for='智能飆股'>
          <useConditionState.Provider>
            <div css={QuerySettingsCss}>
              <QuerySettings />
            </div>

            <div css={ScreenerTableCss}>
              <ScreenerSimpleTable useChart={store.charting}/>
            </div>
          </useConditionState.Provider>
        </goldbrainStock_agentStore.tabs1.WithContent>

        <goldbrainStock_agentStore.tabs1.WithContent for='金頭腦'>
          <FirebaseSymbolGroup />
        </goldbrainStock_agentStore.tabs1.WithContent>

        <goldbrainStock_agentStore.tabs1.WithContent for='即時選股'>
          <Styleds.SidebarButtonGroup>
            <FuiButton.Display variant='long' active={qtyMode.state === 'firebaseLong'} onClick={event => {
            QtyStateStore.state = 'firebaseLong';
        }}>
              多方標的
            </FuiButton.Display>

            <FuiButton.Display variant='short' active={qtyMode.state === 'firebaseShort'} onClick={event => {
            QtyStateStore.state = 'firebaseShort';
        }}>
              空方標的
            </FuiButton.Display>
          </Styleds.SidebarButtonGroup>

          <div css={css `
              height: calc(100% - 30px);
            `}>
            <SimpleQuoteListInteractWithChart data={realtimeSymbol || []} chart={store.charting} indicators={store.charting.strategySelected.indicators}/>
          </div>
        </goldbrainStock_agentStore.tabs1.WithContent>

        <goldbrainStock_agentStore.tabs1.WithContent for='自選股'>
          <WatchListWithChart groupName={'goldbrain_stock_group_1'} useChart={store.charting} indicators={store.charting.strategySelected.indicators}/>
        </goldbrainStock_agentStore.tabs1.WithContent>
      </Styleds.SidebarSymbolGroup>
    </Styleds.Sidebar>);
});
