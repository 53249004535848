import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, pureGrid, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { store } from '~/pages/heineken_template/_private/store';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
export const SgxSymbolQuote = memo(function SgxSymbolQuote(props) {
    const charting = useSnapshot(store).charting;
    const digits = props.digits ?? 2;
    useEffect(() => {
        signalrStore2.addQuote(props.symbol);
        return () => {
            signalrStore2.removeQuote(props.symbol);
        };
    }, [JSON.stringify(charting.symbol)]);
    const value = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const quoteChanges = processQuoteToReadable(value);
    const changeSymbol = quoteChanges.closeChange > 0 ? '+' : null;
    const changeArrow = quoteChanges.closeChange > 0 ? '▲' : quoteChanges.closeChange < 0 ? '▼' : '-';
    return (<styleds.Container selected={charting.symbol === props.symbol} onClick={() => {
            store.charting.changeSymbol(props.symbol);
        }}>
      <styleds.FullName>{props.fullName}</styleds.FullName>
      <styleds.ItemName>
        <SymbolName symbol={props.symbol}/>
      </styleds.ItemName>
      <styleds.QuoteItemValue change={0}>
        <styleds.Arrow change={quoteChanges.closeChange}>{changeArrow}</styleds.Arrow>
        {value?.close?.toFixed(digits)}
      </styleds.QuoteItemValue>
      <styleds.QuoteItemValue change={quoteChanges.closeChange}>
        <styleds.ItemValueChange>
          {changeSymbol}
          {quoteChanges.closeChange?.toFixed(digits)}
        </styleds.ItemValueChange>
        <styleds.ItemValueChange>
          {changeSymbol}
          {quoteChanges.closeChangePercent?.toFixed(digits)}%
        </styleds.ItemValueChange>
      </styleds.QuoteItemValue>
      {/* <styleds.BidValue>{value?.bid === 0 ? '-' : value?.bid}</styleds.BidValue>
          <styleds.AskValue>{value?.ask === 0 ? '-' : value?.ask}</styleds.AskValue> */}
    </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${pureGrid};
    grid-template-rows: 50% 50%;
    grid-template-columns: 28% 32% 40%;
    width: 100%;
    height: 88px;
    justify-items: center;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 600;
    background-color: #f7f7f7;
    border-radius: 2px;
    cursor: pointer;
    padding: 4px;
    border-left: 6px solid ${props => (props.selected === true ? '#b0db56' : '#f7f7f7')};
    &:hover {
      transition: 0.3s;
      background-color: #e8f3f6;
    }
  `,
    FullName: styled.div `
    ${fill_horizontal_cross_center};
    grid-column: 1 / 4;
    grid-row: 1;
    padding: 0 8px;
  `,
    ItemName: styled.div `
    ${fill_horizontal_cross_center};
    padding: 0 0 0 8px;
    width: 100%;
    border-radius: 4px;
    white-space: nowrap;
    color: #222222;
    font-weight: 600;
  `,
    QuoteItemValue: styled.div `
    ${fill_horizontal_all_center};
    color: ${props => (props.change > 0 ? '#af2453' : props.change < 0 ? '#4ec00a' : '#252525')};
    font-size: 14px;
  `,
    ItemValueChange: styled.div `
    ${fill_horizontal_all_center};
  `,
    BidAskContent: styled.div `
    ${fill_horizontal_all_center};
    gap: 8px;
  `,
    BidValue: styled.div `
    ${fill_horizontal_all_center};
    background-color: #af2453;
    border-radius: 2px;
  `,
    AskValue: styled.div `
    ${fill_horizontal_all_center};
    background-color: #81ab33;
    border-radius: 2px;
  `,
    Arrow: styled.div `
    color: ${props => (props.change > 0 ? '#af2453' : props.change < 0 ? '#4ec00a' : '#252525')};
  `,
};
