import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { fontWeight500 } from '~/css/font';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_main_center, jc, pureGrid, } from '~/modules/AppLayout/FlexGridCss';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { fadeInTransformX, fadeIn } from '../styleds';
export const EurexLoginPage = memo(function EurexLoginPage(props) {
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const meUserState = useMeStore(state => state.meUserState);
    const { isPc, isPhone } = useMedia();
    const View = memo(function View() {
        const auth = useFirebaseMeState.useContainer();
        const router = useRouter();
        return (<div css={css `
          ${fill_horizontal_cross_center};
          //${jc.spaceBetween};
          ${fontWeight500};
          padding: 0px 16px;
          width: 240px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 8px;
          border: 1px solid #aaaaaa;
          color: #7b7b7b;
          gap: 4px;
          cursor: pointer;
          &:hover {
            background-color: #f1f1f1;
          }
        `} onClick={() => {
                auth.acts.loginWithGoogle().then(() => {
                    router.reload();
                });
            }}>
        <img css={css `
            width: 32px;
          `} src='google-icon.png'/>
        Continue with Google
      </div>);
    });
    return (<styleds.Container className={props.className}>
      <styleds.DemoContent css={css `
          display: ${isPhone ? 'none' : ''};
        `}>
        <div css={css `
            width: 308px;
            animation: ${fadeInTransformX} 2s;
          `}>
          <div css={css `
              color: #1a1948;
            `}>
            歐交所
          </div>
          <div css={css `
              ${fill_horizontal_all_center}
              color: #1a1948;
              height: 64px;
              line-height: 64px;
              background-color: #1ac676;
              -webkit-text-stroke: 0.3px #1a1948;
              padding: 4px 12px;
            `}>
            商品觀測站
          </div>
        </div>
      </styleds.DemoContent>
      <styleds.Sidebar css={css `
          width: ${!isPhone ? '500px' : '100%'};
        `}>
        <styleds.LogoName src='eurex/eurex-logo-name.png'/>
        <styleds.EurexInfoContent>
          <styleds.TitleWhite>國際化產品</styleds.TitleWhite>
          <styleds.TitleGreen>全天候交易</styleds.TitleGreen>
          <styleds.Subtitle>
            <p>歐交所交易時間覆蓋全球各時區</p>
            <p>為市場參與者提供更加靈活的交易選擇</p>
          </styleds.Subtitle>
        </styleds.EurexInfoContent>
        <styleds.LogintContent>
          <styleds.LoginTitle>登入即可免費使用</styleds.LoginTitle>
          {!meFirebaseState && <View />}
        </styleds.LogintContent>
        <styleds.Copyright>© 歐洲期貨交易所法蘭克福公司</styleds.Copyright>
      </styleds.Sidebar>
    </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${fill_horizontal_all_center}
    position: fixed;
    z-index: 1300;
    font-family: Replica, Arial, serif;
    background-color: #f1f1f1;
  `,
    Sidebar: styled.div `
    ${fill_vertical_all_center};
    ${pureGrid};
    grid-template-rows: repeat(8, 12.5%);
    grid-template-columns: 100%;
    background-color: #1a1948;
    padding: 24px;
    font-family: Replica, Arial, serif;
    font-weight: 900;
    animation: ${fadeIn} 3s;
  `,
    DemoContent: styled.div `
    ${fill_vertical_main_center};
    background-color: #cccccc;
    width: calc(100% - 500px);
    background-image: linear-gradient(90deg, #ffffff, #ffffffcc, #ffffff88),
      url('eurex/eurex-demo.png');
    background-position: right;
    font-family: Replica, Arial, serif;
    font-weight: 900;
    font-size: 48px;
    padding: 32px;
    animation: ${fadeIn} 2s;
  `,
    LogoName: styled.img `
    width: 148px;
    grid-row: 1 / 3;
  `,
    EurexInfoContent: styled.div `
    grid-row: 3 / 4;
  `,
    TitleWhite: styled.div `
    color: #ffffff;
    font-size: 36px;
    line-height: 1.5;
    -webkit-text-stroke: 0.3px #ffffff;
  `,
    TitleGreen: styled.div `
    color: #1a1948;
    font-size: 36px;
    line-height: 44px;
    background-color: #1ac676;
    -webkit-text-stroke: 0.3px #1a1948;
    padding: 4px 12px;
  `,
    Subtitle: styled.div `
    font-size: 18px;
    line-height: 8px;
  `,
    LoginTitle: styled.div `
    color: #01ce7c;
    font-size: 36px;
  `,
    LogintContent: styled.div `
    ${fill_vertical_all_center};
    height: 104px;
    gap: 16px;
    grid-row: 5 / 7;
  `,
    Copyright: styled.div `
    ${fill_horizontal_all_center}
    grid-row: 8;
    align-items: end;
  `,
};
