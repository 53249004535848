import { gd1788_daytrade2 } from '~/trades/indicators/gd1788/gd1788_daytrade2';
import { gd1788_daytrade3 } from '~/trades/indicators/gd1788/gd1788_daytrade3';
import { gd1788_envelopes } from '~/trades/indicators/gd1788/gd1788_envelopes';
import { gd1788_power } from '~/trades/indicators/gd1788/gd1788_power';
import { store } from '~/pages/heineken_template/_private/store';
export const gd1788_strategiesGroup = {
    main: [
        {
            displayName: '波段',
            indicators: [gd1788_daytrade3, gd1788_power],
            interval: '1D',
        },
        {
            displayName: '當沖',
            indicators: [gd1788_daytrade2, gd1788_envelopes],
            interval: '5',
        },
    ],
};
export const gd1788_Strategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[gd1788_daytrade3, gd1788_power, gd1788_daytrade2, gd1788_envelopes],
    ];
    store.charting.initStrategy({
        configs: [...gd1788_strategiesGroup.main],
    });
};
