import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { asiajye_initStyling } from '~/pages/asiajye/_private/asiajye_initStyling';
import { store } from '~/pages/heineken_template/_private/store';
import { asiajye_initStrategies } from './asiajye_initStrategies';
import { Asiajye_Sidebar } from './asiajye_Sidebar';
import { Asiajye_Topbar } from './asiajye_TopBar';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import dayAPI from '~/utils/dayAPI';
import { Charting } from '~/pages/heineken_template/_private/Charting';
export const asiajye_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web',
        'web_pro',
    ]);
    asiajye_initStyling(templateProps);
    asiajye_initStrategies();
    useThemeStore.setState({ theme: 'light' });
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        disabledLeftToolbar: true,
        enableVolumeIndicator: false,
        overrides: store.charting.darkOverrides,
    };
    store.charting.calcFrom = dayAPI().subtract(10, 'day');
    templateProps.layout.Charting = (<div css={css `
        height: 100%;
        width: 100%;
        padding: 4px;
        ${createMobileCss2(css `
          padding: 0 24px 0 0;
        `)}
      `}>
      <Charting />
    </div>);
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;

    grid-template-areas:
      'Drawer1 Row1'
      'Drawer1 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Asiajye_Topbar;
    templateProps.layout.Drawer1 = Asiajye_Sidebar;
    templateProps.hooks.add(fr_me.useInstall);
    templateProps.hooks.add(useSignalrStart2_0);
};
