import { MantineProvider } from '@mantine/core';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { store } from '../heineken_template/_private/store';
import { useThemeStore } from '~/components/theme/useThemeStore';
import styleds from './styled';
import IntradayTrendChart from '~/modules/trendChart';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { css } from '@emotion/react';
import { GodPerformance } from './components/GodPerformance';
export const God_SidePane1 = memo(function God_SidePane1() {
    const colorTheme = useThemeStore(state => state.theme);
    const charting = useSnapshot(store.charting);
    return (<MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar1>
        <TitleSymbolQuote.Default symbol={charting.symbol}/>

        <styleds.TrendChartContent>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={16} transparency={0.4} priceTicksSize={12} priceTicksMargin={-15}/>
        </styleds.TrendChartContent>

        {/* 鱷魚實單 */}
        <styleds.TradingTipsTitle>鱷魚老師紀錄</styleds.TradingTipsTitle>
        <div css={css `
            width: 100%;
            height: calc(100% - 200px);
          `}>
          <GodPerformance />
        </div>
      </styleds.SideBar1>
    </MantineProvider>);
});
