import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { store } from '~/pages/heineken_template/_private/store';
import { Sgx_SidePane1 } from './sgx_SidePane1';
import { Sgx_Topbar } from './sgx_Topbar';
import { Sgx_SidePane2 } from './sgx_SidePane2';
import { Sgx_Footer } from './sgx_Footer';
import { Sgx_DataCard } from './sgx_DataCard';
import { sgx_TopInfo } from './sgx_TopInfo';
import { sgx_initStrategies } from './sgx_initStrategies';
import { selectors } from '~/modules/tradeSheet/tradingSummary/selectors';
import { component } from '~/utils/component';
import { meCheckHandlerHasMe } from '~/modules/SDK/me/meCheckHandlerHasMe';
import { SgxLoginPage } from './component/LoginView';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const sgx_init = (templateProps) => {
    //sgx_initStyling(templateProps)
    sgx_initStrategies();
    useThemeStore.setState({ theme: 'light' });
    store.charting.setThemeMode(useThemeStore.getState().theme);
    //templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')
    templateProps.permissions.pageview = meCheckHandlerHasMe;
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'CN-1',
        interval: '5',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 66px 64px 500px auto 168px;
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;
    font-family: Lato, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Row3 Row3 Row3'
      'Drawer1 Chart Drawer2'
      'Drawer1 Row2 Drawer2'
      'Row4 Row4 Row4';

    ${createIPadCss(css `
      grid-template-rows: 66px 64px 500px auto 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart'
        'Row2 Row2 Row2'
        'Row4 Row4 Row4';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 66px 64px 500px auto 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart'
        'Row2 Row2 Row2'
        'Row4 Row4 Row4';
    `)}
  `;
    templateProps.layout.Row1 = Sgx_Topbar;
    templateProps.layout.Drawer1 = Sgx_SidePane1;
    templateProps.layout.Drawer2 = Sgx_SidePane2;
    templateProps.layout.Row2 = Sgx_DataCard;
    templateProps.layout.Row3 = sgx_TopInfo;
    templateProps.layout.Row4 = Sgx_Footer;
    templateProps.layout.Providers = component(props => {
        return <div id={selectors.PRINT_ROOT}>{props.children}</div>;
    });
    templateProps.layout.login = SgxLoginPage;
    templateProps.hooks.add(useSignalrStart2_0);
};
