import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import IntradayTrendChart from '~/modules/trendChart';
import { store } from '~/pages/heineken_template/_private/store';
import { PositionInfomation, } from '~/modules/investment-consultant/positionValueForm/PositonInfotion';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { indicatorsValueStore } from '../../../modules/investment-consultant/positionValueForm/indicatorsValueStore';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { gd1788_strategiesGroup } from './gd1788_Strategies';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
export const Gd1788_Sidebar = memo(function Sidebar() {
    const charting = useSnapshot(store.charting);
    //const symbolNumber = usegd1788Chart(state_ => state_.symbol)
    const symbolNumber = useSnapshot(store.charting).symbol;
    const value = useSnapshot(indicatorsValueStore);
    const entryDate = isNaN(value.entryDate) ? '---' : new Date(value.entryDate).toLocaleDateString();
    const entryTime = isNaN(value.entryDate)
        ? '---'
        : new Date(value.entryDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const stopLoss = isNaN(value.entryDate)
        ? '---'
        : [value.multipleStopLossPrice[0], value.multipleStopLossPrice[1]];
    const target = isNaN(value.entryDate)
        ? '---'
        : [value.multipleTargetPrice[0], value.multipleTargetPrice[1]];
    const openPositionProfit = isNaN(value.entryDate) ? '---' : value.openPositionProfit;
    const cost = isNaN(value.entryDate) ? '---' : value.entryPrice;
    const positonConfig = [
        {
            displayName: '進場日期',
            value: entryDate,
        },
        {
            displayName: '進場時間',
            value: entryTime,
        },
        {
            displayName: '進場成本',
            value: cost,
            marketPosition: value.marketPosition,
        },
        {
            displayName: '浮動損益',
            value: openPositionProfit,
        },
        {
            displayName: '停損參考',
            value: stopLoss,
        },
        {
            displayName: '停利參考',
            value: target,
        },
    ];
    return (<div css={css `
        padding: 4px;
        gap: 16xp;
      `}>
      <div>
        <TitleSymbolQuote.Default symbol={symbolNumber}/>
      </div>
      <div css={css `
          height: 200px;
          width: 100%;
        `}>
        <IntradayTrendChart symbol={symbolNumber} ticksSize={12} ticksHeight={20} priceTicksMargin={-15} priceTicksSize={11}/>
      </div>
      <div css={css `
          ${fill_horizontal_all_center};
          height: 30px;
        `}>
        {gd1788_strategiesGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<FuiButton.Display key={config.displayName} active={isActive}>
              {config.displayName}
            </FuiButton.Display>);
        })}
      </div>
      <PositionInfomation useChart={store.charting} PositionOptions={positonConfig}/>
    </div>);
});
