import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const Mvp888Stock_Footer = memo(function Kabujuku_Footer(props) {
    return (<div css={css `
        ${fill_horizontal_all_center}
        width: 100%;
        height: 100%;
        background-color: #191342;
        color: #cccccc;
        font-size: 14px;
      `}>
      版權所有 © 2019 萬通國際投顧 111年金管投顧新字第007號
    </div>);
});
