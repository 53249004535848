import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Group, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, useEffect, useState } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import QuoteValue from '~/modules/page_modules/cons_modules/SymbolQuote/QuoteValue';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { store } from '~/pages/heineken_template/_private/store';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
//股票升降跳動要顯示的小數位
const stockDecimal = (value) => {
    if (value < 100) {
        return 2;
    }
    else if (value >= 100) {
        return 1;
    }
    else if (value >= 1000) {
        return 0;
    }
    else
        return 2;
};
export const SymbolModal = memo(function SymbolModal(props) {
    const theme = useThemeStore(t => t.theme);
    const charting = useSnapshot(store).charting;
    const [opened, { open, close }] = useDisclosure(false);
    const [symbolType, setSymbolType] = useState('long');
    const symbol = symbolType === 'long'
        ? props.symbolLong
        : symbolType === 'short'
            ? props.symbolShort
            : props.symbolFeatured;
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [JSON.stringify(symbol)]);
    const value = signalrHooks2.useQuotes(symbol);
    return (<div>
      <Modal css={css `
          &.mantine-Modal-root .mantine-Modal-content {
            height: 80%;
            width: 100%;
          }
        `} size='80%' opened={opened} onClose={close} centered>
        <styleds.header>
          <styleds.selectItem className={theme} selected={symbolType === 'long'} onClick={() => setSymbolType('long')}>
            多方選股 ({props.symbolLong.length})
          </styleds.selectItem>
          <styleds.selectItem className={theme} selected={symbolType === 'short'} onClick={() => setSymbolType('short')}>
            空方選股 ({props.symbolShort.length})
          </styleds.selectItem>
          <styleds.selectItem className={theme} selected={symbolType === 'featured'} onClick={() => setSymbolType('featured')}>
            精選股 ({props.symbolFeatured.length})
          </styleds.selectItem>
        </styleds.header>
        <styleds.body>
          <styleds.symbolQuoteHeader className={theme}>
            <div>代號</div>
            <div>名稱</div>
            <div></div>
            <div>成交</div>
            <div>最高</div>
            <div>最低</div>
            <div>漲跌</div>
            <div>漲跌幅</div>
          </styleds.symbolQuoteHeader>
          <div css={css `
              ${scrollbar2Css};
              height: calc(100% - 100px);
              flex-shrink: 0;
              gap: 2px;
            `}>
            {symbol.length === 0 && <styleds.loadingView>商品載入中</styleds.loadingView>}
            {symbol.length !== 0 && value.length === 0 && (<styleds.loadingView>報價載入中</styleds.loadingView>)}
            {value.length !== 0 &&
            value.map(s => {
                const quoteChanges = processQuoteToReadable(s);
                //變動符號
                const changeSymbol = quoteChanges.closeChange > 0 ? '+' : null;
                //漲停價
                const limitHigh = s.limitUpPrice;
                //跌停價
                const limitLow = s.limitDownPrice;
                //上漲顏色
                const upFill = theme === 'dark' ? '#ff0000' : '#cc0000';
                //下跌顏色
                const dnFill = theme === 'dark' ? '#00cc00' : '#00aa00';
                //一般報價顏色
                const defaultFill = theme === 'dark' ? '#ffffff' : '#252525';
                //最高:漲停文字顏色
                const highFill = limitHigh === s.high ? '#ffffff' : upFill;
                //嘴低:跌停文字顏色
                const lowFill = limitLow === s.low ? '#ffffff' : dnFill;
                //收盤:漲跌停文字顏色
                const closeFill = s.close === limitHigh || s.close === limitLow ? '#ffffff' : defaultFill;
                //漲停背景顏色
                const limitHighBgColor = limitHigh === s.high ? upFill : 'transparent';
                //跌停背景顏色
                const limitLowBgColor = limitLow === s.low ? dnFill : 'transparent';
                //漲跌停背景顏色
                const limitCloseBgColor = limitHigh === s.close ? upFill : limitLow === s.close ? dnFill : 'transparent';
                //報價顏色
                const quoteColor = quoteChanges.closeChange > 0
                    ? upFill
                    : quoteChanges.closeChange < 0
                        ? dnFill
                        : defaultFill;
                return (<styleds.symbolQuoteBody className={theme} selected={charting.symbol === s.symbol} key={s.symbol} onClick={() => {
                        store.charting.changeSymbol(s.symbol);
                    }}>
                    <QuoteValue.Arrow symbolNumber={s.symbol}/>
                    <div>{s.symbol}</div>
                    <SymbolName symbol={s.symbol}/>
                    <styleds.quoteItem>
                      <styleds.quoteString color={closeFill} bgColor={limitCloseBgColor}>
                        {s.close?.toFixed(stockDecimal(s.close ?? 2))}
                      </styleds.quoteString>
                    </styleds.quoteItem>
                    <styleds.quoteItem>
                      <styleds.quoteString color={highFill} bgColor={limitHighBgColor}>
                        {s.high?.toFixed(stockDecimal(s.high ?? 2))}
                      </styleds.quoteString>
                    </styleds.quoteItem>
                    <styleds.quoteItem>
                      <styleds.quoteString color={lowFill} bgColor={limitLowBgColor}>
                        {s.low?.toFixed(stockDecimal(s.low ?? 2))}
                      </styleds.quoteString>
                    </styleds.quoteItem>
                    <styleds.quoteItem>
                      <styleds.quoteString color={quoteColor} bgColor=''>
                        {changeSymbol}
                        {quoteChanges.closeChange?.toFixed(stockDecimal(s.close ?? 2))}
                      </styleds.quoteString>
                    </styleds.quoteItem>
                    <styleds.quoteItem>
                      <styleds.quoteString color={quoteColor} bgColor=''>
                        {changeSymbol}
                        {quoteChanges.closeChangePercent?.toFixed(2)}%
                      </styleds.quoteString>
                    </styleds.quoteItem>
                  </styleds.symbolQuoteBody>);
            })}
          </div>
        </styleds.body>
      </Modal>

      <Group position='center'>
        <styleds.openModalContent className={theme} onClick={open}>
          視窗放大
        </styleds.openModalContent>
      </Group>
    </div>);
});
const styleds = {
    container: styled.div ``,
    header: styled.div `
    ${fill_horizontal_cross_center};
    height: 60px;
    gap: 16px;
    font-size: 36px;
    font-weight: 500;
  `,
    title: styled.div ``,
    body: styled.div `
    ${fill_vertical_cross_center};
  `,
    selectItem: styled.div `
    font-size: 32px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &.dark {
      color: ${props => (props.selected === true ? '#00ffff' : '#fff')};
    }
    &.light {
      color: ${props => (props.selected === true ? '#0000aa' : '#373737')};
    }
  `,
    symbolQuoteHeader: styled.div `
    display: grid;
    grid-template-columns: 10% 10% 14% 12% 12% 12% 12% 16%;
    width: 100%;
    height: 48px;
    font-size: 32px;
    font-weight: 500;
    border-radius: 6px;
    & > * {
      ${fill_horizontal_all_center};
      justify-content: end;
    }
    &.dark {
      background-color: #454545;
    }
    &.light {
      background-color: #e1e1e1;
    }
  `,
    symbolQuoteBody: styled.div `
    display: grid;
    grid-template-columns: 4% 10% 20% 12% 12% 12% 12% 16%;
    width: 100%;
    height: 48px;
    font-size: 32px;
    cursor: pointer;
    font-weight: 500;
    &.dark {
      color: #ffffff;
      border-bottom: 1.6px solid ${props => (props.selected === true ? '#00ffff' : 'transparent')};
      &:hover {
        background-color: #303030;
      }
    }
    &.light {
      color: #252525;
      border-bottom: 1.6px solid ${props => (props.selected === true ? '#0000aa' : 'transparent')};
      &:hover {
        background-color: #f0f0f0;
      }
    }
  `,
    quoteItem: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
    padding: 0px 4px;
  `,
    quoteString: styled.span `
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
    padding: 0 8px;
  `,
    openModalContent: styled.div `
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    &.dark {
      color: #00ffea;
      &:hover {
        color: #00ffff;
      }
    }
    &.light {
      color: #0000aa;
      &:hover {
        color: #0022ff;
      }
    }
  `,
    loadingView: styled.div `
    ${fill_horizontal_all_center};
    font-size: 32px;
  `,
};
