import styled from '@emotion/styled';
import { memo } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { fill_horizontal_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
import { SetPassword } from './SetPassword';
/** 需要抽出當共用funtcion */
const getMatchedSubscription = (subscriptions, agentName, // target name
product) => {
    for (const subscription of subscriptions) {
        if (subscription.agentName === agentName && subscription.productName === product) {
            return subscription;
        }
    }
    return null;
};
export const LogoutView = memo(function LogoutView() {
    const firebaseMe = useFirebaseMeState.useContainer();
    const { meUserState } = useMeStore();
    /** 產品名稱 */
    const agentName = useMeStore(state => state.agentName);
    /** product名稱 */
    const produtName = useMeStore(state => state.product);
    /** 會員信箱 */
    const email = useMeStore(state => state.meUserState?.email);
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, produtName);
    }
    /** 到期日期 */
    const expDate = matchedSubscription && dayAPI(matchedSubscription.expiredAt).format('YYYY-MM-DD');
    return (<styleds.container>
      <styleds.topContent>
        <p>
          <FaUserCircle size={28}/>
        </p>
        <p>{email?.replace('@win988.futures-ai.com', '')}</p>
        <p>到期日期 {expDate}</p>
      </styleds.topContent>

      <styleds.infoContent>
        <SetPassword />
        <styleds.logoutButton onClick={() => firebaseMe.acts.logout()}>登出</styleds.logoutButton>
      </styleds.infoContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    width: 280px;
    height: 360px;
    border-radius: 10px;
    background: linear-gradient(45deg, #25262b, #15161e);
    color: #efefef;
  `,
    topContent: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 104px;
    padding: 8px;
    background-image: linear-gradient(90deg, #25253b52, #212136, #25253b5e),
      url(win988/login-view.png);
    background-size: 100%;
    background-position: 50% 100%;
    border-radius: 10px 10px 0 0;
    & > p {
      padding: 4px;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
    }
  `,
    infoContent: styled.div `
    ${fill_vertical_cross_center};
    ${jc.spaceAround};
    height: 256px;
  `,
    logoutButton: styled.div `
    ${fill_horizontal_all_center};
    width: 240px;
    height: 32px;
    border-radius: 6px;
    background-color: #9b4137;

    &:hover {
      background-color: #9b4137aa;
      cursor: pointer;
    }
  `,
};
