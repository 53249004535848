import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const wu5868_cost = createIndicator({
    displayName: '外資成本線',
    id: 'wu5868-cost',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#big3_net_stock';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const i = context.new_unlimited_var(this._context.symbol.time);
            this._context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            const high = this.PineJS.Std.high(this._context);
            const chip = this.PineJS.Std.close(this._context);
            const unlimited1 = this._context.new_unlimited_var(chip);
            const value1 = unlimited1.adopt(time1, i, 0); // <=這是籌碼
            //主圖商品
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const time = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const chips = value1;
            const chips_array = this._context.new_var(chips);
            const chipsma = this.PineJS.Std.sma(chips_array, 2, this._context);
            const stdv = Math.abs(chips / chipsma);
            const total_closeChips = this._context.new_var();
            const total_chips = this._context.new_var();
            const avg_price = total_closeChips / total_chips;
            const localTimeVar = new Date().getTime();
            const dayHour = 60 * 1000 * 60 * 24;
            const offSetLocation = new Date(time) >= new Date(localTimeVar - dayHour * 80);
            const displayPlotTime = new Date(time) >= new Date(localTimeVar - dayHour * 50);
            //外資成本計算方式
            //買賣張數增加 total_closeChips + close * chips
            //買賣張數減少 total_closeChips - close * chips
            if (!offSetLocation) {
                total_closeChips.set(close * chips);
                total_chips.set(chips);
            }
            else {
                total_closeChips.set(chips >= 0 ? total_closeChips + close * chips : total_closeChips - close * chips);
                total_chips.set(total_chips + Math.abs(chips));
            }
            const cost = displayPlotTime ? avg_price : NaN;
            return [cost === Infinity ? NaN : cost];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [{ id: 'plot_0', type: 'line' }],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 20,
                    color: '#fa5a00',
                },
            },
        },
        styles: {
            plot_0: {
                title: '外資短期成本線',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
