import { store } from '~/pages/heineken_template/_private/store';
export const kabujuku_initStyling = () => {
    store.charting.lightOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#111111',
        'mainSeriesProperties.candleStyle.borderUpColor': '#111111',
        'mainSeriesProperties.candleStyle.downColor': '#111111',
        'mainSeriesProperties.candleStyle.upColor': '#ffffff',
        'mainSeriesProperties.candleStyle.wickDownColor': '#444444',
        'mainSeriesProperties.candleStyle.wickUpColor': '#444444',
        'mainSeriesProperties.candleStyle.drawBorder': true,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#555555',
        'paneProperties.horzGridProperties.color': '#555555',
        'paneProperties.background': '#fafafa',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#222222',
        'scalesProperties.lineColor': '#222222',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        overrides: { ...store.charting.lightOverrides },
    };
};
