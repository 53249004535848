import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const god_movAvg = createIndicator({
    id: 'gpd-movAvg',
    displayName: '均線',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.ohlc.closeArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            close.get(100);
            high.get(100);
            low.get(100);
            const length = this._input(0);
            const ma = this.average(close, length);
            return [ma];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        inputs: [
            {
                id: 'maLength',
                name: '均線計算長度',
                defval: 12,
                type: 'integer',
                min: 1,
                max: 100,
                step: 1,
            },
        ],
        plots: [{ id: 'movAvg', type: 'line' }],
        defaults: {
            precision: 2,
            inputs: {
                maLength: 12,
            },
            styles: {
                movAvg: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: true,
                    transparency: 0,
                    visible: true,
                    color: '#EA0000',
                },
            },
        },
        styles: {
            movAvg: {
                title: 'MA',
                histogramBase: 0,
                joinPoints: false,
            },
        },
    },
});
