import styled from '@emotion/styled';
import { fontWeight400, fontWeight600 } from '~/css/font';
import { generateScrollbar } from '~/css/scrollbarCss';
import { flex } from '~/modules/AppLayout/FlexGridCss';
const Styleds = {
    /** Sidebar 標題 */
    Title: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    color: #eeeeee;
    background-color: #5c6e7e;
    font-size: 22px;
    ${fontWeight600};
    border-radius: 5px;
    margin: 1px 0px;
  `,
    Sidebar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: calc(100% - 5px);
    border-radius: 5px;
    border: 1px solid #aaaaaa;
    font-size: 22px;
    background-size: 100% 40%;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
    padding: 2px;
    ${generateScrollbar('#454545', '#bbbbbb')}
    &.light {
      background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.7)),
        url('goldbrain/background.png');
    }
    &.dark {
      background-color: #14172199;
      background-image: linear-gradient(#14172166, #14172199), url('goldbrain/background.png');
    }
  `,
    SidebarSection: styled.div `
    ${flex.h.allCenter};
    width: 100%;
  `,
    SidebarButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    gap: 2.5px;
  `,
    SidebarPositionForm: styled.div `
    width: 100%;
    height: 300px;
  `,
    SidebarControlBorad: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 50px;
    gap: 5px;
  `,
    SidebarTrendChart: styled.div `
    width: 100%;
    height: 175px;
  `,
    SidebarSymbolGroup: styled.div `
    width: 100%;
    height: calc(100% - 310px);
  `,
    SidebarSymbolText: styled.div `
    ${flex.h.allCenter};
    ${fontWeight400};
    &.light {
      color: #333333;
    }
    &.dark {
      color: #dddddd;
    }
  `,
};
export default Styleds;
