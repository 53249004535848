import { memo } from 'react';
import { css, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
export const AvailableStrategySelect = memo(function AvailableStrategySelect({ strategies, changeDelegate, value }) {
    return (<FormControl>
      <InputLabel disableAnimation={true}>選擇策略</InputLabel>
      <Select css={css `
          width: 200px;
        `} size='small' label='選擇策略' value={value ?? ''} onChange={e => changeDelegate(e)}>
        {strategies.map(account => (<MenuItem key={account.id} value={account.name}>
            {account.name}
          </MenuItem>))}
      </Select>
    </FormControl>);
});
