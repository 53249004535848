import { proxy } from 'valtio';
export const indicatorsValueStore = proxy({
    /** 商品類型 */
    symbolChannel: '',
    /** 商品名稱 */
    symbolNumber: '',
    /** 進場日期 */
    entryDate: NaN,
    /** 部位 */
    marketPosition: 0,
    /** 進場價格 */
    entryPrice: NaN,
    /** 停利價格 */
    targetPrice: NaN,
    /** 停損價格 */
    stopLossPrice: NaN,
    /** 停利價格 */
    multipleTargetPrice: [],
    /** 停損價格 */
    multipleStopLossPrice: [],
    /** 現價 */
    close: NaN,
    /** 未平倉損益 */
    openPositionProfit: NaN,
    /** 最小跳動點 */
    movementTick: NaN,
    //null: [NaN, NaN] as number[],
});
