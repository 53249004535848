import { memo } from 'react';
import { sgxClasses } from '../sgx_DataCard';
import DayPriceDiffChart from './PriceDiffChart';
export const PriceDiffCard = memo(function PriceDiffCard(props) {
    const data = props.data;
    const symbol1 = data.map(s => s.symbol1)[0];
    const symbol2 = data.map(s => s.symbol2)[0];
    return (<sgxClasses.card height={280}>
      <sgxClasses.title>近期價差變動</sgxClasses.title>
      <sgxClasses.subTitle>
        {symbol1} {symbol2} 價差
      </sgxClasses.subTitle>
      <DayPriceDiffChart data={data ?? []} symbol1={symbol1} symbol2={symbol2}/>
    </sgxClasses.card>);
});
