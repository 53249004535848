import { proxy } from 'valtio';
import { FuiDatePicker } from '~/pages/heineken_template/components/FuiDatePicker';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
import dayAPI from '~/utils/dayAPI';
export const imoney8888_store = proxy({
    /** # 選股日期 */
    stockPickedDate: new FuiDatePicker(),
    /** 選股多空 */
    realTimeType: 'long',
    /** 持有股票標的方向 */
    holdingPositionType: 'long',
    /** 是否在持有中模式 */
    isHoldingSymbol: false,
    /** 自選股 多/空 */
    watchlistType: 'long',
    //策略選擇
    tabs: new FuiTabs2(['波段持股', '自選股', '甜蜜股']),
    //過濾商品方式
    filterType: 'default',
    //持股查詢 5天(固定) || 自訂範圍(動態)
    defaultSearchType: true,
    holdingStartDate: dayAPI().subtract(10, 'day'),
    holdingEndDate: dayAPI(),
});
