import { css } from '@emotion/react';
import { PositionInfomationClasses } from '~/modules/investment-consultant/positionValueForm/PositonInfotion';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
export const mvp888Stock_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 4px;
        height: 32px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #dddddd;
        background-color: #595e6e;
        border: 1px solid #555555;
        &:hover {
          background-color: #616d7d;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #5266a1;
        border: 1px solid #828aa1;
        transition: 0.5s;
        &:hover {
          background-color: #6377b2;
          border: 1px solid #828aa1;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      .${PositionInfomationClasses.ItemTitle} {
        background-color: #c6d8e4;
      }
      .${PositionInfomationClasses.ItemValue} {
        background-color: #f3faff;
      }
    }
  `;
};
