import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import FuiButton from '../heineken_template/components/FuiButton';
import { store } from '../heineken_template/_private/store';
import { opkevin_strategiesGroup } from './opkevin_initStrategies';
import { styleds } from './styleds';
export const Opkevin_StrategyGroup = memo(function Opkevin_StrategyGroup() {
    const charting = useSnapshot(store.charting);
    return (<div css={css `
        ${fill_horizontal_cross_center};
        height: 36px;
        gap: 8px;
        padding: 0px 8px;
        background-color: #1a1a1a;
      `}>
      <div>指標: </div>
      <styleds.strategyContent>
        {opkevin_strategiesGroup.main.map(config => {
            const active = charting.strategySelected.displayName === config.displayName;
            return (<FuiButton.Display key={config.displayName} active={active} onClick={() => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </FuiButton.Display>);
        })}
      </styleds.strategyContent>
    </div>);
});
