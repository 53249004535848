import styled from '@emotion/styled';
import { memo } from 'react';
import { FcCandleSticks } from 'react-icons/fc';
import { useSnapshot } from 'valtio';
import { fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton';
import { store } from '~/pages/heineken_template/_private/store';
const groupName = 'asiajye_stock_group_1';
export const WatchlistAddButton = memo(function WatchlistAddButton() {
    const charting = useSnapshot(store).charting;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    return (<styleds.container>
      <styleds.symbolContent>
        <FcCandleSticks size={28}/>
        &nbsp;
        <span>{charting.symbol}</span>
        <span>{dictionary[charting.symbol]}</span>
      </styleds.symbolContent>
      <styleds.buttonContent>
        <AddSymbolButtonSmall groupName={groupName} symbolNumber={charting.symbol} listSize={15}/>
      </styleds.buttonContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 60% 40%;
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 0 4px;
  `,
    symbolContent: styled.div `
    ${fill_horizontal_cross_center};
    padding: 0 4px;
    font-weight: 600;
    color: #383838;
  `,
    buttonContent: styled.div `
    ${fill_vertical_all_center};
  `,
};
