import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, } from '../../AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { SymbolName } from '../../SDK/Symbol/SymbolName';
import { VscClose, VscAdd, VscCheck, VscDebugRestart } from 'react-icons/vsc';
import { useSortSignalrDataStore } from '../../symbolQuote/simple/useSortStore';
import { Menu } from '@mantine/core';
const MenuItem = memo(function MenuItem(props_) {
    return (<Menu.Item>
      <div css={css `
          ${fill_horizontal_all_center};
          transition: 0.3s;
          &:hover {
            font-size: 14px;
          }
        `} onClick={event => {
            /** 阻止加入商品至自選股時執行父元素的切換商品事件 */
            event.stopPropagation();
            if (props_.hadSymbol) {
                props_.watchlistActs.removeSymbol(props_.symbol);
            }
            if (!props_.hadSymbol) {
                props_.watchlistActs.addSymbol(props_.symbol, 40);
            }
        }}>
        {props_.children}
        {!props_.hadSymbol ? (<VscAdd />) : props_.watchlistMode === true ? (<VscClose />) : (<VscCheck />)}
      </div>
    </Menu.Item>);
});
/** 商品列本身 */
export const QuoteBodyChangeFill = memo(function QuoteBodyChangeFill(props) {
    //是否是自選股模式
    const watchlistMode = props.watchlistMode ?? false;
    //報價類
    const quote = props.quote ?? '';
    const change = processQuoteToReadable(props.quote)?.closeChange;
    const charting = useSnapshot(store.charting);
    const currentSymbol = charting.symbol;
    //選擇中
    const isActive = quote?.symbol === currentSymbol;
    //自選
    const hadSymbol1 = props.watchListSymbol1?.includes(props.symbolNumber);
    const hadSymbol2 = props.watchListSymbol2?.includes(props.symbolNumber);
    return (<styleds.container isActive={isActive} changeValue={change} onClick={() => store.charting.changeSymbol(props.symbolNumber)}>
      <div css={fill_horizontal_cross_center}>
        <QuoteValueItem.arrow.Display quote={quote}/>
        <div css={css `
            ${fill_vertical_all_center};
            align-items: start;
            padding: 0px 0px 0px 16px;
          `}>
          <SymbolName symbol={props.symbolNumber}/>
          <span>{props.symbolNumber}</span>
        </div>
      </div>

      <div css={css `
          ${fill_vertical_all_center};
          font-size: 14px;
        `}>
        <QuoteValueItem.ohlc.Display quote={quote} ohlcType={'close'}/>
      </div>
      <div css={css `
          ${fill_vertical_all_center};
          align-items: start;
          padding: 0px 0px 0px 26px;
        `}>
        <QuoteValueItem.change.Display quote={quote} changeType='closeChange'/>
        <QuoteValueItem.change.Display quote={quote} changeType='closeChangePercent' unit='%'/>
      </div>
      {props.listState === 'watchlist1' && (<div css={fill_vertical_all_center} onClick={event => {
                event.stopPropagation();
                if (hadSymbol1) {
                    props.watchlistActs1.removeSymbol(props.symbolNumber);
                }
            }}>
          <VscClose />
        </div>)}
      {props.listState === 'watchlist2' && (<div css={fill_vertical_all_center} onClick={event => {
                event.stopPropagation();
                if (hadSymbol2) {
                    props.watchlistActs2.removeSymbol(props.symbolNumber);
                }
            }}>
          <VscClose />
        </div>)}
      {props.listState === 'default' && (<Menu shadow='md' width={160}>
          <Menu.Target>
            <div css={fill_vertical_all_center}>
              <VscAdd />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <MenuItem symbol={quote.symbol} hadSymbol={hadSymbol1} watchlistActs={props.watchlistActs1} watchlistMode={watchlistMode}>
              自選1
            </MenuItem>
            <MenuItem symbol={quote.symbol} hadSymbol={hadSymbol2} watchlistActs={props.watchlistActs2} watchlistMode={watchlistMode}>
              自選2
            </MenuItem>
          </Menu.Dropdown>
        </Menu>)}
    </styleds.container>);
});
export const QuoteBodyChangeFillHeader = memo(function QuoteBodyChangeFillHeader() {
    const sortSnap = useSnapshot(useSortSignalrDataStore); //useSortSignalrDataStore 遷移
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined;
    }, [sortSnap.sortKey, toggleOrderKey]);
    const handleSortSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<styleds.container isActive={false} changeValue={0} css={css `
        height: 100%;
      `}>
      <styleds.item onClick={() => {
            handleSortSelected('symbol');
        }}>
        商品 {getArrow('symbol')}
      </styleds.item>
      <styleds.item onClick={() => {
            handleSortSelected('close');
        }}>
        最新 {getArrow('close')}
      </styleds.item>
      <styleds.item onClick={() => {
            handleSortSelected('changePrecent');
        }}>
        變動 {getArrow('changePrecent')}
      </styleds.item>
      <styleds.item onClick={() => {
            useSortSignalrDataStore.sortKey = 'none';
            useSortSignalrDataStore.orderKey = 'none';
        }}>
        <VscDebugRestart size={14}/>
      </styleds.item>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    display: grid;
    width: 100%;
    grid-template-columns: 34% 32% 26% 8%;
    grid-template-rows: 100%;
    border: 1px solid ${props => (props.isActive ? '#fafafa' : '#56565600')};
    border-bottom: 1px solid ${props => (props.isActive ? '#fafafa' : '#565656')};
    padding: 0px 2px;
    font-size: 12px;
    cursor: pointer;
    transition: 0.3s;
    background-image: linear-gradient(
      to right,
      transparent,
      transparent,
      ${props => props.changeValue > 0 ? '#ff000011' : props.changeValue < 0 ? '#00ff0011' : '#aaaaaa11'}
    );
    border-radius: 2px;
    &:hover {
      background-color: #aaaaaa11;
    }
  `,
    item: styled.div `
    ${fill_vertical_all_center};
  `,
};
