import styled from '@emotion/styled';
import { intersection } from 'lodash';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountOrderPre } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
export const ConditionAsiajye = memo(function ConditionAiStock() {
    /** 即時訊號商品 */
    const lastTradeString = useSnapshot(staticStore).tradedDate.day0;
    //pre order
    const aiOrderLongEntry = useVirtualAccountOrderPre('asiajye_stock_original', 'B', {})?.map(a => a.symbol);
    //pre order
    const aiOrderShortEntry = useVirtualAccountOrderPre('asiajye_stock_original', 'S', {})?.map(a => a.symbol);
    const data = useDailyRankResource({
        date: lastTradeString.format('YYYY-MM-DD'),
        volume_gte: 1000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
        sort_by: 'volume',
        limit: 2000,
    }, 0);
    const allStock = data.data?.map(a => a.symbol);
    const symbolLong = intersection(allStock, aiOrderLongEntry).slice(0, 5); //5
    const symbolShort = intersection(allStock, aiOrderShortEntry).slice(0, 3); //3
    return (<styleds.container>
      <styleds.listTitle fill='#c85d4a'>多方標的</styleds.listTitle>
      <styleds.listContent fill='#c85d4a33'>
        <SimpleQuoteListInteractWithChart data={symbolLong} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
      <styleds.listTitle fill='#009900'>空方標的</styleds.listTitle>
      <styleds.listContent fill='#00990033'>
        <SimpleQuoteListInteractWithChart data={symbolShort} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 30px calc(50% - 30px) 30px calc(50% - 30px);
  `,
    listTitle: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    color: #ffffff;
    border-radius: 4px;
  `,
    listContent: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    border-radius: 4px;
  `,
};
