import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { sgxIndicatorsValueStorage } from './sgx_store';
import useMedia from '~/hooks/useMedia';
import { store } from '../heineken_template/_private/store';
import { SgxLogOutView } from './component/SgxLogOutView';
export const Sgx_Topbar = memo(function Sgx_Topbar() {
    const { isPc } = useMedia();
    return (<div>
      {isPc && (<styleds.container>
          <img css={css `
              width: 132px;
              height: 48px;
            `} src='sgx/sgx.svg'/>
          <sgxIndicatorsValueStorage.userAvatar.AvatarModal cssset={css `
              width: 336px;
              height: 360px;
            `}>
            <SgxLogOutView />
          </sgxIndicatorsValueStorage.userAvatar.AvatarModal>
        </styleds.container>)}
      {!isPc && (<styleds.container>
          <store.drawerLeft.DrawerToggle css={styleds.drawerCss}/>
          <img css={css `
              width: 132px;
              height: 48px;
            `} src='sgx/sgx.svg'/>
          <sgxIndicatorsValueStorage.userAvatar.AvatarModal cssset={css `
              width: 336px;
              height: 360px;
            `}>
            <SgxLogOutView />
          </sgxIndicatorsValueStorage.userAvatar.AvatarModal>
          <store.drawerRight.DrawerToggle css={styleds.drawerCss}/>
        </styleds.container>)}
    </div>);
});
const styleds = {
    container: styled.div `
    position: fixed;
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    height: 66px;
    z-index: 1000;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid #222222;
    padding: 0 16px;
  `,
    drawerCss: css `
    height: 40px;
    width: 40px;
    color: #112267;
  `,
};
