import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const cz1 = createIndicator({
    id: 'cz1',
    displayName: '波段指標',
    filterOnTheme: 'dark',
    enabledOn(symbol, data, channel) {
        return (symbol.includes('-') || data?.type === channel.os_futures || data?.type === channel.tw_futures);
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const f_0 = () => {
                const e = this.PineJS.Std.close(this._context);
                const t = this.PineJS.Std.hlc3(this._context);
                const i = this._context.new_var(this.PineJS.Std.high(this._context));
                const o = this.PineJS.Std.highest(i, 30, this._context);
                const r = this.PineJS.Std.lowest(i, 30, this._context);
                const s = (25 / (o - r)) * r;
                const a = this._context.new_var(e);
                const l = this._context.new_var(this.PineJS.Std.ema(a, 34, this._context));
                const c = ((l.get(1) - l.get(0)) / t) * s;
                const u = this.PineJS.Std.sqrt(1 + c * c);
                const h = this.PineJS.Std.round((180 * this.PineJS.Std.acos(1 / u)) / 3.141592653589793);
                const d = this.PineJS.Std.iff(this.PineJS.Std.gt(c, 0), -h, h);
                const p = this.PineJS.Std.and(this.PineJS.Std.gt(d, -2.14), this.PineJS.Std.le(d, -0.71))
                    ? 1
                    : 1;
                const _ = this.PineJS.Std.and(this.PineJS.Std.gt(d, -3.57), this.PineJS.Std.le(d, -2.14))
                    ? 1
                    : p;
                const f = this.PineJS.Std.and(this.PineJS.Std.gt(d, -5), this.PineJS.Std.le(d, -3.57))
                    ? 1
                    : _;
                const m = this.PineJS.Std.le(d, -5) ? 2 : f;
                const y = this.PineJS.Std.and(this.PineJS.Std.lt(d, 2.14), this.PineJS.Std.ge(d, 0.71))
                    ? 1
                    : m;
                const g = this.PineJS.Std.and(this.PineJS.Std.lt(d, 3.57), this.PineJS.Std.ge(d, 2.14))
                    ? 1
                    : y;
                const v = this.PineJS.Std.and(this.PineJS.Std.lt(d, 5), this.PineJS.Std.ge(d, 3.57)) ? 1 : g;
                const S = this.PineJS.Std.ge(d, 5) ? 0 : v;
                return [1, S];
            };
            return f_0();
        },
    },
    metainfo: {
        _metainfoVersion: 41,
        is_hidden_study: !1,
        is_price_study: !1,
        scriptIdPart: '',
        isCustomIndicator: true,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !0,
                    color: '#000080',
                },
            },
            precision: 0,
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#dd0000',
                            width: 1,
                            style: 0,
                        },
                        1: {
                            color: '#3e3e3e',
                            width: 1,
                            style: 0,
                        },
                        2: {
                            color: '#0ca74a',
                            width: 1,
                            style: 0,
                        },
                    },
                },
            },
            inputs: {},
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                palette: 'palette_0',
                target: 'plot_0',
                type: 'colorer',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
                isHidden: !1,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                    2: {
                        name: 'Color 2',
                    },
                },
                valToIndex: {
                    0: 0,
                    1: 1,
                    2: 2,
                },
            },
        },
        inputs: [],
    },
});
export const cz1_light = cz1.duplicate({
    id: 'cz1--light',
    filterOnTheme: 'light',
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#000080',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#dd0000',
                        },
                        1: {
                            color: '#aaaaaa',
                        },
                        2: {
                            color: '#0ca74a',
                        },
                    },
                },
            },
        },
    },
});
