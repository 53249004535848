import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { sgxClasses } from '../sgx_DataCard';
import styled from '@emotion/styled';
import { useSnapshot } from 'valtio';
import { sgxIndicatorsValueStorage } from '../sgx_store';
export const TrendCard = memo(function TrendCard() {
    const state = useSnapshot(sgxIndicatorsValueStorage);
    const slope5 = state.slope5;
    const slope10 = state.slope10;
    const slope20 = state.slope20;
    const slope60 = state.slope60;
    const slope120 = state.slope120;
    const ma5 = state.ma5;
    const ma10 = state.ma10;
    const ma20 = state.ma20;
    const ma60 = state.ma60;
    const ma120 = state.ma120;
    return (<sgxClasses.card height={280}>
      <sgxClasses.title>商品趨勢</sgxClasses.title>
      <sgxClasses.subTitle>均線表現</sgxClasses.subTitle>
      <IndicatorsSma slope={slope5} maValue={ma5} length={5}/>
      <IndicatorsSma slope={slope10} length={10} maValue={ma10}/>
      <IndicatorsSma slope={slope20} length={20} maValue={ma20}/>
      <IndicatorsSma slope={slope60} length={60} maValue={ma60}/>
      <IndicatorsSma slope={slope120} length={120} maValue={ma120}/>
    </sgxClasses.card>);
});
const IndicatorsSma = memo(function IndicatorsSma(props_) {
    const trendState = props_.slope > 0 ? '多方' : '空方';
    const trendFill = props_.slope > 0 ? '#aa3344' : '#33aa44';
    return (<div css={css `
          ${fill_horizontal_all_center};
          display: grid;
          grid-template-columns: 48% 32% 20%;
        `}>
        <div>簡單移動平均線({props_.length})</div>
        <div>{props_.maValue?.toFixed(2)}</div>
        <div css={css `
            ${fill_horizontal_all_center};
            height: 24px;
            border-radius: 4px;
            color: #ffffff;
            background-color: ${trendFill};
          `}>
          {trendState}
        </div>
      </div>);
});
const styleds = {
    smaContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 30px;
    font-weight: 600;
    color: #112267;
    font-size: 16px;
  `,
};
