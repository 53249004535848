import { css } from '@emotion/react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
export const asiajye_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      ${FuiButton.classes.button.Root} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 32px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0px 1px 2px 0.4px #dadada;
      }

      ${FuiButton.classes.button.default.Default} {
        background-color: #fafafa;
        border: 1px solid #cccccc;
        color: #252525;
        &:hover {
          border: 1px solid #aaaaaa;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        background-color: #3061d2;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #4071d2;
        }
      }

      ${FuiButton.classes.button.long.Default} {
        background-color: #c19795;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #cb8783;
        }
      }
      ${FuiButton.classes.button.long.Active} {
        background-color: #ce423a;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #db5149;
        }
      }
      ${FuiButton.classes.button.short.Default} {
        background-color: #95a790;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #99b392;
        }
      }
      ${FuiButton.classes.button.short.Active} {
        background-color: #48a847;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #489847;
        }
      }
    }
  `;
};
