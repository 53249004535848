import { css } from '@emotion/react';
import { memo, useEffect, useState } from 'react';
import { MdAddchart } from 'react-icons/md';
import { useSnapshot } from 'valtio';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import { ManualSignal } from '~/modules/investment-consultant/signalNotify/ManualSignal';
import dayAPI from '~/utils/dayAPI';
import FuiButton from '../heineken_template/components/FuiButton';
import { store } from '../heineken_template/_private/store';
import { jerry597_strategyGroup } from './jerry597_initStrategies';
import styleds from './styleds';
import { useThemeStore } from '~/components/theme/useThemeStore';
export const Jerry597_SidePane2 = memo(function Jerry597_SidePane2() {
    const charting = useSnapshot(store.charting);
    const theme = useThemeStore(t => t.theme);
    return (<styleds.SideBar2 className={theme}>
      <styleds.ComponeyLogo src='jerry597/logo.png'/>
      <styleds.StrategyArea className={theme}>
        <styleds.AreaTitle className={theme}>
          <MdAddchart size={'30px'} fill={'#ff5500'}/>
          金旺指標
        </styleds.AreaTitle>
        <styleds.StrategyGroup>
          {jerry597_strategyGroup.future.map(strategyConfig => {
            const isActive = charting.strategySelected.displayName === strategyConfig.displayName;
            return (<FuiButton.Display key={strategyConfig.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(strategyConfig);
                    store.charting.changeSymbol(strategyConfig.symbol ?? '');
                    store.charting.updateFromStrategyState();
                }}>
                {strategyConfig.displayName}
              </FuiButton.Display>);
        })}
        </styleds.StrategyGroup>
      </styleds.StrategyArea>
      <styleds.TradingTipsTitle className={theme}>金旺操作提示</styleds.TradingTipsTitle>
      <styleds.TradingTipsContent>
        <ManualSignal agent={'jerry597'} limit={10} modal={true}/>
      </styleds.TradingTipsContent>
      <TimeCard />
    </styleds.SideBar2>);
});
export const TimeCard = memo(function TimeCard(props) {
    const dayTime = new Date();
    const [time, setTime] = useState(dayTime);
    const theme = useThemeStore(t => t.theme);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const result = dayAPI(time).format('HH:mm:ss');
    return (<div className={theme} css={css `
        ${fill_vertical_all_center};
        height: 64px;
        font-size: 28px;
        border-radius: 4px;
        &.dark {
          background-color: #2f3032;
          color: #fafafa;
        }
        &.light {
          background-color: #d6d6d6;
          color: #252525;
        }
      `}>
      {result}
    </div>);
});
