import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const win9882_trebdtrade_signal_60m = createIndicator({
    id: 'win9882-trebdtrade-signal-60m',
    displayName: '長線波段',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const stopLoss = 20;
            const target = 500;
            const position = context.new_var();
            const entryPrice = context.new_var();
            const entriesToday = context.new_var();
            const close = this.ohlc.closeArray;
            const open = this.ohlc.openArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const volume = context.new_var(this.PineJS.Std.volume(context));
            const datetime = dayAPI(this.PineJS.Std.time(context));
            const state_ = context.new_var();
            const sma20 = this.PineJS.Std.sma(close, 20, this._context);
            const sma5 = this.PineJS.Std.sma(close, 5, this._context);
            const sma60 = this.PineJS.Std.sma(close, 60, this._context);
            const sma76_array = this._context.new_var(sma20);
            const dc = this.ladderChannl(60);
            const mid = dc[4];
            position.get(1);
            entriesToday.get(100);
            entryPrice.get(100);
            close.get(100);
            open.get(100);
            high.get(100);
            low.get(100);
            volume.get(100);
            sma76_array.get(2);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            let buy_exit_icon = NaN;
            let short_exit_icon = NaN;
            if (isNaN(state_.get(0))) {
                state_.set(-1);
            }
            const longEntry = close > mid && mid > sma20 && sma5 - sma20 > 60;
            const shortEntry = close < mid && mid < sma20 && sma20 - sma5 > 60;
            // ---------------------------------------- Entry ----------------------------------------
            if (longEntry)
                position.set(1);
            if (shortEntry)
                position.set(-1);
            if (position.get(0) === 1 && position.get(1) !== 1) {
                entriesToday.set(entriesToday.get(0) + 1);
                entryPrice.set(close.get(0));
                state_.set(1);
                buy_icon = 1;
            }
            if (position.get(0) === -1 && position.get(1) !== -1) {
                entryPrice.set(close.get(0));
                entriesToday.set(entriesToday.get(0) + 1);
                state_.set(-1);
                short_icon = 1;
            }
            const strength = this._input(0);
            const sar_stdv = this._input(1);
            const sar_af = this._input(2);
            const sar_long = this.sarLongStopLoss('linear', 8, position, 10, 1.4, 0.2, 4);
            const sar_short = this.sarShortStopLoss('linear', 9, position, 10, 1.4, 0.6, 4);
            const longEnd = position.get(1) === 1 && position.get(0) === 1 && close < sar_long.get(0);
            const shortEnd = position.get(1) === -1 && position.get(0) === -1 && close > sar_short.get(0);
            // ---------------------------------------- Exit ----------------------------------------
            if (position.get(0) === 1 && position.get(1) === 1) {
                if (high.get(0) > entryPrice.get(1) + target) {
                    buy_target_icon = 1;
                    position.set(0);
                }
                if (longEnd) {
                    buy_exit_icon = 1;
                    position.set(0);
                }
                if (datetime.hour() === 4 && datetime.minute() === 50) {
                    buy_exit_icon = 1;
                    position.set(0);
                }
            }
            if (position.get(0) === -1 && position.get(1) === -1) {
                if (low.get(0) < entryPrice.get(1) - target) {
                    short_target_icon = 1;
                    position.set(0);
                }
                if (shortEnd) {
                    short_exit_icon = 1;
                    position.set(0);
                }
                if (datetime.hour() === 4 && datetime.minute() === 50) {
                    short_exit_icon = 1;
                    position.set(0);
                }
            }
            const colorer = () => {
                return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 3 : 4;
            };
            //sar線--------------------------------------------------
            const sarLongLine = this._context.new_var();
            const sarShortLine = this._context.new_var();
            if (position.get(0) === 1) {
                sarLongLine.set(sar_long.get(0));
                sarShortLine.set(NaN);
            }
            if (position.get(0) === -1) {
                sarLongLine.set(NaN);
                sarShortLine.set(sar_short.get(0));
            }
            if (position.get(0) === 0) {
                sarLongLine.set(NaN);
                sarShortLine.set(NaN);
            }
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                buy_exit_icon,
                short_exit_icon,
                sarLongLine.get(0),
                sarShortLine.get(0),
                colorer(),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag',
                type: 'chars',
            },
            {
                id: 'entryShortFlag',
                type: 'chars',
            },
            {
                id: 'targetLongFlag',
                type: 'chars',
            },
            {
                id: 'targetShortFlag',
                type: 'chars',
            },
            {
                id: 'exitLongFlag',
                type: 'chars',
            },
            {
                id: 'exitShortFlag',
                type: 'chars',
            },
            {
                id: 'sarLong',
                type: 'line',
            },
            {
                id: 'sarShort',
                type: 'line',
            },
            {
                id: '多空輔助',
                type: 'bar_colorer',
                palette: 'barPalette',
            },
        ],
        defaults: {
            styles: {
                entryLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                entryShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
                targetLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                targetShortFlag: {
                    color: '#5cb642',
                    textColor: '#5cb642',
                    transparency: 20,
                    visible: true,
                },
                exitLongFlag: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                exitShortFlag: { color: '#5cb642', textColor: '#5cb642', transparency: 20, visible: true },
                sarLong: { color: '#ff0000', textColor: '#ff0000', transparency: 70, visible: true },
                sarShort: { color: '#00aa00', textColor: '#00aa00', transparency: 70, visible: true },
            },
        },
        styles: {
            entryLongFlag: {
                title: '多單進場',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'Buy',
            },
            entryShortFlag: {
                title: '空單進場',
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'Short',
            },
            targetLongFlag: {
                title: '多單停利',
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'tiny',
                text: 'target',
            },
            targetShortFlag: {
                title: '空單停利',
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'tiny',
                text: 'target',
            },
            exitLongFlag: {
                title: '多單出場',
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'tiny',
                text: 'Exit',
            },
            exitShortFlag: {
                title: '空單出場',
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'tiny',
                text: 'Exit',
            },
        },
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#e39e9c',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#91b48a',
                        width: 1,
                        style: 0,
                    },
                    3: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    4: {
                        color: '#aaaaaa',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
