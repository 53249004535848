import { store } from '~/pages/heineken_template/_private/store';
import { stock_power } from '~/trades/indicators/asiajye_stock/stock_power';
import { stock_trade } from '~/trades/indicators/asiajye_stock/stock_trade';
export const asiajyeStock_strategiesGroup = {
    main: [
        {
            displayName: '-',
            indicators: [stock_trade, stock_power],
            symbol: 'TSEA',
            interval: '1D',
            panesRatio: 72,
        },
    ],
};
export const asiajyeStock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[stock_trade, stock_power],
    ];
    store.charting.initStrategy({
        configs: [...asiajyeStock_strategiesGroup.main],
    });
};
