import { wu5868_bband } from '~/trades/indicators/wu5868/wu5868_bband';
import { wu5868_volume } from '~/trades/indicators/wu5868/wu5868_volume';
import { store } from '../heineken_template/_private/store';
import { wu5868_signal } from '~/trades/indicators/wu5868/wu5868_signal';
import { wu5868_signal_chip } from '~/trades/indicators/wu5868/wu5868_signal_chip';
import { wu5868_cost } from '~/trades/indicators/wu5868/wu5868_cost';
import { wu5868_ma } from '~/trades/indicators/wu5868/wu5868_ma';
export const wu5868_strategiesDayTradeGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '標準指標',
            indicators: [wu5868_volume, wu5868_ma],
            interval: '1D',
            panesRatio: 66,
        },
        {
            displayName: '日線指標',
            indicators: [wu5868_volume, wu5868_signal_chip, wu5868_cost],
            interval: '1D',
            panesRatio: 66,
        },
        // {
        //   displayName: '30分指標',
        //   indicators: [wu5868_volume, wu5868_signal2],
        //   interval: '30',
        //   panesRatio: 66,
        // },
        {
            displayName: '05分指標',
            indicators: [wu5868_volume, wu5868_bband, wu5868_signal],
            interval: '5',
            panesRatio: 66,
        },
    ],
};
export const wu5868_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded];
    store.charting.initStrategy({
        configs: [...wu5868_strategiesDayTradeGroup.main],
    });
};
