import { css } from '@emotion/react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { intersection } from 'lodash';
import React, { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { generateScrollbar } from '~/css/scrollbarCss';
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { useExtremeVolumeResource, usePercentRangeResource, } from '~/modules/screener/containers/useStockScreenerResource';
import { useDatePick } from '~/modules/screener/useDatePick';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton';
import { store } from '~/pages/heineken_template/_private/store';
import { ok1788_customized0 } from '~/trades/indicators/ok1788/ok1788_customized0';
import { ok1788_customized1 } from '~/trades/indicators/ok1788/ok1788_customized1';
import { ok1788_customized2 } from '~/trades/indicators/ok1788/ok1788_customized2';
import { ok1788_customized3 } from '~/trades/indicators/ok1788/ok1788_customized3';
import Styleds from './styleds';
import { MarketStockHiLoChart } from './component/MarketStockHiLoChart';
import { ok1788Store1 } from './ok1788Stock_SidePane1';
const indicators = [ok1788_customized0, ok1788_customized1, ok1788_customized2, ok1788_customized3];
export const dailyRankStateEnum = {
    new_high: '創高',
    new_low: '創低',
    near_high: '區間高',
    near_low: '區間低',
};
export const daysEnum = {
    3: '3天',
    5: '5天',
    10: '10天',
    20: '20天',
    60: '60天',
    120: '120天',
};
export const thresholdEnum = {
    1.25: '量增',
    2.5: '爆量',
};
const ok1788Store2 = proxy({
    /** 使用何種方式篩選股票 `價格`|`成交量` */
    screenerType: 'price',
    /** 篩選的天數 `number` */
    priceDays: 3,
    /** 篩選的天數 `number` */
    volumeDays: 3,
    /** 價格篩選中的類型 `創新高` `創新低` */
    priceState: 'new_high',
    /** 爆量篩選中的倍數 `number` */
    threshold: 1.25,
});
const DefaultSelect = memo(function DefaultSelect(props) {
    const state = useSnapshot(props.store);
    return (<div>
      <InputLabel variant='standard' htmlFor='uncontrolled-native' css={css `
          font-size: 14px;
        `}>
        {props.children}
      </InputLabel>

      <Select css={Styleds.SelectButtonCss} value={state[props.keys]} onChange={e => {
            props.store[props.keys] = e.target.value;
        }} disabled={false}>
        {Object.entries(props.enum).map((m, index) => {
            return (<MenuItem key={index} value={m[0]}>
              <div>{m[1]}</div>
            </MenuItem>);
        })}
      </Select>
    </div>);
});
export const OK1788Stock_SidePane2 = memo(function OK1788_SidePane1(props) {
    const charting = useSnapshot(store.charting);
    const state2 = useSnapshot(ok1788Store2);
    const state1 = useSnapshot(ok1788Store1);
    const compareSymbol = charting.symbol === 'TSEA' ? '2330' : charting.symbol;
    //先頂著 確認好再抽出來
    const date = useDatePick();
    const dailyRankData = usePercentRangeResource({
        days: state2.priceDays,
        status: state2.priceState,
        volume_gte: 500,
        volume_lte: 0,
    });
    const extremeVolumeData = useExtremeVolumeResource({
        date: date,
        days: state2.volumeDays,
        volume_gte: 5000,
        volume_lte: 0,
        threshold: state2.threshold,
    });
    const filterValue = state2.threshold === 2.5 ? 99 : 2;
    const dailyRankSymbol = dailyRankData.data?.map(datum => datum.symbol);
    const extremeVolumeSymbol = extremeVolumeData.data
        ?.filter(a => a.volume / a.volume_ma < state2.threshold * filterValue)
        ?.map(datum => datum.symbol);
    const allSymbol = intersection(dailyRankSymbol, extremeVolumeSymbol);
    /** 依模式切換要顯示的商品 */
    const resultData = () => {
        if (state2.screenerType === 'price')
            return dailyRankSymbol;
        else if (state2.screenerType === 'volume')
            return extremeVolumeSymbol;
        else if (state2.screenerType === 'all')
            return allSymbol;
    };
    const symbolQuantity = resultData()?.length ?? NaN;
    const longLine = state1.longLine1 + state1.longLine2;
    const shortLine = state1.shortLine1 + state1.shortLine2;
    const allLine = longLine + shortLine;
    const longPercent = (longLine / allLine) * 100;
    const shortPercent = (shortLine / allLine) * 100;
    return (<div css={css `
        ${flex.v.crossCenter}
        width: 100%;
        height: 100%;
        padding: 2px;
        gap: 4px;
        //${generateScrollbar('#454545', '#bbbbbb')}
      `}>
      <Styleds.SidebarTitle>多空三道比例比較圖</Styleds.SidebarTitle>
      <div css={css `
          ${flex.h.allCenter};
          width: 100%;
          height: 30px;
          gap: 4px;
          padding: 2px;
          background-color: #eeeeee;
          border-radius: 8px;
        `}>
        <div css={css `
            ${flex.v.allCenter};
            width: ${longPercent}%;
            min-width: 10%;
            max-width: 90%;
            height: 100%;
            background-color: #cc2222;
            color: #ffffff;
            border-radius: 5px;
          `}>
          {longPercent.toFixed(0)}%
        </div>
        <div css={css `
            ${flex.v.allCenter};
            width: ${shortPercent}%;
            min-width: 10%;
            max-width: 90%;
            height: 100%;
            background-color: #00aa00;
            color: #ffffff;
            border-radius: 5px;
          `}>
          {shortPercent.toFixed(0)}%
        </div>
      </div>
      <Styleds.SidebarTitle>創高與創低家數比較圖</Styleds.SidebarTitle>
      <MarketStockHiLoChart />
      <div css={css `
          ${flex.h.allCenter};
          ${pureGrid};
          width: 100%;
          height: 30px;
          grid-template-columns: 40% 60%;
          padding: 5px;
        `}>
        <div css={css `
            ${flex.h.allCenter}
            color:#222222;
          `}>
          自選股狀態
        </div>
        <AddSymbolButtonSmall groupName={'ok1788_stock_group_1'} symbolNumber={charting.symbol} listSize={50}/>
      </div>
      <Styleds.SidebarTitle>
        {state2.screenerType === 'user' ? '自選股模式' : '篩選模式 數量:' + symbolQuantity}
      </Styleds.SidebarTitle>
      <Styleds.Dashboard>
        <div css={css `
            ${flex.h.allCenter}
            width:100%;
            gap: 2px;
          `}>
          <Styleds.ScreenerButton onClick={() => (ok1788Store2.screenerType = 'price')} active={ok1788Store2.screenerType === 'price'}>
            價格
          </Styleds.ScreenerButton>
          <Styleds.ScreenerButton onClick={() => (ok1788Store2.screenerType = 'volume')} active={ok1788Store2.screenerType === 'volume'}>
            交易量
          </Styleds.ScreenerButton>
          <Styleds.ScreenerButton onClick={() => (ok1788Store2.screenerType = 'all')} active={ok1788Store2.screenerType === 'all'}>
            篩選
          </Styleds.ScreenerButton>
          <Styleds.ScreenerButton onClick={() => (ok1788Store2.screenerType = 'user')} active={ok1788Store2.screenerType === 'user'}>
            自選股
          </Styleds.ScreenerButton>
        </div>
        {state2.screenerType !== 'user' && (<Styleds.DashboardSelectGroup>
            {state2.screenerType !== 'volume' && (<>
                <Styleds.DashboardSelectItem>
                  <DefaultSelect keys={'priceDays'} store={ok1788Store2} enum={daysEnum}>
                    價格天數
                  </DefaultSelect>
                </Styleds.DashboardSelectItem>
                <Styleds.DashboardSelectItem>
                  <DefaultSelect keys={'priceState'} store={ok1788Store2} enum={dailyRankStateEnum}>
                    類別
                  </DefaultSelect>
                </Styleds.DashboardSelectItem>
              </>)}
            {state2.screenerType !== 'price' && (<>
                <Styleds.DashboardSelectItem>
                  <DefaultSelect keys={'volumeDays'} store={ok1788Store2} enum={daysEnum}>
                    爆量天數
                  </DefaultSelect>
                </Styleds.DashboardSelectItem>

                <Styleds.DashboardSelectItem>
                  <DefaultSelect keys={'threshold'} store={ok1788Store2} enum={thresholdEnum}>
                    倍數
                  </DefaultSelect>
                </Styleds.DashboardSelectItem>
              </>)}
          </Styleds.DashboardSelectGroup>)}
      </Styleds.Dashboard>

      {state2.screenerType !== 'user' && (<div css={css `
            ${flex.v.crossCenter}
            width: 100%;
            height: calc(100% - 560px);
          `}>
          <SimpleQuoteListInteractWithChart data={resultData() ?? []} indicators={indicators} chart={store.charting}/>
        </div>)}

      {state2.screenerType === 'user' && (<div css={css `
            width: 100%;
            height: calc(100% - 560px);
          `}>
          <WatchListWithChart groupName={'ok1788_stock_group_1'} useChart={store.charting} indicators={indicators}/>
        </div>)}
    </div>);
});
