import { sgx_valueProvider } from '~/trades/indicators/sgx/sgx_valueProvider';
import { store } from '../heineken_template/_private/store';
const sgxIndicators1 = [sgx_valueProvider];
export const sgx_strategyGroup = {
    main: [
        {
            displayName: 'sgx-1',
            indicators: sgxIndicators1,
            interval: '60',
            panesRatio: 80,
            //calcFrom: dayAPI().subtract(14, 'day'),
        },
    ],
};
export const sgx_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...sgxIndicators1];
    store.charting.initStrategy({
        configs: [...sgx_strategyGroup.main],
    });
};
