import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { Kbar } from '../Kbar';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { SortArrow } from '../content/SortArrow';
import { store } from '~/pages/heineken_template/_private/store';
import { RiAddLine } from 'react-icons/ri';
import { VscClose } from 'react-icons/vsc';
import { edwin_store } from '../../edwin_store';
import { stockDecimal } from './KbarSymbolQuote';
import { useBig3StockDataStore } from '../content/WatchListContent';
const KbarWatchListBody = memo(function KbarWatchListBody(props) {
    const { state: watchListState1, acts: watchListActs1 } = useSymbolWatchList(props.groupName);
    const quote = props.quote;
    const trust = props.data;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const value = useSnapshot(signalrStore2.values.quote)[quote.symbol];
    const quoteChanges = processQuoteToReadable(value);
    const quoteChangesPercent = quoteChanges.closeChangePercent;
    const changeArrow = quoteChangesPercent > 0 ? '+' : '';
    const close = value?.close ?? 0;
    const limitPrice = close === value?.limitDownPrice || close === value?.limitUpPrice;
    const hadSymbol = watchListState1.symbolListArray.includes(quote.symbol);
    const trustTr = trust.trust_diff_value;
    const trustValue = trustTr / 1e8;
    const unit = '億';
    const float = 2;
    const foreignerTr = trust.foreigner_diff_value;
    const foreignerValue = foreignerTr / 1e8;
    const charting = useSnapshot(store).charting;
    const selected = charting.symbol === quote.symbol;
    return (<styleds.body.container onClick={() => store.charting.changeSymbol(quote.symbol)} selected={selected}>
      <styleds.body.addButton onClick={event => {
            event.stopPropagation();
            !hadSymbol
                ? watchListActs1.addSymbol(quote.symbol, 30)
                : watchListActs1.removeSymbol(quote.symbol);
        }}>
        {!hadSymbol && <RiAddLine color='#cacaca'/>}
        {hadSymbol && <VscClose color='#e95050'/>}
      </styleds.body.addButton>
      <styleds.body.content>
        <Kbar quote={value}/>
      </styleds.body.content>
      <styleds.body.symbolNameContent>
        <span>{dictionary[quote.symbol]}</span>
        <span>{quote.symbol}</span>
      </styleds.body.symbolNameContent>
      <styleds.body.content>
        <styleds.body.price quote={quoteChangesPercent} limit={limitPrice}>
          {close.toFixed(stockDecimal(close))}
        </styleds.body.price>
      </styleds.body.content>
      <styleds.body.content>
        <styleds.body.quote quote={quoteChangesPercent}>
          {changeArrow}
          {quoteChangesPercent.toFixed(2)}
        </styleds.body.quote>
      </styleds.body.content>
      <styleds.body.content>
        {foreignerValue.toFixed(float)}
        {unit}
      </styleds.body.content>
      <styleds.body.content>
        {trustValue.toFixed(float)}
        {unit}
      </styleds.body.content>
    </styleds.body.container>);
});
const KbarWatchListHeader = memo(function KbarWatchListHeader() {
    const state = useSnapshot(edwin_store);
    const sortState = useSnapshot(useSortSignalrDataStore);
    const sortBig3StockState = useSnapshot(useBig3StockDataStore);
    const toggleOrderKey = sortState.orderKey === 'desc' ? 'asc' : 'desc';
    const handleColumnSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    const toggleTrustOrderKey = sortBig3StockState.orderKey === 'desc' ? 'asc' : 'desc';
    const handleTurstSelected = useCallback((sortKey) => {
        ;
        (useBig3StockDataStore.sortKey = sortKey),
            (useBig3StockDataStore.orderKey = toggleTrustOrderKey);
    }, [toggleTrustOrderKey]);
    return (<styleds.header.container>
      <styleds.header.item></styleds.header.item>
      <styleds.header.item onClick={() => {
            handleColumnSelected('changePrecent');
            handleTurstSelected('none');
        }}>
        K棒
      </styleds.header.item>
      <styleds.header.item onClick={() => {
            handleColumnSelected('symbol');
            handleTurstSelected('none');
        }}>
        代號
        <SortArrow sortKey='symbol' sotre={useSortSignalrDataStore}/>
      </styleds.header.item>
      <styleds.header.item onClick={() => {
            handleColumnSelected('close');
            handleTurstSelected('none');
        }}>
        成交
        <SortArrow sortKey='close' sotre={useSortSignalrDataStore}/>
      </styleds.header.item>
      <styleds.header.item onClick={() => {
            handleColumnSelected('changePrecent');
            handleTurstSelected('none');
        }}>
        幅度%
        <SortArrow sortKey='changePrecent' sotre={useSortSignalrDataStore}/>
      </styleds.header.item>
      <styleds.header.item onClick={() => {
            handleColumnSelected('none');
            handleTurstSelected('foreigner');
        }}>
        外資
        <SortArrow sortKey='foreigner' sotre={useBig3StockDataStore}/>
      </styleds.header.item>
      <styleds.header.item onClick={() => {
            handleColumnSelected('none');
            handleTurstSelected('trust');
        }}>
        投信
        <SortArrow sortKey='trust' sotre={useBig3StockDataStore}/>
      </styleds.header.item>
    </styleds.header.container>);
});
export default { body: KbarWatchListBody, header: KbarWatchListHeader };
const styleds = {
    header: {
        container: styled.div `
      display: grid;
      grid-template-columns: 4% 10% 18% 16% 20% 16% 16%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      background-color: #1a1e27;
      user-select: none;
    `,
        item: styled.div `
      ${fill_horizontal_all_center};
      font-size: 12px;
    `,
    },
    body: {
        container: styled.div `
      display: grid;
      grid-template-columns: 4% 10% 18% 14% 18% 18% 18%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      padding: 2px 4px;
      font-size: 13px;
      cursor: pointer;
      border: 1px solid ${props => (props.selected ? '#c7c7c7' : 'transparent')};
      background-color: #141821;
      &:hover {
        background-color: #1b1e26;
        transition: 0.3s;
      }
      flex-shrink: 0;
    `,
        addButton: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
      height: 50%;
      margin-top: 9px;
      border-radius: 4px;
      &:hover {
        transform: scale(1.2);
      }
      &:active {
        transform: scale(1.4);
      }
    `,
        content: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
    `,
        quote: styled.div `
      ${fill_horizontal_all_center};
      width: 42px;
      border-radius: 6px;
      color: ${props => (props.quote > 0 ? '#d51f1f' : props.quote < 0 ? '#59c03d' : '#cccc22')};
      font-size: 13px;
      font-weight: 500;
      justify-content: end;
    `,
        symbolNameContent: styled.div `
      ${fill_vertical_all_center};
      align-items: start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      &:hover {
        overflow: visible;
      }
      & > * {
        line-height: 16px;
      }
    `,
        price: styled.div `
      background-color: ${props => props.limit === true && props.quote > 0
            ? '#ff0000'
            : props.limit === true && props.quote < 0
                ? '#00aa00'
                : 'transparent'};

      padding: 2px;
      border-radius: 2px;
      color: ${props => (props.limit === true ? '#ffffff' : '')};
    `,
    },
};
