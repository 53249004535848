import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const weng888_gapTrade = createIndicator({
    displayName: '隔日沖',
    id: 'weng888-gapTrade',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.ohlc.closeArray;
            const high = this.ohlc.highArray;
            const open = this.ohlc.openArray;
            const highset = this.PineJS.Std.highest(high, 20, this._context);
            const conditionLong = high.get(0) >= highset && close.get(0) / open.get(0) > 1.05;
            const position = this._context.new_var();
            position.get(2);
            if (conditionLong) {
                position.set(1);
            }
            let buy_icon = NaN;
            let short_icon = NaN;
            if (position.get(0) === 1 && position.get(1) !== 1) {
                buy_icon = 1;
            }
            if (position.get(0) === 1 && position.get(1) === 1) {
                position.set(0);
                short_icon = 1;
            }
            return [buy_icon, short_icon];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#ff2e26',
                    textColor: '#ff2e26',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {},
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
