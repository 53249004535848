import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider } from '@mantine/core';
import { memo, useEffect } from 'react';
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useDoubleMA, useFinanciaReport, useReviveMACD, useTdccStock, useVolumeMa, } from '~/modules/screener/containers/useStockScreenerResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { revive_store } from '../revive_store';
const TypeBotton = memo(function TypeBotton(props) {
    const state = useSnapshot(revive_store);
    const displayType = props.type === 'long' ? '篩選A' : '篩選B';
    const selected = props.type === state.stockSelectionType;
    return (<styleds.button selected={selected} className={props.type} onClick={() => (revive_store.stockSelectionType = props.type)}>
      {displayType}
    </styleds.button>);
});
const SelectCard = memo(function SelectCard(props_) {
    const state = useSnapshot(revive_store);
    const handleConditionToggle = (condition) => {
        if (state.stockSelectionCondition.includes(condition)) {
            revive_store.stockSelectionCondition = state.stockSelectionCondition.filter(s => s !== condition);
        }
        else {
            revive_store.stockSelectionCondition = [...state.stockSelectionCondition, condition];
        }
    };
    const selected = state.stockSelectionCondition.includes(props_.a);
    return (<styleds.selectCard selected={selected} className={state.stockSelectionType} onClick={() => {
            handleConditionToggle(props_.a);
        }}>
      {selected ? <BsCheckCircleFill /> : <BsCheckCircle />}
      &nbsp;
      {props_.children}
    </styleds.selectCard>);
});
export const StockSelectionBoard = memo(function StockSelectionBoard() {
    const state = useSnapshot(revive_store);
    const baseDate = useSnapshot(staticStore).tradedDate.day0;
    const bs = state.stockSelectionType === 'long' ? 'b' : 's';
    const maType = bs === 'b' ? 1 : 3;
    /** 雙均線選股資料 */
    const doubleMa = useDoubleMA({
        date: baseDate.format('YYYY-MM-DD'),
        ma1: 17,
        ma2: 88,
        bs: bs,
        volume_gte: 1000,
        amount_gte: 1000000,
    }).data;
    /** 依照交易量篩選當作基本股池 */
    const baseSymbol = useVolumeMa({
        date: baseDate.format('YYYY-MM-DD'),
        ma: 5,
        amount_gte: 30000000,
    }).data;
    /** 雙均線商品 */
    const maSymbol = doubleMa?.filter(s => s.type === maType)?.map(s => s.symbol);
    /** macd選股商品 */
    const macdSymbol = useReviveMACD({ date: baseDate.format('YYYY-MM-DD'), bs: bs }).data;
    /** 大股東選股商品 */
    const tdccSymbol = useTdccStock({
        date: baseDate.format('YYYY-MM-DD'),
        bs: bs,
        weeks_ago: 2,
    }).data;
    /** 財報選股商品 */
    const financialReportSymbol = useFinanciaReport({
        date: baseDate.format('YYYY-MM-DD'),
        bs: bs,
    }).data;
    useEffect(() => {
        let updatedStockSymbol = [];
        // 添加
        if (state.stockSelectionCondition.includes('doubleMa')) {
            updatedStockSymbol.push(...(maSymbol ?? []));
        }
        if (state.stockSelectionCondition.includes('financialReport')) {
            updatedStockSymbol.push(...(financialReportSymbol ?? []));
        }
        if (state.stockSelectionCondition.includes('macd')) {
            updatedStockSymbol.push(...(macdSymbol ?? []));
        }
        if (state.stockSelectionCondition.includes('tdcc')) {
            updatedStockSymbol.push(...(tdccSymbol ?? []));
        }
        if (state.stockSelectionCondition.length >= 2) {
            const selectedSymbols = [];
            let commonSymbols;
            for (const condition of state.stockSelectionCondition) {
                let conditionSymbols;
                switch (condition) {
                    case 'doubleMa':
                        conditionSymbols = maSymbol;
                        break;
                    case 'financialReport':
                        conditionSymbols = financialReportSymbol;
                        break;
                    case 'macd':
                        conditionSymbols = macdSymbol;
                        break;
                    case 'tdcc':
                        conditionSymbols = tdccSymbol;
                        break;
                    default:
                        break;
                }
                if (conditionSymbols) {
                    selectedSymbols.push(...conditionSymbols);
                    if (!commonSymbols) {
                        commonSymbols = conditionSymbols;
                    }
                    else {
                        commonSymbols = commonSymbols.filter(symbol => conditionSymbols?.includes(symbol));
                    }
                }
            }
            if (commonSymbols) {
                revive_store.stockSymbol = commonSymbols.filter(item1 => baseSymbol?.some(item2 => item2 === item1));
            }
            else {
                revive_store.stockSymbol = [];
            }
        }
        else {
            revive_store.stockSymbol = [];
            updatedStockSymbol = [];
        }
    }, [state.stockSelectionCondition, state.stockSelectionType]);
    return (<styleds.container>
      <styleds.typeContent>
        <TypeBotton type={'long'}>多方</TypeBotton>
        <TypeBotton type={'short'}>空方</TypeBotton>
      </styleds.typeContent>
      <styleds.title>
        <Divider css={css `
            width: 100%;
          `} color={'#efefef'} my='sm' labelPosition='center' label='條件選擇(最少兩個條件)'/>
      </styleds.title>
      {state.stockSelectionType === 'long' && (<styleds.itemContent>
          <SelectCard a={'financialReport'}>近三季度營收或毛利或淨利率QoQ%或YoY%上升</SelectCard>
          <SelectCard a={'tdcc'}>近三周800張以上大戶比率增加</SelectCard>
          <SelectCard a={'macd'}>日線MACD柱體顏色轉紅</SelectCard>
          <SelectCard a={'doubleMa'}>17/88日均線金叉</SelectCard>
        </styleds.itemContent>)}
      {state.stockSelectionType === 'short' && (<styleds.itemContent>
          <SelectCard a={'financialReport'}>近三季度營收或毛利或淨利率QoQ%或YoY%下降</SelectCard>
          <SelectCard a={'tdcc'}>近三周800張以上大戶比率減少</SelectCard>
          <SelectCard a={'macd'}>日線MACD柱體顏色轉綠</SelectCard>
          <SelectCard a={'doubleMa'}>17/88日均線死叉</SelectCard>
        </styleds.itemContent>)}
    </styleds.container>);
});
const styleds = {
    //button---------------------------
    button: styled.div `
    ${fill_vertical_all_center};
    background-color: #2f2f2f;
    border: 1px solid ${props => (props.selected === true ? '#acacac' : '#686868')};
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    &.long {
      background-color: ${props => (props.selected === true ? '#551100' : '#2f2f2f')};
    }
    &.short {
      background-color: ${props => (props.selected === true ? '#005500' : '#2f2f2f')};
    }
    transition: 0.3s;
  `,
    //board----------------------------
    selectCard: styled.div `
    ${fill_horizontal_cross_center};
    height: 42px;
    border: 1px solid ${props => (props.selected ? '#acacac' : '#454545')};
    color: ${props => (props.selected ? '#fafafa' : '#aaaaaa')};
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      margin-left: 4px;
    }
    &.long {
      background-color: ${props => (props.selected ? '#563a3a' : '#3a3a3f')};
    }
    &.short {
      background-color: ${props => (props.selected ? '#41553a' : '#3a3a3f')};
    }
  `,
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #25262a;
    height: 252px;
    padding: 2px;
    user-select: none;
  `,
    title: styled.div `
    ${fill_vertical_all_center};
    height: 30px;
    font-size: 14px;
  `,
    typeContent: styled.div `
    ${fill_horizontal_all_center};
    height: 36px;
    gap: 4px;
    padding: 2px;
  `,
    itemContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 66px);
    gap: 4px;
    padding: 0px 2px;
  `,
};
