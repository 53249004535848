import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
const styleds = {
    Sidebar: styled.div `
    ${fill_vertical_cross_center};
    gap: 8px;
    background-color: #141721;
    padding: 8px 4px;
  `,
    Title: styled.div `
    ${props => {
        const baseCssset = css `
        display: flex;
        width: 100%;
        height: 28px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
      `;
        const buyCssset = css `
        background-color: rgb(170, 0, 0);
      `;
        const sellCssset = css `
        background-color: rgb(0, 170, 0);
      `;
        return css([
            baseCssset,
            props.variant === 'long' && buyCssset,
            props.variant === 'short' && sellCssset,
        ]);
    }}
  `,
    ButtonGroupContent: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 8px;
  `,
    ModePageContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 40px);
    gap: 8px;
  `,
    SymbolList: styled.div `
    width: 100%;
    height: calc(100% - 36px);
  `,
};
export default styleds;
