import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { Charting } from '~/pages/heineken_template/_private/Charting';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { Preset_LoginPageOfCarousel } from '../heineken_template/_preset/preset_LoginPageOfCarousel';
import { DataTree_MarqueeQuote } from './DataTree_MarqueeQuote';
import { DataTree_SidePane2 } from './datatree_SidePane2';
import { DataTree_SidePane1 } from './datatree_SidePane1';
import { datatree_initStyling } from './datatree_initStyling';
import { Datatree_Topbar } from './datatree_Topbar';
import { Datatree_Footer } from './datatree_Footer';
import { datatree_initStrategies } from './datatree_initStrategies';
export const datatree_init = {
    global(templateProps) {
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Drawer1 = DataTree_SidePane1;
        templateProps.layout.Drawer2 = DataTree_SidePane2;
        templateProps.layout.Row1 = Datatree_Topbar;
        templateProps.layout.Row2 = Datatree_Footer;
        templateProps.layout.Row3 = DataTree_MarqueeQuote;
    },
    indexPage(templateProps) {
        datatree_init.global(templateProps);
        datatree_initStyling(templateProps);
        datatree_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: '5',
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledPaneMenu: true,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderSaveload: false,
            disabledHeaderCompare: false,
            overrides: {
                ...store.charting.darkOverrides,
                'paneProperties.legendProperties.showLegend': false,
                'paneProperties.topMargin': 5,
                'paneProperties.bottomMargin': 5,
                'scalesProperties.fontSize': 14,
                'paneProperties.backgroundType': 'gradient',
                'paneProperties.backgroundGradientStartColor': '#141517',
                'paneProperties.backgroundGradientEndColor': '#171718',
                'paneProperties.vertGridProperties.color': '#333333',
                'paneProperties.horzGridProperties.color': '#333333',
            },
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 64px calc(100vh - 112px) 124px;
      grid-template-columns: 1fr 336px 336px;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Drawer1 Drawer2'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px 64px calc(100% - 116px) 124px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Row3 Row3 Row3'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 64px calc(100% - 116px) 124px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Row3 Row3 Row3'
          'Chart Chart Chart'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Charting = Charting;
        templateProps.layout.Row1 = Datatree_Topbar;
        templateProps.layout.Row2 = Datatree_Footer;
        templateProps.layout.Row3 = DataTree_MarqueeQuote;
        templateProps.layout.Drawer1 = DataTree_SidePane1;
        templateProps.layout.Drawer2 = DataTree_SidePane2;
        templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]}/>;
    },
    ['trading_analyzer/index.page'](templateProps) {
        datatree_init.global(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 48px calc(100vh - 96px) 1fr;
      grid-template-columns: 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Col1 Col1'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 1fr;
        grid-template-columns: 1fr;
        place-content: flex-start;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        place-content: flex-start;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Row1 = Datatree_Topbar;
        templateProps.layout.Col1 = <div></div>;
        templateProps.layout.Row2 = <div></div>;
        templateProps.layout.Row3 = <div></div>;
        templateProps.layout.Drawer1 = <div></div>;
        templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]}/>;
    },
};
