import { css } from '@emotion/react';
import { PositionInfomationClasses } from '~/modules/investment-consultant/positionValueForm/PositonInfotion';
import { SymbolQuoteInfoClasses } from '~/modules/symbolQuote/information-form';
import { store } from '~/pages/heineken_template/_private/store';
export const s178178_initStyling = (templateProps) => {
    //FuiButton.Display => 動不了css
    //store.globalCssset = css`
    templateProps.globalCssset = css `
    #__body {
      .${PositionInfomationClasses.ItemTitle} {
        background-image: linear-gradient(to right, #313033, #323446);
      }

      .${SymbolQuoteInfoClasses.button} {
        background-image: linear-gradient(to right, #323135, #2c2c35);
      }
    }
  `;
};
store.charting.darkOverrides = {
    'mainSeriesProperties.candleStyle.borderDownColor': '#5aF502',
    'mainSeriesProperties.candleStyle.borderUpColor': '#ff164E',
    'mainSeriesProperties.candleStyle.downColor': '#00aa00',
    'mainSeriesProperties.candleStyle.upColor': '#cc0000',
    'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
    'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'mainSeriesProperties.showPriceLine': true,
    'mainSeriesProperties.showCountdown': true,
    'paneProperties.horzGridProperties.style': 1,
    'paneProperties.vertGridProperties.style': 1,
    'paneProperties.topMargin': 15,
    'paneProperties.bottomMargin': 15,
    'paneProperties.vertGridProperties.color': '#444444',
    'paneProperties.horzGridProperties.color': '#444444',
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': '#181819',
    'timeScale.rightOffset': 0,
    'scalesProperties.fontSize': 14,
    'scalesProperties.textColor': '#cccccc',
    'scalesProperties.lineColor': '#cccccc',
};
