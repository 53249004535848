import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { memo } from 'react';
import { ResponsiveContainer, YAxis, XAxis, Tooltip, CartesianGrid, ComposedChart, Line, Label, } from 'recharts-new';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
const OpenInterestChart = memo(function SimpleTrendChart(props) {
    const data = props.data;
    if (!data || (data && data.length === 0))
        return (<div css={css `
          ${fill_horizontal_all_center}
        `}>
        ...尚未載入
      </div>);
    //價差diff
    const maxDiff = Math.max(...(data?.map(datum => (datum?.openInterest1 !== null ? datum?.openInterest1 : 0)) || []));
    const minDiff = Math.min(...(data?.map(datum => (datum?.openInterest2 !== null ? datum?.openInterest2 : 0)) || []));
    const absMaxDiff = Math.max(Math.abs(maxDiff), Math.abs(minDiff));
    const yRangeDiff = [0, absMaxDiff * 1.1];
    const yTickDiff = [0, (absMaxDiff * 1.1) / 2, absMaxDiff * 1.1];
    return (<ResponsiveContainer width='100%' height='100%'>
      <ComposedChart css={css `
          cursor: pointer !important;
        `} data={data} margin={{
            top: 16,
            right: 1,
            left: -32,
            bottom: 1,
        }}>
        <CartesianGrid strokeDasharray='0 0' vertical={false}/>
        <XAxis dataKey='date' tickFormatter={str => str.slice(-5)}/>
        <YAxis fontSize={12} width={98} domain={yRangeDiff} ticks={yTickDiff} tickFormatter={str => (Number(str) / 1000).toFixed(0) + 'K'}>
          <Label value={'未平倉交易量'} angle={-90} position='outside' fill='#666666' fontSize={14}/>
        </YAxis>

        <Tooltip content={<CustomizedTooltip />}/>

        <Line type='linear' dot={false} dataKey='openInterest1' stroke='#0f8baa'/>
        <Line type='linear' dot={false} dataKey='openInterest2' stroke='#F7552F'/>
      </ComposedChart>
    </ResponsiveContainer>);
});
const CustomizedTooltip = ({ payload }) => {
    if (!payload || (payload && payload.length < 1))
        return null;
    const datum = payload[0].payload;
    const date = datum.date;
    const volume1 = datum.openInterest1;
    const volume2 = datum.openInterest2;
    const symbol1 = datum.symbol1;
    const symbol2 = datum.symbol2;
    return (<styleds.container>
      <div css={css `
          color: #222222;
        `}>
        日期: {date}
      </div>
      <div css={css `
          color: #222222;
        `}></div>
      <div css={css `
          color: #0f8baa;
        `}>
        {symbol1}: {volume1}
      </div>
      <div css={css `
          color: #f7552f;
        `}>
        {symbol2}: {volume2}
      </div>
    </styleds.container>);
};
const styleds = {
    container: styled.div `
    width: 148px;
    padding: 4px;
    background-color: #fafafacc;
    opacity: 1;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
  `,
};
export default OpenInterestChart;
