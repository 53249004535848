import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const edwin_evn = createIndicator({
    id: 'edwin-evn',
    displayName: 'EVN',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.ohlc.closeArray;
            const evn = this.PineJS.Std.sma(close, 20, this._context);
            const k = 0.3 / 100;
            const upper_evn = evn * (1 + k);
            const lower_evn = evn * (1 - k);
            return [upper_evn, lower_evn];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        visible: false,
        plots: [
            { id: 'upper', type: 'line' },
            { id: 'lower', type: 'line' },
        ],
        styles: {
            upper: {
                title: 'upper',
                histogramBase: 0,
                joinPoints: false,
            },
            lower: {
                title: 'lower',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        defaults: {
            inputs: {},
            styles: {
                upper: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 50,
                    visible: true,
                    color: '#fe8b23',
                },
                lower: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 50,
                    visible: true,
                    color: '#208eed',
                },
            },
            precision: 2,
        },
        inputs: [],
    },
});
