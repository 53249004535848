import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const m168168_bandTrade = createIndicator({
    displayName: '訊號',
    id: 'm168168-bandTrade',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#big3_net_stock';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(0);
            const time0 = context.new_var(this._context.symbol.time);
            //籌碼商品 ex:外資買賣超
            this._context.select_sym(1);
            const time1 = this._context.new_var(this._context.symbol.time);
            const sym1 = this.PineJS.Std.open(this._context);
            const unlimited1 = this._context.new_unlimited_var(sym1);
            const chip = unlimited1.adopt(time1, time0, 0); //商品最終數值
            //主圖商品
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const close = this.ohlc.close;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const open = this.ohlc.open;
            const hlc3 = this.PineJS.Std.hlc3(this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const volume_array = this._context.new_var(volume);
            const close_array = this.ohlc.closeArray;
            const low_array = this.ohlc.lowArray;
            const high_arry = this.ohlc.highArray;
            const open_array = this.ohlc.openArray;
            //籌碼商品陣列要在這宣告,不能在select_sym(1)時宣告,會永遠NaN
            const chip_array = this._context.new_var(chip);
            chip_array.get(100);
            const marketposition = this._context.new_var();
            const entryPrice = this._context.new_var();
            const entryDate = this._context.new_var();
            const long_stoploss = this._context.new_var();
            const short_stoploss = this._context.new_var();
            const pvflag = this._context.new_var();
            volume_array.get(100);
            close_array.get(100);
            open_array.get(100);
            itime_array.get(100);
            marketposition.get(1);
            marketposition.get(2);
            pvflag.get(2);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            let buy_exit_icon = NaN;
            let short_exit_icon = NaN;
            //-------------------------------
            const ratio0 = chip_array.get(0) / volume_array.get(0);
            const ratio1 = chip_array.get(1) / volume_array.get(1);
            const ratio2 = chip_array.get(2) / volume_array.get(2);
            const condition_buy_ratio = ratio0 > 0.2 || ratio1 > 0.2 || ratio2 > 0.2;
            const condition_short_ratio = ratio0 < -0.2 || ratio1 < -0.2 || ratio2 < -0.2;
            const ma20 = this.PineJS.Std.sma(close_array, 20, this._context);
            const ma_volume = this.PineJS.Std.sma(volume_array, 60, this._context);
            const lowest = this.PineJS.Std.lowest(low_array, 5, this._context);
            const higest = this.PineJS.Std.highest(high_arry, 5, this._context);
            const rangeLow60 = this.PineJS.Std.lowest(low_array, 60, this._context);
            const rangeLow20 = this.PineJS.Std.lowest(low_array, 10, this._context);
            const condition_default_buy = chip_array.get(0) > 0 &&
                chip_array.get(1) > 0 &&
                chip_array.get(2) > 0 &&
                close_array.get(0) >= close_array.get(1) &&
                close_array.get(1) >= close_array.get(2) &&
                close_array.get(2) >= close_array.get(3) &&
                close > open &&
                volume > ma_volume;
            //外資連續買超比重的條件
            const condition_ratio_buy = ratio0 + ratio1 + ratio2 > 0.4 && ratio0 > 0 && ratio1 > 0 && ratio2 > 0 && close > open;
            //外資當日比重的條件
            const condition_daily_ratio_buy = ratio0 > 0.6; //&& volume > ma_volume
            const condition_buy = condition_default_buy || condition_ratio_buy || condition_daily_ratio_buy;
            const condition_short = 
            // chip_array.get(0) < 0 &&
            // chip_array.get(1) < 0 &&
            // chip_array.get(2) < 0 &&
            close_array.get(0) < close_array.get(1) &&
                close_array.get(1) < close_array.get(2) &&
                close_array.get(2) < close_array.get(3) &&
                close < open &&
                low > rangeLow60 &&
                low <= rangeLow20 &&
                volume < ma_volume;
            if (condition_buy) {
                marketposition.set(1);
            }
            if (condition_short) {
                marketposition.set(-1);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                entryPrice.set(close);
                entryDate.set(itime);
                long_stoploss.set(lowest);
                short_stoploss.set(NaN);
                pvflag.set(0);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
                entryPrice.set(close);
                entryDate.set(itime);
                short_stoploss.set(higest);
                long_stoploss.set(NaN);
                pvflag.set(0);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                //第一次停利
                if (pvflag.get(0) === 0 && high > entryPrice.get(0) * 1.15) {
                    buy_target_icon = 1;
                    pvflag.set(1);
                }
                //第二次停利
                if (pvflag.get(0) === 1 && high > entryPrice.get(0) * 1.5) {
                    buy_target_icon = 1;
                    // marketposition.set(0)
                    // long_stoploss.set(NaN)
                    pvflag.set(2);
                }
                //外資連續出貨 比重大於0.3 且收紅
                if (close_array.get(0) > close_array.get(1) && close > open && chip < 0 && ratio0 < -0.3) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    long_stoploss.set(NaN);
                }
                //外資出貨 比重大於0.5
                if (itime_array.get(2) > entryDate.get(0) && chip < 0 && ratio0 < -0.4) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    long_stoploss.set(NaN);
                }
                //小於停損線
                if (close < long_stoploss.get(0)) {
                    marketposition.set(0);
                    long_stoploss.set(NaN);
                }
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                //第一次停利
                if (pvflag.get(0) === 0 && close < entryPrice.get(0) * 0.85) {
                    short_target_icon = 1;
                    pvflag.set(1);
                }
                //第二次停利
                if (pvflag.get(0) === 1 && close < entryPrice.get(0) * 0.5) {
                    short_target_icon = 1;
                    // marketposition.set(0)
                    // short_stoploss.set(NaN)
                    pvflag.set(2);
                }
                //外資出貨 比重大於0.5
                if (itime_array.get(2) > entryDate.get(0) && chip > 0 && ratio0 > 0.4) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                    short_stoploss.set(NaN);
                }
                //大於停損線
                if (close > short_stoploss.get(0)) {
                    marketposition.set(0);
                    short_stoploss.set(NaN);
                }
            }
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                buy_exit_icon,
                short_exit_icon,
                long_stoploss.get(0),
                short_stoploss.get(0),
            ];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_4: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: { in_0: 20 },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '.',
                size: 'tiny',
                text: 'target',
                title: '多單參考停利訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '.',
                size: 'tiny',
                text: 'target',
                title: '空單參考停利訊號',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'tiny',
                text: 'exit',
                title: '多單參考出場訊號',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'tiny',
                text: 'exit',
                title: '空單參考出場訊號',
            },
        },
        is_price_study: !0,
        inputs: [
            {
                id: 'in_0',
                name: '長度',
                defval: 20,
                type: 'integer',
                min: 1,
                max: 200,
            },
        ],
        scriptIdPart: '',
    },
});
