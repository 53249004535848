import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { SideBarOptionChart } from '~/modules/investment-consultant/optionChart/SideBarOptionChart';
import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular';
import { DataChart } from '../big3_stock/big3Stock_SidePane2';
import { getOptionIntradayEndDate, getOptionIntradayStartDate, } from '../daddy960_opkevin/component/optionAnalyze/getOptionContractDateTime';
import { optionAnalyzeStore } from '../daddy960_opkevin/component/optionAnalyze/optionAnalyzeStore';
import { useGetOptionContract } from '../daddy960_opkevin/component/optionAnalyze/useGetOptionContract';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { staticStore } from '../heineken_template/_private/staticStore';
import { Speedometers } from './component/Speedometers';
import { datatree_store } from './datatree_store';
export const DataTree_SidePane2 = memo(function DataTree_SidePane2() {
    const state = useSnapshot(optionAnalyzeStore);
    //先設最近交易日結束區間
    const intraday = useSnapshot(staticStore).tradedDate.intraday;
    const endDateTime = getOptionIntradayEndDate(intraday);
    const intradayStartTime = getOptionIntradayStartDate(intraday);
    // 這邊先一開始先拿選擇權
    /** 契約代號 */
    const defaultContract = useGetOptionContract(endDateTime).default ?? '';
    /** 近期所有契約代號 */
    const contractMonth = useGetOptionContract(endDateTime).contractMonth ?? [''];
    /** 近期契約對應的結算日 */
    const allContractSettlementDate = useGetOptionContract(endDateTime).settlementDate ?? { ['']: '' };
    /** default契約的結算日 */
    const settlementDate = allContractSettlementDate?.[state.currentContract] ?? '';
    useEffect(() => {
        // 首次render將option store基本的資訊set好
        if (defaultContract !== '') {
            optionAnalyzeStore.currentContract = defaultContract;
            optionAnalyzeStore.allContractSettlementDate = allContractSettlementDate;
            optionAnalyzeStore.allContract = contractMonth;
            optionAnalyzeStore.contractStartDateTime = intradayStartTime;
            optionAnalyzeStore.intradayEndTime = endDateTime;
        }
        //defaultContract拿到後,其他資料都會拿到,所以Dependency只放defaultContract
    }, [defaultContract]);
    useEffect(() => {
        optionAnalyzeStore.currentSettlementDate = settlementDate;
    }, [state.currentContract]);
    const valueState = useSnapshot(datatree_store);
    const valueTrend = Math.round(getIndicatorAngular(valueState.valueTrend) * 100);
    const valuePower = Math.round(getIndicatorAngular(valueState.valuePower) * 100);
    const valueSmallPower = Math.round(getIndicatorAngular(valueState.valueSmallPower) * 100);
    const signalLong = valueTrend > 0 && valuePower > 0 && valuePower > valueSmallPower;
    const signalShort = valueTrend < 0 && valuePower < 0 && valuePower < valueSmallPower;
    const signal = signalLong ? 100 : signalShort ? -100 : 0;
    return (<styleds.container>
      <styleds.card height={160}>
        <GaugeContent value={signal}>趨勢動能訊號</GaugeContent>
        <GaugeContent value={valueTrend}>主力動向</GaugeContent>
        <GaugeContent value={valuePower}>主力能量</GaugeContent>
      </styleds.card>
      <styleds.title>選擇權區間損益圖表</styleds.title>
      <styleds.card height={60}>
        <OptionInfo opState={state}/>
      </styleds.card>
      <styleds.card height={180}>
        <SideBarOptionChart />
      </styleds.card>
      <styleds.title>市場漲跌家數</styleds.title>
      <styleds.card height={220}>
        <useStockPriceChangeDistributionState.Provider>
          <DataChart />
        </useStockPriceChangeDistributionState.Provider>
      </styleds.card>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #161726;
    padding: 4px;
    gap: 4px;
  `,
    card: styled.div `
    ${fill_horizontal_all_center};
    height: ${props => props.height}px;
    border-radius: 8px;
    background-color: #1d1e2a;
    ${jc.spaceBetween};
    padding: 4px;
  `,
    //sidebar標題
    title: styled.div `
    display: flex;
    direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100%;
    border-radius: 5px;
    background-color: #3a3b46;
  `,
    //顯示選擇權圖表資訊
    infoDateTime: styled.p `
    margin: 0px;
    border-radius: 5px;
    background-color: #3a3b46;
    font-size: 14px;
    padding: 2px 4px;
  `,
};
//客製化科技感的儀表板
const GaugeContent = memo(function GaugeContent(props) {
    return (<div css={css `
        ${fill_vertical_all_center};
        width: 96px;
        height: 136px;
        gap: 8px;
      `}>
      <div css={css `
          width: 100%;
          height: 100px;
        `}>
        <Speedometers value={props.value}/>
      </div>
      <div css={css `
          width: 100%;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #414d65;
          border-radius: 4px;
          font-size: 14px;
        `}>
        {props.children}
      </div>
    </div>);
});
const OptionInfo = memo(function OptionInfo(props) {
    return (<div css={css `
        ${fill_horizontal_all_center};
        gap: 8px;
        & * span {
          font-size: 14px;
        }
      `}>
      <div>
        <span>合約</span>
        <styleds.infoDateTime>{props.opState.currentContract}</styleds.infoDateTime>
      </div>
      <div>
        <span>起始</span>
        <styleds.infoDateTime>
          {props.opState.contractStartDateTime.format('MM/DD(dd) HH:mm')}
        </styleds.infoDateTime>
      </div>
      <div>
        <span>終止</span>
        <styleds.infoDateTime>
          {props.opState.intradayEndTime.format('MM/DD(dd) HH:mm')}
        </styleds.infoDateTime>
      </div>
    </div>);
});
