import { css } from '@emotion/react';
import FuiButton from '../heineken_template/components/FuiButton';
import { store } from '../heineken_template/_private/store';
export const opkevin_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.candleStyle.downColor': '#00ff00',
        'mainSeriesProperties.candleStyle.upColor': '#ff0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.wickUpColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'paneProperties.vertGridProperties.color': '#363636',
        'paneProperties.horzGridProperties.color': '#363636',
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#16161e',
        'paneProperties.legendProperties.showSeriesOHLC': true,
        'timeScale.rightOffset': 5,
        'scalesProperties.fontSize': 16,
        'scalesProperties.textColor': '#f8f9f9',
        'scalesProperties.showSeriesLastValue': true,
        'scalesProperties.showStudyLastValue': true,
    };
    templateProps.globalCssset = css `
    #__next {
      .Drawer2 {
        width: 140px;
        background: linear-gradient(85deg, #121212, #121212);
      }
      .MuiModal-backdrop {
        background: linear-gradient(90deg, #12121211, #121212);
      }

      ${FuiButton.classes.button.Root} {
        border-radius: 5px;
        height: 26px;
        width: 112px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #1e1e1e;
        border: 1px solid #555555;
        &:hover {
          background-color: #2f3136;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #354a93;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #496ac0;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
    }
  `;
};
