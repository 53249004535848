import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { useIntradayPick } from '~/modules/screener/useDatePick';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource';
import FuiButton from '../heineken_template/components/FuiButton';
import { store } from '../heineken_template/_private/store';
import { styleds } from './styleds';
import { yaya168Stock_store } from './yaya168Stock_store';
export const Yaya168Stock_SidePane2 = memo(function Yaya168Stock_SidePane2() {
    const state = useSnapshot(yaya168Stock_store);
    const intraDay = useIntradayPick();
    const dataLong = useVirtualTransaction('yaya168_stock_long', {
        beginDatetime: intraDay,
        endDatetime: intraDay,
    });
    const dataLong2 = useVirtualTransaction('yaya168_stock_long2', {
        beginDatetime: intraDay,
        endDatetime: intraDay,
    });
    const dataLong_ = dataLong
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        ?.map(datum => datum.symbol) || [];
    const dataLong2_ = dataLong2
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        ?.map(datum => datum.symbol) || [];
    const potentialSymbol = useFirebaseValue('yaya168_signal', 'long');
    const resultSymbol2 = () => {
        if (state.tabsOfMain.store.active === '墊高') {
            return dataLong_;
        }
        if (state.tabsOfMain.store.active === '點火') {
            return dataLong2_;
        }
        if (state.tabsOfMain.store.active === '精選潛力股') {
            return potentialSymbol?.symbols;
        }
    };
    return (<styleds.SideBar2>
      <styleds.ButtonContent>
        <yaya168Stock_store.tabsOfMain.WithButton to='墊高'>
          <FuiButton.Display>墊高</FuiButton.Display>
        </yaya168Stock_store.tabsOfMain.WithButton>
        <yaya168Stock_store.tabsOfMain.WithButton to='點火'>
          <FuiButton.Display>點火</FuiButton.Display>
        </yaya168Stock_store.tabsOfMain.WithButton>
        <yaya168Stock_store.tabsOfMain.WithButton to='精選潛力股'>
          <FuiButton.Display>精選潛力股</FuiButton.Display>
        </yaya168Stock_store.tabsOfMain.WithButton>
      </styleds.ButtonContent>

      <styleds.SymbolListContent>
        <SimpleQuoteListInteractWithChart data={resultSymbol2() ?? []} chart={store.charting}></SimpleQuoteListInteractWithChart>
      </styleds.SymbolListContent>

      <styleds.ButtonContent>
        <FuiButton.Display>自選股</FuiButton.Display>
      </styleds.ButtonContent>

      <styleds.SymbolListContent>
        <WatchListWithChart useChart={store.charting} groupName='yaya168_stock_group_1'></WatchListWithChart>
      </styleds.SymbolListContent>
    </styleds.SideBar2>);
});
