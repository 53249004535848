import { css } from '@emotion/react';
import { memo, useState } from 'react';
import { BsSoundwave } from 'react-icons/bs';
import { fill, fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
export const SoundTestButton = memo(function SoundTestButton() {
    const [isPlaySound, setPlaySound] = useState(false);
    const sounds = useSoundStore.getState();
    const handleClickplaySound = () => {
        if (isPlaySound) {
            return;
        }
        setPlaySound(true);
        sounds.playSoundWin168ExitDebounced();
        console.log('sound test');
        setTimeout(() => {
            setPlaySound(false);
        }, 3000);
    };
    return (<div css={containerCss} onClick={event => {
            handleClickplaySound();
        }}>
      <div css={contentCss}>
        <BsSoundwave />
        測試聲音
      </div>

      <div css={isPlaySound ? fadeinCss : null}></div>
    </div>);
});
const containerCss = css `
  position: relative;
  ${fill}
  background-color: #444444;
  border: 1px solid #999999;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #555555cc;
  }
`;
const contentCss = css `
  ${fill_vertical_all_center}
  position: absolute;
  font-size: 12px;
`;
const fadeinCss = css `
  ${fill_horizontal_cross_center};
  animation: progress 3s;
  background-color: #555555;
  border-radius: 5px;
  @keyframes progress {
    0% {
      width: 0%;
    }
    70% {
      width: 100%;
      background-color: #555555;
    }
    90% {
      width: 100%;
      background-color: #444444;
    }
    100% {
      width: 100%;
      background-color: #444444;
    }
  }
`;
