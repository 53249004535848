import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import dayAPI from '~/utils/dayAPI';
import { apirc } from '~/configs/apirc';
import { futuresContractToNumber } from './component/useSgxSymbolData';
import { useSnapshot } from 'valtio';
import { sgxIndicatorsValueStorage } from './sgx_store';
import { store } from '../heineken_template/_private/store';
import { Divider } from '@mantine/core';
import { css } from '@emotion/react';
export const Sgx_SidePane2 = memo(function Sgx_SidePane2() {
    const state = useSnapshot(sgxIndicatorsValueStorage);
    const charting = useSnapshot(store.charting);
    const sgxData = apirc.marketDataAPI.sgxSymbolData.useSWR({
        symbol: charting.symbol.replace('-1', ''),
        from: dayAPI().subtract(36, 'day').unix(),
        to: dayAPI().unix(),
    }).data;
    //先過濾非日盤時段
    const tradingSessionData = sgxData?.filter(s => s.trading_session === 0);
    //時間排序 最新在前面
    const sortDateData = tradingSessionData?.sort((a, b) => dayAPI(b.date).unix() - dayAPI(a.date).unix());
    //最新交易資料
    const data1 = sortDateData?.filter(s => s.symbol === state.symbol1)[0];
    const data2 = sortDateData?.filter(s => s.symbol === state.symbol2)[0];
    return (<styleds.container>
      <styleds.header>
        <styleds.title>商品資訊</styleds.title>
        <styleds.grayDivider />
      </styleds.header>

      <SymbolCard data={data1 ?? null}/>
      <SymbolCard data={data2 ?? null}/>
    </styleds.container>);
});
const SymbolCard = memo(function SymbolCard(props) {
    const data = props.data;
    const close = data?.close ?? 0;
    const lastClose = data?.last_close ?? 0;
    const open = data?.open ?? 0;
    const high = data?.high ?? 0;
    const low = data?.low ?? 0;
    const volume = data?.volume ?? 0;
    const change = data?.change ?? 0;
    const openInterest = data?.open_interest ?? 0;
    const changePercent = (change / lastClose) * 100;
    const amp = (data?.high ?? 0) - (data?.low ?? 0);
    const contractMMYY = data?.symbol.slice(-2) +
        '年' +
        futuresContractToNumber(data?.symbol.slice(-3, -2)) +
        '月';
    return (<styleds.symbolInfoCard>
      <styleds.infoValue>
        <span>商品</span>
        <span>{data ? data?.symbol : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>合約</span>
        <span>{data ? contractMMYY : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>日期</span>
        <span>{data ? data?.date : '-'}</span>
      </styleds.infoValue>
      <Divider my='sm' css={css `
          width: 100%;
        `}/>
      <styleds.infoValue>
        <span>開盤</span>
        <span>{data ? open : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>最高</span>
        <span>{data ? high : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>最低</span>
        <span>{data ? low : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>收盤</span>
        <span>{data ? close : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>前次收盤</span>
        <span>{data ? lastClose : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>交易量</span>
        <span>{data ? volume : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>價格變動</span>
        <styleds.infoValueFill change={change}>{data ? change : '-'}</styleds.infoValueFill>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>價格變動%</span>
        <styleds.infoValueFill change={change}>
          {data ? changePercent.toFixed(2) + '%' : '-'}
        </styleds.infoValueFill>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>未平倉量</span>
        <span>{data ? openInterest : '-'}</span>
      </styleds.infoValue>
      <styleds.infoValue>
        <span>振幅</span>
        <span>{data ? amp : '-'}</span>
      </styleds.infoValue>
    </styleds.symbolInfoCard>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    border-left: 1px solid #e0e4ea;
    gap: 8px;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    width: 100%;
    height: 36px;
  `,
    title: styled.div `
    ${fill_horizontal_cross_center};
    height: 32px;
    width: auto;
    color: #112267;
    font-size: 16px;
    font-weight: 600;
  `,
    grayDivider: styled.div `
    flex: 1 1 auto;
    display: block;
    height: 6px;
    background: #e7e9f0;
    margin-left: 20px;
  `,
    symbolInfoCard: styled.div `
    position: sticky;
    ${fill_vertical_cross_center};
    width: 100%;
    height: 388px;
    background-color: #f9f9f9;
    border: 1px solid #acacac;
    border-radius: 4px;
    padding: 16px;
    font-size: 14px;
    gap: 4px;
    font-weight: 500;
    color: #252525;
    &:hover {
      background-color: #e8f3f6;
    }
  `,
    infoValue: styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    & > * {
      font-weight: 500;
    }
  `,
    infoValueFill: styled.span `
    color: ${props => (props.change > 0 ? '#af2453' : props.change < 0 ? '#43990f' : '#252525')};
    width: 100%;
  `,
};
