import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { eurexIndicatorsValueStorage } from '../example/eurexIndicatorsValueStorage';
export const win9882_main_trend = createIndicator({
    displayName: '趨勢',
    id: 'win9882-main-trend',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const dmi = this.dmi(this._input(0), 14);
            const di_long = dmi[0];
            const di_short = dmi[1];
            const adx = dmi[2];
            const di_long_array = this._context.new_var(di_long);
            const di_short_array = this._context.new_var(di_short);
            const long = this.PineJS.Std.sma(di_long_array, 2, this._context);
            const short = this.PineJS.Std.sma(di_short_array, 2, this._context);
            const result = this._context.new_var();
            const color = this._context.new_var();
            if (long > short && di_long > di_short && adx > di_short) {
                result.set(long - short);
                color.set(0);
                eurexIndicatorsValueStorage.powerDMI = 1;
            }
            else if (long < short && di_long < di_short && adx > di_long) {
                result.set(long - short);
                color.set(1);
                eurexIndicatorsValueStorage.powerDMI = -1;
            }
            else {
                result.set(long - short);
                color.set(2);
                eurexIndicatorsValueStorage.powerDMI = 0;
            }
            return [result.get(0), color.get(0)];
        },
    },
    metainfo: {
        is_price_study: !1,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            { id: 'plot_0', type: 'line' },
            {
                id: 'powerPalette',
                type: 'colorer',
                target: 'plot_0',
                palette: 'powerPalette',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#f2c34f',
                },
            },
            precision: 2,
            inputs: { in_0: 14 },
        },
        palettes: {
            powerPalette: {
                colors: {
                    0: {
                        color: '#da5441',
                        width: 0,
                        style: 0,
                    },
                    1: {
                        color: '#00980a',
                        width: 0,
                        style: 0,
                    },
                    2: {
                        color: '#5D616E',
                        width: 0,
                        style: 0,
                    },
                },
            },
        },
        styles: {
            plot_0: {
                title: 'trend',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
            {
                id: 'in_0',
                name: 'length',
                defval: 14,
                type: 'integer',
                min: 1,
                max: 240,
            },
        ],
    },
});
