import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const Edwin_Footer = memo(function Edwin_Footer() {
    return <styleds.container>警語</styleds.container>;
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    height: 200px;
  `,
};
