import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { fontWeight600 } from '~/css/font';
const styleds = {
    //#region Side Pane 1
    SideBar1: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    padding: 4px;
    &.dark {
      background-color: #232222;
      color: #ffffff;
    }
    &.light {
      background-color: #f7f7f7;
      color: #232222;
    }
  `,
    IntradayTrendChart: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 172px;
    border-radius: 4px;
    padding: 4px;
    &.dark {
      background-color: #424242;
    }
    &.light {
      background-color: #ececec;
    }
  `,
    StrategyTitle: styled.div `
    ${fill_vertical_all_center}
    width: 100%;
    height: 32px;
    background: linear-gradient(90deg, #ff0000, #b02d2d);
    color: #ffffff;
    border-radius: 2px;
    font-size: 20px;
    ${fontWeight600};
  `,
    SymbolTypeButton: styled.div `
    ${fill_vertical_all_center}
    width: 100%;
    height: 28px;
    border-radius: 2px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      border: 1px solid #fafafa;
      transition: 0.5s;
    }
    &.dark {
      background-color: ${props => (props.selected === true ? '#fe952a' : '#3f4545')};
      color: ${props => (props.selected === true ? '#fafafa' : '#aaaaaa')};
      border: 1px solid #878787;
    }
    &.light {
      background-color: ${props => (props.selected === true ? '#fe952a' : '#ededed')};
      color: ${props => (props.selected === true ? '#fafafa' : '#676767')};
      border: 1px solid #565656;
    }
  `,
    ScreenerTitle: styled.div `
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    ${fontWeight600};
    ${options => {
        const buyCssset = options.variant === 'long' &&
            css `
          background-color: #c62a31;
        `;
        const shortCssset = options.variant === 'short' &&
            css `
          background-color: #00ae00;
        `;
        return css([buyCssset, shortCssset]);
    }}
  `,
    ComponeyLogo: styled.img `
    width: 100%;
    height: 64px;
    border-radius: 4px;
  `,
    WatchlistButton: styled.div `
    display: grid;
    grid-template-columns: 40% 60%;

    height: 30px;
    width: 100%;
    &.dark {
      background-color: #424242;
      color: #ffffff;
    }
    &.light {
      background-color: #acacac;
      color: #262626;
    }
  `,
    //#endregion
    //#region Side Pane 2
    SideBar2: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    padding: 4px;
    &.dark {
      background-color: #232222;
      color: #ffffff;
    }
    &.light {
      background-color: #f7f7f7;
      color: #232222;
    }
  `,
    Logo: styled.div `
    width: 100%;
    height: 176px;
    //background-color: #252525;
    background-image: url('jerry597/logo-god.png');
    background-size: 40%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
  `,
    TradingTipsTitle: styled.div `
    ${fill_vertical_all_center}
    height: 32px;
    background-color: #fe952a;
    border-radius: 2px;
    font-size: 22px;
    ${fontWeight600};
  `,
    TradingTipsContent: styled.div `
    ${fill_vertical_all_center}
    height: calc(100% - 208px);
    font-size: 16px;

    border-radius: 8px;
    &.dark {
      background: linear-gradient(90deg, #252525, #333333);
    }
    &.light {
      background: linear-gradient(90deg, #fafafa, #f7f7f7);
    }
  `,
    //#new
    StrategyArea: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 72px;
    border-radius: 4px;
    padding: 4px;
    gap: 2px;
    &.dark {
      background-color: #424242;
      color: #ffffff;
    }
    &.light {
      background-color: #ececec;
      color: #252525;
    }
  `,
    SymbolLsitArea: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: calc(100% - 286px);

    border-radius: 4px;
    padding: 4px;
    gap: 2px;
    &.dark {
      background-color: #232222;
    }
    &.light {
      background-color: #ececec;
    }
  `,
    AreaTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    font-size: 20px;
    color: #ffffff;
    border-radius: 2px;
    gap: 8px;
    ${fontWeight600};
    &.dark {
      background-color: #333333;
      color: #ffffff;
    }
    &.light {
      background-color: #c2c2c2;
      color: #2a2a2a;
    }
  `,
    SymbolList: styled.div `
    height: calc(50% - 48px);
    width: 100%;
  `,
    StrategyGroup: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 5px;
  `,
};
export default styleds;
