import { memo } from 'react';
import { css } from '@emotion/react';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
const defaultBarCss = css `
  border-radius: 1px;
  height: 24px;
  transition-property: width;
  transition-duration: 0.5s;
`;
const RiseFallBarGrade = memo(function RiseFallBar(props) {
    const data = props.data;
    const mData = Array.from({ length: 21 }, (_, i) => ({ count: 0, percentage: i - 10 }));
    data.forEach(d => (mData[parseInt(d[0]) + 10] = { count: d[1], percentage: parseInt(d[0]) }));
    //為了減少閱讀障礙將漲跌幅的%數級距調整為2
    const result = [];
    const result2 = [];
    //從最小數字合併排序到大
    //(-10,-9)(-8,-7)(-6,-5)(-4,-3)(-2,-1)
    mData.forEach(item => {
        if (item.percentage < 0) {
            const index = Math.floor(item.percentage / 2) + 5;
            if (!result[index]) {
                result[index] = { count: item.count, percentage: item.percentage };
            }
            else {
                //console.log('小於0', item.percentage)
                result[index].count += item.count;
            }
        }
    });
    //從最大數字合併排序到小
    //(10,9)(8,7)(6,5)(4,3)(2,1)(0)
    mData.reverse().forEach(item => {
        if (item.percentage >= 0) {
            const index = Math.round(item.percentage / 2) + 5;
            if (!result2[index]) {
                result2[index] = { count: item.count, percentage: item.percentage };
            }
            else {
                // console.log('大於0', item.percentage)
                result2[index].count += item.count;
            }
        }
    });
    const newData = result.concat(result2).filter(s => s);
    const all = newData.reduce((accumulator, current) => accumulator + current.count, 0);
    const fall = newData?.reduce((accumulator, current) => {
        if (current.percentage < 0) {
            return accumulator + current.count;
        }
        return accumulator;
    }, 0);
    const rise = newData?.reduce((accumulator, current) => {
        if (current.percentage > 0) {
            return accumulator + current.count;
        }
        return accumulator;
    }, 0);
    return (<div>
      <div css={css `
          ${flex.h.allCenter}
          width: 100%;
          color: white;
          gap: 1px;
        `}>
        {newData?.map((value, index) => {
            return (<div key={index} css={css `
                width: ${(800 * value.count) / all}px;
                background-color: ${barFill(value.percentage)};
                ${defaultBarCss};
              `}/>);
        })}
      </div>

      <div css={css `
            ${flex.h.default};
            ${jc.spaceBetween};
            width: 100%
            gap: 8px;
            letter-spacing: -1px;
            margin-top: 8px;
          `}>
        <div>
          下跌家數：{fall} ({((fall / all) * 100).toFixed(0)}%)
        </div>
        <div>
          上漲家數：{rise} ({((rise / all) * 100).toFixed(0)}%)
        </div>
      </div>
    </div>);
});
const barFill = (percentage) => {
    if (percentage === 2)
        return classesFill.horizontalBarUpFill1;
    else if (percentage === 4)
        return classesFill.horizontalBarUpFill2;
    else if (percentage === 6)
        return classesFill.horizontalBarUpFill3;
    else if (percentage === 8)
        return classesFill.horizontalBarUpFill4;
    else if (percentage === 10)
        return classesFill.horizontalBarUpFill5;
    else if (percentage === -2)
        return classesFill.horizontalBarDnFill1;
    else if (percentage === -4)
        return classesFill.horizontalBarDnFill2;
    else if (percentage === -6)
        return classesFill.horizontalBarDnFill3;
    else if (percentage === -8)
        return classesFill.horizontalBarDnFill4;
    else if (percentage === -10)
        return classesFill.horizontalBarDnFill5;
    else
        return classesFill.horizontalBarDefaultFill;
};
const classesFill = {
    horizontalBarDefaultFill: '#bbbbbb',
    horizontalBarUpFill1: '#FFCDD2',
    horizontalBarUpFill2: '#EF9A9A',
    horizontalBarUpFill3: '#E57373',
    horizontalBarUpFill4: '#EF5350',
    horizontalBarUpFill5: '#F44336',
    horizontalBarDnFill1: '#C8E6C9',
    horizontalBarDnFill2: '#A5D6A7',
    horizontalBarDnFill3: '#81C784',
    horizontalBarDnFill4: '#66BB6A',
    horizontalBarDnFill5: '#4CAF50',
};
export default RiseFallBarGrade;
