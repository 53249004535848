import React, { memo } from 'react';
import _ from 'lodash';
import { css } from '@emotion/react';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
const Separator = memo(function Separator(props) {
    return (<div style={{
            position: 'absolute',
            height: '100%',
            transform: `rotate(${props.turns}turn)`,
        }}>
      <div style={props.style}/>
    </div>);
});
const RadialSeparators = memo(function RadialSeparators(props) {
    const turns = 1 / props.count;
    return (<div css={css `
        ${fill_vertical_all_center};
      `}>
      {_.range(props.count).map(index => (<Separator key={index} turns={index * turns} style={props.style}/>))}
    </div>);
});
export default RadialSeparators;
