import { memo } from 'react';
import { styleds } from './styleds';
import { store } from '~/pages/heineken_template/_private/store';
import FuiButton from '../heineken_template/components/FuiButton';
import { proxy, useSnapshot } from 'valtio';
import { big3Stock_strategiesGroup } from './big3Stock_initStrategies';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { css } from '@emotion/react';
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import { staticStore } from '../heineken_template/_private/staticStore';
import StockChangePercentGradeChart from '~/modules/investment-consultant/symbolRankingChart/StockChangePercentGradeChart';
import RiseFallBarGrade from '../futuresai/stock-price-change-distribution/components/RiseFallBarGrade';
export const big3StockStore = proxy({ datyType: 'volume' });
export const Big3Stock_SidePane2 = memo(function Big3Stock_SidePane2() {
    const state = useSnapshot(big3StockStore);
    const charting = useSnapshot(store.charting);
    const stockCategory = useSnapshot(staticStore);
    return (<styleds.SideBar>
      策略選擇
      <styleds.StrategyGroup>
        {big3Stock_strategiesGroup.main.map(config => {
            const isActive = config.displayName === charting.strategySelected.displayName;
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={() => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </FuiButton.Display>);
        })}
      </styleds.StrategyGroup>
      <div css={css `
          ${fill_vertical_all_center};
          width: 80%;
          height: 30px;
          background-color: #333333;
          border-radius: 7px;
          cursor: pointer;
          &:hover {
            background-color: #444444;
          }
        `} onClick={() => (big3StockStore.datyType =
            state.datyType === 'changePercent' ? 'volume' : 'changePercent')}>
        市場排行: {state.datyType === 'changePercent' ? '漲跌幅' : '成交值'}
      </div>
      <div css={css `
          width: 100%;
          height: calc(100% - 400px);
        `}>
        <SymbolRankingHorizontalBar.Display symbols={stockCategory.symbol.stockCategory.map(s => s.index_symbol).slice(0, 25) ?? []} type={state.datyType} fontSize={15} nameLabelWidth={48} barSize={22}/>
      </div>
      市場漲跌家數
      <div css={css `
          width: 100%;
          height: 204px;
          background-color: #232733;
          border-radius: 5px;
          padding: 4px;
        `}>
        <useStockPriceChangeDistributionState.Provider>
          <DataChart />
        </useStockPriceChangeDistributionState.Provider>
      </div>
    </styleds.SideBar>);
});
//市場漲跌家數
export const DataChart = memo(function DataChart_() {
    const { state: { overallCount, fallCount, flatCount, riseCount, vBar }, } = useStockPriceChangeDistributionState.useContainer();
    // const up = (riseCount / overallCount) * 100
    // const dn = (fallCount / overallCount) * 100
    // const no = flatCount / overallCount
    return (<div css={css `
        height: 100%;
        width: 100%;
        font-size: 14px;
      `}>
      <div css={css `
          width: 100%;
          height: calc(100% - 56px);
        `}>
        <StockChangePercentGradeChart.display data={vBar}/>
      </div>
      <RiseFallBarGrade data={vBar}/>
    </div>);
});
