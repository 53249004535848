import { store } from '~/pages/heineken_template/_private/store';
import { south_signal } from '~/trades/indicators/south/south_signal';
import { south_op } from '~/trades/indicators/south/south_op';
import { south_strongWeakGate } from '~/trades/indicators/south/south_strongWeakGate';
import { south_iv } from '~/trades/indicators/south/south_iv';
export const south_strategiesGroup = {
    main: [
        {
            displayName: '南哥量化當沖選股',
            indicators: [south_signal, south_strongWeakGate, south_op, south_iv],
            interval: '5',
        },
    ],
};
export const indicatorGroup = [south_signal, south_strongWeakGate, south_op, south_iv];
export const south_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[south_signal, south_strongWeakGate, south_op, south_iv],
    ];
    store.charting.initStrategy({
        configs: [...south_strategiesGroup.main],
    });
    store.charting.changeSymbol('TX-1');
};
// export const returnIndicators = (state: typeof south_agentStore.strategyType) => {
//   if (state === 'RT_south_01') {
//     return south_strategiesGroup.main.map(s => s.indicators)[0]
//   } else if (state === 'RT_south_02') {
//     return south_strategiesGroup.main.map(s => s.indicators)[1]
//   }
// }
// export const returnInterval = (state: typeof south_agentStore.strategyType) => {
//   if (state === 'RT_south_01' || state === 'RT_south_02') {
//     return '5'
//   } else return '1D'
// }
// export const returnWatchIndicators = (state: typeof south_agentStore.watchListStrategyType) => {
//   if (state === 'RT_south_01') {
//     return south_strategiesGroup.main.map(s => s.indicators)[0]
//   } else if (state === 'RT_south_02') {
//     return south_strategiesGroup.main.map(s => s.indicators)[1]
//   }
// }
