import { ok1788_customized2 } from '~/trades/indicators/ok1788/ok1788_customized2';
import { ok1788_customized1 } from '~/trades/indicators/ok1788/ok1788_customized1';
import { ok1788_customized3 } from '~/trades/indicators/ok1788/ok1788_customized3';
import { ok1788_customized0 } from '~/trades/indicators/ok1788/ok1788_customized0';
import { store } from '~/pages/heineken_template/_private/store';
export const ok1788Stock_initStrategies = () => {
    store.charting.initStrategy({
        configs: [
            {
                displayName: '交易指標',
                indicators: [
                    ok1788_customized0,
                    ok1788_customized1,
                    ok1788_customized2,
                    ok1788_customized3,
                ],
                symbol: 'TSEA',
                interval: '1D',
                panesRatio: 66,
            },
        ],
    });
};
