import { memo } from 'react';
import { fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { SgxGauges } from './SgxGauges';
import { sgxClasses } from '../sgx_DataCard';
import styled from '@emotion/styled';
import { useSnapshot } from 'valtio';
import { sgxIndicatorsValueStorage } from '../sgx_store';
import { store } from '~/pages/heineken_template/_private/store';
// 要做5種技術指標 macd-osc、kd、dmi
const trend = (score) => {
    if (score >= -1 && score <= 1) {
        return '震盪趨勢';
    }
    else if (score === 2) {
        return '偏多趨勢';
    }
    else if (score === 3) {
        return '強勢趨勢';
    }
    else if (score === 4) {
        return '過熱趨勢';
    }
    else if (score === -2) {
        return '偏空趨勢';
    }
    else if (score === -3) {
        return '強空趨勢';
    }
    else if (score === -4) {
        return '過弱趨勢';
    }
    else
        return 'unknown';
};
const trendValue = (score) => {
    if (score >= -1 && score <= 1) {
        return 50;
    }
    else if (score === 2) {
        return 65;
    }
    else if (score === 3) {
        return 80;
    }
    else if (score === 4) {
        return 95;
    }
    else if (score === -2) {
        return 35;
    }
    else if (score === -3) {
        return 20;
    }
    else if (score === -4) {
        return 5;
    }
    else
        return NaN;
};
export const IndicatorsCard = memo(function IndicatorsCard() {
    const state = useSnapshot(sgxIndicatorsValueStorage);
    const rsv5 = state.rsv5;
    const rsv20 = state.rsv20;
    const rsv60 = state.rsv60;
    const rsv120 = state.rsv120;
    const bias5 = state.bias5;
    const bias20 = state.bias20;
    const bias60 = state.bias60;
    const bias120 = state.bias120;
    const slope5 = state.slope5;
    const slope10 = state.slope10;
    const slope20 = state.slope20;
    const slope60 = state.slope60;
    const slope120 = state.slope120;
    const slopeArray = [slope5, slope10, slope20, slope60, slope120];
    const dmi5 = state.dmi5;
    const dmi20 = state.dmi20;
    const dmi60 = state.dmi60;
    const dmi120 = state.dmi120;
    const dmiArray = [dmi5, dmi20, dmi60, dmi120];
    //<25弱 >75強
    const rsvScore = (rsv5 + rsv20 + rsv60 + rsv120) / 4;
    //<-0.5空 >0.5多
    const biasScore = (bias5 + bias20 + bias60 + bias120) / 4;
    //-1含三個以上 空  1含三個以上 多
    const slopeLong = slopeArray.filter(s => s > 0).length > 2;
    const slopeShort = slopeArray.filter(s => s < 0).length > 2;
    //-1含兩個以上 空  1含兩個以上 多
    const dmiLong = dmiArray.filter(s => s > 0).length > 2;
    const dmiShort = dmiArray.filter(s => s < 0).length > 2;
    //部位趨勢 -1 1 0
    const rsvTrend = rsvScore > 75 ? 1 : rsvScore < 25 ? -1 : 0;
    const biasTrend = biasScore > 0.8 ? 1 : biasScore < -0.8 ? -1 : 0;
    const slopeTrend = slopeLong === true ? 1 : slopeShort === true ? -1 : 0;
    const dmiTrend = dmiLong === true ? 1 : dmiShort === true ? -1 : 0;
    const trendScore = rsvTrend + biasTrend + slopeTrend + dmiTrend;
    const trendScoreArray = [rsvTrend, biasTrend, slopeTrend, dmiTrend];
    const charting = useSnapshot(store.charting);
    const interval = charting.interval;
    const intervalUnit = interval.toString().match(/^\d+$/) ? '分' : '';
    return (<sgxClasses.card height={280}>
      <sgxClasses.title>技術指標彙整</sgxClasses.title>
      <sgxClasses.subTitle>
        {interval + intervalUnit + '週期'} {trend(trendScore)}
      </sgxClasses.subTitle>
      <SgxGauges currentValue={trendValue(trendScore)}/>
      <styleds.gridContent>
        <styleds.trendTypeContent>
          <styleds.trendTitle fill='#33aa33'>空方</styleds.trendTitle>
          <span>{trendScoreArray.filter(s => s < 0).length}項</span>
        </styleds.trendTypeContent>
        <styleds.trendTypeContent>
          <styleds.trendTitle fill='#cccccc'>盤整</styleds.trendTitle>
          <span>{trendScoreArray.filter(s => s === 0).length}項</span>
        </styleds.trendTypeContent>
        <styleds.trendTypeContent>
          <styleds.trendTitle fill='#aa3344'>多方</styleds.trendTitle>
          <span>{trendScoreArray.filter(s => s > 0).length}項</span>
        </styleds.trendTypeContent>
      </styleds.gridContent>
    </sgxClasses.card>);
});
const styleds = {
    gridContent: styled.div `
    display: flex;
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding: 8px;
    & > * {
      ${fill_vertical_all_center};
      width: 100%;
    }
  `,
    trendTypeContent: styled.div `
    & > * {
      ${fill_vertical_all_center}
    }
  `,
    trendTitle: styled.div `
    width: 48px;
    height: 24px;
    background-color: ${props => props.fill};
    color: #fafafa;
    border-radius: 4px;
  `,
};
