import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const rich888_chips3 = createIndicator({
    id: 'rich888-chips3',
    displayName: '力道指標',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const type = '#bs_indicator_acc';
            const symbol = ticker + type;
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(1);
            const o = this.PineJS.Std.high(this._context);
            const o_array = this._context.new_var(o);
            const o_pos = o > 0 ? o : NaN;
            const o_neg = o < 0 ? o : NaN;
            const ma = this.PineJS.Std.sma(o_array, 5, this._context);
            return [o_pos, o_neg];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#007f00',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '買超',
                histogramBase: 0,
            },
            plot_1: {
                title: '賣超',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
const defaultStyles = {
    styles: {
        plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: 5,
            trackPrice: false,
            transparency: 40,
            color: '#ff0000',
        },
        plot_1: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: 5,
            trackPrice: false,
            transparency: 40,
            color: '#007f00',
        },
    },
    precision: 0,
    inputs: {},
};
