import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fontWeight600 } from '~/css/font';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import GoToBottomButton from '~/pages/daddy960_opkevin/component/GoToBottomButton';
export const Revive_Footer = memo(function Revive_Footer() {
    return (<div css={css `
        ${fill_vertical_all_center}
        background: linear-gradient(359deg, #15161e, #25262b);
        color: #efefef;
        font-size: 14px;
      `}>
      <LinkGroup />
      <p>Copyright © 2020-2023 散戶是爹 960daddy 版權所有</p>
      <span css={css `
          font-size: 11px;
          padding: 8px;
        `}>
        本資料內容僅供參考，不負責投資之盈虧，亦不保證最低之收益。本公司當盡力對任何投資意見與市場分析結果提供正確之資訊，惟可能因市場變化而隨時更動，如有任何遺漏或疏忽，請即通知本公司修正，本公司任何董事或受僱人，對此不負任何法律責任。投資人應審慎考量本身之投資風險，自行作投資判斷。
      </span>
    </div>);
});
const LinkGroup = memo(function LinkGroup() {
    const { isPhone } = useMedia();
    return (<div css={css `
        ${isPhone ? fill_horizontal_all_center : null};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 16px;
      `}>
      <styleds.linkContainer>
        <styleds.linkTitle>社群連結</styleds.linkTitle>
        <Link href='https://youtu.be/Z2T40_fwjKQ'>股市更生人Youtube</Link>
        <Link href='https://youtu.be/Z2T40_fwjKQ'>股市更生人FaceBook</Link>
      </styleds.linkContainer>
      <styleds.linkContainer>
        <styleds.linkTitle>工具介紹</styleds.linkTitle>
        <Link href='https://youtu.be/Z2T40_fwjKQ'>股市更生人系統介紹</Link>
      </styleds.linkContainer>
      <styleds.linkContainer>
        <styleds.linkTitle>相關連結</styleds.linkTitle>
        <Link href='https://rstock.com.tw/'>股市更生人官網</Link>
        {/* <DonateLink href='https://960.oen.tw/?t=1681136671759'>
          <BiDonateHeart size={12} />
          抖內平台
        </DonateLink> */}
      </styleds.linkContainer>
      <GoToBottomButton.dispaly />
    </div>);
});
const Link = memo(function Link(props) {
    return (<p css={css `
        line-height: 12px;
      `}>
      <styleds.linkItem href={props.href}>{props.children}</styleds.linkItem>
    </p>);
});
const DonateLink = memo(function DonateLink(props) {
    return (<p css={css `
        line-height: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
      `}>
      <styleds.donateItem href={props.href}>{props.children}</styleds.donateItem>
    </p>);
});
const styleds = {
    linkContainer: styled.div `
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 16px;
  `,
    linkTitle: styled.p `
    color: #ffffff;
    font-size: 18px;
    line-height: 12px;
    ${fontWeight600};
  `,
    linkItem: styled.a `
    font-size: 13px;
    color: #ffffff;
    border: solid 1.5px transparent;
    border-radius: 5px;

    &:hover {
      color: #4270ef;
      line-height: 10px;
      font-size: 13.4px;
      transition: 0.3s;
    }
  `,
    donateItem: styled.a `
    font-size: 13px;
    color: #ffffff;
    border-radius: 4px;
    background-image: linear-gradient(to right, #4a4454, #444a64);
    padding: 2px;
    transition: 0.3s;
    &:hover {
      line-height: 12px;
      font-size: 13.4px;
      transition: 0.3s;
      background-image: linear-gradient(to right, #8f41e9, #578aef);
    }

    animation: neon 4s linear infinite;
    @keyframes neon {
      0% {
        box-shadow: #9f41e9 0px 0px 10px 2px;
      }
      30% {
        box-shadow: #578aef 0px 0px 10px 2px;
      }
      50% {
        box-shadow: #57caef 0px 0px 10px 2px;
      }
      70% {
        box-shadow: #578aef 0px 0px 10px 2px;
      }
      100% {
        box-shadow: #9f41e9 0px 0px 10px 2px;
      }
    }
  `,
};
