import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
const sounds = useSoundStore.getState();
let exit_sound_flag_ = 0;
export const lung88988_strategy = createIndicator({
    displayName: 'SUPERSTOCK',
    id: 'lung88988strategy',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const positionType = useChartPositionStore.positionType;
            const avgPrice = this.dayTradeAvgPrice();
            const dc = this.ladderChannl(10);
            const useAvgPriceExit = 0;
            const dcExit = 1;
            const priceExit = 1;
            const open = this.ohlc.open;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close = this.ohlc.close;
            const open_array = this.ohlc.openArray;
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const close_array = this.ohlc.closeArray;
            const volume = this.PineJS.Std.volume(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const ihours = new Date(itime).getHours();
            const iminutes = new Date(itime).getMinutes();
            const entryTime = this._context.new_var();
            const day_highest = this._context.new_var();
            const day_lowest = this._context.new_var();
            const volume_array = this._context.new_var();
            const day_kbar_max_volume = this._context.new_var();
            const day_end_volume = this._context.new_var();
            const day_start_volume = this._context.new_var();
            const day_start_high = this._context.new_var();
            const day_start_low = this._context.new_var();
            const marketposition = this._context.new_var();
            const entryPrice = this._context.new_var();
            const buy_targetFlag = this._context.new_var();
            const short_targetFlag = this._context.new_var();
            const buy_trade_count = this._context.new_var();
            const short_trade_count = this._context.new_var();
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = 0;
            let short_target_icon = 0;
            let buy_exit_icon = 0;
            let short_exit_icon = 0;
            let buy_timeExit_icon = 0;
            let short_timeExit_icon = 0;
            //let buy_stoploss_icon = 0
            buy_trade_count.get(0);
            buy_targetFlag.get(1);
            short_targetFlag.get(1);
            open_array.get(1);
            low_array.get(1);
            high_array.get(1);
            close_array.get(1);
            volume_array.get(1);
            marketposition.get(2);
            itime_array.get(2);
            //重置每日最高、最低、最大單根K棒成交量
            if (ihours === 9 && iminutes === 0) {
                day_highest.set(high);
                day_lowest.set(low);
                day_kbar_max_volume.set(volume);
                buy_trade_count.set(0);
                short_trade_count.set(0);
                marketposition.set(0);
            }
            //更新新高
            if (high > day_highest) {
                day_highest.set(high);
            }
            //更新新低
            if (low < day_lowest) {
                day_lowest.set(low);
            }
            //更新當日單跟k最大成交量
            if (volume > day_kbar_max_volume) {
                day_kbar_max_volume.set(volume);
            }
            //紀錄尾盤成交量
            if (ihours === 13 && iminutes === 30) {
                day_end_volume.set(volume);
            }
            //紀錄第一根5分k的高點、低點、成交量
            if (ihours === 9 && iminutes === 0) {
                day_start_high.set(high);
                day_start_low.set(low);
                day_start_volume.set(volume);
            }
            //處理上下影線及定義
            const candlestick0 = Math.abs(close - open);
            const candlestick1 = Math.abs(close_array.get(1) - open_array.get(1));
            const upper_shadow0 = close >= open ? high - close : high - open;
            const lower_shadow0 = close <= open ? close - low : open - low;
            const upper_shadow1 = close_array.get(1) >= open_array.get(1)
                ? high_array.get(1) - close_array.get(1)
                : high_array.get(1) - open_array.get(1);
            const lower_shadow1 = close_array.get(1) <= open_array.get(1)
                ? close_array.get(1) - low_array.get(1)
                : open_array.get(1) - low_array.get(1);
            //處理交易訊號
            //第一根5分k的成交量大於昨天尾盤成交量
            const buy_condition = ihours < 13 &&
                //marketposition.get(0) === 0 &&
                buy_trade_count.get(0) === 0 &&
                high > day_start_high.get(0) &&
                day_start_volume.get(0) > day_end_volume.get(0);
            const short_condition = ihours < 13 &&
                //marketposition.get(0) === 0 &&
                short_trade_count.get(0) === 0 &&
                low < day_start_low.get(0) &&
                day_start_volume.get(0) > day_end_volume.get(0);
            if (positionType !== 'short' && buy_condition && !short_condition) {
                marketposition.set(1);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                entryPrice.set(close);
                entryTime.set(itime);
                buy_targetFlag.set(0);
                buy_icon = 1;
                buy_trade_count.set(1);
            }
            if (positionType !== 'long' && short_condition && !buy_condition) {
                marketposition.set(-1);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                entryPrice.set(close);
                entryTime.set(itime);
                short_targetFlag.set(0);
                short_icon = 1;
                short_trade_count.set(1);
            }
            //後面只要出現5分k是當天最大量，然後是當天最高價就出短出場訊號
            const buy_exit_condition1 = high >= day_highest.get(0) && volume >= day_kbar_max_volume;
            const buy_exit_condition2 = upper_shadow0 > candlestick0 * 2 && upper_shadow1 > candlestick1 * 2;
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                //分k是當天最大量也是當天最高價
                if (buy_targetFlag.get(0) < 2 && buy_exit_condition1) {
                    if (buy_targetFlag.get(0) < 1)
                        buy_target_icon = 1;
                    buy_targetFlag.set(buy_targetFlag.get(0) + 1);
                    //console.log(new Date(itime), '最大量+最高價')
                }
                //連續兩根上影線
                if (buy_targetFlag.get(0) < 2 && buy_exit_condition2) {
                    if (buy_targetFlag.get(0) < 1)
                        buy_target_icon = 1;
                    buy_targetFlag.set(buy_targetFlag.get(0) + 1);
                    //console.log(new Date(itime), '連續兩根上影線')
                }
                //滿足上述兩條件共兩次就出場
                if (buy_targetFlag.get(0) === 2) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    //console.log(new Date(itime), '滿足2次短停利出場')
                }
                //階梯通道 (上階梯線 當值!0 & 前值=0 轉換成顯示上階梯線)
                if (dcExit === 1 && isNaN(dc[3]) && !isNaN(dc[1])) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    //console.log(new Date(itime), '小於階梯線出場')
                }
                //當沖成本線
                // if (useAvgPriceExit === 1 && low < avgPrice) {
                //   buy_exit_icon = 1
                //   marketposition.set(0)
                //   console.log(new Date(itime), '小於成本線出場')
                // }
                //-2.5%出場
                if (priceExit === 1 && low < entryPrice * 0.975) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    //console.log(new Date(itime), '-2.5%出場')
                }
                //跌破0900低點
                if (low < day_start_low) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                    //console.log(new Date(itime), '跌破0900低點出場')
                }
                //時間出場
                if (buy_targetFlag.get(0) < 2 && ihours === 13 && iminutes === 25) {
                    buy_timeExit_icon = 1;
                    marketposition.set(0);
                }
            }
            const short_exit_condition1 = low <= day_lowest.get(0) && volume >= day_kbar_max_volume;
            const short_exit_condition2 = lower_shadow0 > candlestick0 * 2 && lower_shadow1 > candlestick1 * 2;
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                //分k是當天最大量也是當天最高價
                if (short_targetFlag.get(0) < 2 && short_exit_condition1) {
                    if (short_targetFlag.get(0) < 1)
                        short_target_icon = 1;
                    short_targetFlag.set(short_targetFlag.get(0) + 1);
                    console.log(new Date(itime), '最大量+最低價');
                }
                //連續兩根下影線
                if (short_targetFlag.get(0) < 2 && short_exit_condition2) {
                    if (short_targetFlag.get(0) < 1)
                        short_target_icon = 1;
                    short_targetFlag.set(short_targetFlag.get(0) + 1);
                    console.log(new Date(itime), '連續兩根下影線');
                }
                //滿足上述兩條件共兩次就出場
                if (short_targetFlag.get(0) === 2) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                    console.log(new Date(itime), '滿足2次短停利出場');
                }
                //階梯通道 (下階梯線 當值!0 & 前值=0 轉換成顯示下階梯線)
                if (dcExit === 1 && isNaN(dc[2]) && !isNaN(dc[0])) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                    console.log(new Date(itime), '大於階梯線出場');
                }
                //當沖成本線
                // if (useAvgPriceExit === 1 && high > avgPrice) {
                //   short_exit_icon = 1
                //   marketposition.set(0)
                //   console.log(new Date(itime), '大於成本線出場')
                // }
                //-2.5%出場
                if (priceExit === 1 && high > entryPrice * 1.025) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                    console.log(new Date(itime), '-2.5%出場');
                }
                //漲過0900高點
                if (high > day_start_high) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                    console.log(new Date(itime), '漲過0900高點出場');
                }
                //時間出場
                if (short_targetFlag.get(0) < 2 && ihours === 13 && iminutes === 25) {
                    short_timeExit_icon = 1;
                    marketposition.set(0);
                    console.log(new Date(itime), '-2.5%出場');
                }
            }
            //聲音------------------------------------------------------------------
            const displayTime = new Date(itime).toLocaleTimeString('en-US');
            const kbarLoadingTimeMs = 10000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const new_bar = this._context.symbol.isNewBar;
            /** 進場部位聲音 */
            if (isInitialized && new_bar) {
                if (marketposition.get(1) === 1 &&
                    marketposition.get(2) !== 1 &&
                    entryTime.get(0) === itime_array.get(1)) {
                    sounds.playSoundWin168LongDebounced();
                    console.log('✅', displayTime, '進多單');
                }
                else if (marketposition.get(1) === -1 &&
                    marketposition.get(2) !== -1 &&
                    entryTime.get(0) === itime_array.get(1)) {
                    sounds.playSoundWin168ShortDebounced();
                    console.log('✅', displayTime, '進空單');
                }
            }
            //出場部位聲音
            if (isInitialized && new_bar) {
                exit_sound_flag_ = 0;
            }
            const long_exit_condition = (buy_target_icon === 1 && exit_sound_flag_ !== 1) ||
                (buy_exit_icon === 1 && exit_sound_flag_ !== 1);
            const short_exit_condition = (short_target_icon === 1 && exit_sound_flag_ !== 1) ||
                (short_exit_icon === 1 && exit_sound_flag_ !== 1);
            if (isInitialized) {
                if (long_exit_condition) {
                    sounds.playSoundWin168ExitDebounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '當沖多單停利或出場訊號');
                }
                if (short_exit_condition) {
                    sounds.playSoundWin168ExitDebounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '當沖空單停利或出場訊號');
                }
            }
            return [
                buy_icon,
                buy_target_icon,
                buy_exit_icon,
                buy_timeExit_icon,
                short_icon,
                short_target_icon,
                short_exit_icon,
                short_timeExit_icon,
                dc[0],
                dc[1],
            ];
        },
    },
    metainfo: {
        is_price_study: !0,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'line',
            },
            {
                id: 'plot_9',
                type: 'line',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
                plot_5: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
                plot_6: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
                plot_7: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
                plot_8: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00aa00',
                },
                plot_9: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#aa0000',
                },
            },
            precision: 2,
            inputs: {
            // in_0: 10, in_1: 1, in_2: 1, in_3: 1
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'Buy',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '*',
                size: 'small',
                text: 'target',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: 'exit',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: 'day close',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'short',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                char: '*',
                size: 'small',
                text: 'target',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                text: 'exit',
            },
            plot_7: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: 'day close',
            },
            plot_8: {
                title: 'Lower',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_9: {
                title: 'Upper',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
        // {
        //   id: 'in_0',
        //   name: '階梯length',
        //   defval: 60,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_1',
        //   name: '成本線出場0=off/1=on',
        //   defval: 1,
        //   type: 'integer',
        //   min: 0,
        //   max: 1,
        // },
        // {
        //   id: 'in_2',
        //   name: '階梯線出場0=off/1=on',
        //   defval: 1,
        //   type: 'integer',
        //   min: 0,
        //   max: 1,
        // },
        // {
        //   id: 'in_3',
        //   name: '價格%數出場0=off/1=on',
        //   defval: 1,
        //   type: 'integer',
        //   min: 0,
        //   max: 1,
        // },
        ],
    },
});
