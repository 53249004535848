import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase';
const symbols = ['2330', '1313', '2323', '2002'];
const watchListGroup = 'datatree_group_1';
export const DataTree_SidePane1 = memo(function DataTree_SidePane1() {
    return (<styleds.container>
      <SymbolPlatformBase.Display symbol={symbols} watchListGroup={watchListGroup} listTypeSwitch={false}/>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #161726;
    padding: 4px;
  `,
};
