import { useSnapshot } from 'valtio';
import { indicatorsValueStore } from '~/modules/investment-consultant/positionValueForm/indicatorsValueStore';
import { store } from '~/pages/heineken_template/_private/store';
export const useGetIndicatorsValue = () => {
    const symbolNumber = useSnapshot(store.charting).symbol;
    const value = useSnapshot(indicatorsValueStore);
    const entryDate = isNaN(value.entryDate) ? '---' : new Date(value.entryDate).toLocaleDateString();
    const entryTime = isNaN(value.entryDate)
        ? '---'
        : new Date(value.entryDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const stopLoss = isNaN(value.entryDate) ? '---' : value.stopLossPrice.toFixed(2);
    const target0 = isNaN(value.entryDate) ? '---' : value.multipleTargetPrice[0].toFixed(2);
    const target1 = isNaN(value.entryDate) ? '---' : value.multipleTargetPrice[1].toFixed(2);
    const movementTick = value.movementTick;
    const openPositionProfit = isNaN(value.entryDate) || symbolNumber.includes('TS')
        ? '---'
        : symbolNumber.includes('TX')
            ? (value.openPositionProfit * movementTick).toFixed(0)
            : (value.openPositionProfit * 1000).toFixed(0);
    const cost = isNaN(value.entryDate) ? '---' : value.entryPrice;
    const positonConfig = [
        {
            displayName: '進場日期',
            value: entryDate,
        },
        {
            displayName: '進場時間',
            value: entryTime,
        },
        {
            displayName: '進場成本',
            value: cost,
            marketPosition: value.marketPosition,
        },
        {
            displayName: '浮動損益',
            value: openPositionProfit,
        },
        {
            displayName: '預掛停損',
            value: stopLoss,
        },
        {
            displayName: '預掛停利1',
            value: target0,
        },
        {
            displayName: '預掛停利2',
            value: target1,
        },
    ];
    return positonConfig;
};
