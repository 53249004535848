import { css } from '@emotion/react';
import { memo } from 'react';
import { fontWeight600 } from '~/css/font';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import Styleds from './styleds';
export const TechnologyTitle = memo(function TechnologyTitle(props_) {
    return (<Styleds.Title>
      <TechnologyArrow />
      <div css={css `
          ${flex.h.allCenter};
          width: 100%;
          position: absolute;
          ${fontWeight600}
        `}>
        {props_.children}
      </div>
    </Styleds.Title>);
});
export const TechnologyFlooer = memo(function TechnologyFlooer(props_) {
    return (<div css={css `
        ${flex.h.allCenter};
        background: url('lung88988/technology-lineflooer.png');
        background-size: 100% 50px;
        width: 100%;
        height: 50px;
        position: relative;
      `}>
      {props_.children}
    </div>);
});
export const TechnologyLine = memo(function TechnologyLine(props_) {
    const rotateValue = props_.transform;
    return (<img src='/lung88988/technology-line.png' css={css `
        width: 100%;
        height: 16px;
        transform: rotate(${rotateValue}deg);
      `}/>);
});
export const TechnologyArrow = memo(function TechnologyArrow() {
    return (<img src='/lung88988/technology-arrow.png' css={css `
        width: 40%;
        height: 20px;
        opacity: 0.5;
      `}/>);
});
export const TechnologyCircle = memo(function TechnologyCircle() {
    return (<img src='/lung88988/technology-circle.png' css={css `
        width: 90%;
        height: 160px;
      `}/>);
});
export const TechnologyWind = memo(function TechnologyWind(props_) {
    return (<div css={css `
          ${flex.h.allCenter};
          background: url('lung88988/technology-wind-${props_.styledNumber}.png');
          background-size: 100% 100%;
          width: 100%;
          height: ${props_.height}px;
          position: relative;
        `}>
        {props_.children}
      </div>);
});
