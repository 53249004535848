import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { eurex_store } from '~/pages/eurex/_private/eurex_store';
import dayAPI from '~/utils/dayAPI';
const getMatchedSubscription = (subscriptions, agentName) => {
    for (const subscription of subscriptions) {
        if (subscription.agentName === agentName) {
            return subscription;
        }
    }
    return null;
};
export const SgxLogOutView = memo(function SgxLogOutView() {
    const firebaseMe = useFirebaseMeState.useContainer();
    const { meUserState } = useMeStore();
    /** 產品名稱 */
    const agentName = useMeStore(state => state.agentName);
    /** 會員名稱 */
    const userName = meUserState?.name ?? '-';
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName);
    }
    /** 到期日期 */
    const expDate = matchedSubscription && dayAPI(matchedSubscription.startedAt).format('YYYY-MM-DD');
    const nowDate = dayAPI().format('YYYY-MM-DD');
    const dyaDiff = dayAPI(nowDate).diff(expDate, 'day') + 1;
    const hour = dayAPI().hour();
    const greetingString = () => {
        if (hour >= 6 && hour < 11) {
            return '早安!';
        }
        if (hour >= 11 && hour < 18) {
            return '午安!';
        }
        else
            return '晚安!';
    };
    return (<styleds.Container>
      <styleds.TopContent>
        <styleds.Greeting>{greetingString()}</styleds.Greeting>
        <styleds.UserName>{userName}</styleds.UserName>
      </styleds.TopContent>
      <styleds.InforContent>
        <styleds.DateContent>
          <div>啟用日期: {expDate}</div>
          <div>已啟用天數: {dyaDiff}天</div>
        </styleds.DateContent>
        <div css={css `
            ${fill_horizontal_all_center};
            height: 36px;
          `}>
          確定要登出嗎?
        </div>
        <styleds.ButtonGroupContent>
          <styleds.CancelButton onClick={() => eurex_store.userAvatar.store.modal.close()}>
            取消
          </styleds.CancelButton>
          <styleds.LogoutButton onClick={firebaseMe.acts.logout}>登出</styleds.LogoutButton>
        </styleds.ButtonGroupContent>
      </styleds.InforContent>
    </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${fill_vertical_cross_center};
    width: 336px;
    height: 360px;
    border-radius: 4px;
    gap: 16px;
    font-family: Replica, Arial, serif;
    font-weight: 600;
  `,
    TopContent: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 124px;
    background-color: #191946;
    background-image: linear-gradient(90deg, #191946cc, #191946cc, #19194655),
      url('sgx/logout-background.png');
    background-size: cover;
    color: #ffffff;
    gap: 16px;
    padding: 16px;
    border-radius: 4px 4px 0 0;
  `,
    InforContent: styled.div `
    ${fill_vertical_all_center};
    height: calc(100% - 124px);
    ${jc.spaceAround};
  `,
    Greeting: styled.div `
    ${fill_vertical};
    height: 40px;
    font-size: 32px;
    color: #1ac676;
    gap: 8px;
  `,
    UserName: styled.div `
    font-size: 24px;
    color: #ffffff;
  `,
    DateContent: styled.div `
    background-color: #f1f1f1;
    width: 80%;
    padding: 8px 16px;
    color: #1a1948;
  `,
    ButtonGroupContent: styled.div `
    ${fill_horizontal_all_center};
    gap: 32px;
    height: 48px;
  `,
    LogoutButton: styled.div `
    ${fill_vertical_all_center};
    width: 120px;
    height: 32px;
    background-color: #4192b0;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #51a2c0;
    }
  `,
    CancelButton: styled.div `
    ${fill_vertical_all_center};
    width: 120px;
    height: 32px;
    color: #18193c;
    border: 1px solid #f1f1f1;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  `,
};
