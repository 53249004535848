import { proxy } from 'valtio';
import { FuiSignals } from '~/pages/heineken_template/components/FuiSignals';
import { south_strategies } from './south_strategies';
export const south_store = proxy({
    signals: new FuiSignals({
        configs: south_strategies.map(strategy => strategy.config),
    }),
    symbol: '',
    currentPosition: 0,
    entryPrice: 0,
    exitPrice: 0,
    stoplossPrice: 0,
    entryTime: '',
    exitTime: '',
    side: '',
});
