import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const lung_power = createIndicator({
    displayName: '主力籌碼量能',
    id: 'lungpower',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const length_ma = 60;
            const diff_ma = 5;
            const close = this.ohlc.close;
            const open = this.ohlc.open;
            const low = this.ohlc.low;
            const high = this.ohlc.high;
            const close_array = this.ohlc.closeArray;
            const open_array = this.ohlc.openArray;
            const low_array = this.ohlc.lowArray;
            const high_array = this.ohlc.highArray;
            const avgOpen_array = this._context.new_var();
            const avgClose_array = this._context.new_var();
            open_array.get(1);
            close_array.get(1);
            const avgClose = (open + high + low + close) / 4;
            if (isNaN(avgClose)) {
                avgClose_array.set(close_array.get(1));
            }
            else {
                avgClose_array.set(avgClose);
            }
            const avg_ma = this.PineJS.Std.ema(avgClose_array, length_ma, this._context);
            const ma = this.PineJS.Std.sma(close_array, length_ma, this._context);
            const diff = avgClose_array.get(0) - avg_ma;
            const diff_array = this._context.new_var(diff);
            const ma_diff = this.PineJS.Std.ema(diff_array, diff_ma, this._context);
            let val0 = NaN;
            let val1 = NaN;
            if (ma_diff > 0) {
                val0 = ma_diff;
            }
            else
                val1 = ma_diff;
            return [val0, val1];
        },
    },
    metainfo: {
        is_price_study: !1,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#d75442',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#009900',
                },
            },
            precision: 2,
            inputs: {
            // in_0: 60,
            // in_1: 5,
            },
        },
        styles: {
            plot_0: {
                title: '+籌碼量能',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '-籌碼量能',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        precision: 0,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: '均線length',
        //   defval: 60,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_1',
        //   name: 'Difflength',
        //   defval: 5,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        ],
    },
});
