import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import KbarSymbolQuote from '../quote/KbarSymbolQuote';
import { edwinGroupName } from '../WatchListGroupName';
import { Chip, Select } from '@mantine/core';
import { FcCalendar } from 'react-icons/fc';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { edwin_store } from '../../edwin_store';
import dayAPI from '~/utils/dayAPI';
import { css } from '@emotion/react';
import { useMount } from 'react-use';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
export const ScreenerContent = memo(function ScreenerContent(props) {
    const value = props.quoteValue;
    const symbols = props.symbols;
    const state = useSnapshot(edwin_store);
    const tradeDateArray = useSnapshot(staticStore);
    //首次載入把選股日設為最近一個交易日
    useEffect(() => {
        edwin_store.screenerDate = dayAPI(tradeDateArray.tradedDate.day0).format('YYYY-MM-DD');
    }, [tradeDateArray.tradedDate.day0, tradeDateArray.tradedDate.days]);
    const historyDate = Array.from(new Set(tradeDateArray.tradedDate.days.map(s => s.format('YYYY-MM-DD'))));
    const dateTimeData = state.screenerType === 'history'
        ? historyDate
        : [tradeDateArray.tradedDate.intraday.format('YYYY-MM-DD')];
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'amount';
    });
    //---------------------------------------------------------------
    useEffect(() => {
        if (state.screenerType === 'realTime') {
            edwin_store.screenerDate = tradeDateArray.tradedDate.intraday.format('YYYY-MM-DD');
        }
        if (state.screenerType === 'history') {
            edwin_store.screenerDate = dateTimeData[0];
        }
    }, [state.screenerType, tradeDateArray.tradedDate.intraday]);
    const load = value.length === symbols?.length && symbols?.length !== 0;
    return (<styleds.container>
      <styleds.selecter>
        <Chip css={css `
            .mantine-hwb60 {
              width: 72px;
              height: 32px;
              background-color: #252931;
            }
          `} checked={state.screenerType === 'realTime'} onChange={() => (edwin_store.screenerType = 'realTime')} size={'xs'} radius='md' color='yellow'>
          即時
        </Chip>
        <Chip css={css `
            .mantine-hwb60 {
              width: 72px;
              height: 32px;
              background-color: #252931;
            }
          `} checked={state.screenerType === 'history'} onChange={() => (edwin_store.screenerType = 'history')} size={'xs'} radius='md' color='yellow'>
          歷史
        </Chip>

        <Select css={css `
            .mantine-58jpum {
              width: 160px;
              background-color: #292c33;
            }
          `} disabled={state.screenerType === 'realTime' ? true : false} icon={<FcCalendar size='24px'/>} defaultValue={dateTimeData[0]} value={state.screenerDate} data={dateTimeData} onChange={(date) => {
            edwin_store.screenerDate = date;
        }}/>
      </styleds.selecter>
      <styleds.header>
        <KbarSymbolQuote.header />
      </styleds.header>
      <styleds.body>
        {load &&
            value?.map((quote, index) => {
                return (<KbarSymbolQuote.body groupName={edwinGroupName} key={index} quote={quote}/>);
            })}
        {!load && <LoadCard.Loading />}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    selecter: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 36px;
  `,
    header: styled.div `
    width: 100%;
    height: 36px;
    flex-shrink: 0;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 80px);
    gap: 2px;
  `,
};
