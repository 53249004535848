import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fontWeight500 } from '~/css/font';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { EurexLogOutView } from '~/modules/investment-consultant/loginPage/EurexLogOutView';
import { store } from '~/pages/heineken_template/_private/store';
import { eurex_store } from './eurex_store';
export const Eurex_Topbar = memo(function Eurex_Topbar() {
    const { isPc } = useMedia();
    return (<div>
      {isPc && (<styleds.Topbar>
          <styleds.HeaderTitle>EUREX 歐交所商品觀測網站</styleds.HeaderTitle>
          <div css={css `
              ${fill_horizontal_all_center};
              width: 140px;
              gap: 16px;
            `}>
            <eurex_store.userAvatar.AvatarModal cssset={css `
                width: 336px;
                height: 360px;
              `}>
              <EurexLogOutView />
            </eurex_store.userAvatar.AvatarModal>
            <styleds.EurexLogo src='eurex/eurex-logo.png'/>
          </div>
        </styleds.Topbar>)}
      {!isPc && (<styleds.Topbar>
          <store.drawerLeft.DrawerToggle css={styleds.drawerCss}/>
          <div css={css `
              ${fill_horizontal_all_center};
              gap: 16px;
            `}>
            <div css={css `
                font-size: 14px;
              `}>
              EUREX 歐交所資訊觀測網站
            </div>
            <eurex_store.userAvatar.AvatarModal cssset={css `
                width: 336px;
                height: 360px;
              `}>
              <EurexLogOutView />
            </eurex_store.userAvatar.AvatarModal>
          </div>
          <store.drawerRight.DrawerToggle css={styleds.drawerCss}/>
        </styleds.Topbar>)}
    </div>);
});
const styleds = {
    Topbar: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    background-color: #1b1851;
    padding: 16px;
    width: 100%;
    height: 48px;
    position: fixed;
    z-index: 1;
    color: #ffffff;
    font-family: Replica, Arial, serif;
    font-weight: 600;
    -webkit-text-stroke: 0.3px #ffffff;
  `,
    EurexLogo: styled.img `
    margin-top: 36px;
    width: 60px;
    hight: 156px;
  `,
    HeaderTitle: styled.div `
    font-size: 20px;
    ${fontWeight500};
  `,
    drawerCss: css `
    height: 40px;
    width: 40px;
    color: #1ac777;
  `,
};
