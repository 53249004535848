import { memo } from 'react';
import { useSnapshot } from 'valtio';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const Display = memo(function Display(props) {
    const isActive = useSnapshot(store.charting).strategySelected.displayName === props.strategy.displayName;
    return (<FuiButton.Display {...props.FuiButtonProps} className={props.className} active={isActive} onClick={event => {
            store.charting.setStrategy(props.strategy);
            store.charting.updateFromStrategyState();
        }}>
      {props.children || props.strategy.displayName}
    </FuiButton.Display>);
});
export default {
    Display,
};
