import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { wu5868_initStrategies } from './wu5868_initStrategies';
import { Wu5868_MarqueeQuote } from './wu5868_MarqueeQuote';
import { Wu5868_SidePane1 } from './wu5868_SidePane1';
import { Wu5868_SidePane2 } from './wu5868_SidePane2';
import { Wu5868_Topbar } from './wu5868_Topbar';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const wu5856_init = (templateProps) => {
    //wu5856_initStyling(templateProps)
    wu5868_initStrategies();
    useThemeStore.setState({ theme: 'light' });
    store.charting.setThemeMode('light');
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: '2330',
        interval: '1D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: store.charting.lightOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 64px 1fr;
    grid-template-columns: 336px 336px 1fr;

    background-image: linear-gradient(to bottom, #16171c, #303237);

    grid-template-areas:
      'Row1 Row1 Row1'
      'Row3 Row3 Row3'
      'Drawer1 Drawer2 Chart'
      'Drawer1 Drawer2 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 64px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 64px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = <div></div>;
    templateProps.layout.Drawer1 = Wu5868_SidePane1;
    templateProps.layout.Drawer2 = Wu5868_SidePane2;
    templateProps.layout.Row3 = Wu5868_MarqueeQuote;
    templateProps.layout.Row1 = Wu5868_Topbar;
    templateProps.hooks.add(useSignalrStart2_0);
};
