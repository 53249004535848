import React, { memo } from 'react';
export const Line_svg = memo(function Line_svg() {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
      <g>
        <path d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z' fill='#00B900'></path>
        <path d='M12 5c-4.418 0-8 2.822-8 6.302 0 3.233 3.092 5.896 7.073 6.259a.487.487 0 01.325.212c.177.292.051.912-.086 1.608-.137.695.609.334.774.254.131-.064 3.514-1.972 5.527-3.844C19.086 14.648 20 13.06 20 11.302 20 7.822 16.419 5 12 5zm-2.667 7.952a.427.427 0 01-.43.423H7.441c-.258 0-.602-.09-.602-.508v-3.13a.427.427 0 01.43-.423h.086a.426.426 0 01.43.423v2.707h1.118a.426.426 0 01.43.423v.085zm1.206-.085a.43.43 0 11-.86 0v-3.04a.43.43 0 11.86 0v3.04zm3.784 0a.476.476 0 01-.43.424.486.486 0 01-.507-.243L12 11.103v1.764a.43.43 0 01-.86 0V9.822a.427.427 0 01.43-.423.544.544 0 01.488.31c.133.196 1.406 1.973 1.406 1.973v-1.86a.43.43 0 01.86 0v3.045h-.001zm2.58-1.945a.422.422 0 01.3.722.42.42 0 01-.3.124h-1.118v.677h1.119a.424.424 0 01.305.726.424.424 0 01-.305.12h-1.678a.382.382 0 01-.388-.38V9.78a.384.384 0 01.388-.38h1.678a.423.423 0 110 .845h-1.119v.677h1.119z' fill='#fff'></path>
      </g>
      <defs>
        <clipPath id='clip0_51878_68092'>
          <rect width='24' height='24' fill='#fff'></rect>
        </clipPath>
      </defs>
    </svg>);
});
export const Discord_svg = memo(function Discord_svg() {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
      <g>
        <path d='M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z' fill='#596AEA'></path>
        <path fillRule='evenodd' clipRule='evenodd' d='M16.515 17.978a.73.73 0 01-.62-.27l-.22-.266c-.247-.302-.1-.758.258-.914.17-.075.327-.154.473-.235.399-.224.16-.535-.27-.376a9.726 9.726 0 01-2.142.635 10.348 10.348 0 01-3.826-.015 12.396 12.396 0 01-2.171-.635 8.668 8.668 0 01-1.211-.576.193.193 0 01-.05-.034.225.225 0 00-.032-.023c-.095-.053-.174.05-.1.13.284.31.792.758 1.583 1.112.332.148.466.572.237.855l-.266.329a.753.753 0 01-.645.282c-1.232-.105-2.15-.506-2.8-.938-1.009-.67-1.34-1.926-1.207-3.128.252-2.298.918-4.253 1.418-5.462.363-.879.981-1.64 1.83-2.068 1.428-.722 2.615-.785 2.874-.79a.11.11 0 01.087.041.118.118 0 01-.058.187c-1.36.411-2.328.922-2.934 1.309-.126.08-.044.21.092.147 1.58-.694 2.836-.886 3.353-.93l.034-.006a1.24 1.24 0 01.217-.024 12.5 12.5 0 012.984-.03c.398.047.804.113 1.217.203.514.113.705-.262.21-.44-.137-.05-.278-.1-.425-.147a.16.16 0 01-.072-.257.146.146 0 01.114-.052c.286.007 1.441.08 2.83.775.87.435 1.5 1.212 1.87 2.111.497 1.206 1.15 3.137 1.403 5.403.137 1.221-.203 2.498-1.232 3.17-.655.43-1.575.825-2.803.927zm-7.248-6.877c-.842 0-1.507.739-1.507 1.64 0 .901.68 1.64 1.507 1.64.842 0 1.507-.739 1.507-1.64.015-.901-.665-1.64-1.507-1.64zm5.392 0c-.842 0-1.507.739-1.507 1.64 0 .901.68 1.64 1.507 1.64.842 0 1.507-.739 1.507-1.64 0-.901-.665-1.64-1.507-1.64z' fill='#fff'></path>
      </g>
      <defs>
        <clipPath id='clip0_51878_68089'>
          <rect width='24' height='24' fill='#fff'></rect>
        </clipPath>
      </defs>
    </svg>);
});
export const Tiwtter_svg = memo(function Tiwtter_svg() {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
      <g>
        <path d='M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z' fill='#1DA1F2'></path>
        <path d='M14.873 5.758a3.15 3.15 0 00-3.068 3.15c0 .246.026.487.08.718a8.947 8.947 0 01-6.496-3.292 3.149 3.149 0 00.976 4.207 3.144 3.144 0 01-1.428-.395v.04a3.153 3.153 0 002.528 3.09 3.17 3.17 0 01-1.423.054 3.153 3.153 0 002.942 2.188 6.317 6.317 0 01-3.912 1.35c-.254 0-.505-.015-.752-.044a8.915 8.915 0 004.83 1.416c5.797 0 8.966-4.802 8.966-8.966 0-.137-.002-.274-.008-.41a6.383 6.383 0 001.572-1.63 6.27 6.27 0 01-1.81.496 3.158 3.158 0 001.386-1.743 6.312 6.312 0 01-2.001.765 3.147 3.147 0 00-2.3-.994h-.082z' fill='#fff'></path>
      </g>
      <defs>
        <clipPath id='clip0_51878_68090'>
          <rect width='24' height='24' fill='#fff'></rect>
        </clipPath>
      </defs>
    </svg>);
});
export const Facebook_svg = memo(function Facebook_svg() {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
      <g>
        <path d='M18 4H6v17h12V4z' fill='#fff'></path>
        <path fillRule='evenodd' clipRule='evenodd' d='M10.306 18.528h2.698v-6.529h1.8l.238-2.25h-2.038l.002-1.126c0-.586.056-.901.898-.901h1.125v-2.25h-1.8c-2.162 0-2.923 1.092-2.923 2.927v1.35H8.958V12h1.348v6.529zM12 24C5.373 24 0 18.627 0 12 0 5.372 5.373 0 12 0s12 5.372 12 12c0 6.627-5.373 12-12 12z' fill='#4267B2'></path>
      </g>
      <defs>
        <clipPath id='clip0_51878_68091'>
          <rect width='24' height='24' fill='#fff'></rect>
        </clipPath>
      </defs>
    </svg>);
});
export const Telegram_svg = memo(function Telegram_svg() {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
      <g>
        <path d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z' fill='#08C'></path>
        <path d='M19.001 6.758a9.43 9.43 0 01-.214 1.117l-2.012 9.504a1.58 1.58 0 01-.18.526.584.584 0 01-.69.297 1.382 1.382 0 01-.516-.249c-1.012-.753-2.028-1.496-3.038-2.253a.197.197 0 00-.31.02c-.498.498-1.007.982-1.504 1.473a.887.887 0 01-.691.294.346.346 0 01-.367-.277c-.31-.975-.629-1.946-.947-2.92-.107-.346-.218-.66-.318-.992a.29.29 0 00-.22-.218c-.99-.304-1.978-.615-2.97-.926a1.548 1.548 0 01-.377-.156.346.346 0 01-.058-.64 1.78 1.78 0 01.58-.324l4.048-1.562 8.74-3.37.07-.028c.552-.221.97.066.974.684zM9.86 16.54c.056-.028.049-.063.052-.09.066-.712.135-1.42.194-2.133a.595.595 0 01.207-.432c1.897-1.703 3.792-3.411 5.686-5.125.12-.107.242-.215.345-.325.049-.049.11-.1.073-.177-.038-.076-.121-.076-.197-.065a.95.95 0 00-.39.162c-2.328 1.477-4.66 2.95-7 4.42a.215.215 0 00-.103.301c.29.868.57 1.729.853 2.603.104.283.198.57.28.86z' fill='#FDFDFD'></path>
      </g>
      <defs>
        <clipPath id='clip0_51878_68088'>
          <rect width='24' height='24' fill='#fff'></rect>
        </clipPath>
      </defs>
    </svg>);
});
