import { memo } from 'react';
import { SymbolList } from './symbolList';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useGetContinuousColor } from '~/modules/screener/containers/useStockScreenerResource';
export const ConditionStrong = memo(function ConditionStrong() {
    const intraDay = useSnapshot(staticStore).tradedDate.day0;
    const data = useGetContinuousColor({
        date: intraDay.format('YYYY-MM-DD'),
        days: 3,
        order_by: 'desc',
        rg: 'r',
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 5e8,
        amount_lte: 0,
    });
    const symbol = data.data
        ?.filter(datum => datum.change_percent > 1)
        .map(datum => datum.symbol)
        .slice(0, 5) ?? [];
    return (<SymbolList symbol={symbol} titleFillColor='#c85d4a'>
      強勢飆股
    </SymbolList>);
});
