import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
//DC 雙階梯線
export const pionex_strategy01 = createIndicator({
    id: 'pionex-strategy01',
    displayName: 'pionex_strategy01',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.ohlc.close;
            const open = this.ohlc.open;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close_array = this.ohlc.closeArray;
            const ma = this.PineJS.Std.sma(close_array, 120, this._context);
            const dc1 = this.ladderChannl(14);
            const dc2 = this.ladderChannl(46);
            const ma140 = this.PineJS.Std.sma(close_array, 140, this._context);
            const ma7 = this.PineJS.Std.sma(close_array, 7, this._context);
            const nwma77 = this.PineJS.Std.vwma(close_array, 77, this._context);
            const fast = this.PineJS.Std.wma(close_array, 7, this._context);
            const slow = this.PineJS.Std.wma(close_array, 14, this._context);
            const dif = fast - slow;
            const dif_array = this._context.new_var(dif);
            const dem = this.PineJS.Std.ema(dif_array, 9, this._context);
            const osc = dif - dem;
            const position = context.new_var();
            const entryPrice = context.new_var();
            const entryType = context.new_var();
            position.get(1);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            const buy_exit_icon = NaN;
            const short_exit_icon = NaN;
            // ---------------------------------------- Condition ----------------------------------------
            const longEntry = dc1[4] > dc2[4] && osc >= 0 && ma7 > ma140 && close > ma7 && entryType.get(0) !== 1;
            const shortEntry = dc1[4] < dc2[4] && osc <= 0 && ma7 < ma140 && close < ma7 && entryType.get(0) !== -1;
            // ---------------------------------------- Entry ----------------------------------------
            if (longEntry)
                position.set(1);
            if (shortEntry)
                position.set(-1);
            if (position.get(0) === 1 && position.get(1) !== 1) {
                entryPrice.set(close);
                entryType.set(1);
                buy_icon = 1;
            }
            if (position.get(0) === -1 && position.get(1) !== -1) {
                entryPrice.set(close);
                entryType.set(-1);
                short_icon = 1;
            }
            // ---------------------------------------- Exit ----------------------------------------
            if (position.get(0) === 1 && position.get(1) === 1) {
                if (high < nwma77 && high > entryPrice * 1.001) {
                    buy_target_icon = 1;
                    position.set(0);
                }
            }
            if (position.get(0) === -1 && position.get(1) === -1) {
                if (low > nwma77 && low < entryPrice * 0.999) {
                    short_target_icon = 1;
                    position.set(0);
                }
            }
            const colorer = () => {
                return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2;
            };
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                buy_exit_icon,
                short_exit_icon,
                colorer(),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag',
                type: 'shapes',
            },
            {
                id: 'entryShortFlag',
                type: 'shapes',
            },
            {
                id: 'targetLongFlag',
                type: 'shapes',
            },
            {
                id: 'targetShortFlag',
                type: 'shapes',
            },
            {
                id: 'exitLongFlag',
                type: 'shapes',
            },
            {
                id: 'exitShortFlag',
                type: 'shapes',
            },
            {
                id: '部位',
                type: 'bar_colorer',
                palette: 'barPalette',
            },
        ],
        defaults: {
            styles: {
                entryLongFlag: { color: '#f04848', textColor: '#f04848', transparency: 10, visible: true },
                entryShortFlag: { color: '#00b070', textColor: '#00b070', transparency: 10, visible: true },
                targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
                targetShortFlag: {
                    color: '#00b070',
                    textColor: '#fafafa',
                    transparency: 20,
                    visible: true,
                },
                exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
                exitShortFlag: { color: '#00b070', textColor: '#fafafa', transparency: 20, visible: true },
            },
            inputs: {},
        },
        styles: {
            entryLongFlag: {
                title: '買進',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_triangle_up',
                size: 'small',
                text: 'Buy',
            },
            entryShortFlag: {
                title: '賣出',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_triangle_down',
                size: 'small',
                text: 'Short',
            },
            targetLongFlag: {
                title: '買進停利',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_label_down',
                size: 'tiny',
                text: 'Target',
            },
            targetShortFlag: {
                title: '賣出停利',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_label_up',
                size: 'tiny',
                text: 'Target',
            },
            exitLongFlag: {
                title: '買進出場',
                isHidden: false,
                location: 'BelowBar',
                plottype: 'shape_label_up',
                size: 'tiny',
                text: 'Exit',
            },
            exitShortFlag: {
                title: '賣出出場',
                isHidden: false,
                location: 'AboveBar',
                plottype: 'shape_label_down',
                size: 'tiny',
                text: 'Exit',
            },
        },
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#b2b5be',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
