import { daddy960_tc1688_qqTony } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_qqTony';
import { daddy960_tc1688_turtle } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_turtle';
import { valueProvider } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_valueProvider';
import { store } from '../heineken_template/_private/store';
import { daddy960_tc1688_macdImpluseTv } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_macdImpluseTv';
import { daddy960_tc1688_heikinAshiLine1 } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_heikinAshiLine1';
import { daddy960_tc1688_heikinAshiLine2 } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_heikinAshiLine2';
const DEFAULT_SYMBOL = 'XAUUSD';
const tc1688Indicators1 = [
    //daddy960_tc1688_heikinAshi,
    daddy960_tc1688_heikinAshiLine1,
    //daddy960_tc1688_heikinAshi2,
    daddy960_tc1688_heikinAshiLine2,
    daddy960_tc1688_macdImpluseTv,
    valueProvider,
];
const tc1688Indicators2 = [daddy960_tc1688_turtle, daddy960_tc1688_qqTony, valueProvider];
export const daddy960_tc1688_strategyGroup = {
    main: [
        {
            displayName: 'tc1688_1',
            indicators: tc1688Indicators1,
            interval: '60',
            panesRatio: 80,
            //calcFrom: dayAPI().subtract(14, 'day'),
        },
        {
            displayName: 'tc1688_2',
            indicators: tc1688Indicators2,
            interval: '60',
            panesRatio: 80,
        },
    ],
};
export const daddy960_tc1688_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...tc1688Indicators1,
        ...tc1688Indicators2,
    ];
    store.charting.initStrategy({
        configs: [...daddy960_tc1688_strategyGroup.main],
    });
    store.charting.changeSymbol(DEFAULT_SYMBOL);
};
