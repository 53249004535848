import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import IntradayTrendChart from '~/modules/trendChart';
import Styleds from './styleds';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import PriceDiffChart from '~/modules/SDK/priceDiffChart';
import PercentDiffChart from '~/modules/SDK/percentDiffChart';
import usePriceDiffValue from '~/modules/SDK/priceDiffChart/usePriceDiffValue';
import { usePercentDiffValue } from '~/modules/SDK/percentDiffChart/usePercentDiffValue';
import { TechnologyLine, TechnologyWind } from './technology_Component';
export const Lung88988_SidePane2 = memo(function SidePane1(props) {
    const charting = useSnapshot(store.charting);
    const chartPosition = useSnapshot(useChartPositionStore);
    const theme = useThemeStore(s => s.theme);
    const { diffValue, diffUpdateTime } = usePriceDiffValue();
    const { changPercent1, changPercent2, changPercentUpdateTime } = usePercentDiffValue('TSE13', 'TSE17');
    return (<Styleds.Sidebar className={theme}>
      <Styleds.SymbolInfoTitle>
        <TitleSymbolQuote.Default symbol='TSEA'/>
      </Styleds.SymbolInfoTitle>
      <Styleds.TrendChartContainer>
        <IntradayTrendChart symbol={'TSEA'} ticksSize={14} ticksHeight={20} priceTicksMargin={2} priceTicksSize={14}/>
      </Styleds.TrendChartContainer>
      <TechnologyLine transform={0}/>

      <Styleds.SymbolInfoTitle>
        <TitleSymbolQuote.Default symbol='TSE13'/>
      </Styleds.SymbolInfoTitle>
      <Styleds.TrendChartContainer>
        <IntradayTrendChart symbol={'TSE13'} ticksSize={14} ticksHeight={20} priceTicksMargin={2} priceTicksSize={14}/>
      </Styleds.TrendChartContainer>
      <TechnologyLine transform={0}/>

      <Styleds.SymbolInfoTitle>
        <TitleSymbolQuote.Default symbol='TSE17'/>
      </Styleds.SymbolInfoTitle>
      <Styleds.TrendChartContainer>
        <IntradayTrendChart symbol={'TSE17'} ticksSize={14} ticksHeight={20} priceTicksMargin={2} priceTicksSize={14}/>
      </Styleds.TrendChartContainer>
      <TechnologyLine transform={0}/>

      <Styleds.SubTitle>
        台指期與加權指數價差: {diffValue}
        <Styleds.SubText>更新時間{diffUpdateTime}</Styleds.SubText>
      </Styleds.SubTitle>

      <TechnologyWind styledNumber={2} height={124}>
        <PriceDiffChart symbol1='TXAM-1' symbol2='TSEA'/>
      </TechnologyWind>

      <Styleds.SubTitle>
        電金指數漲跌幅比較
        <Styleds.SidebarSubItemGroup>
          <Styleds.SidebarSubItemValue className='TSE13'>
            電子 {changPercent1}%
          </Styleds.SidebarSubItemValue>
          <Styleds.SidebarSubItemValue className='TSE17'>
            金融 {changPercent2}%
          </Styleds.SidebarSubItemValue>
        </Styleds.SidebarSubItemGroup>
      </Styleds.SubTitle>

      <TechnologyWind styledNumber={2} height={124}>
        <PercentDiffChart.dispaly symbol1='TSE13' symbol2='TSE17'/>
      </TechnologyWind>
    </Styleds.Sidebar>);
});
