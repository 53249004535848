import { css } from '@emotion/react';
import TrendTable from '../eurex/_private/component/TrendTable';
import { store } from '../heineken_template/_private/store';
export const daddy960_tc1688_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.candleStyle.downColor': '#00ff00',
        'mainSeriesProperties.candleStyle.upColor': '#ff0000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.wickUpColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'paneProperties.vertGridProperties.color': '#363636',
        'paneProperties.horzGridProperties.color': '#363636',
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#16161e',
        'paneProperties.legendProperties.showSeriesOHLC': true,
        'timeScale.rightOffset': 5,
        'scalesProperties.fontSize': 16,
        'scalesProperties.textColor': '#f8f9f9',
        'scalesProperties.showSeriesLastValue': true,
        'scalesProperties.showStudyLastValue': true,
    };
    templateProps.globalCssset = css `
    #__next {
      ${TrendTable.classes.title} {
        background-color: #202026;
        border-radius: 4px;
      }

      ${(TrendTable.classesFill.upFill = css `
        &.dark {
          color: #ff0000;
        }
      `)}

      ${(TrendTable.classesFill.dnFill = css `
        &.dark {
          color: #00ff00;
        }
      `)}
    }
  `;
};
