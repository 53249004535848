import { memo, useEffect, useRef } from 'react';
import OptionChart from './component/OptionChart';
import { OptionTable } from './component/OptionTable';
import { OptionContractSelect } from './component/OptionContractSelect';
import { OptionEndTradeTimeSelect, OptionStartTradeTimeSelect, } from './component/OptionTradeTimeSelect';
import { styleds } from './styleds';
import { css } from '@emotion/react';
import { horizontalScrollbarCss } from '~/css/scrollbarCss';
import { optionAnalyzeStore } from './component/optionAnalyze/optionAnalyzeStore';
import { useGetOptionContract } from './component/optionAnalyze/useGetOptionContract';
import { useSnapshot } from 'valtio';
import { getOptionIntradayEndDate } from './component/optionAnalyze/getOptionContractDateTime';
import { staticStore } from '../heineken_template/_private/staticStore';
import { fill } from '~/modules/AppLayout/FlexGridCss';
export const Opkevin_Col = memo(function Opkevin_Col() {
    const scrollAreaRef = useRef(null);
    const state = useSnapshot(optionAnalyzeStore);
    //先設最近交易日結束區間
    const intraday = useSnapshot(staticStore).tradedDate.intraday;
    const endDateTime = getOptionIntradayEndDate(intraday);
    // 這邊先一開始先拿選擇權
    /** 契約代號 */
    const defaultContract = useGetOptionContract(endDateTime).default ?? '';
    /** 近期所有契約代號 */
    const contractMonth = useGetOptionContract(endDateTime).contractMonth ?? [''];
    /** 近期契約對應的結算日 */
    const allContractSettlementDate = useGetOptionContract(endDateTime).settlementDate ?? { ['']: '' };
    /** default契約的結算日 */
    const settlementDate = allContractSettlementDate?.[state.currentContract] ?? '';
    useEffect(() => {
        // 首次render將option store基本的資訊set好
        if (defaultContract !== '') {
            optionAnalyzeStore.currentContract = defaultContract;
            optionAnalyzeStore.allContractSettlementDate = allContractSettlementDate;
            optionAnalyzeStore.allContract = contractMonth;
        }
        //defaultContract拿到後,其他資料都會拿到,所以Dependency只放defaultContract
    }, [defaultContract]);
    useEffect(() => {
        optionAnalyzeStore.currentSettlementDate = settlementDate;
    }, [state.currentContract]);
    useEffect(() => {
        if (scrollAreaRef.current) {
            const scrollArea = scrollAreaRef.current;
            const center = (scrollArea.scrollWidth - scrollArea.clientWidth) / 2;
            scrollArea.scrollLeft = center;
        }
    }, []);
    console.log({
        currentContract: defaultContract,
        currentSettlementDate: settlementDate,
        intraday: intraday.format('YYYY/MM/DD'),
        endDateTime: endDateTime.format('YYYY/MM/DD HH:mm'),
        contractStartDateTime: state.contractStartDateTime.format('YYYY/MM/DD HH:mm'),
    });
    //試用到期判斷
    // const [freeTrial, setFreeTrial] = useState(true)
    // const expiredDate = '2023/03/11 06:00'
    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if (dayAPI().format('YYYY/MM/DD HH:mm') > expiredDate) {
    //       setFreeTrial(false)
    //     }
    //   }, 60000)
    //   return () => clearInterval(interval)
    // }, [])
    return (<styleds.col>
      {/* {freeTrial !== true && <NoPermissionsView />} */}

      <div css={fill}>
        <styleds.selecterBoard>
          <styleds.selecterContract>
            <OptionContractSelect />
          </styleds.selecterContract>

          <styleds.selecterDateTime>
            <OptionStartTradeTimeSelect />
          </styleds.selecterDateTime>

          <styleds.selecterDateTime>
            <OptionEndTradeTimeSelect />
          </styleds.selecterDateTime>
        </styleds.selecterBoard>

        <div css={css `
            width: 100%;
            height: 200px;
          `}>
          <OptionChart.display />
        </div>
        <div ref={scrollAreaRef} css={css `
            ${horizontalScrollbarCss};
            width: 100%;
            height: calc(100% - 264px);
          `}>
          <OptionTable />
        </div>
      </div>
    </styleds.col>);
});
//試用到期畫面 土炮刻 opkevin_init permissions 2023/03/11 00:00
// const NoPermissionsView = memo<ReactProps>(function NoPermissionsView() {
//   return (
//     <div
//       css={css`
//         ${fill_vertical_all_center};
//         height: 150%;
//         width: 999%;
//         position: fixed;
//         z-index: 10000;
//         margin-top: -60px;
//         background: linear-gradient(90deg, #121212, #353535);
//         background-size: 100%;
//       `}
//     >
//       <div
//         css={css`
//           display: flex;
//           position: fixed;
//           flex-direction: column;
//           align-items: center;
//           right: 50%;
//           top: 50%;
//           transform: translate(50%, -50%);
//           background-color: #54545454;
//           border-radius: 5px;
//           padding: 16px;
//           gap: 8px;
//         `}
//       >
//         <h3>免費體驗已結束</h3>
//         <UnregisteredView />
//         <a
//           href='https://liff.line.me/1645278921-kWRPP32q/?accountId=367wxcpl'
//           target={'_blank'}
//           rel='noreferrer'
//         >
//           <img
//             src='/futuresai/index/line.png'
//             css={css`
//               width: 64px;
//             `}
//           />
//         </a>
//       </div>
//     </div>
//   )
// })
