import { css } from '@emotion/react';
import SymbolOverviewChart from '~/modules/investment-consultant/symbolList/overView/SymbolOverviewChart';
import SymbolSimpleCard from '~/modules/investment-consultant/symbolList/body/SymbolCardSimpleBody';
import { quoteBasicStyled } from '~/modules/investment-consultant/symbolList/css/quoteBasicStyled';
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar';
export const investment_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__next {
      ${SymbolOverviewChart.classes.container} {
      }
      ${(quoteBasicStyled.valueDnFill = css `
        &.dark {
          color: #00cc00;
        }
        &.light {
          color: #22aa22;
        }
      `)}
      //調整報價 漲/跌 顏色
      ${(SymbolSimpleCard.classesFill.upFill = css `
        &.dark {
          color: #ff0000;
        }
        &.light {
          color: #ff6666;
        }
      `)}
      //調整報價 漲/跌 顏色
      ${(SymbolSimpleCard.classesFill.dnFill = css `
        &.dark {
          color: #00ff00;
        }
        &.light {
          color: #55cc55;
        }
      `)}
      //調整報價 漲停/跌停 背景顏色
        ${(SymbolSimpleCard.classesFill.limitUpFill = css `
        &.dark {
          background-color: #ff0000;
        }
        &.light {
          background-color: #ff6666;
        }
        > div {
          color: #ffffff;
        }
      `)}
      //調整報價 漲停/跌停 背景顏色
      ${(SymbolSimpleCard.classesFill.limitDnFill = css `
        &.dark {
          background-color: #00ff00;
        }
        &.light {
          background-color: #55cc55;
        }
        > div {
          color: #ffffff;
        }
      `)}
      ${(SymbolRankingHorizontalBar.classesFill.horizontalBarUpFill = '#cc00aa')}
      ${(SymbolRankingHorizontalBar.classesFill.horizontalBarDnFill = '#00aacc')}
    }
  `;
};
