import { proxy } from 'valtio';
import { FuiUserAvatar } from '~/pages/heineken_template/components/FuiUserAvatar';
import dayAPI from '~/utils/dayAPI';
export const edwin_store = proxy({
    //sidepan1 模式
    mode: 'screener',
    userAvatar: new FuiUserAvatar(),
    displayType: 'card',
    industryType: 'default',
    screenerType: 'realTime',
    trustType: 'buy',
    screenerDate: '',
    quoteValueContainer: [],
    trustDate: dayAPI(),
});
