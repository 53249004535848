import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const win9882_attack_trend = createIndicator({
    id: 'win9882-attack-trend',
    displayName: '攻擊區',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const f_0 = function (e, t, i) {
                return (e - t) / (0.015 * i);
            };
            const i = this.PineJS.Std.hlc3(this._context);
            const r = 20; //this._input(0)
            const o = this._context.new_var(i);
            const s = this.PineJS.Std.sma(o, r, this._context);
            const a = this._context.new_var(i);
            const l = this.PineJS.Std.dev(a, r, this._context);
            const line_up = 40; //this._input(1)
            const line_dn = -40; //this._input(2)
            const value = f_0(i, s, l);
            const value_array = this._context.new_var(value);
            const value_color = value_array.get(0) > line_up ? 0 : value_array.get(0) < line_dn ? 1 : 2;
            return [value, line_up, line_dn, value_color];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        is_price_study: !1,
        scriptIdPart: '',
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 4,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#2196F3',
                },
                plot_1: {
                    linestyle: 2,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#333333',
                },
                plot_2: {
                    linestyle: 2,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#333333',
                },
            },
            palettes: {
                palette_0: {
                    colors: {
                        0: {
                            color: '#df484c',
                            width: 2,
                            style: 0,
                        },
                        1: {
                            color: '#5cb642',
                            width: 2,
                            style: 0,
                        },
                        2: {
                            color: '#888888',
                            width: 2,
                            style: 0,
                        },
                    },
                },
            },
            filledAreasStyle: {
                fill_0: {
                    color: '#eeeeee',
                    transparency: 90,
                    visible: !0,
                },
            },
            inputs: {
            // in_0: 20,
            // in_1: 40,
            // in_2: -40,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: '多方顏色',
                    },
                    1: {
                        name: '空方顏色',
                    },
                    2: {
                        name: '多空不明',
                    },
                },
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'colorer',
                target: 'plot_0',
                palette: 'palette_0',
            },
        ],
        styles: {
            plot_0: {
                title: '金頭腦指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '上界線',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '下界線',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        filledAreas: [
            {
                id: 'fill_0',
                objAId: 'plot_1',
                objBId: 'plot_2',
                type: 'plot_plot',
                title: '界線區間顏色',
            },
        ],
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'length',
        //   defval: 20,
        //   type: 'integer',
        //   min: 1,
        //   max: 2e3,
        // },
        // {
        //   id: 'in_1',
        //   name: 'length',
        //   defval: 40,
        //   type: 'integer',
        //   min: 1,
        //   max: 300,
        // },
        // {
        //   id: 'in_2',
        //   name: 'length',
        //   defval: -40,
        //   type: 'integer',
        //   min: -300,
        //   max: -1,
        // },
        ],
        precision: 4,
    },
});
