import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Checkbox, Divider, NumberInput, Slider } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { BsFillInfoCircleFill, BsSliders } from 'react-icons/bs';
import { MdAddCircle } from 'react-icons/md';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
export const TradeBoard = memo(function TradeBoard() {
    const charting = useSnapshot(store).charting;
    const [orderContract, setOrderContract] = useState(25);
    const crypto = charting.symbol.replace('USDT', '');
    useEffect(() => {
        setOrderContract(orderContract);
    }, [orderContract]);
    return (<styleds.container href='https://www.pionex.com/zh-TW/futures/BTC.PERP_USDT/Bot' target={'_blank'} rel='noreferrer'>
      <styleds.qtyTypeContant>
        <styleds.orderTypeButton>全倉</styleds.orderTypeButton>
        <styleds.orderTypeButton>5x</styleds.orderTypeButton>
        <styleds.adjustmentButton>
          <BsSliders />
        </styleds.adjustmentButton>
      </styleds.qtyTypeContant>

      <styleds.orderTypeContent>
        <span css={css `
            font-weight: 800;
            color: #000;
          `}>
          全倉
        </span>
        <span>市價單</span>
        <span>條件單</span>
        <span>分倉買賣</span>
        <BsFillInfoCircleFill fill={'#81838a'}/>
      </styleds.orderTypeContent>

      <styleds.displayContractContent>{charting.symbol + '數量'}</styleds.displayContractContent>
      <NumberInput defaultValue={orderContract} value={orderContract} placeholder='數量' min={1} max={100} step={1} onChange={(val) => setOrderContract(val ?? 0)} formatter={(value) => value + '%'}/>
      <styleds.orderContractSliderContent>
        <Slider color='gray' size='xs' radius='xl' css={css `
            width: 100%;
            .mantine-15mcosn {
              font-size: 12px;
            }
          `} defaultValue={orderContract} showLabelOnHover={false} min={1} max={100} step={1} value={orderContract} onChange={value => setOrderContract(value)} marks={[
            { value: 25, label: '25%' },
            { value: 50, label: '50%' },
            { value: 75, label: '75%' },
        ]}/>
      </styleds.orderContractSliderContent>
      <styleds.orderContractInputContent>
        <span>
          <span>買入</span>
          <span css={css `
              color: #f04848;
            `}>
            0.000 {crypto}
          </span>
        </span>
        <span>
          <span>賣出</span>
          <span css={css `
              color: #00b070;
            `}>
            0.000 {crypto}
          </span>
        </span>
      </styleds.orderContractInputContent>
      <styleds.orderContractInputContent>
        <span>可用保證金</span>
        <span css={css `
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            color: #252525;
          `}>
          <MdAddCircle size={14}/>
          0.000 USDT
        </span>
      </styleds.orderContractInputContent>
      <Divider my='sm'/>

      <styleds.AdjustContent>
        <Checkbox label='僅減倉' color='orange'/>
      </styleds.AdjustContent>
      <styleds.AdjustContent>
        <Checkbox label='只盈止損' color='orange'/>
      </styleds.AdjustContent>

      <styleds.topupButton>充值</styleds.topupButton>
      <div>
        <styleds.accountInfoContent>
          <span>可買 0.000 BTC</span>
          <span>可賣 0.000 BTC</span>
        </styleds.accountInfoContent>
        <styleds.accountInfoContent>
          <span>保證金 0.00 USDT</span>
          <span>保證金 0.00 USDT</span>
        </styleds.accountInfoContent>
        <styleds.accountInfoContent>
          <span>預估強平價 0.0 USDT</span>
          <span>預估強平價 0.0 USDT</span>
        </styleds.accountInfoContent>
      </div>
    </styleds.container>);
});
const styleds = {
    container: styled.a `
    ${fill_vertical_cross_center};
    padding: 4px;
    font-size: 14px;
    color: #71737a;
    & > * {
      width: 100%;
    }
    &:hover {
      background-color: #f9f9f9;
    }
  `,
    //倉位選擇
    qtyTypeContant: styled.div `
    ${fill_horizontal_all_center};
    height: 28px;
    gap: 8px;
    color: #252525;
  `,
    //下單方式選擇 (全倉 市價單 條件單 分倉買賣)
    orderTypeContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 36px;
    gap: 12px;
    color: #252525;
  `,
    //顯示選取合約的數量
    displayContractContent: styled.div `
    width: 100%;
    height: 24px;
    font-size: 12px;
    color: #71737a;
  `,
    //滑動選取合約數量
    orderContractSliderContent: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 48px;
  `,
    //數字輸入選取合約數量
    orderContractInputContent: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    height: 28px;
    font-size: 12px;
  `,
    //下單種類按鈕
    orderTypeButton: styled.div `
    ${fill_vertical_all_center};
    width: 140px;
    height: 28px;
    background-color: #f2f3f5;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #e4e7ed;
    }
  `,
    //設定下單種類按鈕
    adjustmentButton: styled.div `
    ${fill_vertical_all_center};
    background-color: #f2f3f5;
    border-radius: 4px;
    height: 28px;
    width: 28px;
    cursor: pointer;
    &:hover {
      background-color: #e4e7ed;
    }
  `,
    //儲值按鈕
    topupButton: styled.div `
    ${fill_vertical_all_center};
    background-color: #eb4747;
    color: #fafafa;
    height: 36px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background-color: #ee6262;
    }
  `,
    //check box 設定專用
    AdjustContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 28px;
    color: #71737a;
  `,
    //帳戶資金資訊
    accountInfoContent: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    height: 28px;
    font-size: 12px;
    color: #71737a;
  `,
};
