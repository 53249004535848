import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import FuiButton from '../heineken_template/components/FuiButton';
import { store } from '../heineken_template/_private/store';
import { jerry597_store } from './jerry597_store';
import { useThemeStore } from '~/components/theme/useThemeStore';
export const Jerry597_Row = memo(function Jerry597_Row() {
    const theme = useThemeStore(state => state.theme);
    const state = useSnapshot(jerry597_store);
    const { isPc } = useMedia();
    const fill = isPc ? fill_horizontal_cross_center : fill_horizontal_all_center;
    return (<div className={theme} css={css `
        ${fill};
        &.dark {
          background-color: #232222;
          color: #ffffff;
        }
        &.light {
          background-color: #f7f7f7;
          color: #262626;
        }
        gap: 4px;
        & > * {
          width: 160px;
          height: 32px;
        }
      `}>
      <FuiButton.Display variant='long' active={state.strategy === 'long'} onClick={() => {
            jerry597_store.strategy = 'long';
            store.charting.updateFromState();
        }}>
        多方策略
      </FuiButton.Display>
      <FuiButton.Display variant='short' active={state.strategy === 'short'} onClick={() => {
            jerry597_store.strategy = 'short';
            store.charting.updateFromState();
        }}>
        空方策略
      </FuiButton.Display>
      <FuiButton.Display active={state.strategy === 'default'} onClick={() => {
            jerry597_store.strategy = 'default';
            store.charting.updateFromState();
        }}>
        多空策略
      </FuiButton.Display>
    </div>);
});
