import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { fontWeight500 } from '~/css/font';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, pureGrid, } from '~/modules/AppLayout/FlexGridCss';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { fadeIn } from '~/modules/investment-consultant/optionChart/SideBarOptionChart';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { SocialLinkGroup } from '../sgx_Footer';
export const SgxLoginPage = memo(function SgxLoginPage(props) {
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const { isPhone } = useMedia();
    const View = memo(function View() {
        const auth = useFirebaseMeState.useContainer();
        const router = useRouter();
        return (<div css={css `
          ${fill_horizontal_cross_center};
          ${fontWeight500};
          padding: 0px 16px;
          width: 240px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 8px;
          border: 1px solid #aaaaaa;
          color: #7b7b7b;
          gap: 4px;
          cursor: pointer;
          &:hover {
            background-color: #f1f1f1;
          }
        `} onClick={() => {
                auth.acts.loginWithGoogle().then(() => {
                    router.reload();
                });
            }}>
        <img css={css `
            width: 32px;
          `} src='google-icon.png'/>
        Continue with Google
      </div>);
    });
    return (<styleds.Container className={props.className}>
      <styleds.TopBar>
        <img css={css `
            width: 148px;
            height: 40px;
          `} src='sgx/sgx-white.png'/>
      </styleds.TopBar>
      <styleds.Body>
        <styleds.LoginCard css={css `
            width: ${isPhone ? '92%' : '400px'};
          `}>
          <styleds.TitleContent>
            <styleds.Title>
              <styleds.icon />
              新加坡交易所
            </styleds.Title>
            <styleds.Title>商品觀測報告</styleds.Title>
          </styleds.TitleContent>
          <styleds.LonginTip>登入即可免費使用</styleds.LonginTip>
          <styleds.LonginView>{!meFirebaseState && <View />}</styleds.LonginView>
          <styleds.socialContent>
            關注我們
            <SocialLinkGroup />
          </styleds.socialContent>
          <styleds.Copyright>©新加坡交易所。版權所有。</styleds.Copyright>
        </styleds.LoginCard>
      </styleds.Body>
    </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${fill_vertical_cross_center};
    ${pureGrid};
    grid-template-rows: 72px 1fr;
    font-family: Lato, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
    position: fixed;
    z-index: 1300;
    background-color: #fefeff;
  `,
    TopBar: styled.div `
    ${fill_horizontal_cross_center};
    width: 100%;
    height: 72px;
    border-bottom: 2px solid #222222;
    background-color: #112267;
    padding: 0 16px;
  `,
    Body: styled.div `
    ${fill_horizontal_all_center};
    background: url('/sgx/sgx-mastbanner.jpeg');
    background-repeat: no-repeat;
    background-size: 132%;
    background-position: bottom;
  `,
    LoginCard: styled.div `
    ${pureGrid};
    grid-template-rows: repeat(8, 12.5%);
    width: 400px;
    height: 90%;
    background-color: #fafafa;
    border: 1px solid #cacaca;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 #c5cbce;
    animation: ${fadeIn} 2s;
    & > * {
      ${fill_vertical_all_center};
    }
  `,
    Copyright: styled.span `
    ${fill_horizontal_all_center}
    grid-row: 8;
    font-size: 14px;
    color: #252525;
  `,
    icon: styled.div `
    background: #a4dd36;
    margin-top: 5px;
    width: 18px;
    height: 18px;
    -webkit-box-shadow: 7px 5px 0 #091f60;
    box-shadow: 7px 5px 0 #091f60;
    margin-right: 16px;
    margin-bottom: 8px;
  `,
    TitleContent: styled.div `
    grid-row: 1 / 3;
    background-image: url('sgx/derivatives.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  `,
    Title: styled.span `
    font-size: 32px;
    color: #112267;
    font-weight: 600;
  `,
    LonginTip: styled.span `
    grid-row: 4;
    font-size: 24px;
    color: #112267;
    font-weight: 600;
  `,
    LonginView: styled.div `
    grid-row: 5;
  `,
    socialContent: styled.div `
    grid-row: 7;
    color: #112267;
    & > * {
      ${fill_horizontal_all_center};
    }
  `,
};
