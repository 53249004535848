import { css } from '@emotion/react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const m168168_initStyling = (templateProps) => {
    templateProps.globalCssset = css `
    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 4px;
        height: 32px;
      }
      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #333333;
        border: 1px solid #555555;
        &:hover {
          background-color: #3a3a3a;
          border: 1px solid #777777;
          color: #eeeeee;
          transition: 0.5s;
        }
    }
    ${FuiButton.classes.button.default.Active} {
      color: #ffffff;
      background-color: #5a5a5a;
      border: 1px solid #bbbbbb;
      transition: 0.5s;
      &:hover {
        background-color: #4a4a4a;
        border: 1px solid #bbbbbb;
        color: #eeeeee;
        transition: 0.5s;
      }
    }
  `;
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#ffffff',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ee534f',
        'mainSeriesProperties.candleStyle.downColor': '#ffffff',
        'mainSeriesProperties.candleStyle.upColor': '#b61c1c',
        'mainSeriesProperties.candleStyle.wickDownColor': '#ffffff',
        'mainSeriesProperties.candleStyle.wickUpColor': '#ee534f',
        'mainSeriesProperties.candleStyle.drawBorder': true,
        'mainSeriesProperties.highLowAvgPrice.highLowPriceLabelsVisible': true,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': false,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#444444',
        'paneProperties.horzGridProperties.color': '#444444',
        'paneProperties.backgroundType': 'gradient',
        'paneProperties.backgroundGradientStartColor': '#131625',
        'paneProperties.backgroundGradientEndColor': '#000000',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#cccccc',
        'scalesProperties.lineColor': '#cccccc',
    };
};
