import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const jerry597_stockSubChart = createIndicator({
    id: 'jerry597-stockSubChart',
    displayName: '金旺股票指標',
    filterOnTheme: 'dark',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const fastLength = this._input(0);
            const slowLength = this._input(1);
            const macdLength = this._input(2);
            const macd = this.macd(fastLength, slowLength, macdLength);
            const oscRed = macd.osc > 0 ? macd.osc : NaN;
            const oscGreen = macd.osc < 0 ? macd.osc : NaN;
            return [oscRed, oscGreen];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'positive', type: 'line' },
            { id: 'negative', type: 'line' },
        ],
        defaults: {
            styles: {
                positive: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff1744',
                },
                negative: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#2196f3',
                },
            },
            precision: 2,
            inputs: {
                Param1: 12,
                Param2: 26,
                Param3: 9,
            },
        },
        styles: {
            positive: {
                title: 'OSC',
                histogramBase: 0,
                joinPoints: false,
            },
            negative: {
                title: 'OSC',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [
            {
                id: 'Param1',
                name: 'Fast Length',
                defval: 12,
                type: 'integer',
                min: 1,
                max: 500,
                step: 1,
            },
            {
                id: 'Param2',
                name: 'Slow Length',
                defval: 26,
                type: 'integer',
                min: 1,
                max: 500,
                step: 1,
            },
            {
                id: 'Param3',
                name: 'MACD Length',
                defval: 9,
                type: 'integer',
                min: 1,
                max: 500,
                step: 1,
            },
        ],
    },
});
export const jerry597_stockSubChart_light = jerry597_stockSubChart.duplicate({
    id: 'jerry597-stockSubChart--light',
    filterOnTheme: 'light',
    metainfo: {
        defaults: {
            styles: {
                positive: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff1744',
                },
                negative: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#2196f3',
                },
            },
        },
    },
});
