import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, flex } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { useDatePick } from '~/modules/screener/useDatePick';
import { store } from '~/pages/heineken_template/_private/store';
export const God_Topbar = memo(function God_Topbar() {
    const { isPc } = useMedia();
    const lastTradeDate = useDatePick();
    return (<div>
      {isPc && (<div css={css `
            ${flex.h.allCenter}
            justify-content: end;
            background-color: #273455;
            width: 100%;
            height: 100%;
          `}>
          <ChartingServerSwitch charting={store.charting} css={css `
              background-color: #16161e;
            `}/>
          <UserAvatarAsDialogButton />
        </div>)}
      {!isPc && (<Preset_Topbar css={css `
            background-color: #273455;
          `} showLeftBurger showRightBurger componentsInRight={<div css={fill_horizontal_all_center}>
              <ChartingServerSwitch charting={store.charting} css={css `
                  background-color: #16161e;
                `}/>
              <UserAvatarAsDialogButton />
            </div>}></Preset_Topbar>)}
    </div>);
});
