import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { crocodiles } from './crocodiles';
import dayAPI from '~/utils/dayAPI';
export const god_intradayLong = createIndicator({
    id: 'god-intradayLong',
    displayName: '當沖策略(多)',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const _calcIndicator = (params) => {
                const { biasLength, percentRLength, rsiLength, kdLength } = params;
                const series = context.new_var(crocodiles(resolution, this.bias(biasLength), this.percentR(percentRLength), this.rsi(close, rsiLength), this.stochastic(kdLength, 3, 3).k));
                series.get(100);
                return series;
            };
            const resolution = context.symbol.period;
            //#region Prepare data
            const position = context.new_var();
            const entriesToday = context.new_var();
            const close = this.ohlc.closeArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const volume = context.new_var(this.PineJS.Std.volume(context));
            const datetime = dayAPI(this.PineJS.Std.time(context));
            position.get(1);
            entriesToday.get(100);
            close.get(100);
            high.get(100);
            low.get(100);
            volume.get(100);
            //#endregion
            //#region Calc indicators
            const volumeThreshold = this.average(volume, 6) + this.average(volume, 12);
            const biasLength = 12;
            const percentRLength = 13;
            const rsiLength = 6;
            const kdLength = 9;
            //#region Params 1Min
            let params1Min = {
                biasLength: 0,
                percentRLength: 0,
                rsiLength: 0,
                kdLength: 0,
            };
            if (resolution === '1') {
                params1Min = {
                    biasLength: biasLength,
                    percentRLength: percentRLength,
                    rsiLength: rsiLength,
                    kdLength: kdLength,
                };
            }
            else if (resolution === '5') {
                params1Min = {
                    biasLength: Math.ceil(biasLength / 5),
                    percentRLength: Math.ceil(percentRLength / 5),
                    rsiLength: Math.ceil(rsiLength / 5),
                    kdLength: Math.ceil(kdLength / 5),
                };
            }
            else if (resolution === '15') {
                params1Min = {
                    biasLength: Math.ceil(biasLength / 15),
                    percentRLength: Math.ceil(percentRLength / 15),
                    rsiLength: Math.ceil(rsiLength / 15),
                    kdLength: Math.ceil(kdLength / 15),
                };
            }
            //#endregion
            //#region Params 5Min
            let params5Min = {
                biasLength: 0,
                percentRLength: 0,
                rsiLength: 0,
                kdLength: 0,
            };
            if (resolution === '1') {
                params5Min = {
                    biasLength: biasLength * 5,
                    percentRLength: percentRLength * 5,
                    rsiLength: rsiLength * 5,
                    kdLength: kdLength * 5,
                };
            }
            else if (resolution === '5') {
                params5Min = {
                    biasLength: biasLength,
                    percentRLength: percentRLength,
                    rsiLength: rsiLength,
                    kdLength: kdLength,
                };
            }
            else if (resolution === '15') {
                params5Min = {
                    biasLength: Math.ceil(biasLength / 3),
                    percentRLength: Math.ceil(percentRLength / 3),
                    rsiLength: Math.ceil(rsiLength / 3),
                    kdLength: Math.ceil(kdLength / 3),
                };
            }
            //#endregion
            //#region Params 15Min
            let params15Min = {
                biasLength: 0,
                percentRLength: 0,
                rsiLength: 0,
                kdLength: 0,
            };
            if (resolution === '1') {
                params15Min = {
                    biasLength: biasLength * 15,
                    percentRLength: percentRLength * 15,
                    rsiLength: rsiLength * 15,
                    kdLength: kdLength * 15,
                };
            }
            else if (resolution === '5') {
                params15Min = {
                    biasLength: biasLength * 3,
                    percentRLength: percentRLength * 3,
                    rsiLength: rsiLength * 3,
                    kdLength: kdLength * 3,
                };
            }
            else if (resolution === '15') {
                params15Min = {
                    biasLength: biasLength * 15,
                    percentRLength: percentRLength * 15,
                    rsiLength: rsiLength * 15,
                    kdLength: kdLength * 15,
                };
            }
            //#endregion
            const indicator1Min = _calcIndicator(params1Min);
            const indicator5Min = _calcIndicator(params5Min);
            const indicator15Min = _calcIndicator(params15Min);
            //#endregion
            //#region Conditions
            const timeCondition = datetime.hour() <= 13 && datetime.hour() >= 8;
            const timeConditionPM = (datetime.hour() >= 15 && datetime.hour() < 24) ||
                (datetime.hour() >= 0 && datetime.hour() <= 4);
            const longEntry = (timeCondition || timeConditionPM) &&
                indicator1Min.get(0) < 15 &&
                indicator5Min.get(0) < 15 &&
                indicator15Min.get(0) < 15 &&
                volume.get(0) > volumeThreshold;
            //#endregion
            let entryLongFlag = NaN;
            if (longEntry) {
                position.set(1);
            }
            // ---------------------------------------- Entry ----------------------------------------
            if (position.get(0) === 1 && position.get(1) !== 1) {
                entryLongFlag = 1;
            }
            if (position.get(1) === 1)
                position.set(0);
            return [entryLongFlag];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        inputs: [],
        plots: [{ id: 'entryFlagLong', type: 'chars' }],
        defaults: {
            precision: 2,
            inputs: {},
            styles: {
                entryFlagLong: { color: '#FF0000', textColor: '#FF0000', transparency: 20, visible: true },
            },
        },
        styles: {
            entryFlagLong: {
                title: '進場點(多)',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多單進場',
            },
        },
    },
});
