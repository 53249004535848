import { store } from '~/pages/heineken_template/_private/store';
import { eurex_main_trend } from '~/trades/indicators/eurex/eurex_main_trend';
import { eurex_power } from '~/trades/indicators/eurex/eurex_power';
import { eurex_trend_line } from '~/trades/indicators/eurex/eurex_trend_line';
import { eurexIndicatorValueProvider } from '~/trades/indicators/example/eurexIndicatorValueProvider';
export const eurex_strategiesGroup = {
    main: [
        {
            displayName: '-',
            indicators: [eurexIndicatorValueProvider, eurex_trend_line, eurex_main_trend, eurex_power],
            symbol: 'FDX-1',
            interval: '60',
            panesRatio: 72,
        },
    ],
};
export const eurex_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[eurexIndicatorValueProvider, eurex_trend_line, eurex_main_trend, eurex_power],
    ];
    store.charting.initStrategy({
        configs: [...eurex_strategiesGroup.main],
    });
};
