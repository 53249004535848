import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 成交量 */
export const imoney8888_volume = createIndicator({
    displayName: '成交量',
    id: 'imoney8888-volume',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const vol = this.PineJS.Std.volume(this._context);
            const display_volume = vol > 10e7 ? vol / 10e7 : vol;
            const vol_array = this._context.new_var(display_volume);
            vol_array.get(2);
            //20ma均線
            const avg_ma1 = this.PineJS.Std.sma(vol_array, 20, this._context);
            //近30天最大量
            const maxVolume = this.PineJS.Std.highest(vol_array, 30, this._context);
            const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1;
            const colorIndexMax = vol < maxVolume * 0.5 ? 0 : 1;
            const color = vol >= vol_array.get(1) ? 0 : 1;
            return [display_volume, color];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'vol',
                type: 'line',
            },
            {
                id: 'volumePalette',
                type: 'colorer',
                target: 'vol',
                palette: 'volumePalette',
            },
        ],
        palettes: {
            volumePalette: {
                colors: {
                    0: {
                        name: '多方趨勢',
                    },
                    1: {
                        name: '空方趨勢',
                    },
                },
            },
        },
        defaults: {
            //樣式調整
            styles: {
                //--
                plot_0: {
                    transparency: 0,
                    visible: !0,
                },
                //--
                vol: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            //填充區域
            palettes: {
                volumePalette: {
                    colors: {
                        0: {
                            color: '#fe9700',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#fe9700',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
            inputs: {},
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
        precision: 0,
    },
});
