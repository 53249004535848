import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { imoney8888_store } from '~/pages/imoney8888/_private/imoney8888_store';
import dayAPI from '~/utils/dayAPI';
export const imoney8888_signal = createIndicator({
    displayName: '示範策略',
    id: 'imoney8888-signal',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const positionType = imoney8888_store.holdingPositionType;
            //主要均線
            const length = this._input(0);
            //diff差值區間
            const diffRange = 0.07;
            //最大量統計區間日期
            const dayRange = 30;
            //秀出近期出現訊號的商品
            const showDay = 94;
            const itime = this.PineJS.Std.time(this._context);
            const iyear = new Date(itime).getFullYear();
            const close = this.PineJS.Std.close(this._context);
            const close_array = this.ohlc.closeArray;
            const low_array = this.ohlc.lowArray;
            const high_array = this.ohlc.highArray;
            const open = this.ohlc.open;
            //
            const maSeason = this.PineJS.Std.sma(close_array, 60, this._context);
            const maMounth = this.PineJS.Std.sma(close_array, 20, this._context);
            const maSeason_array = this._context.new_var(maSeason);
            const maMounth_array = this._context.new_var(maMounth);
            //
            const ma5 = this.PineJS.Std.sma(close_array, 5, this._context);
            const ma10 = this.PineJS.Std.sma(close_array, 10, this._context);
            const ma20 = this.PineJS.Std.sma(close_array, 20, this._context);
            //
            const diff = ((ma5 - ma10) / ma5) * 10;
            const diff_array = this._context.new_var(diff);
            const volume = this.PineJS.Std.volume(this._context);
            const volume_array = this._context.new_var(volume);
            //近期n天最大成交量
            const maxVolume = this.PineJS.Std.highest(volume_array, dayRange, this._context);
            const maxVolume_array = this._context.new_var(maxVolume);
            const marketPosition = this._context.new_var();
            const entryPrice = this._context.new_var();
            const stopLossPriceLong = this._context.new_var();
            const stopLossPriceShort = this._context.new_var();
            const plotStopLoss = this._context.new_var();
            const hotFlage = this._context.new_var();
            const firstEntry = this._context.new_var();
            //
            let buy_icon = NaN;
            let short_icon = NaN;
            let longHot_icon = NaN;
            //let target2_long_icon = NaN
            let shortHot_icon = NaN;
            //let target2_short_icon = NaN
            let exit_long_icon = NaN;
            let exit_short_icon = NaN;
            stopLossPriceLong.get(2);
            stopLossPriceShort.get(2);
            marketPosition.get(2);
            diff_array.get(2);
            close_array.get(5);
            low_array.get(5);
            high_array.get(5);
            maMounth_array.get(5);
            maSeason_array.get(5);
            volume_array.get(2);
            maxVolume_array.get(2);
            firstEntry.get(2);
            plotStopLoss.get(2);
            //雙均線上揚
            const twoMaLineTypeLong = maSeason_array.get(1) > maSeason_array.get(2) &&
                maMounth_array.get(1) > maMounth_array.get(2);
            //雙均線下彎
            const twoMaLineTypeShort = maSeason_array.get(1) < maSeason_array.get(2) &&
                maMounth_array.get(1) < maMounth_array.get(2);
            //找出近期n天最大量並設定最大量*0.5的位置
            const smallVolumeLong = volume_array.get(1) < maxVolume_array.get(1) * 0.5;
            //通用條件: diff區間 量縮
            const conditioin = diff_array.get(1) <= diffRange && diff_array.get(1) >= -diffRange;
            //成交量篩選
            const volumeCondition = volume_array.get(1) > 50 && volume_array.get(1) < 10000;
            //策略邏輯----------------------------------------------
            const showEntry = iyear >= 2022 && dayAPI(itime) > dayAPI(new Date()).subtract(showDay, 'days');
            const showIcon = itime > firstEntry.get(0) && !isNaN(firstEntry.get(0));
            //多方進場
            if (marketPosition.get(1) !== -1 &&
                twoMaLineTypeLong &&
                conditioin &&
                smallVolumeLong &&
                volumeCondition) {
                marketPosition.set(1);
            }
            //空方進場
            if (marketPosition.get(1) !== 1 && twoMaLineTypeShort && conditioin && volumeCondition) {
                marketPosition.set(-1);
            }
            //多單進場設定進場價格
            if (marketPosition.get(1) !== 1 && marketPosition.get(0) === 1) {
                const stoplossLong = low_array.get(1) * 0.97;
                if (showEntry) {
                    if (positionType !== 'short') {
                        buy_icon = 1;
                        hotFlage.set(0);
                    }
                    plotStopLoss.set(stoplossLong);
                }
                if (showEntry && isNaN(firstEntry.get(0))) {
                    firstEntry.set(itime);
                }
                entryPrice.set(open);
                stopLossPriceLong.set(stoplossLong);
            }
            //空單進場設定進場價格
            if (marketPosition.get(1) !== -1 && marketPosition.get(0) === -1) {
                if (showEntry) {
                    if (positionType !== 'long') {
                        short_icon = 1;
                        hotFlage.set(0);
                    }
                    plotStopLoss.set(high_array * 1.03);
                }
                if (showEntry && isNaN(firstEntry.get(0))) {
                    firstEntry.set(itime);
                }
                entryPrice.set(open);
                stopLossPriceShort.set(high_array.get(1) * 1.03);
            }
            //多單出場邏輯
            if (marketPosition.get(1) === 1 && marketPosition.get(0) === 1) {
                //顯示過熱邏輯
                if (hotFlage.get(0) === 0 &&
                    close > entryPrice.get(0) * 1.03 &&
                    diff_array.get(0) < diff_array.get(1) &&
                    volume_array.get(0) < volume_array.get(1)) {
                    if (showIcon && positionType !== 'short') {
                        longHot_icon = 1;
                    }
                    hotFlage.set(1);
                }
                //(獲利超過3%開始執行停利 小於20ma閃人)
                // console.log(
                //   dayAPI(itime).format('YYYY-MM-DD'),
                //   close_array.get(1) < maMounth_array.get(1),
                //   close_array.get(1) > entryPrice.get(0) * 1.03,
                // )
                if (close_array.get(1) < maMounth_array.get(1) * 0.95 &&
                    close_array.get(1) > entryPrice.get(0) * 1.03) {
                    if (showIcon && positionType !== 'short') {
                        exit_long_icon = 1;
                    }
                    marketPosition.set(0);
                    hotFlage.set(0);
                    plotStopLoss.set(NaN);
                }
                //(停損)
                if (close_array.get(1) < stopLossPriceLong.get(0)) {
                    if (showIcon && positionType !== 'short') {
                        exit_long_icon = 1;
                    }
                    marketPosition.set(0);
                    plotStopLoss.set(NaN);
                }
            }
            //空單出場邏輯
            if (marketPosition.get(1) === -1 && marketPosition.get(0) === -1) {
                // //顯示過熱邏輯
                if (hotFlage.get(0) === 0 &&
                    close > entryPrice.get(0) * 0.97 &&
                    diff_array.get(0) < diff_array.get(1) &&
                    volume_array.get(0) < volume_array.get(1)) {
                    if (showIcon && positionType !== 'long') {
                        shortHot_icon = 1;
                    }
                    hotFlage.set(1);
                }
                //(獲利超過3%開始執行停利 大於20ma閃人)
                if (close_array.get(1) > maMounth_array.get(1) * 1.05 &&
                    close_array.get(1) < entryPrice.get(0) * 0.97) {
                    if (showIcon && positionType !== 'long') {
                        exit_short_icon = 1;
                    }
                    marketPosition.set(0);
                    hotFlage.set(0);
                    plotStopLoss.set(NaN);
                }
                //(停損)
                if (close_array.get(1) > stopLossPriceShort.get(0)) {
                    if (showIcon && positionType !== 'long') {
                        exit_short_icon = 1;
                    }
                    marketPosition.set(0);
                    plotStopLoss.set(NaN);
                }
            }
            //colorIndex
            const colorIndex = () => {
                if (marketPosition.get(0) === 1 && marketPosition.get(1) === 1) {
                    return NaN;
                }
                else if (marketPosition.get(0) === -1 && marketPosition.get(1) === -1) {
                    return NaN;
                }
                else
                    return NaN;
            };
            return [
                maMounth,
                buy_icon,
                short_icon,
                longHot_icon,
                shortHot_icon,
                exit_long_icon,
                exit_short_icon,
                showEntry ? plotStopLoss.get(0) : NaN,
                colorIndex(),
            ];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'line',
            },
            {
                id: '狀態',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        styles: {
            plot_0: {
                title: '20ma',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                isHidden: false,
                location: 'BelowBar',
                char: '🐣',
                size: 'small',
                text: '',
                title: '金雞參考訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '☃️',
                size: 'small',
                text: '',
                title: '雪人參考訊號',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '🔥',
                size: 'small',
                text: '',
                title: '過熱參考',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                char: '❄️',
                size: 'small',
                text: '',
                title: '過冷參考',
            },
            plot_6: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'tiny',
                text: '',
                title: '強制出場',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'tiny',
                text: '',
                title: '強制出場',
            },
            plot_8: {
                title: '停損參考',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: false,
                    color: '#78b3f0',
                },
                plot_2: {
                    color: '#ff9700',
                    textColor: '#ff9700',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#00b2b3',
                    textColor: '#00b2b3',
                    transparency: 0,
                    visible: true,
                },
                plot_4: {
                    color: '#ff9700',
                    textColor: '#ff9700',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#00b2b3',
                    textColor: '#00b2b3',
                    transparency: 0,
                    visible: true,
                },
                plot_6: {
                    color: '#f99703',
                    textColor: '#f99703',
                    transparency: 0,
                    visible: false,
                },
                plot_7: {
                    color: '#00b2b3',
                    textColor: '#00b2b3',
                    transparency: 0,
                    visible: false,
                },
                plot_8: {
                    linestyle: 2,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: !1,
                    transparency: 35,
                    visible: true,
                    color: '#ff0000',
                },
            },
            inputs: {},
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#f19d38',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#00ffaa',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
