import { memo } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, Cell } from 'recharts-new';
import { useThemeStore } from '~/components/theme/useThemeStore';
const StockChangePercentGradeChart = memo(function StockChangePercentChart({ data }) {
    //原有資料,漲跌幅級距為1
    const mData = Array.from({ length: 21 }, (_, i) => ({ count: 0, percentage: i - 10 }));
    data.forEach(d => (mData[parseInt(d[0]) + 10] = { count: d[1], percentage: parseInt(d[0]) }));
    //為了減少閱讀障礙將漲跌幅的%數級距調整為2
    const result = [];
    const result2 = [];
    //從最小數字合併排序到大
    //(-10,-9)(-8,-7)(-6,-5)(-4,-3)(-2,-1)
    mData.forEach(item => {
        if (item.percentage < 0) {
            const index = Math.floor(item.percentage / 2) + 5;
            if (!result[index]) {
                result[index] = { count: item.count, percentage: item.percentage };
            }
            else {
                //console.log('小於0', item.percentage)
                result[index].count += item.count;
            }
        }
    });
    //從最大數字合併排序到小
    //(10,9)(8,7)(6,5)(4,3)(2,1)(0)
    mData.reverse().forEach(item => {
        if (item.percentage >= 0) {
            const index = Math.round(item.percentage / 2) + 5;
            if (!result2[index]) {
                result2[index] = { count: item.count, percentage: item.percentage };
            }
            else {
                // console.log('大於0', item.percentage)
                result2[index].count += item.count;
            }
        }
    });
    const newData = result.concat(result2).filter(s => s);
    const theme = useThemeStore(s => s.theme);
    /** 文字與座標顏色 */
    const defaultFill = theme === 'light' ? '#555555' : '#dddddd';
    return (<ResponsiveContainer width='100%' height='100%'>
        <BarChart data={newData} margin={{ top: 8, left: 2, right: 2, bottom: 2 }}>
          <XAxis interval={0} dataKey='percentage' tickLine={false} stroke={defaultFill} fontSize={12} tickFormatter={ticks => ticks + '%'}/>

          <Bar dataKey='count' isAnimationActive={false} radius={1} 
    //label={{ content: <CustomizedLabel /> }}
    label={{ position: 'top', fill: defaultFill, fontSize: 12 }}>
            {newData.map((entry, index) => (<Cell key={`cell-${index}`} fill={fill123(entry.percentage)}/>))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>);
});
const fill123 = (change) => {
    if (change === 0)
        return classesFill.horizontalBarDefaultFill;
    else if (change > 0 && change < 2)
        return classesFill.horizontalBarUpFill1;
    else if (change >= 2 && change < 4)
        return classesFill.horizontalBarUpFill2;
    else if (change >= 4 && change < 6)
        return classesFill.horizontalBarUpFill3;
    else if (change >= 6 && change < 8)
        return classesFill.horizontalBarUpFill4;
    else if (change >= 8 && change <= 10)
        return classesFill.horizontalBarUpFill5;
    else if (change < 0 && change > -2)
        return classesFill.horizontalBarDnFill1;
    else if (change <= -2 && change > -4)
        return classesFill.horizontalBarDnFill2;
    else if (change <= -4 && change > -6)
        return classesFill.horizontalBarDnFill3;
    else if (change <= -6 && change > -8)
        return classesFill.horizontalBarDnFill4;
    else if (change <= -8 && change >= -10)
        return classesFill.horizontalBarDnFill5;
};
const classesFill = {
    horizontalBarDefaultFill: '#bbbbbb',
    horizontalBarUpFill1: '#FFCDD2',
    horizontalBarUpFill2: '#EF9A9A',
    horizontalBarUpFill3: '#E57373',
    horizontalBarUpFill4: '#EF5350',
    horizontalBarUpFill5: '#F44336',
    horizontalBarDnFill1: '#C8E6C9',
    horizontalBarDnFill2: '#A5D6A7',
    horizontalBarDnFill3: '#81C784',
    horizontalBarDnFill4: '#66BB6A',
    horizontalBarDnFill5: '#4CAF50',
};
export default {
    display: StockChangePercentGradeChart,
    classesFill,
};
